import React, { useContext, useEffect, useState } from "react";
import Footer from "../../Common/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useErrorBoundary } from "preact/hooks";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import Config from "../../Config";
import Alerts from "../../Alerts";
import axios from "axios";
import Commonheader from "../../Common/Commonheader";
import { UserContext } from "../../Contexts/UserContext";
import { useAuthHandling } from "../../Common/useAuthHandling";

function TemporaryCreateuser() {
  // let params = useParams();
  let { hotelname, hotelid } = useParams();
  let navigate = useNavigate();

  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const [active, setActive] = useState("en");
  const [role, setRole] = useState([]);
  const [be_language, setBe_language] = useState([]);
  const [be_languagefull, setBe_languagefull] = useState([]);

  const language = localStorage.getItem("be_language");
  const [alert, setAlert] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [formerror, setError] = useState({});
  const [formerror1, setError1] = useState({});
  const [formerror2, setError2] = useState({});
  const [formerror3, setError3] = useState({});
  const [formerror4, setError4] = useState({});
  const [formerror5, setError5] = useState({});

  const [defaultval, setDefaultval] = useState([]);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [adminuser, setAdminuser] = useState({
    user_name: "",
    surname: "",
    email: "",
    // password: "",
    image: "",
    // c_password: "",
  });
  const [country, setCountry] = useState([]);
  const [roledata, setRoledata] = useState("");
  const [langdata, setLangdata] = useState("");
  const [countrydata, setCountrydata] = useState("");
  const [hotelidnew, setHotelidnew] = useState(
    hotel_selectid ? hotel_selectid : ""
  );

  const getRole = async (e) => {
    if (language === null) {
      const res = await fetch(`${Config.apiurl}role-list`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data1 = await res.json();
      const data = data1.data;

      if (data1.status_code !== "200" || !data) {
        console.log("error");
      } else {
        setRole(data);
      }
    } else {
      const res = await fetch(`${Config.apiurl}role-list`, {
        method: "POST",
        body: JSON.stringify({
          language: language,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data1 = await res.json();
      const data = data1.data;

      if (data1.status_code !== "200" || !data) {
        console.log("error");
      } else {
        setRole(data);
      }
    }
  };

  const getlanguage = async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data.data) {
      console.log("error");
    } else {
      setBe_language(data.data);
    }
  };

  const getfulllanguage = async (e) => {
    const res = await fetch(`${Config.apiurl}booking-engine-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data.data) {
      console.log("error");
    } else {
      setBe_languagefull(data.data);
    }
  };

  const getcountry = async (e) => {
    const res = await fetch(`${Config.apiurl}country-list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data.data) {
      console.log("error");
    } else {
      setCountry(data.data);
    }
  };

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setAdminuser({ ...adminuser, [name]: value });
  };
  const handleInputdata = (roledata) => {
    setRoledata(roledata);
  };

  const handleInputlang = (langdata) => {
    setLangdata(langdata);
  };
  const handleInputcountry = (countrydata) => {
    setCountrydata(countrydata);
  };
  const [selectedMulti, setselectedMulti] = useState([]);
  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }
  const [selectedMulti1, setselectedMulti1] = useState([]);
  function handleMulti1(selectedMulti1) {
    setselectedMulti1(selectedMulti1);
  }

  console.log(defaultval);
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regex1 =
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[~`¿¡!#$%^&*€£@+÷=\-\[\]\\';,/{}|\\":<>\?\._])./;

    if (!values.user_name) {
      errors.user_name = "Name is required!";
    }
    if (!values.surname) {
      errors.surname = "Surname is required!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    // if (!values.password) {
    //   errors.password = "Password is required!";
    // } else if (!regex1.test(values.password)) {
    //   errors.password =
    //     "Password must contain at least one number, one lowercase letter, one uppercase letter and one special character!";
    // } else if (values.password.length < 8) {
    //   errors.password = "The password must be at least 8 characters!";
    // }
    // if (!values.c_password) {
    //   errors.c_password = "Confirm password is required!";
    // } else if (values.password !== values.c_password) {
    //   errors.c_password = "The provided passwords do not match!";
    // }

    // if (!values.c_password) {
    //   errors.c_password = "Confirm password is required!";
    // }

    // if(values.password &&values.c_password){
    //     if(values.password!==values.c_password){
    //         errors.c_password = "Confirm password must be same as password!";

    //     }
    // }

    return errors;
  };

  const validatelang = (values) => {
    const errors = {};
    if (!values) {
      errors.lang = "Language is required!";
    }

    return errors;
  };
  const validatecountry = (values) => {
    const errors = {};
    if (!values) {
      errors.country = "Country is required!";
    }

    return errors;
  };
  const validateselect = (values) => {
    const errors = {};
    if (values.length === 0) {
      errors.langselect = "Language is required!";
    }

    return errors;
  };
  const validatestatus = (values) => {
    const errors = {};
    if (!values) {
      errors.status = "Status is required!";
    }

    return errors;
  };
  const PostData = async (e) => {
    e.preventDefault();

    const ress = validate(adminuser);
    const resslang = validatelang(langdata);
    const resscountry = validatecountry(countrydata);
    const ressselect = validateselect(selectedMulti);
    const ressstatus = validatestatus(status);

    setError(ress);
    setError2(resslang);
    setError3(resscountry);
    setError4(ressselect);
    setError5(ressstatus);

    if (
      !ress.user_name &&
      !ress.surname &&
      !ress.email &&
      // !ress.password &&
      // !ress.c_password &&
      !ressstatus.status &&
      !resslang.lang &&
      !resscountry.country &&
      !ressselect.langselect
    ) {
      const formData = new FormData();
      formData.append("en_name", adminuser.user_name);
      formData.append("en_surname", adminuser.surname);
      // formData.append("password", adminuser.password);
      formData.append("email", adminuser.email);
      // formData.append("password_confirmation", adminuser.c_password);
      formData.append("is_active", status);

      formData.append("profile_image", adminuser.image);
      formData.append("role", defaultval.value);
      formData.append("hotel", hotelid);

      formData.append("default_language", langdata.value);
      for (let i = 0; i < selectedMulti.length; i++) {
        formData.append(`optional_language[${i}]`, selectedMulti[i].value);
      }
      // formData.append("optional_language", per);
      formData.append("country", countrydata.value);
      for (let i = 0; i < selectedMulti1.length; i++) {
        if (selectedMulti1[i].name) {
          if (selectedMulti1[i].code !== "en") {
            formData.append(
              `multi_lang[${i}][lang_code]`,
              selectedMulti1[i].code
            );
            formData.append(
              `multi_lang[${i}][fields][name]`,
              selectedMulti1[i].name
            );
            formData.append(
              `multi_lang[${i}][fields][surname]`,
              selectedMulti1[i].surname
            );
          }
        }
      }
      // formData.append("multi_lang", lang);
      axios
        .post(`${Config.apiurl}hotel/user/add`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.status_code || res.status_code === "200") {
            //    if(res.status===true){
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("success");
            Reset();
            if (hotelidnew) {
              setTimeout(() => navigate("/hotel-user-list"), 3000);
            } else {
              setTimeout(() => navigate("/temporary-property"), 3000);
            }
          } else {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("error");
          }
        });
    }
  };

  const onFileChange = (e) => {
    let name = e.target.name;
    setAdminuser({ ...adminuser, [name]: e.target.files[0] });
  };

  function Reset() {
    setAdminuser({
      user_name: "",
      surname: "",
      email: "",
      // password: "",
      // c_password: "",
      image: "",
    });
    setRoledata("");
    setLangdata("");
    setCountrydata("");
    setselectedMulti([]);
    setselectedMulti1([]);
    setStatus("");
  }

  useEffect(() => {
    getRole();
    getlanguage();
    getcountry();
    getfulllanguage();
    const defaultOption = role.find((option) => option.label === "Hotel User");
    setDefaultval(defaultOption);
  }, [language, role.length]);

  useEffect(() => {
    setHotelidnew(hotel_selectid);
  }, [hotel_selectid]);

  return (
    <div id="layout-wrapper">
      <Commonheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Hotel USER</h4>
                </div>
              </div>
            </div>
            {alert ? (
              <Alerts type={alerttype} message={alertmessage} open={open} />
            ) : (
              ""
            )}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create New Hotel User
                      </h4>
                      <div className="flex-shrink-0">
                        {hotelidnew ? (
                          <Link
                            type="button"
                            className="btn db-other-button"
                            to="/hotel-user-list"
                            style={{ marginRight: "5px" }}
                          >
                            Extranet Users
                          </Link>
                        ) : (
                          <Link
                            type="button"
                            className="btn db-other-button"
                            to="/temporary-property"
                            style={{ marginRight: "5px" }}
                          >
                            Hotel Creation
                          </Link>
                        )}
                        {/* <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to=""
                          onClick={Reset}
                        >
                          Cancel
                        </button> */}
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          onClick={PostData}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-translation-style">
                                <ul>
                                  <li
                                    className={active === "en" ? "active" : ""}
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => setActive("en")}
                                      className={
                                        active === "en" ? "active" : ""
                                      }
                                    >
                                      EN
                                    </Link>
                                  </li>
                                  <li
                                    className={
                                      active === "trans" ? "active" : ""
                                    }
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => setActive("trans")}
                                      className={
                                        active === "trans" ? "active" : ""
                                      }
                                    >
                                      Translations
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {active === "en" ? (
                              <>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Name
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={adminuser.user_name}
                                      name="user_name"
                                      onChange={handleInput}
                                    />
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.user_name}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Last Name
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={adminuser.surname}
                                      name="surname"
                                      onChange={handleInput}
                                    />
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.surname}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Email
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder=""
                                      value={adminuser.email}
                                      name="email"
                                      onChange={handleInput}
                                    />
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.email}
                                    </label>
                                  </div>
                                </div>

                                {/* <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Password
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      placeholder=""
                                      value={adminuser.password}
                                      name="password"
                                      onChange={handleInput}
                                    />
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.password}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Confirm Password
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                      placeholder=""
                                      value={adminuser.c_password}
                                      name="c_password"
                                      onChange={handleInput}
                                    />
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.c_password}
                                    </label>
                                  </div>
                                </div> */}
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Profile Image
                                      {/* <span className="form-validation-required">
                                    *
                                  </span> */}
                                    </label>
                                    <input
                                      type="file"
                                      className="form-control"
                                      placeholder=""
                                      id="lastNameinput"
                                      name="image"
                                      onChange={onFileChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Role
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>

                                    <Select
                                      className="form-select1"
                                      data-trigger
                                      options={role}
                                      value={defaultval}
                                      defaultValue={defaultval}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Default Language
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <Select
                                      id="ForminputState"
                                      className="form-select1"
                                      options={be_languagefull}
                                      value={langdata}
                                      onChange={handleInputlang}
                                    ></Select>
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror2.lang}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Optional Language
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <MultiSelect
                                      options={be_languagefull}
                                      value={selectedMulti}
                                      onChange={setselectedMulti}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      allowCustomValue={true}
                                      aria-expanded="true"
                                    />
                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror4.langselect}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Hotel{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={hotelname}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Country{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <Select
                                      className="form-control"
                                      data-choices
                                      name="choices-single-default"
                                      id="choices-single-default"
                                      options={country}
                                      value={countrydata}
                                      onChange={handleInputcountry}
                                    ></Select>

                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror3.country}
                                    </label>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Status{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <select
                                      id="ForminputState"
                                      className="form-select"
                                      value={status}
                                      onChange={(e) =>
                                        setStatus(e.target.value)
                                      }
                                    >
                                      <option selected>Choose...</option>
                                      <option value="1">Active</option>
                                      <option value="0">Inactive</option>
                                    </select>
                                    <label style={{ color: "red" }}>
                                      {formerror5.status}
                                    </label>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="form-translation-choose-lang">
                                  <span
                                    for="firstNameinput"
                                    className="form-label db-lang-choose"
                                  >
                                    Choose Language:{" "}
                                  </span>
                                  <div className="lang-choose-select-option">
                                    <MultiSelect
                                      options={be_language}
                                      value={selectedMulti1}
                                      onChange={setselectedMulti1}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      allowCustomValue={true}
                                      aria-expanded="true"
                                    />
                                  </div>
                                </div>
                                <div className="appendmore"></div>
                                {selectedMulti1.length > 0
                                  ? selectedMulti1.map((element, index) => {
                                      return (
                                        <div
                                          key={index}
                                          class="col-md-12 translation-fill-section"
                                          id="lang_ +val"
                                          data-value="1"
                                        >
                                          <div class="translation-selected-lang">
                                            <p>
                                              <span class="translation-selected-lang-head">
                                                {element.code}
                                              </span>
                                            </p>
                                          </div>
                                          <div className="row">
                                            <div class="col-md-6">
                                              <div class="mb-3">
                                                <label
                                                  for="firstNameinput"
                                                  class="form-label"
                                                >
                                                  Name
                                                  <span class="form-validation-required">
                                                    *
                                                  </span>
                                                </label>
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  placeholder=""
                                                  value={
                                                    selectedMulti1[index].name
                                                  }
                                                  onChange={(e) =>
                                                    (selectedMulti1[
                                                      index
                                                    ].name = e.target.value)
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div class="col-md-6">
                                              <div class="mb-3">
                                                <label
                                                  for="firstNameinput"
                                                  class="form-label"
                                                >
                                                  Last Name
                                                  <span class="form-validation-required">
                                                    *
                                                  </span>
                                                </label>
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  placeholder=""
                                                  value={
                                                    selectedMulti1[index]
                                                      .surname
                                                  }
                                                  onChange={(e) =>
                                                    (selectedMulti1[
                                                      index
                                                    ].surname = e.target.value)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : ""}
                              </>
                            )}
                          </div>

                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default TemporaryCreateuser;
