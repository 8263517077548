import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Commonheader from "../../Common/Commonheader";
import Nodatafound from "../../Common/Nodatafound";
import Toastmodal from "../../Common/Toastmodal";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Config from "../../Config";
import { UserContext } from "../../Contexts/UserContext";
import DeleteModal from "../../DeleteModal";
import Loading from "../../Loading";

function Reviews() {
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);

  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [list, setList] = useState([]);

  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showFullData, setShowFullData] = useState([]);

  const handleDropdownClickhotel = (i) => {
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  const handleChange = (e, i, index, id) => {
    const newListArray = [...list];
    newListArray[i].reviews[index].public_show_status =
      !newListArray[i].reviews[index].public_show_status;
    setList(newListArray);
    showPublic(i, index, id);
  };

  const getall = async (e) => {
    const res = await fetch(`${Config.apiurl}review/list`, {
      method: "POST",
      body: JSON.stringify({
        hotels: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setLoading(true);
        setList(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getall();
      } else {
        setLoading(true);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getall();
    } else {
      console.log("error");
    }
  };

  const showPublic = async (i, index, id) => {
    const res = await fetch(`${Config.apiurl}review/change-status`, {
      method: "POST",
      body: JSON.stringify({
        review_id: id,
        public_status:
          list[i]["reviews"][index].public_show_status === true ? 1 : 0,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data) {
      console.log("error");
      setToastmodal(true);
      setToastmessage(data.message);
      setToasttype("error");
      setToastlarge(true);
    } else {
      console.log("success");
      setToastmodal(true);
      setToastmessage(data.message);
      setToasttype("success");
      setToastlarge(true);
      getall();
    }
  };

  useEffect(() => {
    getall();
  }, [id]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    if (list.length > 0) {
      const initialState = list.map((item, index) => ({
        hotel: index === 0,
      }));
      setShowFullData(initialState);
    }
  }, [list?.length]);

  return (
    <div className="page-content">
      <Commonheader />
      <div className="container-fluid">
        <div className="row project-wrapper">
          <div className="row dashboard-top-tab-style">
            <div className="col-xxl-12 dashboard-main-style">
              <div className="">
                <div className="">
                  {/* <h4 className="">{hoteldetails.hotel_name}</h4> */}
                </div>
                <div className="row project-wrapper">
                  <div className="container-fluid">
                    <div className="row align-items-center gy-3">
                      <div className="col-sm">
                        <div style={{ fontSize: "16px" }}>REVIEWS</div>
                      </div>
                    </div>
                    <div className="row dashboard-top-tab-style">
                      <div className="col-xxl-12 dashboard-main-style">
                        {list.map((li, i) => {
                          return (
                            <>
                              <div
                                className={
                                  list.length > 1
                                    ? "row hotel_single_list"
                                    : "row"
                                }
                                key={i}
                              >
                                {list.length > 1 ? (
                                  <div className="hotel_service_display hotel_service_display_12">
                                    <div className="hotel_service_display_14">
                                      <div className="hk_service_all_hotel">
                                        <span
                                          className=""
                                          style={{ marginLeft: "10px" }}
                                        >
                                          {" "}
                                          {i + 1}.
                                        </span>
                                        <span style={{ marginLeft: "10px" }}>
                                          {li.hotel_name}
                                        </span>
                                      </div>
                                    </div>
                                    {showFullData[i]?.hotel === true ? (
                                      <span
                                        className="db-modify_toggle_btn"
                                        onClick={() =>
                                          handleDropdownClickhotel(i)
                                        }
                                      >
                                        <span
                                          className="bx bxs-chevron-up"
                                          style={{ fontSize: "20px" }}
                                        ></span>
                                      </span>
                                    ) : (
                                      <span
                                        className="db-modify_toggle_btn"
                                        onClick={() =>
                                          handleDropdownClickhotel(i)
                                        }
                                      >
                                        <span
                                          className="bx bxs-chevron-down"
                                          style={{ fontSize: "20px" }}
                                        ></span>
                                      </span>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div
                                  className={`row ${
                                    showFullData[i]?.hotel === true
                                      ? ""
                                      : "d-none"
                                  }`}
                                >
                                  {li.reviews.length > 0 ? (
                                    <div className="card-body pt-0">
                                      <div>
                                        <div
                                          className=""
                                          style={{ marginTop: "2%" }}
                                        >
                                          <table
                                            className="table table-nowrap align-middle"
                                            id="orderTable"
                                            style={{
                                              width: "100%",
                                              marginLeft: "12px",
                                            }}
                                          >
                                            <colgroup>
                                              <col style={{ width: "25%" }} />
                                              <col style={{ width: "15%" }} />
                                              <col style={{ width: "50%" }} />
                                              <col style={{ width: "10%" }} />
                                            </colgroup>
                                            <thead
                                              className=""
                                              style={{
                                                backgroundColor: "#008080",
                                                color: "white",
                                                height: "55px",
                                              }}
                                            >
                                              <tr className="text-uppercase">
                                                <th className="" data-sort="id">
                                                  Customer Info
                                                </th>
                                                <th
                                                  className=""
                                                  data-sort="customer_name"
                                                >
                                                  Reviews
                                                </th>
                                                <th
                                                  className=""
                                                  data-sort="customer_name"
                                                >
                                                  Review Message
                                                </th>
                                                <th
                                                  className=""
                                                  data-sort="customer_name"
                                                >
                                                  Show Public
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                              {li.reviews.map((data, index) => {
                                                const starIcons = [];
                                                for (let j = 0; j < 5; j++) {
                                                  if (j < data.average_review) {
                                                    starIcons.push(
                                                      <img
                                                        src="/images/icon/star.png"
                                                        className="rating_img_left"
                                                        key={j}
                                                      />
                                                    );
                                                  } else {
                                                    starIcons.push(
                                                      <img
                                                        src="/images/icon/star_no.png"
                                                        className="rating_img_left"
                                                        key={j}
                                                      />
                                                    );
                                                  }
                                                }
                                                return (
                                                  <>
                                                    <tr key={index}>
                                                      <td>
                                                        <div>
                                                          <span
                                                            style={{
                                                              fontWeight: "500",
                                                            }}
                                                          >
                                                            {" "}
                                                            Name :
                                                          </span>{" "}
                                                          <span>
                                                            {
                                                              data.customer
                                                                .first_name
                                                            }{" "}
                                                            {
                                                              data.customer
                                                                .last_name
                                                            }
                                                          </span>
                                                        </div>
                                                        <div>
                                                          {" "}
                                                          <span
                                                            style={{
                                                              fontWeight: "500",
                                                            }}
                                                          >
                                                            Email :{" "}
                                                          </span>
                                                          <span>
                                                            {
                                                              data.customer
                                                                .email
                                                            }
                                                          </span>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        {starIcons}
                                                        {/* <img src="/images/icon/star.png" className=""/>
                                                  <img src="/images/icon/star.png" className="rating_img_left"/>
                                                  <img src="/images/icon/star.png" className="rating_img_left"/>
                                                  <img src="/images/icon/star.png" className="rating_img_left"/>
                                                  <img src="/images/icon/star_no.png" className="rating_img_left"/> */}
                                                      </td>
                                                      <td
                                                        style={{
                                                          whiteSpace: "normal",
                                                        }}
                                                      >
                                                        {data.comments}
                                                      </td>
                                                      <td
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          id="formCheck1"
                                                          value={
                                                            data.public_show_status
                                                          }
                                                          checked={
                                                            data.public_show_status &&
                                                            data.public_show_status ===
                                                              true
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(e) =>
                                                            handleChange(
                                                              e,
                                                              i,
                                                              index,
                                                              data.review_id
                                                            )
                                                          }
                                                          style={{
                                                            width: "16px",
                                                          }}
                                                        />
                                                      </td>
                                                    </tr>
                                                  </>
                                                );
                                              })}
                                              {/* <tr>
                                                <td>
                                                  <div><span style={{fontWeight:"500"}}> Name :</span> <span>Clara</span></div>
                                                  <div> <span style={{fontWeight:"500"}}>Email : </span><span>clara@gmail.com</span></div>
                                                </td>
                                                <td>
                                                  <img src="/images/icon/star.png" className=""/>
                                                  <img src="/images/icon/star.png" className="rating_img_left"/>
                                                  <img src="/images/icon/star.png" className="rating_img_left"/>
                                                  <img src="/images/icon/star_no.png" className="rating_img_left"/>
                                                  <img src="/images/icon/star_no.png" className="rating_img_left"/>
                                                </td>
                                                <td style={{ whiteSpace: 'normal' }}>We are surprise with the hotel surroundings , it has lots of restaurant and coffee shop
                                                 to go to for breakfast. You can also get your snacks in the evening at the nearest 24/7 convenient store. It is so convenient to travel with car because they have spacious car parking exclusive for guest.
                                                </td>
                                                <td style={{textAlign:"center"}}>
                                                  <input type="checkbox" className="form-check-input" id="formCheck1" style={{ width: "16px" }}/>
                                                </td>
                                                </tr>
                                                <tr>
                                                <td>
                                                  <div><span style={{fontWeight:"500"}}> Name :</span> <span>Manu Antony</span></div>
                                                  <div> <span style={{fontWeight:"500"}}>Email : </span><span>manu123@gmail.com</span></div>
                                                </td>
                                                <td>
                                                  <img src="/images/icon/star.png" className=""/>
                                                  <img src="/images/icon/star.png" className="rating_img_left"/>
                                                  <img src="/images/icon/star.png" className="rating_img_left"/>
                                                  <img src="/images/icon/star.png" className="rating_img_left"/>
                                                  <img src="/images/icon/star.png" className="rating_img_left"/>
                                                </td>
                                                <td style={{ whiteSpace: 'normal' }}>It's my 3rd stay in this hotel ..... Rooms are clean and comfortable. Staff is friendly .. Location is good ..close to the metro station.
                                                </td>
                                                <td style={{textAlign:"center"}}>
                                                  <input type="checkbox" className="form-check-input" id="formCheck1" style={{ width: "16px" }}/>
                                                </td>
                                                </tr>
                                                <tr>
                                                <td>
                                                  <div><span style={{fontWeight:"500"}}>Name :</span><span>Mathew David</span></div>
                                                  <div><span style={{fontWeight:"500"}}>Email : </span><span>mathewdavid@gmail.com</span></div>
                                                </td>
                                                <td>
                                                  <img src="/images/icon/star.png" className=""/>
                                                  <img src="/images/icon/star.png" className="rating_img_left"/>
                                                  <img src="/images/icon/star.png" className="rating_img_left"/>
                                                  <img src="/images/icon/star.png" className="rating_img_left"/>
                                                  <img src="/images/icon/star_no.png" className="rating_img_left"/>
                                                </td>
                                                <td style={{ whiteSpace: 'normal' }}>It's my 3rd stay in this hotel ..... Rooms are clean and comfortable. Staff is friendly .. Location is good ..close to the metro station.</td>
                                                <td style={{textAlign:"center"}}>
                                                  <input type="checkbox" className="form-check-input" id="formCheck1" style={{ width: "16px" }}/>
                                                </td>
                                                </tr> */}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <Nodatafound />
                                  )}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Reviews;
