import React, { useContext,useEffect, useState } from 'react'
import Header from '../../../Layouts/Header';
import Sidebarloyalty from './Sidebars/Sidebarloyalty';
import { UserContext } from '../Contexts/UserContext';

function Loyaltyheader() {
    const user_type = localStorage.getItem("user_type");
    const {updateHotel_select, hotel_select} = useContext(UserContext);

    const [showhotel,setShowhotel]=useState(false);
    const [dashboard,setDashboard]=useState(true);
    const[pagename,setPagename]=useState("loyalty");

    useEffect(()=>{
      setShowhotel(hotel_select.length>0?true:false);
    },[hotel_select])
    return (
      <div>
        <Header setShowhotel={setShowhotel} dashboard={dashboard} pagename={pagename} user_type={user_type} showhotel={showhotel}/>
       
          <Sidebarloyalty showhotel={showhotel}/>
         
      </div>
    )
}

export default Loyaltyheader
