import React, { useContext, useEffect } from 'react'
import Header from '../../../Layouts/Header';
import { useState } from 'react';
import Reservationsidebar from './Sidebars/Reservationsidebar';
import { UserContext } from '../Contexts/UserContext';

function Reservationheader() {
  const {updateHotel_select, hotel_select} = useContext(UserContext);

    const user_type = localStorage.getItem("user_type");
    const [showhotel,setShowhotel]=useState(false);
    const [dashboard,setDashboard]=useState(true);
    const[pagename,setPagename]=useState("condition");

    useEffect(()=>{
      setShowhotel(hotel_select.length>0?true:false);
    },[hotel_select])
    return (
      <div>
        <Header setShowhotel={setShowhotel} dashboard={dashboard} pagename={pagename} user_type={user_type} showhotel={showhotel}/>
       
          <Reservationsidebar showhotel={showhotel}/>
         
      </div>
    )
}

export default Reservationheader
