import React, { useContext, useEffect, useState } from "react";
import Footer from "../../Common/Footer";
import Reservationheader from "../../Common/Reservationheader";
import { Link, useParams } from "react-router-dom";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Config from "../../Config";
import moment from "moment";
import Toastmodal from "../../Common/Toastmodal";
import Loading from "../../Loading";

function Reservationview() {
  
  let {reservation_id} = useParams();
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [sendEmail,setSendEmail] = useState(true);
  const [ResendEmail,setResendEmail] = useState("RESEND_RESERVATION_COPY");
  const [loader, setLoader] = useState(false);
  const [email,setEmail] = useState("");
  const [list,setList] = useState([]);
  const [formerror, setError] = useState({});
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [free_message,setFree_message] = useState("");
  const [free_subject,setFree_subject] = useState("");
  const [showCardNumber, setShowCardNumber] = useState(false);
  const [attemptToShowAgain, setAttemptToShowAgain] = useState(false);
  const [hasShownOnce, setHasShownOnce] = useState(false);

  const handleShowClick = () => {
    if (!hasShownOnce) {
      setShowCardNumber(true);
      setHasShownOnce(true);
    } else {
      setAttemptToShowAgain(true);
    }
  };

  const handleHideClick = () => {
    setShowCardNumber(false);
  };

  const getView = async () => {
    const res = await fetch(`${Config.apiurl}reservation/view/${reservation_id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setList(data.data);
        setLoader(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getView();
      } else {
        setLoader(true);
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getView();
    } else {
      console.log("error");
    }
  };

  const EmailSend = async (e) => {
    e.preventDefault();

    let isFormValid = true;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    const errors = {};
    if(!email){
      isFormValid = false;
      errors.email= "Email is required";
    } 
    else if (!regex.test(email)) {
      isFormValid = false;
      errors.email = "This is not a valid email format!";
    }
    if(ResendEmail==="RESEND_FREEMESSAGE"){
    if(!free_message){
      isFormValid = false;
      errors.free_message= "This field is required";
    } 
    if(!free_subject){
      isFormValid = false;
      errors.free_subject= "This field is required";
    } 
   }
  
  setError(errors);

  if(isFormValid===true){
   
   let messageContent;
   let messageSubject;

   if(ResendEmail==="RESEND_FREEMESSAGE"){
    messageContent=free_message;
    messageSubject=free_subject
   }

      const res = await fetch(`${Config.apiurl}reservation/confirm-mail/send`,{
          method: "POST",
          body: JSON.stringify({
            reservation_id:reservation_id,
            email:email,
            mail_type:ResendEmail,
            free_message:messageContent,
            free_subject:messageSubject,
            send_me_copy:sendEmail
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200") {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else if (data.reauth === true) {
          handleUnauthorized();
          EmailSend();
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        EmailSend();
      } else {
        console.log("error");
      }
    }
  };

  function processDBValue(dbValue) {
    if (dbValue === null || dbValue === undefined) {
      return "";
    }
    dbValue = dbValue.toString();
    const numericValue = parseFloat(dbValue.replace(/,/g, ""));
    const result = numericValue !== 0 ? numericValue.toFixed(2) : dbValue;
    return result.endsWith(".00") ? result.slice(0, -3) : result;
  }

  useEffect(() => {
    getView();
  }, []);

  return (
    <div className="main-content">
      <Reservationheader />
      <div className="page-content reservation-page-content">
        <div className="container-fluid">
          {loader===false?
          <Loading/>
          :
          <div className="row">
            <div className= "row" >
            <div className="col-xxl-12 col-sm-12">
            <div className="modify-period-submit">
              {list.is_edit===1?
                <Link type="button" to={`/reservation-edit/${reservation_id}`}
                    className="btn db-save-button"
                    style={{ height: "30px", bottom: "8px" }}>
                    {" "}
                    Edit
                </Link>
                :
                <Link type="button" to="#"
                className="btn reserve_edit_disable_button"
                style={{ height: "30px", bottom: "8px" }}>
                {" "}
                Edit
                </Link>
               }
                  </div>
                    </div>
                      <div
                        className="row project-wrapper"
                        style={{ marginLeft: "10px",padding:"0px 15px" }}
                      >
                        <div className="row dashboard-top-tab-style">
                          <div
                            className="col-xxl-12 dashboard-main-style"
                            style={{ marginTop: "5px" }}
                          >
                              <div style={{ border: "1px solid #d2d2d2",padding:"10px 20px" }}
                              >
                                <div>
                                  
                                  <div>
                                  <table className="table  table-borderless mb-0">
                                    <tbody>
                                        <tr>
                                        <th className="" scope="row">
                                           Name:
                                        </th>
                                        <td className="text-muted">{list?.customer?.name}</td>
                                        <th className="" scope="row">
                                            Email:
                                        </th>
                                        <td className="text-muted">{list.customer?.mail}</td>
                                        <th className="" scope="row">
                                           Phone:
                                        </th>
                                        <td className="text-muted">{list.customer?.calling_code} {list.customer?.phone_number}</td>
                                        </tr>
                                        <tr>
                                       
                                        <th className="" scope="row">
                                           Country:
                                        </th>
                                        <td className="text-muted">{list.customer?.country}</td>
                                        <th className="" scope="row">
                                           Ip Address:
                                        </th>
                                        <td className="text-muted">{list.ip_address}</td>
                                        
                                        </tr>
                                        
                                        <tr>
                                        <th className="" scope="row">
                                           Code:
                                        </th>
                                        <td className="text-muted">{list.reservation_key}</td>
                                        <th className="" scope="row">
                                           Stay:
                                        </th>
                                        <td className="text-muted">{moment(list.check_in).format('DD MMMM, YYYY')} - {moment(list.check_out).format('DD MMMM, YYYY')}
                                         {list.no_of_nights > 1?
                                          <span>, {list.no_of_nights} nights</span>
                                          :
                                          <span>, {list.no_of_nights} night</span>
                                         }
                                        </td>
                                        <th className="" scope="row">
                                           Reserved On:
                                        </th>
                                        <td className="text-muted">{list.reservation_date}</td>
                                        </tr>
                                        <tr>
                                        <th className="" scope="row">
                                           Status:
                                        </th>
                                        <td className="text-muted" colSpan="5">
                                        {list.revision_status?
                                        <>
                                        <span>{list.revision_status}</span><br/>
                                        </>
                                        :""}
                                        {list.cancel_reason?
                                        <>
                                        <span><span style={{fontWeight:"500"}}>Cancel reason:</span> {list.cancel_reason}</span><br/>
                                        </>
                                        :""}
                                         {list.cancel_comment?
                                         <>
                                        <span><span style={{fontWeight:"500"}}>Cancel comment:</span> {list.cancel_comment}</span><br/>
                                        </>
                                        :""}
                                        {list.comments?
                                        <>
                                        <span><span style={{fontWeight:"500"}}>Comments:</span> {list.comments}</span>
                                        </>
                                        :""}
                                         {list.cancel_date?
                                        <>
                                        <span><span style={{fontWeight:"500"}}>Cancel Date:</span> {list.cancel_date}</span>
                                        </>
                                        :""}
                                        </td>
                                        </tr>
                                        <tr>
                                        <th>Source:</th>
                                        <td className="text-muted">{list.source}</td>
                                        <th>Device:</th>
                                        <td className="text-muted">{list.device}</td>
                                        <th>Language:</th>
                                        <td className="text-muted">{list.language}</td>
                                        </tr>
                                    <tr>
                                    <th>Promocode:</th>
                                      <td className="text-muted"></td>
                                    <th>Club ID:</th>
                                      <td className="text-muted">{list.club?.member}</td>
                                    <th>Club Level:</th>
                                    <td className="text-muted">{list.club?.level}</td>
                                    </tr>
                                    <tr>
                                   
                                      <th>Payment Type:</th>
                                      <td className="text-muted">{list.cancel_policy?.payment_data}</td>
                                      <th>Method Of Payment:</th>
                                        <td className="text-muted">{list.cancel_policy?.cancel_type}</td>
                                    </tr>
                                    <tr>
                                      <th className="" scope="row">
                                        Cancellation Policy:
                                      </th>
                                      <td className="text-muted" colSpan="5">
                                        {list.cancel_policy?.description}
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                  {/* {list.card?
                                  <>
                                 <div className="reserve_view_creditcard">
                                    Credit Card
                                  </div>
                                  <div className=" d-flex">
                                    <div className="reserve_view_card_div">
                                      {list.card?.holder_name}<br />
                                      {list.card?.expiry_month}/{list.card?.expiry_year}<br />
                                      {showCardNumber && <span>{list.card?.number}</span>}
                                    </div>
                                    <div style={{ marginTop: "2%",marginLeft:"2%" }}>
                                      {!showCardNumber ? (
                                        <button
                                          className="btn btn-primary"
                                          onClick={handleShowClick}
                                        >
                                          Show card number once
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-primary"
                                          onClick={handleHideClick}
                                        >
                                          Hide card number
                                        </button>
                                      )}
                                    </div>
                                    </div>
                                    {attemptToShowAgain && !showCardNumber && (
                                      <div style={{ color: "red" }}>
                                        Already the card number shown once, cannot be shown again.
                                      </div>
                                    )}
                                 
                                  </>
                                  :""} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="reservation-main-sec" style={{padding:"0px 35px"}}>
                    <div style={{fontSize:"16px",fontWeight:"500"}}>Reservation Details</div>
                    <div className="row">
                        <div className="col-xxl-12">
                        <div
                            className=""
                            style={{overflowX:"auto" }}
                        >
                            <table className="table reservation-list-table reservation-main-table-style">
                            <tr>
                                <th className="new-head">
                                Type
                                </th>
                                <th
                                className="new-head"
                                title="Rate"
                                >
                                Rate
                                </th>
                                <th
                                className="new-head"
                                title="Board"
                                >
                                Board
                                </th>
                                <th
                                className="new-head"
                                title="Intervals"
                                >
                                Intervals
                                </th>
                                <th
                                className="new-head"
                                title="Nights"
                                >
                                No.of Nights
                                </th>
                                <th className="new-head" title="Total">
                                Total Room Rate
                                </th>
                                <th className="new-head" title="Total">
                                Total
                                </th>
                              </tr>
                              {list?.room_types?.map((room, ind) => {
                                  return (
                                <tr key={ind}>
                                <td className="scrollable-cols">
                               {room.room_title}
                                </td>
                                <td className="scrollable-cols">
                                {room.room_rate} {list.currency.code}
                                </td>
                                <td className="scrollable-cols">
                                    {room.board}
                                </td>
                                <td className="scrollable-cols">
                                {moment(list.check_in).format('DD MMMM, YYYY')} - {moment(list.check_out).format('DD MMMM, YYYY')}
                                </td>
                                <td className="scrollable-cols">
                                    {room.no_of_nights}
                                </td>
                                <td className="scrollable-cols">
                                {processDBValue(room.subtotal_without_tax)} {list.currency.code}
                                </td>
                                {ind === 0 && <td rowSpan={list.room_types.length} className="scrollable-cols">{processDBValue(list.total_room_rates)} {list.currency.code} (with taxes)</td>}
                                </tr>
                                  );
                                })}
                            
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                      <div className="reservation-main-sec" style={{padding:"30px 35px"}}>
                        <div style={{border:"1px solid #d2d2d2",padding:"10px 30px"}}>
                        <div style={{fontSize:"16px",fontWeight:"500"}}>Resend reservation to client</div>
                        <div style={{marginTop:"3%"}}>
                        <div className="col-md-4">
                          <div className="input-group">
                            <span
                              className="input-group-text input-joint-color"
                              id="inputGroup-sizing-sm">Email
                              <span className="form-validation-required">*</span>
                            </span>
                            <input type="text" className="form-control"
                              placeholder="example@gmail.com"
                              value={email}
                              onChange={(e)=>setEmail(e.target.value)}
                            />
                            
                          </div>
                          <label style={{ color: "red" }}>
                                {formerror.email}
                              </label>
                          </div>
                          <div style={{fontSize:"16px",fontWeight:"500",marginTop:"3%"}}>With this Subject & Content</div>
                          <div className="col-md-12" style={{marginTop:"2%"}}>
                              <input
                                className="form-check-input"
                                type="radio"
                                id="formCheck1"
                                name="resend_type"
                                value="RESEND_RESERVATION_COPY"
                                checked={ResendEmail==="RESEND_RESERVATION_COPY"}
                                onChange={(e)=>setResendEmail(e.target.value)}
                              />
                              <span style={{marginLeft:"10px"}}>
                                <span style={{fontWeight:"600"}}>Subject :</span><span style={{fontWeight:"500"}}> {list.hotel_name}</span> - Reservation Confirmation
                              </span>
                              <div style={{marginLeft:"2%",marginTop:"5px"}}>
                                <span style={{fontWeight:"600"}}>Content :</span> We are delighted to confirm your reservation with <span style={{fontWeight:"500"}}> {list.hotel_name}</span>. Below, you will find all the details of your upcoming stay:
                              </div>
                            </div>

                            <div className="col-md-12" style={{marginTop:"2%"}}>
                              <input
                                className="form-check-input"
                                type="radio"
                                id="formCheck1"
                                name="resend_type"
                                value="RESEND_INVALIDCARDWARNING"
                                checked={ResendEmail==="RESEND_INVALIDCARDWARNING"}
                                onChange={(e)=>setResendEmail(e.target.value)}
                              />
                              {/* <span  style={{marginLeft:"10px"}}>
                                Dear guest, There has been a problem with the payment card you provided with your reservation. Please contact us before 48 hours.
                              </span> */}
                               <span style={{marginLeft:"10px"}}>
                                <span style={{fontWeight:"600"}}>Subject :</span> Issue with Your Payment Card - Please Contact <span style={{fontWeight:"500"}}> {list.hotel_name}</span>
                              </span>
                              <div style={{marginLeft:"2%",marginTop:"5px"}}>
                                <span style={{fontWeight:"600"}}>Content :</span> We regret to inform you that there has been an issue with the payment card you provided for your reservation with <span style={{fontWeight:"500"}}> {list.hotel_name}</span>. To avoid any disruption to your booking, please contact us within the next 48 hours to resolve this matter.
                              </div>
                            </div>

                            <div className="col-md-12" style={{marginTop:"2%"}}>
                              <input
                                className="form-check-input"
                                type="radio"
                                id="formCheck1"
                                name="resend_type"
                                value="RESEND_INVALIDCARDANDCANCEL"
                                checked={ResendEmail==="RESEND_INVALIDCARDANDCANCEL"}
                                  onChange={(e)=>setResendEmail(e.target.value)}
                              />
                              {/* <span  style={{marginLeft:"10px"}}>
                              Dear guest, There has been a problem with the payment card you provided with your reservation. We are sorry to let you know that we will have to cancel the booking.
                              <div style={{marginLeft:"23px"}}>
                              (ATTENTION: The system will cancel the reservation and send the cancellation announcement)
                              </div>
                              </span> */}
                              <span style={{marginLeft:"10px"}}>
                                <span style={{fontWeight:"600"}}>Subject :</span> Urgent: Payment Issue - Your Reservation is Being Cancelled
                              </span>
                              <div style={{marginLeft:"2%",marginTop:"5px"}}>
                                <span style={{fontWeight:"600"}}>Content :</span> We regret to inform you that there has been an issue with the payment card you provided for your reservation with <span style={{fontWeight:"500"}}> {list.hotel_name}</span>. Unfortunately, due to this problem, we will have to cancel your booking.
                              </div>
                            </div>

                            <div className="col-md-12" style={{marginTop:"2%"}}>
                              <input
                                className="form-check-input"
                                type="radio"
                                id="formCheck1"
                                name="resend_type"
                                value="RESEND_RECONFIRMRESERVATION"
                                checked={ResendEmail==="RESEND_RECONFIRMRESERVATION"}
                                 onChange={(e)=>setResendEmail(e.target.value)}
                              />
                              {/* <span  style={{marginLeft:"10px"}}>
                              We reconfirm your reservation after we have changed your credit card successfully. We are looking forward to your arrival.
                              </span> */}
                               <span style={{marginLeft:"10px"}}>
                                <span style={{fontWeight:"600"}}>Subject :</span> Reservation Reconfirmed - We Look Forward to Your Arrival!
                              </span>
                              <div style={{marginLeft:"2%",marginTop:"5px"}}>
                                <span style={{fontWeight:"600"}}>Content :</span> We are pleased to inform you that your reservation with <span style={{fontWeight:"500"}}> {list.hotel_name}</span> has been successfully reconfirmed following the update to your credit card information.
                              </div>
                            </div>
                            <div className="col-md-12" style={{marginTop:"2%"}}>
                              <input
                                className="form-check-input"
                                type="radio"
                                id="formCheck1"
                                name="resend_type"
                                value="RESEND_PENDING_TRANSFERENCE"
                                checked={ResendEmail==="RESEND_PENDING_TRANSFERENCE"}
                                  onChange={(e)=>setResendEmail(e.target.value)}
                              />
                              {/* <span  style={{marginLeft:"10px"}}>
                              Dear client: We remind you that we haven't received the bank transfer for this reservation yet. Please contact us for any question you may have.
                              </span> */}
                               <span style={{marginLeft:"10px"}}>
                                <span style={{fontWeight:"600"}}>Subject :</span> Reminder: Pending Bank Transfer for Your Reservation
                              </span>
                              <div style={{marginLeft:"2%",marginTop:"5px"}}>
                                <span style={{fontWeight:"600"}}>Content :</span> This is a friendly reminder that we have not yet received the bank transfer for your reservation with <span style={{fontWeight:"500"}}> {list.hotel_name}</span>. We kindly ask that you complete the payment at your earliest convenience to ensure your booking remains confirmed.
                              </div>
                            </div>
                            <div className="col-md-12" style={{marginTop:"2%"}}>
                              <input
                                className="form-check-input"
                                type="radio"
                                id="formCheck1"
                                name="resend_type"
                                value="RESEND_CANCELLEDRESERVATION"
                                checked={ResendEmail==="RESEND_CANCELLEDRESERVATION"}
                                onChange={(e)=>setResendEmail(e.target.value)}
                              />
                              {/* <span  style={{marginLeft:"10px"}}>
                              Dear client: We regret to inform that we CANCEL your reservation after failing to receive the agreed bank transfer.
                              <div style={{marginLeft:"23px"}}>
                              (ATTENTION: The system will cancel the reservation and send the cancellation announcement)
                              </div>
                              </span> */}
                              <span style={{marginLeft:"10px"}}>
                                <span style={{fontWeight:"600"}}>Subject :</span> Important: Reservation Cancellation Due to Unreceived Payment
                              </span>
                              <div style={{marginLeft:"2%",marginTop:"5px"}}>
                                <span style={{fontWeight:"600"}}>Content :</span> We regret to inform you that, due to the failure to receive the agreed bank transfer for your reservation with <span style={{fontWeight:"500"}}> {list.hotel_name}</span>, we have to cancel your booking.
                              </div>
                            </div>
                            <div className="col-md-12" style={{marginTop:"2%"}}>
                              <input
                                className="form-check-input"
                                type="radio"
                                id="formCheck1"
                                name="resend_type"
                                value="RESEND_FREEMESSAGE"
                                checked={ResendEmail==="RESEND_FREEMESSAGE"}
                                  onChange={(e)=>setResendEmail(e.target.value)}
                              />
                              <span  style={{marginLeft:"10px"}}>
                               Free text (Write it in the guest language)
                              </span>
                            </div>
                            {ResendEmail==="RESEND_FREEMESSAGE"?
                            <>
                             <span style={{fontWeight:"600",marginLeft:"2%"}}>Subject : </span>
                            <input type="text" className="reserve_view_free_input"
                              value={free_subject}
                              onChange={(e)=>setFree_subject(e.target.value)}/><br/>

                            <label style={{ color: "red",marginLeft:"15px" }}>
                              {formerror.free_subject}
                            </label>

                            <span style={{fontWeight:"600",marginLeft:"6px"}}>Content : </span>
                            <input type="text" className="reserve_view_free_input"
                              value={free_message}
                              onChange={(e)=>setFree_message(e.target.value)}/><br/>

                            <label style={{ color: "red",marginLeft:"15px" }}>
                            {formerror.free_message}
                          </label>
                          </>
                            :""}
                        </div>
                        <div className="col-md-12" style={{marginTop:"3%"}}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck1"
                                value={sendEmail}
                                checked={sendEmail===true?true:false}
                                onChange={()=>setSendEmail(!sendEmail)}
                              />
                              
                              <span  style={{marginLeft:"10px"}}>
                               Send me a copy of what guest receives
                              </span>
                              <div style={{marginTop:"3%"}}>
                                <Link type="button" to="#"
                                    className="btn db-save-button"
                                    onClick={EmailSend}
                                    style={{ height: "30px"}}>
                                    {" "}
                                    Send
                                </Link>
                              </div>
                          </div>
                      </div>
                    </div>
                   </div>
             }
                </div>
           </div>
           {toastmodal === true ? (
          <Toastmodal
            setToast={setToastmodal}
            message={toastmessage}
            type={toasttype}
            large={toastlarge}
          />
        ) : (
          ""
        )}
      <Footer />
    </div>
  );
}

export default Reservationview;
