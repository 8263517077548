import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Link } from "react-router-dom";
import Select from "react-select";
import Commonimagepath from "../Commonimagepath";
import Previousmodal from "./Previousmodal";

function Roominformaton({
  setTab,
  facility,
  handleChangees,
  addInputField,
  removeInputFields,
  onFileChange,
  bedtype,
  handleChangeesinner,
  handleChangetoggroom,
  addInputFieldRoom,
  roomprevtab,
  roominfonext,
  formerror6,
  roominfo,
  setRoominfo,
  handleChangeesroomfac,
  roominfoupdatestatus,
  room_info_status,
  setRoominfos,
  formerror11,
  removeImageRoom,
  roomprev,
  setRoomprev,
  Prvfunction,
  closetab,
  handleChangeesinnerbed,
  roombutton,
  loaderspin,
}) {
  if (roominfo.length === 0) {
    setRoominfo([
      {
        room_beds: [],
        room_detail: {
          baby_status: false,
          extra_bed_status: false,
          room_id: "",
        },
        room_facilities: [],
        room_images: [],
        room_imagesnew: [],
        removed_room_img: [],
      },
    ]);

    setRoominfos([
      {
        room_images: [],
      },
    ]);
  }

  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [symbolsrate] = useState(["e", "E", "+", "-"]);

  return (
    <div id="RoomInfo" className="tabcontent">
      <div className="card-body" id="scroller">
        <div className="temp_room_basic_status">Basic Room</div>
        <div className="live-preview">
          <form action="#">
            {roominfo.map((data, index) => {
              return (
                <div key={index}>
                  <div className="addmore_roominfo room-size">
                    <span className="room_info_count">
                      <span className="room_sect_no be_form_roomsec_1">
                        {index + 1}
                      </span>
                    </span>
                    <span className="be_form_room_info_label_40 be_form_room_info_label_50">
                      <label
                        for="firstNameinput"
                        className="form-label be_form_label"
                      >
                        Room Name
                        <span className="form-validation-required">*</span>
                        <div class="info-tag-new">i</div>
                        <span className="tooltiptext-new">
                          Name of the room in this specific roomtype
                        </span>
                      </label>
                      <input
                        type="text"
                        className="form-control text_tuppi"
                        placeholder=""
                        value={data.room_detail.room_title}
                        onChange={(e) =>
                          handleChangees(index, e.target.value, "room_title")
                        }
                        name="room_title"
                      />

                      {formerror6.map((err, i) => {
                        return (
                          <>
                            {index === i && err && err.room_name ? (
                              <label style={{ color: "red" }}>
                                {err.room_name}
                              </label>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </span>
                    <span className="be_form_room_info_label_30 be_form_room_info_label_50">
                      <label
                        for="firstNameinput"
                        className="form-label be_form_label"
                      >
                        Room Images
                        <span className="form-validation-required">*</span>
                        <div class="info-tag-new mar_1">i</div>
                        <span className="tooltiptext-new">
                          Upload minimum five images. Use the Ctrl or Command
                          key to choose all the images simultaneously, only
                          accept jpg,jpeg,png format.
                        </span>
                        <span className="span-img-size">
                          ( 1024 px * 683 px)
                        </span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        placeholder=""
                        onChange={(e) =>
                          onFileChange(index, e, "room_imagesnew")
                        }
                        multiple
                      />
                      <label className="label_image_crop">
                        {" "}
                        (<span class="form-validation-required">*</span>if you
                        do not resize it as per the mentioned size, it can
                        affect the quality of the images)
                      </label>

                      {formerror11.map((err, i) => {
                        return (
                          <>
                            {index === i && err && err.room_image ? (
                              <label style={{ color: "red" }}>
                                {err.room_image}
                              </label>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </span>

                    <div className="be_form_room_info_label_full_one row mb-4">
                      {data.room_images.length !== 0
                        ? data.room_images.map((image, i) => {
                            return (
                              <div
                                className="col-sm-4 col-6 col-md-3 col-lg-2 mt-2 m18"
                                key={i}
                              >
                                <img
                                  src={`${Commonimagepath.apiurl}${image}`}
                                  alt=""
                                  className="position-relative"
                                  style={{
                                    width: "100px",

                                    marginTop: "5px",
                                    marginLeft: "5px",
                                    display: "block",
                                  }}
                                />

                                <button
                                  className="remov-button"
                                  type="button"
                                  onClick={(e) => removeImageRoom(image, index)}
                                >
                                  X
                                </button>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                    <span className="be_form_room_info_label_30 room_info_featured">
                      <label
                        for="firstNameinput"
                        className="form-label be_form_label room-labl"
                      >
                        Featured Facilities
                        <span className="form-validation-required">*</span>
                        <div class="info-tag-new">i</div>
                        <span className="tooltiptext-new fetured_radius">
                          Facilities inside this room
                        </span>
                      </label>
                      {/* <MultiSelect
                                  options={facility}
                                  value={data.room_facilities}
                                  onChange={(e) => handleChangeesroomfac(index, e,"room_facilities")}  
                                  labelledBy="Select"
                                  selectionType="counter"
                                  closeOnChangedValue={false}
                                  aria-expanded="true"
                                /> */}

                      <MultiSelect
                        options={facility}
                        value={data.room_facilities}
                        onChange={(e) =>
                          handleChangeesroomfac(index, e, "room_facilities")
                        }
                        // onChange={(val)=>
                        //   {
                        //     const filteredVal = val.filter(option => option.value !== 'other');
                        //     handleChangeesroomfac(index, filteredVal,"room_facilities");

                        //     }}
                        labelledBy="Select"
                        selectionType="counter"
                        closeOnChangedValue={false}
                        aria-expanded="true"
                      />

                      {formerror6.map((err, i) => {
                        return (
                          <>
                            {index === i && err && err.facility ? (
                              <label style={{ color: "red" }}>
                                {err.facility}
                              </label>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </span>
                    <div className="be_form_room_info_label_full be_form_room_info_label_full_img row mb-4">
                      {data.room_images.length !== 0
                        ? data.room_images.map((image, i) => {
                            return (
                              <div
                                className="col-sm-4 col-6 col-md-3 col-lg-2 mt-2 m18"
                                key={i}
                              >
                                <img
                                  src={`${Commonimagepath.apiurl}${image}`}
                                  alt=""
                                  className="position-relative"
                                  style={{
                                    width: "100px",

                                    marginTop: "5px",
                                    marginLeft: "5px",
                                    display: "block",
                                  }}
                                />

                                <button
                                  className="remov-button"
                                  type="button"
                                  onClick={(e) => removeImageRoom(image, index)}
                                >
                                  X
                                </button>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                    {data.room_facilities.map((fac, i) => {
                      return (
                        <div key={i}>
                          {fac.value === "other" ? (
                            <span className="be_form_room_info_label_full other_facility">
                              <label
                                for="firstNameinput"
                                className="form-label form_spec_color be_form_label"
                              >
                                Other facilities{" "}
                                <div class="info-tag-new">i</div>
                                <span className="tooltiptext-new">
                                  Enter any additional facility you want to
                                  mention and separate it with comma{" "}
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={data.otherfacilitry}
                                onChange={(e) =>
                                  handleChangees(
                                    index,
                                    e.target.value,
                                    "otherfacilitry"
                                  )
                                }
                                name="otherfacilitry"
                              />

                              {formerror6.map((err, i) => {
                                return (
                                  <>
                                    {index === i &&
                                    err &&
                                    err.otherfacilitry ? (
                                      <label style={{ color: "red" }}>
                                        {err.otherfacilitry}
                                      </label>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}

                    <span className="be-form-line-separation"></span>
                    <span className="be_form_room_info_label_25">
                      <label
                        for="firstNameinput"
                        className="form-label be_form_label"
                      >
                        Room Inventory
                        <span className="form-validation-required">*</span>{" "}
                        <div class="info-tag-new ap1">i</div>
                        <span className="tooltiptext-new">
                          Inventory of this particular room type
                        </span>
                      </label>
                      <input
                        type="number"
                        className="form-control ari1"
                        placeholder=""
                        onKeyDown={(e) =>
                          symbolsArr.includes(e.key) && e.preventDefault()
                        }
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                        min={0}
                        value={data.room_detail.room_inventory}
                        onChange={(e) =>
                          handleChangees(
                            index,
                            e.target.value,
                            "room_inventory"
                          )
                        }
                        name="room_inventory"
                      />
                      {formerror6.map((err, i) => {
                        return (
                          <>
                            {index === i && err && err.room_inventory ? (
                              <label style={{ color: "red" }}>
                                {err.room_inventory}
                              </label>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </span>
                    <span className="be_form_room_info_label_25">
                      <label
                        for="firstNameinput"
                        className="form-label be_form_label"
                      >
                        Min. Number Of Adult{" "}
                        <span className="form-validation-required">*</span>
                        <div class="info-tag-new">i</div>
                        <span className="tooltiptext-new">
                          Minimum Number of adult can accommodate in this room
                          type
                        </span>
                      </label>
                      <input
                        type="number"
                        className="form-control aj45"
                        placeholder=""
                        onKeyDown={(e) =>
                          symbolsArr.includes(e.key) && e.preventDefault()
                        }
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                        value={data.room_detail.min_no_adults}
                        onChange={(e) =>
                          handleChangees(index, e.target.value, "min_no_adults")
                        }
                        name="min_no_adults"
                        min={0}
                      />

                      {formerror6.map((err, i) => {
                        return (
                          <>
                            {index === i && err && err.minimum_adult ? (
                              <label style={{ color: "red" }}>
                                {err.minimum_adult}
                              </label>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </span>
                    <span className="be_form_room_info_label_25">
                      <label
                        for="firstNameinput"
                        className="form-label be_form_label"
                      >
                        Max. Number Of Adult{" "}
                        <span className="form-validation-required">*</span>
                        <div class="info-tag-new">i</div>
                        <span className="tooltiptext-new">
                          Maximum Number of adult can accommodate in this room
                          type
                        </span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder=""
                        onKeyDown={(e) =>
                          symbolsArr.includes(e.key) && e.preventDefault()
                        }
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                        value={data.room_detail.max_no_adults}
                        onChange={(e) =>
                          handleChangees(index, e.target.value, "max_no_adults")
                        }
                        name="max_no_adults"
                        min={0}
                      />
                      {formerror6.map((err, i) => {
                        return (
                          <>
                            {index === i && err && err.max_adult ? (
                              <label style={{ color: "red" }}>
                                {err.max_adult}
                              </label>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </span>
                    <span className="be_form_room_info_label_25">
                      <label
                        for="firstNameinput"
                        className="form-label be_form_label"
                      >
                        Max. Number Of Child{" "}
                        <span className="form-validation-required">*</span>
                        <div class="info-tag-new">i</div>
                        <span className="tooltiptext-new">
                          Maximum Number of child can accommodate in this room
                          type
                        </span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder=""
                        onKeyDown={(e) =>
                          symbolsArr.includes(e.key) && e.preventDefault()
                        }
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                        value={data.room_detail.max_no_child}
                        onChange={(e) =>
                          handleChangees(index, e.target.value, "max_no_child")
                        }
                        name="max_no_child"
                        min={0}
                      />

                      {formerror6.map((err, i) => {
                        return (
                          <>
                            {index === i && err && err.max_child ? (
                              <label style={{ color: "red" }}>
                                {err.max_child}
                              </label>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </span>
                    <span className="be_form_room_info_label_25">
                      <label
                        for="firstNameinput"
                        className="form-label be_form_label"
                      >
                        Max. Child Age
                        <span className="form-validation-required">*</span>
                        <div class="info-tag-new">i</div>
                        <span className="tooltiptext-new">
                          Maximum Age of child can accommodate in this room type
                        </span>
                      </label>
                      <input
                        type="number"
                        className="form-control ap2"
                        placeholder=""
                        onKeyDown={(e) =>
                          symbolsArr.includes(e.key) && e.preventDefault()
                        }
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                        value={data.room_detail.max_child_age}
                        onChange={(e) =>
                          handleChangees(index, e.target.value, "max_child_age")
                        }
                        name="max_child_age"
                        min={0}
                      />

                      {formerror6.map((err, i) => {
                        return (
                          <>
                            {index === i && err && err.max_child_age ? (
                              <label style={{ color: "red" }}>
                                {err.max_child_age}
                              </label>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </span>
                    <span className="be_form_room_info_label_25">
                      <label
                        for="firstNameinput"
                        className="form-label be_form_label"
                      >
                        Baby Cot Status
                        <span className="form-validation-required">*</span>
                        <div class="info-tag-new">i</div>
                        <span className="tooltiptext-new">
                          Room type provide free baby cot
                        </span>
                      </label>
                      <span
                        className="form-check form-switch form-switch-lg mb-3 "
                        dir="ltr"
                      >
                        <input
                          type="checkbox"
                          name="baby_status"
                          className="form-check-input loyalty_expiration extra1 ari2"
                          value={data.room_detail.baby_status}
                          checked={
                            data.room_detail.baby_status === true ? true : false
                          }
                          onChange={(val) => handleChangetoggroom(index, val)}
                        />
                      </span>
                    </span>
                    <span className="be_form_room_info_label_25 extra_float">
                      <label
                        for="firstNameinput"
                        className="form-label be_form_label"
                      >
                        Room Area (square meter)
                        <span className="form-validation-required">*</span>
                        <div class="info-tag-new">i</div>
                        <span className="tooltiptext-new">
                          Area of the room in square meter{" "}
                        </span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Room area in square meter"
                        onKeyDown={(e) =>
                          symbolsArr.includes(e.key) && e.preventDefault()
                        }
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                        value={data.room_detail.room_area}
                        onChange={(e) =>
                          handleChangees(index, e.target.value, "room_area")
                        }
                        name="room_area"
                        min={0}
                      />

                      {formerror6.map((err, i) => {
                        return (
                          <>
                            {index === i && err && err.room_area ? (
                              <label style={{ color: "red" }}>
                                {err.room_area}
                              </label>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </span>
                    <span className="be_form_room_info_label_15 extra_float">
                      <label
                        for="firstNameinput"
                        className="form-label be_form_label"
                      >
                        Sort Order
                        <span className="form-validation-required">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        // placeholder="Room area in square meter"
                        onKeyDown={(e) =>
                          symbolsArr.includes(e.key) && e.preventDefault()
                        }
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                        value={data.room_detail.sort_order}
                        onChange={(e) =>
                          handleChangees(index, e.target.value, "sort_order")
                        }
                        name="sort_order"
                        min={0}
                      />

                      {formerror6.map((err, i) => {
                        return (
                          <>
                            {index === i && err && err.sort_order ? (
                              <label style={{ color: "red" }}>
                                {err.sort_order}
                              </label>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </span>
                    <span
                      className="be_form_room_info_label_25"
                      style={{ clear: "both" }}
                    >
                      <label
                        for="firstNameinput"
                        className="form-label be_form_label"
                      >
                        Extra Bed Status
                        <span className="form-validation-required">*</span>
                        <div class="info-tag-new">i</div>
                        <span className="tooltiptext-new">
                          {" "}
                          It is possible to add an extra bed to this room type
                        </span>
                      </label>
                      <span
                        className="form-check form-switch form-switch-lg mb-3 extra_bed1"
                        dir="ltr"
                      >
                        <input
                          type="checkbox"
                          name="extra_bed_status"
                          className="form-check-input loyalty_expiration extra1"
                          value={data.room_detail.extra_bed_status}
                          checked={
                            data.room_detail.extra_bed_status === true
                              ? true
                              : false
                          }
                          onChange={(val) => handleChangetoggroom(index, val)}
                        />
                      </span>
                    </span>
                    {data.room_detail.extra_bed_status === true ? (
                      <span className="be_form_room_info_label_25">
                        <label
                          for="firstNameinput"
                          className="form-label be_form_label"
                        >
                          Extra Bed Rate
                          <span className="form-validation-required">*</span>
                          <div class="info-tag-new  tag_display">i</div>
                          <span className="tooltiptext-new">
                            An additional bed in this room type is charged at
                            this rate
                          </span>
                        </label>

                        <input
                          type="number"
                          onKeyDown={(e) =>
                            symbolsrate.includes(e.key) && e.preventDefault()
                          }
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                          name="extra_bed_charge"
                          className="form-control"
                          value={data.room_detail.extra_bed_charge}
                          onChange={(e) =>
                            handleChangees(
                              index,
                              e.target.value,
                              "extra_bed_charge"
                            )
                          }
                          min={0}
                        />

                        {formerror6.map((err, i) => {
                          return (
                            <>
                              {index === i && err && err.extra_bed_charge ? (
                                <label style={{ color: "red" }}>
                                  {err.extra_bed_charge}
                                </label>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      </span>
                    ) : (
                      ""
                    )}
                    <span className="be_form_room_info_label_25 bed_float">
                      <label
                        for="firstNameinput"
                        className="form-label be_form_label "
                      >
                        Number of bedrooms
                        <span className="form-validation-required">*</span>
                        <div class="info-tag-new">i</div>
                        <span className="tooltiptext-new">
                          {" "}
                          Total number of rooms inside in this particular room
                          type
                        </span>
                      </label>
                      <input
                        type="number"
                        onKeyDown={(e) =>
                          symbolsArr.includes(e.key) && e.preventDefault()
                        }
                        onFocus={(e) =>
                          e.target.addEventListener(
                            "wheel",
                            function (e) {
                              e.preventDefault();
                            },
                            { passive: false }
                          )
                        }
                        className="form-control"
                        placeholder=""
                        id="room_nos"
                        maxlength="5"
                        min={0}
                        value={data.room_detail.no_of_rooms}
                        // addInputFieldRoom
                        onChange={(e) =>
                          addInputFieldRoom(
                            index,
                            e.target.value,
                            "no_of_rooms"
                          )
                        }
                        name="no_of_rooms"
                      />

                      {formerror6.map((err, i) => {
                        return (
                          <>
                            {index === i && err && err.room_nos ? (
                              <label style={{ color: "red" }}>
                                {err.room_nos}
                              </label>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </span>
                    <div className="clearfix"></div>
                    {data.room_detail.no_of_rooms > 0
                      ? data.room_beds.map((ele, ind) => {
                          return (
                            <>
                              <span class="roomtype-info">
                                <span class="be-form-line-separation">
                                  <span class="room-sec-title">
                                    Room {ind + 1}
                                  </span>
                                </span>
                                <span class="be_form_room_info_label_25 beds_per_room">
                                  <label
                                    for="firstNameinput"
                                    class="form-label be_form_label"
                                  >
                                    Number of beds per Room
                                    <span class="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="number"
                                    class="form-control"
                                    placeholder=""
                                    value={ele.bed_count}
                                    onKeyDown={(e) =>
                                      symbolsArr.includes(e.key) &&
                                      e.preventDefault()
                                    }
                                    onFocus={(e) =>
                                      e.target.addEventListener(
                                        "wheel",
                                        function (e) {
                                          e.preventDefault();
                                        },
                                        { passive: false }
                                      )
                                    }
                                    min={0}
                                    onChange={(e) =>
                                      handleChangeesinner(
                                        index,
                                        ind,
                                        e.target.value,
                                        "bed_count"
                                      )
                                    }
                                    name="bed_count"
                                  />

                                  {formerror6.map((err, i) => {
                                    return (
                                      <>
                                        {err.roomNo
                                          ? err.roomNo.map((err1, inde) => {
                                              return (
                                                <>
                                                  {index === i &&
                                                  inde === ind &&
                                                  err1 &&
                                                  err1.no_of_beds_per_room ? (
                                                    <label
                                                      style={{ color: "red" }}
                                                    >
                                                      {err1.no_of_beds_per_room}
                                                    </label>
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              );
                                            })
                                          : ""}
                                      </>
                                    );
                                  })}
                                </span>
                                <span class="be_form_room_info_label_25 bedtype">
                                  <label
                                    for="firstNameinput"
                                    class="form-label be_form_label api3"
                                  >
                                    BedType
                                    <span class="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <select
                                    class="form-select"
                                    id="bedtype_select "
                                    value={ele.bed_type}
                                    onChange={(e) =>
                                      handleChangeesinnerbed(
                                        index,
                                        ind,
                                        e.target.value,
                                        "bed_type"
                                      )
                                    }
                                    name="bed_type"
                                  >
                                    <option value="">Choose</option>
                                    {bedtype.map((bed, j) => {
                                      return (
                                        <option key={j} value={bed.value}>
                                          {bed.label}
                                        </option>
                                      );
                                    })}
                                    <option value="other">Other</option>
                                  </select>

                                  {formerror6.map((err, i) => {
                                    return (
                                      <>
                                        {err.roomNo
                                          ? err.roomNo.map((err1, inde) => {
                                              return (
                                                <>
                                                  {index === i &&
                                                  inde === ind &&
                                                  err1 &&
                                                  err1.bed_type ? (
                                                    <label
                                                      style={{ color: "red" }}
                                                    >
                                                      {err1.bed_type}
                                                    </label>
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              );
                                            })
                                          : ""}
                                      </>
                                    );
                                  })}
                                </span>
                                {ele.bed_type === "other" ? (
                                  <span class="be_form_room_info_label_25 other_bedtype ">
                                    <label
                                      for="firstNameinput"
                                      class="form-label be_form_label"
                                    >
                                      Other Bed Type
                                      <span class="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder=""
                                      value={ele.other}
                                      onChange={(e) =>
                                        handleChangeesinner(
                                          index,
                                          ind,
                                          e.target.value,
                                          "other"
                                        )
                                      }
                                      name="other"
                                    />

                                    {formerror6.map((err, i) => {
                                      return (
                                        <>
                                          {err.roomNo
                                            ? err.roomNo.map((err1, inde) => {
                                                return (
                                                  <>
                                                    {index === i &&
                                                    inde === ind &&
                                                    err1 &&
                                                    err1.other ? (
                                                      <label
                                                        style={{ color: "red" }}
                                                      >
                                                        {err1.other}
                                                      </label>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                );
                                              })
                                            : ""}
                                        </>
                                      );
                                    })}
                                  </span>
                                ) : (
                                  ""
                                )}
                                <span class="be_form_room_info_label_25 bedtype">
                                  <label
                                    for="firstNameinput"
                                    class="form-label be_form_label"
                                  >
                                    Bed Size (Cm)
                                    <span class="form-validation-required">
                                      *
                                    </span>
                                    <div class="info-tag-new">i</div>
                                    <span className="tooltiptext-new">
                                      Bed size in Cm{" "}
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control api4"
                                    placeholder=""
                                    value={ele.bedsize}
                                    onChange={(e) =>
                                      handleChangeesinner(
                                        index,
                                        ind,
                                        e.target.value,
                                        "bedsize"
                                      )
                                    }
                                    name="bedsize"
                                  />

                                  {formerror6.map((err, i) => {
                                    return (
                                      <>
                                        {err.roomNo
                                          ? err.roomNo.map((err1, inde) => {
                                              return (
                                                <>
                                                  {index === i &&
                                                  inde === ind &&
                                                  err1 &&
                                                  err1.bed_size ? (
                                                    <label
                                                      style={{ color: "red" }}
                                                    >
                                                      {err1.bed_size}
                                                    </label>
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              );
                                            })
                                          : ""}
                                      </>
                                    );
                                  })}
                                </span>
                              </span>
                              <span class="be-form-line-separation"></span>
                            </>
                          );
                        })
                      : ""}

                    <span className="roomtype-append"></span>
                    <span className="be_form_room_info_label_full">
                      <label
                        for="firstNameinput"
                        className="form-label be_form_label"
                      >
                        Room Description{" "}
                        <span className="form-validation-required">*</span>
                        <div class="info-tag-new">i</div>
                        <span className="tooltiptext-new">
                          Give details about this particular roomtype.
                        </span>
                      </label>
                      <textarea
                        className="db-desc-box"
                        rows="3"
                        value={data.room_detail.description}
                        onChange={(e) =>
                          handleChangees(index, e.target.value, "description")
                        }
                        name="description"
                      ></textarea>

                      {formerror6.map((err, i) => {
                        return (
                          <>
                            {index === i && err && err.room_des ? (
                              <label style={{ color: "red" }}>
                                {err.room_des}
                              </label>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </span>
                    <span style={{ padding: "10px" }}></span>
                  </div>
                  <div className="clearfix"></div>
                  <div className="room_info_appendmore"></div>

                  <a
                    className={
                      index === 0
                        ? "room_info_remove_more be_form_common_remove_more d-none"
                        : "room_info_remove_more be_form_common_remove_more"
                    }
                    onClick={(e) => removeInputFields(index)}
                  >
                    X
                  </a>
                  <div className="clearfix"></div>
                  <div
                    className={
                      index === roominfo.length - 1 ? "boder1 d-none" : "boder1"
                    }
                  ></div>
                </div>
              );
            })}
            <div className="be-form-add-anthor-section">
              <Link
                className="room-info-addmore be_form_common_add_more"
                id="room-info-addmore"
                data-count="1"
                to="#"
                onClick={addInputField}
              >
                Add next room type
              </Link>
              <div className="add-more-bottom-space"></div>
            </div>
          </form>
          <div className="tab_nav_section">
            <div className="tab_nav_section_btns_form">
              <button
                className="btn btn-be-form-cancel btn-be-back"
                // onClick={(e) => setTab("Hotel-info"),setHoteldetails}
                onClick={roomprevtab}
              >
                Prev
              </button>

              {roominfoupdatestatus === true && roombutton === true ? (
                <button
                  className="btn btn-success btn-be-common"
                  // onClick={() => setTab("Room-info")}
                  onClick={roominfonext}
                  disabled
                >
                  Save & Next
                </button>
              ) : roominfoupdatestatus === true && roombutton === false ? (
                <button
                  className="btn btn-success btn-be-common"
                  // onClick={() => setTab("Room-info")}
                  onClick={roominfonext}
                >
                  Save & Next
                </button>
              ) : roominfoupdatestatus === false &&
                room_info_status === true ? (
                <button
                  className="btn btn-success btn-be-common"
                  onClick={(e) => setTab("Freequency")}
                >
                  Next
                </button>
              ) : roombutton === true ? (
                <button
                  className="btn btn-success btn-be-common"
                  // onClick={() => setTab("Room-info")}
                  onClick={roominfonext}
                  disabled
                >
                  Save & Next
                </button>
              ) : (
                <button
                  className="btn btn-success btn-be-common"
                  // onClick={() => setTab("Room-info")}
                  onClick={roominfonext}
                >
                  Save & Next
                </button>
              )}
            </div>
          </div>
          {roomprev === true ? (
            <Previousmodal
              message="Room information"
              roomprev={roomprev}
              setRoomprev={setRoomprev}
              tag="room"
              Prvfunction={Prvfunction}
              closetab={closetab}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Roominformaton;
