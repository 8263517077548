import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Nodatafound from "../../../Common/Nodatafound";
import Privatesaleheader from "../../../Common/Privatesaleheader";
import Toastmodal from "../../../Common/Toastmodal";
import { useAuthHandling } from "../../../Common/useAuthHandling";
import Config from "../../../Config";
import { UserContext } from "../../../Contexts/UserContext";
import DeleteModal from "../../../DeleteModal";
import Loading from "../../../Loading";

function Agencieslist() {
  const [list, setList] = useState([]);
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
    const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
    const [hotelname, setHotelname] = useState(
    hotel_select ? hotel_select[0]?.label : ""
  );
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageindex, setPageindex] = useState(1);
  const [paginate, setPaginate] = useState([]);
  const [pageid, setPageid] = useState("");
  const [selehotel, setSelechotel] = useState([]);
  const [showFullData, setShowFullData] = useState([]);
  const [delete_hotelId, setDelete_hotelId] = useState("");

  const handleDropdownClickhotel = (i) => {
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  function tog_animationFlip(ids,hotel_id) {
    setDelete_hotelId(hotel_id);
    setmodal_animationFlip(!modal_animationFlip);
    setPageid(ids);
  }

  const getlist = async (e) => {
    const res = await fetch(
      `${Config.apiurl}private-sale/agencies/list`,
      {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200" || !data.data) {
        console.log("error");
      } else {
        setLoading(false);
        setList(data.data.data);
        setPaginate(data.data.last_page);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getlist();
    } else {
      console.log("error");
    }
  };

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}private-sale/agencies/list=${currentPage}`,
      {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
        }),

        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const data = await res.json();
    if (data.status_code === "200") {
      return data.data.data;
    } else if (data.reauth === true) {
      handleUnauthorized();
      fetchComments(currentPage);
    }
  };

  const Delete = async (ids) => {
    const res = await fetch(
      `${Config.apiurl}hotel/private-sale/agencies/delete`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id: delete_hotelId,
          company_id: ids,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const data = await res.json();
    if (res.status === 200) {
      setToastmodal(true);
      setToastmessage(data.message);
      setToasttype("success");
      setToastlarge(true);
      tog_animationFlip(false);
      getlist();
    } else if (res.reauth === true) {
      handleUnauthorized();
      Delete();
    } else {
      setToastmodal(true);
      setToastmessage(data.message);
      setToasttype("error");
      setToastlarge(true);
    }
  };

  const handlePageClick = async (data) => {
    if (data === 1) {
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
      setList(commentsFormServer);
    } else {
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
      setList(commentsFormServer);
    }
  };

  useEffect(() => {
    getlist();
  }, [id]);

  const handleHotelSelection = (hotelId) => {
    const isSelected = selehotel.includes(hotelId);
    if (isSelected) {
      setSelechotel(selehotel.filter((id) => id !== hotelId));
    } else {
      setSelechotel([...selehotel, hotelId]);
    }
  };

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    if (list.length > 0) {
      const initialState = list.map((item, index) => ({
        hotel: index === 0,
        companies: Array(item.companies.length).fill(false),
      }));
      setShowFullData(initialState);
    }
  }, [list]);

console.log(selehotel);

  return (
    <div className="main-content">
      <Privatesaleheader />
      <div className="page-content">
        <div className="container-fluid">
          {/* <h4 className="" style={{ marginLeft: "10px" }}>
            {hotelname}
          </h4> */}
          <div
            className="row align-items-center gy-3"
            style={{ marginTop: "5px" }}
          >
            <div className="col-sm d-flex justify-content-between">
              <h5 className="" style={{ color: "#008080" }}>
                LIST OF AGENCIES/COMPANIES
              </h5>
              <div className="flex-shrink-0 d-flex justify-content-between">
                {selehotel.length>0?
                <Link
                  type="button"
                  to={`/agencies-and-companies-list/create/${selehotel}`}
                  className="btn db-save-button"
                >
                  Create
                </Link>
                :
                <Link
                type="button"
                to={`/agencies-and-companies-list/create/${id}`}
                className="btn db-save-button"
              >
                Create
              </Link>
              }
              </div>
            </div>
          </div>
          <div
            className="col-xxl-12 dashboard-main-style"
            style={{ marginTop: "5px" }}
          >
            <div className="card">
                <div className="card-body pt-0">
                  <div>
                    <div className="">
                    {list.map((li, i) => {
                            return (
                              <>
                                <div
                                  className={
                                    list.length > 1
                                      ? "row hotel_single_list"
                                      : "row"
                                  }
                                  key={i}
                                >
                                  {list.length > 1 ? (
                                    <div className="hotel_service_display hotel_service_display_12">
                                      <div className="hotel_service_display_14">
                                      {list.length > 1 ? (
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`hotelCheckbox${i}`}
                                        checked={selehotel.includes(
                                          li.hotel_id
                                        )}
                                        onChange={() =>
                                          handleHotelSelection(li.hotel_id)
                                        }
                                        style={{
                                          position: "relative",
                                          top: "8px",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}

                                        <div className="hk_service_all_hotel" style={{marginLeft:"10px"}}>
                                          <span className="">
                                            {" "}
                                            {pageindex === 1
                                              ? i + 1
                                              : (pageindex - 1) * 10 + i + 1}
                                            .
                                          </span>
                                          <span style={{ marginLeft: "10px" }}>
                                            {li.hotel_name}
                                          </span>
                                        </div>
                                      </div>
                                      {showFullData[i]?.hotel === true ? (
                                        <span
                                          className="db-modify_toggle_btn"
                                          onClick={() =>
                                            handleDropdownClickhotel(i)
                                          }
                                        >
                                          <span
                                            className="bx bxs-chevron-up"
                                            style={{ fontSize: "20px" }}
                                          ></span>
                                        </span>
                                      ) : (
                                        <span
                                          className="db-modify_toggle_btn"
                                          onClick={() =>
                                            handleDropdownClickhotel(i)
                                          }
                                        >
                                          <span
                                            className="bx bxs-chevron-down"
                                            style={{ fontSize: "20px" }}
                                          ></span>
                                        </span>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                        <table
                          className={`table table-nowrap align-middle ${
                            showFullData[i]?.hotel === true
                              ? ""
                              : "d-none"
                          }`}
                          id="orderTable"
                        >
                          {li.companies.length > 0 ? 
                                  <>
                          <thead className="text-muted table-light">
                            <tr className="text-uppercase">
                              <th scope="col" style={{ width: "25px" }}>
                                <div className="form-check"></div>
                              </th>
                              <th className="" data-sort="id">
                                SL No
                              </th>
                              <th className="" data-sort="customer_name">
                                Name
                              </th>
                              <th className="" data-sort="customer_name">
                                Status
                              </th>
                              <th className="" data-sort="city">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {li.companies.map((data, ind) => {
                              return (
                                <>
                                  <tr key={ind}>
                                    <th scope="row">
                                      <div className="form-check"></div>
                                    </th>
                                    <td className="id">
                                      {(pageindex - 1) * 10 + ind + 1}
                                    </td>
                                    <td
                                      className="name"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {data.name}
                                    </td>
                                    <td className="status">
                                      {data.status === false ? (
                                        <span class="badge badge-soft-danger text-uppercase">
                                          {" "}
                                          INACTIVE
                                        </span>
                                      ) : (
                                        <span className="badge badge-soft-success text-uppercase">
                                          ACTIVE
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <ul className="list-inline hstack gap-2 mb-1">
                                        <li
                                          className="list-inline-item edit"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Edit"
                                        >
                                          <Link
                                            to={`/agencies-and-companies-list/edit/${data.company_id}/${li.hotel_id}`}
                                            data-bs-toggle="modal"
                                            className="text-primary d-inline-block edit-item-btn"
                                          >
                                            <i className="ri-pencil-fill fs-16"></i>
                                          </Link>
                                        </li>
                                        <li
                                          className="list-inline-item"
                                          data-bs-toggle="tooltip"
                                          data-bs-trigger="hover"
                                          data-bs-placement="top"
                                          title="Remove"
                                        >
                                          <Link
                                            className="text-danger d-inline-block remove-item-btn"
                                            data-bs-toggle="modal"
                                            to="#"
                                            onClick={() =>
                                              tog_animationFlip(data.company_id,li.hotel_id)
                                            }
                                          >
                                            <i className="ri-delete-bin-5-fill fs-16"></i>
                                          </Link>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                          </>
                          :
                          <Nodatafound/>
                        }
                        </table>
                        </div>
                        </>
                        );
                      })}
                    </div>
                    {paginate > 1 ? (
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={paginate}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
             
            </div>
          </div>
          {toastmodal === true ? (
            <Toastmodal
              setToast={setToastmodal}
              message={toastmessage}
              type={toasttype}
              large={toastlarge}
            />
          ) : (
            ""
          )}
        </div>
        {modal_animationFlip ? (
          <DeleteModal
            modal_animationFlip={modal_animationFlip}
            tog_animationFlip={tog_animationFlip}
            pageid={pageid}
            DeleteData={Delete}
            message={"Agency/Company"}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Agencieslist;
