import React, { useState } from "react";
import Footer from "../../Common/Footer";
import { Link } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import Commonheader from "../../Common/Commonheader";
function Ratelist() {
  const [level, setLevel] = useState("Arrival");
  const SingleOptions = [
    { value: "india", label: "India" },
    { value: "america", label: "America" },
  ];
  const [selectedMulti, setselectedMulti] = useState([]);
  const SingleOptions1 = [
    { value: "deluxe", label: "Deluxe Studio" },
    { value: "delux-bed", label: "Deluxe One Bedroom apartment" },
    { value: "premier-bed", label: "Premier One Bedroom apartment" },
  ];
  const [selectedMulti1, setselectedMulti1] = useState([]);
  const SingleOptions2 = [
    { value: "Room-only", label: "Room Only" },
    { value: "Breakfast", label: "Breakfast" },
    { value: "Full-board", label: "Full Board" },

    { value: "Half-board", label: "Half Board" },
  ];
  const [selectedMulti2, setselectedMulti2] = useState([]);
  const SingleOptions3 = [
    { value: "Policy-1", label: "Policy 1 " },
    { value: "Policy-2", label: "Policy 2" },
    { value: "Policy-3", label: "Policy 3" },
  ];
  const [selectedMulti3, setselectedMulti3] = useState([]);
  return (
    <div id="layout-wrapper">
      <Commonheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Rate List</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Hotel Info</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Rate List</Link>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create New Rate List
                      </h4>
                      <div className="flex-shrink-0">
                        <Link
                          type="button"
                          className="btn db-other-button"
                          to="rooms.html"
                          style={{ marginRight: "5px" }}
                        >
                          Rate List
                        </Link>
                        <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to=""
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target=""
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-translation-style">
                                <ul>
                                  <li className="active">EN</li>
                                  <li>
                                    <Link to="/rate-list-translation">
                                      Translations
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Public Name
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder=""
                                  min="1"
                                  max="5"
                                  onFocus={(e) =>
                                    e.target.addEventListener(
                                      "wheel",
                                      function (e) {
                                        e.preventDefault();
                                      },
                                      { passive: false }
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  for="lastNameinput"
                                  className="form-label"
                                >
                                  Internal Name
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder=""
                                  min="1"
                                  max="5"
                                  onFocus={(e) =>
                                    e.target.addEventListener(
                                      "wheel",
                                      function (e) {
                                        e.preventDefault();
                                      },
                                      { passive: false }
                                    )
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Default release *
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder=""
                                  min="1"
                                  max="5"
                                  onFocus={(e) =>
                                    e.target.addEventListener(
                                      "wheel",
                                      function (e) {
                                        e.preventDefault();
                                      },
                                      { passive: false }
                                    )
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Currency{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <select
                                  className="form-control"
                                  data-choices
                                  name="choices-single-default"
                                  id="choices-single-default"
                                >
                                  <option value="">Choose ...</option>
                                  <option value="Choice 1">AED</option>
                                  <option value="Choice 2">INR </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Market{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>

                                <MultiSelect
                                  options={SingleOptions}
                                  value={selectedMulti}
                                  onChange={setselectedMulti}
                                  labelledBy="Select"
                                  selectionType="counter"
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Confidential{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <div
                                  className="form-check form-switch form-switch-lg mb-3"
                                  dir="ltr"
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customSwitchsizelg"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Loyalty club{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <div
                                  className="form-check form-switch form-switch-lg mb-3"
                                  dir="ltr"
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customSwitchsizelg"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Apply general increment{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <div
                                  className="form-check form-switch form-switch-lg mb-3"
                                  dir="ltr"
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customSwitchsizelg"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="padding-10"></div>
                          <div className="col-ms-12">
                            <div className="form-translation-style">
                              <h6 className="sub-title-form">
                                Assign to Rooms / Occupency and Boards
                              </h6>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Rooms and Occupancy{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <MultiSelect
                                  options={SingleOptions1}
                                  value={selectedMulti1}
                                  onChange={setselectedMulti1}
                                  labelledBy="Select"
                                  selectionType="counter"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Boards{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <MultiSelect
                                  options={SingleOptions2}
                                  value={selectedMulti2}
                                  onChange={setselectedMulti2}
                                  labelledBy="Select"
                                  selectionType="counter"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="padding-10"></div>
                          <div className="col-ms-12">
                            <div className="form-translation-style">
                              <h6 className="sub-title-form">
                                Cacellation Payment Policies
                              </h6>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Assigned Policies{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <MultiSelect
                                  options={SingleOptions3}
                                  value={selectedMulti3}
                                  onChange={setselectedMulti3}
                                  labelledBy="Select"
                                  selectionType="counter"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Default policy*{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <select className="form-control select_translation_codes">
                                  <option value="">Choose ...</option>
                                  <option value="Choice 1">Policy 1</option>
                                  <option value="Choice 2">Policy 2</option>
                                  <option value="Choice 3">Policy 3</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="padding-10"></div>
                          <div className="col-ms-12">
                            <div className="form-translation-style">
                              <h6 className="sub-title-form">
                                Other Configuration{" "}
                              </h6>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Reference foe calculating minum / maximum stay{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <ul
                                  className="stay_type_form"
                                  id="referencr_stay_type"
                                >
                                  <li
                                    className={
                                      level === "Arrival" ? "active" : ""
                                    }
                                    onClick={() => setLevel("Arrival")}
                                  >
                                    By Arrival
                                  </li>
                                  <li
                                    className={level === "Stay" ? "active" : ""}
                                    onClick={() => setLevel("Stay")}
                                  >
                                    By Stay
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Status{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <select
                                  id="ForminputState"
                                  className="form-select"
                                >
                                  <option value="">Choose ...</option>
                                  <option value="Choice 1">Active</option>
                                  <option value="Choice 2">Inactive </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default Ratelist;
