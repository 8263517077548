import React, { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import { useState } from "react";
import { useEffect } from "react";
import Config from "../Config";
import Footer from "../Common/Footer";
import Roomheader from "../Common/Roomheader";
import Toastmodal from "../Common/Toastmodal";
import { useAuthHandling } from "../Common/useAuthHandling";
import { UserContext } from "../Contexts/UserContext";
import { Modal, ModalBody } from "reactstrap";

function Roomdetailsedit() {
  let navigate = useNavigate();
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const { updateHotel_select, hotel_select } = useContext(UserContext);

  const [language, setLanguage] = useState([]);
  const [selectedMulti, setselectedMulti] = useState([]);
  const [selectedMulti1, setselectedMulti1] = useState([]);
  const [board, setBoard] = useState([]);
  const [tab, setTab] = useState("EN");
  const [active, setActive] = useState(false);
  const [roomdetails, setRoomdetails] = useState([]);
  const [occupancy, setOccupancy] = useState([]);
  let params = useParams();
  const [showoccupancy, setShowoccupancy] = useState(false);
  const [id, setId] = useState(params.type_id);
  const [hotelid, setHotelid] = useState(params.hotel_id);
  const [sortorder, setSortorder] = useState(0);
  const [formerror, setError] = useState({});
  const [removeoccupancyid, setRemoveoccupancyid] = useState([]);

  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);

  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [symbolsrate] = useState(["e", "E", "+", "-"]);
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);

  function tog_animationFlip() {
    setmodal_animationFlip(!modal_animationFlip);
  }

  const handleYesClick = () => {
    setRoomdetails((prevDetails) => ({ ...prevDetails, confirm_status: 1 }));
    tog_animationFlip(false);
  };

  const handleNoClick = () => {
    setRoomdetails((prevDetails) => ({ ...prevDetails, confirm_status: 0 }));
    tog_animationFlip(false);
  };

  let handleChangetogghotel = (e) => {
    let value = e.target.checked;
    let name = e.target.name;
    setRoomdetails({ ...roomdetails, [name]: value });
  };
  const handlechange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setRoomdetails({ ...roomdetails, [name]: value });
  };

  let handleChangeshow = (e) => {
    let value = e.target.checked;
    setShowoccupancy(value);
  };

  const getValue = async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setLanguage(data.data);
    }
  };

  const getBoard = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/hotel-boards`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: hotelid,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data1 = await res.json();
    const data = data1.data;
    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setBoard(data);
    }
  };

  const getinfo = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/room/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data1 = await res.json();

    if (data1.status_code === "200") {
      const data = data1.data;
      setRoomdetails(data.room);
      setOccupancy(data.occupancy);
      setselectedMulti(data.display_board);
      setselectedMulti1(data.multi_lang);
      setSortorder(data?.room?.sort_order);
      console.log(data?.room?.sort_order);
    } else if (data1.reauth === true) {
      handleUnauthorized();
      getinfo();
    } else {
      console.log("error");
    }
  };

  const [inputFields, setInputFields] = useState([{ adult: [], child: [] }]);
  const addInputField = () => {
    setOccupancy([
      ...occupancy,
      {
        adult: "",
        child: "",
      },
    ]);
  };
  const removeInputFields = (index) => {
    const rows = [...occupancy];
    rows.splice(index, 1);
    setOccupancy(rows);
    const deletedRow = occupancy[index];

    removeoccupancyid.push(deletedRow.room_occupancy_id);
  };

  useEffect(() => {
    getValue();
    getBoard();
    getinfo();
  }, []);
  // let Update = () => {}

  const handleoccu = (e, i) => {
    let name = e.target.name;
    let value = e.target.value;

    let newval = [...occupancy];
    occupancy[i][name] = value;
    setOccupancy(newval);
  };

  const handlechangeforocc = (e) => {
    console.log("handlechangeforocc");
    console.log(e.target.value);
    let name = e.target.name;
    let value = e.target.value;
    let min_adult, adults, childs;
    setRoomdetails({ ...roomdetails, [name]: value });
    const occu = [...occupancy];
    if (name === "min_no_adults") {
      min_adult = parseInt(e.target.value);
      adults = parseInt(roomdetails.max_no_adults);
      childs = parseInt(roomdetails.max_no_child);
    } else if (name === "max_no_adults") {
      adults = parseInt(e.target.value);
      min_adult = parseInt(roomdetails.min_no_adults);
      childs = parseInt(roomdetails.max_no_child);
    } else if (name === "max_no_child") {
      adults = parseInt(roomdetails.max_no_adults);
      min_adult = parseInt(roomdetails.min_no_adults);
      childs = parseInt(e.target.value);
    }
    let newOccupancy = [];
    console.log(adults);
    console.log(min_adult);
    console.log(childs);

    if (!isNaN(min_adult) && !isNaN(adults) && !isNaN(childs)) {
      console.log("rr");
      if (min_adult !== 0 && adults !== 0) {
        for (let i = min_adult; i <= adults; i++) {
          if (childs !== 0) {
            for (let j = 0; j <= childs; j++) {
              let combinationExists = occu.some(
                (item) => item.adult === i && item.child === j
              );

              if (!combinationExists) {
                occu.push({ adult: i, child: j });
                newOccupancy.push({ adult: i, child: j });
              } else {
                newOccupancy.push({ adult: i, child: j });
              }
            }
          } else {
            let combinationExists = occu.some(
              (item) => item.adult === i && item.child === 0
            );

            if (!combinationExists) {
              occu.push({ adult: i, child: 0 });
              newOccupancy.push({ adult: i, child: 0 });
            } else {
              newOccupancy.push({ adult: i, child: 0 });
            }
          }
        }
      }

      console.log("occupancy", occu);
      console.log("newOccupancy", newOccupancy);

      const itemsToRemove = occu.filter(
        (item) =>
          !newOccupancy.some(
            (newItem) =>
              newItem.adult === item.adult && newItem.child === item.child
          )
      );
      itemsToRemove.forEach((newItem) => {
        if (newItem.room_occupancy_id) {
          removeoccupancyid.push(newItem.room_occupancy_id);
        }
      });

      const commonItems = occu.filter((item) =>
        newOccupancy.some(
          (newItem) =>
            newItem.adult === item.adult && newItem.child === item.child
        )
      );
      setOccupancy(commonItems);
      console.log("itemsToRemove", itemsToRemove);
    }
  };

  let Update = async () => {
    let isFormValid = true;

    const errors = {};
    if (!roomdetails.room_title) {
      isFormValid = false;

      errors.room_title = "Room name is required!";
    }

    if (!roomdetails.room_inventory) {
      isFormValid = false;

      errors.room_inventory = "Room inventory is required!";
    }
    if (!roomdetails.no_of_rooms) {
      isFormValid = false;

      errors.no_of_rooms = "No of rooms is required!";
    }
    if (!roomdetails.min_no_adults) {
      isFormValid = false;

      errors.min_no_adults = "Minimum number of adult is required!";
    }
    if (!roomdetails.max_no_adults) {
      isFormValid = false;

      errors.max_no_adults = "Maximum number of adult is required!";
    }
    if (!roomdetails.max_no_child) {
      isFormValid = false;

      errors.max_no_child = "Maximum number of child required!";
    }
    if (!roomdetails.max_child_age) {
      isFormValid = false;

      errors.max_child_age = "Maximum Child age is required!";
    }
    if (!roomdetails.status) {
      isFormValid = false;

      errors.status = "Status is required!";
    }
    if (sortorder !== 0) {
      if (!sortorder) {
        isFormValid = false;

        errors.sortorder = "Sort order is required!";
      }
    }

    if (roomdetails.extra_bed_status === true) {
      if (roomdetails.base_occupancy !== 0) {
        if (!roomdetails.base_occupancy) {
          isFormValid = false;

          errors.base_occupancy = "Base Occupancy required!";
        }
      }
    }
    if (!roomdetails.room_area) {
      isFormValid = false;

      errors.room_area = "Room area is required!";
    }
    if (selectedMulti.length === 0) {
      isFormValid = false;

      errors.selectedMulti = "Hotel board is required!";
    }

    setError(errors);

    if (isFormValid === true) {
      let occu = [];
      let newocc = [];
      for (let i = 0; i < occupancy.length; i++) {
        if (occupancy[i].room_occupancy_id) {
          let multiarray = {};
          multiarray.occ_id = occupancy[i].room_occupancy_id;
          multiarray.adult = occupancy[i].adult;
          multiarray.child = occupancy[i].child;
          occu.push(multiarray);
        } else {
          let multiarray = {};
          multiarray.adult = occupancy[i].adult;
          multiarray.child = occupancy[i].child;
          newocc.push(multiarray);
        }
      }

      let lang = [];
      for (let i = 0; i < selectedMulti1.length; i++) {
        if (selectedMulti1[i].name) {
          if (selectedMulti1[i].code !== "en") {
            let multiarray = {};
            multiarray.lang_code = selectedMulti1[i].code;
            multiarray.room_title = selectedMulti1[i].name;

            lang.push(multiarray);
          }
        }
      }

      let board = [];

      for (let i = 0; i < selectedMulti.length; i++) {
        board.push(selectedMulti[i].value);
      }

      let upsell_status;
      if (roomdetails.basic_room === true) {
        upsell_status = false;
      } else {
        upsell_status = roomdetails.upselling_available;
      }

      let confirmstatus;
      if (roomdetails.confirm_status) {
        confirmstatus = roomdetails.confirm_status;
      }

      const res = await fetch(`${Config.apiurl}hotel/room/${id}`, {
        method: "POST",
        body: JSON.stringify({
          hotel: roomdetails.hotel_id,
          en_room_title: roomdetails.room_title,
          room_inventory: roomdetails.room_inventory,
          no_of_rooms: roomdetails.no_of_rooms,
          min_no_adults: roomdetails.min_no_adults,
          max_no_adults: roomdetails.max_no_adults,
          max_no_child: roomdetails.max_no_child,
          max_child_age: roomdetails.max_child_age,
          baby_status: roomdetails.baby_status,
          room_area: roomdetails.room_area,
          display_board: board,
          extra_bed_status: roomdetails.extra_bed_status === true ? 1 : 0,
          base_occupancy: roomdetails.base_occupancy,
          is_active: roomdetails.status,
          hide_from_dynamic_section: roomdetails.hide_from_dynamic_section,
          hidden_room_status: roomdetails.hidden_room_status,
          confidential_room_status: roomdetails.confidential_room_status,
          show_only_occupancy_match_status:
            roomdetails.show_only_occupancy_match_status,
          multi_lang: lang,
          occupancy: occu,
          removed_occupancy: removeoccupancyid,
          new_occupancy: newocc,
          confirm_status: confirmstatus,
          basic_room_status: roomdetails.basic_room,
          upselling_available_status: upsell_status,
          sort_order: sortorder,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await res.json();
      if (data.status_code === "200") {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(false);
        setTimeout(() => navigate("/rooms"), 3000);
      } else if (data.reauth === true) {
        handleUnauthorized();
        Update();
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(false);
      }
    }
  };

  return (
    <div className="main-content">
      <Roomheader />
      <div className="page-content">
        <div className="container-fluid">
          <div className="row project-wrapper">
            <div className="row dashboard-top-tab-style">
              <div className="col-xxl-12 dashboard-main-style">
                <div className="card">
                  <div className="card-header">
                    <div className="row align-items-center gy-3">
                      <div className="col-sm">
                        <h5 className="card-title mb-0">Edit Room Details</h5>
                      </div>
                      <div className="col-sm-auto">
                        <div className="d-flex gap-1 flex-wrap">
                          <Link className="btn  db-other-button" to="/rooms">
                            Room Details
                          </Link>
                          {/* <button
                            type="button"
                            className="btn db-cancel-button"
                          >
                            Cancel
                          </button> */}
                          <button
                            className="btn db-save-button"
                            onClick={Update}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="live-preview">
                      <form action="#">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-translation-style">
                              <ul>
                                <li
                                  className={tab === "EN" ? "active" : ""}
                                  onClick={(e) => setTab("EN")}
                                >
                                  EN
                                </li>
                                <li
                                  className={tab === "TRANS" ? "active" : ""}
                                  onClick={(e) => setTab("TRANS")}
                                >
                                  Translations
                                </li>
                              </ul>
                            </div>
                          </div>

                          {tab === "EN" ? (
                            <>
                              <div className="row g-3">
                                <div className="col-lg-3">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Room Id
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="room_key"
                                      value={roomdetails.room_key}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Room Name
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="room_title"
                                      value={roomdetails.room_title}
                                      onChange={handlechange}
                                    />
                                    <label style={{ color: "red" }}>
                                      {formerror.room_title}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-2">
                                  <div className="input-group">
                                    <label
                                      for="firstNameinput"
                                      className="input-group-text input-joint-color"
                                    >
                                      Inventory
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      onKeyDown={(e) =>
                                        symbolsArr.includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      type="text"
                                      className="form-control"
                                      name="room_inventory"
                                      value={roomdetails.room_inventory}
                                      onChange={handlechange}
                                    />
                                    <label style={{ color: "red" }}>
                                      {formerror.room_inventory}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-2">
                                  <div className="input-group">
                                    <label
                                      for="firstNameinput"
                                      className="input-group-text input-joint-color"
                                    >
                                      No.of Rooms
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      onKeyDown={(e) =>
                                        symbolsArr.includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      className="form-control"
                                      name="no_of_rooms"
                                      value={roomdetails.no_of_rooms}
                                      onChange={handlechange}
                                    />
                                    <label style={{ color: "red" }}>
                                      {formerror.no_of_rooms}
                                    </label>
                                  </div>
                                </div>

                                <div className="row g-3">
                                  <div className="col-lg-3">
                                    <ul className="rate-extra-info">
                                      <li className="label-li">
                                        <label
                                          className="form-label"
                                          style={{ marginLeft: "4px" }}
                                        >
                                          Baby Cot Status
                                          <span className="form-validation-required"></span>
                                        </label>
                                      </li>
                                      <li className="text-li">
                                        <span
                                          className="form-check form-switch form-check-inline condition-active-status"
                                          dir="ltr"
                                        >
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="inlineswitch5"
                                            name="baby_status"
                                            value={roomdetails.baby_status}
                                            checked={
                                              roomdetails.baby_status === true
                                                ? true
                                                : false
                                            }
                                            onChange={handleChangetogghotel}
                                            style={{
                                              width: "50px",
                                              height: "25px",
                                            }}
                                          />
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="col-lg-2">
                                    <div className="input-group">
                                      <label
                                        for="firstNameinput"
                                        className="input-group-text input-joint-color"
                                        style={{ width: "120px" }}
                                      >
                                        Min.No.of Adult
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        onKeyDown={(e) =>
                                          symbolsArr.includes(e.key) &&
                                          e.preventDefault()
                                        }
                                        type="text"
                                        className="form-control"
                                        name="min_no_adults"
                                        value={roomdetails.min_no_adults}
                                        // onChange={handlechange}
                                        onChange={handlechangeforocc}
                                      />
                                      <label style={{ color: "red" }}>
                                        {formerror.min_no_adults}
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-lg-2">
                                    <div className="input-group">
                                      <label
                                        for="firstNameinput"
                                        className="input-group-text input-joint-color"
                                        style={{ width: "120px" }}
                                      >
                                        Max.No.of Adult
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        onKeyDown={(e) =>
                                          symbolsArr.includes(e.key) &&
                                          e.preventDefault()
                                        }
                                        type="text"
                                        className="form-control"
                                        name="max_no_adults"
                                        value={roomdetails.max_no_adults}
                                        // onChange={handlechange}
                                        onChange={handlechangeforocc}
                                      />
                                      <label style={{ color: "red" }}>
                                        {formerror.max_no_adults}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-2">
                                    <div className="input-group">
                                      <label
                                        for="firstNameinput"
                                        className="input-group-text input-joint-color"
                                        style={{ width: "120px" }}
                                      >
                                        Max.No.of Child
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        onKeyDown={(e) =>
                                          symbolsArr.includes(e.key) &&
                                          e.preventDefault()
                                        }
                                        type="text"
                                        className="form-control"
                                        name="max_no_child"
                                        value={roomdetails.max_no_child}
                                        // onChange={handlechange}
                                        onChange={handlechangeforocc}
                                      />
                                      <label style={{ color: "red" }}>
                                        {formerror.max_no_child}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-lg-2">
                                    <div className="input-group">
                                      <label
                                        for="firstNameinput"
                                        className="input-group-text input-joint-color"
                                        style={{ width: "120px" }}
                                      >
                                        Max.Age of Child
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        onKeyDown={(e) =>
                                          symbolsArr.includes(e.key) &&
                                          e.preventDefault()
                                        }
                                        type="text"
                                        className="form-control"
                                        name="max_child_age"
                                        value={roomdetails.max_child_age}
                                        onChange={handlechange}
                                      />
                                      <label style={{ color: "red" }}>
                                        {formerror.max_child_age}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row g-3">
                                <div className="col-lg-3">
                                  <ul className="rate-extra-info">
                                    <li className="label-li">
                                      <label
                                        className="form-label"
                                        style={{ marginLeft: "4px" }}
                                      >
                                        Extra Bed Status
                                        <span className="form-validation-required"></span>
                                      </label>
                                    </li>
                                    <li className="text-li">
                                      <span
                                        className="form-check form-switch form-check-inline condition-active-status"
                                        dir="ltr"
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="inlineswitch5"
                                          name="extra_bed_status"
                                          value={roomdetails.extra_bed_status}
                                          checked={
                                            roomdetails.extra_bed_status ===
                                            true
                                              ? true
                                              : false
                                          }
                                          onChange={handleChangetogghotel}
                                          style={{
                                            width: "50px",
                                            height: "25px",
                                          }}
                                        />
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                                {roomdetails.extra_bed_status === true ? (
                                  <div className="col-md-3">
                                    <div className="col-lg-12">
                                      <div className="input-group">
                                        <label
                                          for="firstNameinput"
                                          className="input-group-text input-joint-color"
                                          style={{ width: "142px" }}
                                        >
                                          Base Occupancy
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                          <span
                                            className="info-tag"
                                            style={{ backgroundColor: "black" }}
                                          >
                                            i
                                            <span class="tooltiptext">
                                              Maximum no. of adult without extra
                                              bed
                                            </span>
                                          </span>
                                        </label>
                                        <input
                                          onKeyDown={(e) =>
                                            symbolsrate.includes(e.key) &&
                                            e.preventDefault()
                                          }
                                          type="text"
                                          className="form-control"
                                          name="base_occupancy"
                                          value={roomdetails.base_occupancy}
                                          onChange={handlechange}
                                        />
                                        <label style={{ color: "red" }}>
                                          {formerror.base_occupancy}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {/* <div className={room===true?"col-md-2":"d-none"}>
                                            <div className="col-lg-12">
                                             <div className="input-group">
                                                <label for="firstNameinput" className="input-group-text input-joint-color" style={{width:"120px"}}>Extra Bed Rate<span
                                                        className="form-validation-required">*</span></label>
                                                <input type="text" className="form-control" placeholder=""/>
                                            </div>
                                        </div>
                                        </div> */}

                                <div className="col-lg-3">
                                  <div className="input-group">
                                    <label
                                      for="firstNameinput"
                                      className="input-group-text input-joint-color"
                                    >
                                      Room Area
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      onKeyDown={(e) =>
                                        symbolsrate.includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      type="text"
                                      className="form-control"
                                      name="room_area"
                                      value={roomdetails.room_area}
                                      onChange={handlechange}
                                    />
                                    <label style={{ color: "red" }}>
                                      {formerror.room_area}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Sort Order{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="number"
                                      className="sort-order-field"
                                      value={sortorder}
                                      onChange={(e) =>
                                        setSortorder(e.target.value)
                                      }
                                      min={0}
                                      onFocus={(e) =>
                                        e.target.addEventListener(
                                          "wheel",
                                          function (e) {
                                            e.preventDefault();
                                          },
                                          { passive: false }
                                        )
                                      }
                                      style={{ width: "65px" }}
                                    />
                                    <label style={{ color: "red" }}>
                                      {formerror.sortorder}
                                    </label>
                                  </div>
                                </div>
                                <div className="row g-3">
                                  <div className="col-lg-6">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                        style={{ height: "40px" }}
                                      >
                                        Hotel Boards
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <div className="col-md-8">
                                        <MultiSelect
                                          options={board}
                                          value={selectedMulti}
                                          onChange={(val) => {
                                            setselectedMulti(val);
                                          }}
                                          labelledBy="Select"
                                          selectionType="counter"
                                          //closeOnChangedValue={true}
                                          aria-expanded="true"
                                        />
                                        <label style={{ color: "red" }}>
                                          {formerror.selectedMulti}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="input-group">
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                      >
                                        Status{" "}
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </label>
                                      <select
                                        id="ForminputState"
                                        className="form-select"
                                        value={roomdetails.status}
                                        onChange={handlechange}
                                        name="status"
                                      >
                                        <option value="">Choose...</option>
                                        <option
                                          {...(roomdetails.status === true
                                            ? "selected"
                                            : "")}
                                          value={true}
                                        >
                                          Active
                                        </option>
                                        <option
                                          {...(roomdetails.status === false
                                            ? "selected"
                                            : "")}
                                          value={false}
                                        >
                                          Inactive
                                        </option>
                                      </select>
                                    </div>
                                    <label style={{ color: "red" }}>
                                      {formerror.status}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    name="basic_room"
                                    value={roomdetails.basic_room}
                                    checked={
                                      roomdetails.basic_room === true
                                        ? true
                                        : false
                                    }
                                    onChange={handleChangetogghotel}
                                  />
                                  <span className="room-check">Basic Room</span>
                                  <span style={{ paddingLeft: "3%" }}>
                                    {roomdetails.basic_room === true ? (
                                      <>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="formCheck1"
                                          name="upselling_available"
                                          value={
                                            roomdetails.upselling_available
                                          }
                                          checked={
                                            roomdetails.upselling_available ===
                                            true
                                              ? true
                                              : false
                                          }
                                          onChange={handleChangetogghotel}
                                          disabled
                                        />
                                        <span
                                          className="room-check"
                                          style={{ color: "#d2d2d2" }}
                                        >
                                          Upselling
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="formCheck1"
                                          name="upselling_available"
                                          value={
                                            roomdetails.upselling_available
                                          }
                                          checked={
                                            roomdetails.upselling_available ===
                                            true
                                              ? true
                                              : false
                                          }
                                          onChange={handleChangetogghotel}
                                        />
                                        <span className="room-check">
                                          Upselling
                                        </span>
                                      </>
                                    )}
                                  </span>
                                </div>
                                <div className="col-md-12">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    name="hide_from_dynamic_section"
                                    value={
                                      roomdetails.hide_from_dynamic_section
                                    }
                                    checked={
                                      roomdetails.hide_from_dynamic_section ===
                                      true
                                        ? true
                                        : false
                                    }
                                    onChange={handleChangetogghotel}
                                  />
                                  <span className="room-check">
                                    Hide it at the dynamic website section
                                  </span>
                                </div>

                                <div className="col-md-12">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    name="hidden_room_status"
                                    value={roomdetails.hidden_room_status}
                                    checked={
                                      roomdetails.hidden_room_status === true
                                        ? true
                                        : false
                                    }
                                    onChange={handleChangetogghotel}
                                  />
                                  <span className="room-check">
                                    Hidden room for Metas
                                  </span>
                                </div>

                                <div className="col-md-12">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    name="confidential_room_status"
                                    value={roomdetails.confidential_room_status}
                                    checked={
                                      roomdetails.confidential_room_status ===
                                      true
                                        ? true
                                        : false
                                    }
                                    onChange={handleChangetogghotel}
                                  />
                                  <span className="room-check">
                                    Confidential: Promotional code required
                                  </span>
                                </div>

                                <div className="col-md-12">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    name="show_only_occupancy_match_status"
                                    value={
                                      roomdetails.show_only_occupancy_match_status
                                    }
                                    checked={
                                      roomdetails.show_only_occupancy_match_status ===
                                      true
                                        ? true
                                        : false
                                    }
                                    onChange={handleChangetogghotel}
                                  />
                                  <span className="room-check">
                                    Only show when the requested occupancy
                                    matches exactly with an inventory occupancy
                                  </span>
                                </div>

                                <div className="col-md-12">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    name="occupancy"
                                    value={showoccupancy}
                                    checked={
                                      showoccupancy === true ? true : false
                                    }
                                    onChange={handleChangeshow}
                                  />
                                  <span className="room-check">
                                    Show Occupancy
                                  </span>
                                  <div
                                    className={
                                      showoccupancy === true
                                        ? "row"
                                        : "row d-none"
                                    }
                                    style={{ marginLeft: "20px" }}
                                  >
                                    {/* <div className="col-md-4 d-flex ">
                                          <div for="firstNameinput" className="addmore_select-label_roomedit ">
                                            No.of Adult
                                            <span className="form-validation-required">*</span>
                                          </div>
                                          <div for="firstNameinput" className="addmore_select-label_roomedit" >
                                          No.of Child 
                                            <span className="form-validation-required">*</span>
                                          </div>
                                        </div> */}

                                    <div className="row mb-2 roomedit_rows">
                                      <div className="addmore_select-label_roomedit">
                                        No.of Adult
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </div>
                                      <div className=" addmore_select-label_roomedit">
                                        No.of Child
                                        <span className="form-validation-required">
                                          *
                                        </span>
                                      </div>
                                    </div>
                                    {occupancy.map((data, index) => {
                                      return (
                                        <div key={index}>
                                          <div
                                            className="row mb-2 roomedit_rows"
                                            key={index}
                                          >
                                            <div className="col-md-1 mr-2">
                                              <input
                                                onKeyDown={(e) =>
                                                  symbolsArr.includes(e.key) &&
                                                  e.preventDefault()
                                                }
                                                type="number"
                                                min={0}
                                                className="form-control room_edit_in"
                                                name="adult"
                                                value={data.adult}
                                                onChange={(e) => {
                                                  handleoccu(e, index);
                                                }}
                                                onFocus={(e) =>
                                                  e.target.addEventListener(
                                                    "wheel",
                                                    function (e) {
                                                      e.preventDefault();
                                                    },
                                                    { passive: false }
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-1">
                                              <input
                                                onKeyDown={(e) =>
                                                  symbolsArr.includes(e.key) &&
                                                  e.preventDefault()
                                                }
                                                type="number"
                                                min={0}
                                                className="form-control room_edit_in"
                                                name="child"
                                                value={data.child}
                                                onChange={(e) => {
                                                  handleoccu(e, index);
                                                }}
                                                onFocus={(e) =>
                                                  e.target.addEventListener(
                                                    "wheel",
                                                    function (e) {
                                                      e.preventDefault();
                                                    },
                                                    { passive: false }
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-1 padding_01">
                                              <span
                                                className={
                                                  index === occupancy.length - 1
                                                    ? "extrarate-room-link"
                                                    : "extrarate-room-link d-none"
                                                }
                                              >
                                                <Link
                                                  className="extra-rate-add"
                                                  to="#"
                                                  onClick={addInputField}
                                                >
                                                  +
                                                </Link>
                                              </span>
                                              <Link
                                                className={
                                                  index === 0
                                                    ? "remove_more d-none"
                                                    : "remove_more"
                                                }
                                                to="#"
                                                onClick={(e) =>
                                                  removeInputFields(index)
                                                }
                                              >
                                                X
                                              </Link>
                                              <br></br>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className="padding-10"></div>
                            </>
                          ) : (
                            <>
                              <div className="col-lg-4">
                                <div className="input-group">
                                  <label
                                    className="input-group-text input-joint-color"
                                    id="inputGroup-sizing-default"
                                    style={{ width: "130px" }}
                                  >
                                    Choose Language
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <div className="col-md-7">
                                    <MultiSelect
                                      options={language}
                                      value={selectedMulti1}
                                      onChange={(val) => setselectedMulti1(val)}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      closeOnChangedValue={true}
                                      aria-expanded="true"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="appendmore"></div>
                              {selectedMulti1.length > 0
                                ? selectedMulti1.map((element, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="col-md-12 translation-fill-section"
                                        id="lang_ +val"
                                        data-value="1"
                                        style={{ marginTop: "20px" }}
                                      >
                                        <div className="translation-selected-lang">
                                          <p>
                                            <span className="translation-selected-lang-head">
                                              {element.code}
                                            </span>
                                          </p>
                                        </div>
                                        <div className="col-lg-4">
                                          <div className="input-group">
                                            <label
                                              className="input-group-text input-joint-color"
                                              id="inputGroup-sizing-default"
                                            >
                                              Room Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              value={element.name}
                                              onChange={(e) =>
                                                setselectedMulti1(
                                                  (prevsetselectedMulti1) =>
                                                    prevsetselectedMulti1.map(
                                                      (item, i) =>
                                                        i === index
                                                          ? {
                                                              ...item,
                                                              name: e.target
                                                                .value,
                                                            }
                                                          : item
                                                    )
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : ""}
                            </>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        id="flipModal"
        className="new_modal_loyal"
        isOpen={modal_animationFlip}
        toggle={() => {
          tog_animationFlip();
        }}
        modalClassName="flip"
        centered
        style={{ fontFamily: "IBM Plex Sans,sans-serif" }}
      >
        <ModalBody
          className="modal-body text-center"
          style={{ height: "150px", width: "540px" }}
        >
          <div className="restr_policy_expand" style={{ marginTop: "10px" }}>
            Basic room is already exist, Do you want to set this room as basic
            room?
          </div>
          <button className="board_create_click" style={{ marginTop: "20px" }}>
            <Link to="#" onClick={() => handleYesClick()}>
              {" "}
              Yes{" "}
            </Link>
          </button>
          <button
            className="board_create_click"
            style={{ marginTop: "20px", marginLeft: "2%" }}
          >
            <Link to="#" onClick={() => handleNoClick()}>
              {" "}
              No{" "}
            </Link>
          </button>
        </ModalBody>
      </Modal>
      <Footer />

      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Roomdetailsedit;
