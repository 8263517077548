import React, { useState } from "react";
import { Link } from "react-router-dom";
import Alerts from "../../Alerts";
import Basicsettingsheader from "../../Common/Basicsettingsheader";
import Commonheader from "../../Common/Commonheader";
import Footer from "../../Common/Footer";
import Config from "../../Config";
import { useAuthHandling } from "../../Common/useAuthHandling";

function Currencyadd() {
  const [currency, setCurrency] = useState({
    title: "",
    symbol: "",
    code: "",
    symbolplacement: "",
    status: "",
  });
  const [alert, setAlert] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [formerror, setError] = useState({});
  const [open, setOpen] = useState(false);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  function Reset() {
    setCurrency({
      title: "",
      symbol: "",
      code: "",
      symbolplacement: "",
      status: "",
    });
  }
  let name, value;
  const handleInput = (e) => {
    e.preventDefault();
    console.log(e);
    name = e.target.name;
    value = e.target.value;
    setCurrency({ ...currency, [name]: value });
  };

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Title is required!";
    }
    if (!values.code) {
      errors.code = "Code is required!";
    }
    if (!values.symbol) {
      errors.symbol = "Symbol is required!";
    }
    if (!values.symbolplacement) {
      errors.symbolplacement = "Symbolplacement is required!";
    }
    if (!values.status) {
      errors.status = "Status is required!";
    }
    return errors;
  };

  const PostData = async (e) => {
    e.preventDefault();
    const ress = validate(currency);
    setError(ress);
    if (
      !ress.title &&
      !ress.code &&
      !ress.symbol &&
      !ress.symbolplacement &&
      !ress.status
    ) {
      const res = await fetch(`${Config.apiurl}admin/currency/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          name: currency.title,
          symbol: currency.symbol,
          code: currency.code,
          symbol_placement: currency.symbolplacement,
          is_active: currency.status,
        }),
      });

      const data = await res.json();

      if (data.status_code === "200" ) {
        setAlert(true);
        setAlertmessage(data.message);
        setOpen(true);
        setAlerttype("success");
        Reset();

        
      } 
      else if(data.reauth===true){
        handleUnauthorized();
        PostData();
      }
      else {
        setAlert(true);
        setAlertmessage(data.message);
        setOpen(true);
        setAlerttype("error");
      }
    }
  };
  return (
    <div id="layout-wrapper">
    <Basicsettingsheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Currency</h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Settings</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="#">Basic Settings</Link>
                      </li>
                      <li className="breadcrumb-item active">Currency</li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>
            {alert ? (
              <Alerts type={alerttype} message={alertmessage} open={open} />
            ) : (
              ""
            )}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex card-header-currency">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create Currency
                      </h4>
                      <div className="flex-shrink-0">
                        <Link
                          type="button"
                          className="btn db-other-button btn-currency"
                          to="/basic_settings/currency-list"
                          style={{ marginRight: "5px" }}
                        >
                          Currencies
                        </Link>
                        <button
                          type="button"
                          className="btn db-cancel-button save-currency"
                          data-bs-toggle="offcanvas"
                          to=""
                          onClick={Reset}
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          className="btn db-save-button save-currency"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target=""
                          onClick={PostData}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Title{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  name="title"
                                  value={currency.title}
                                  onChange={handleInput}
                                />
                                <label style={{ color: "red" }}>
                                  {formerror.title}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="lastNameinput"
                                  className="form-label"
                                >
                                  Symbol
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  name="symbol"
                                  id="lastNameinput"
                                  value={currency.symbol}
                                  onChange={handleInput}
                                />
                                <label style={{ color: "red" }}>
                                  {formerror.symbol}
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Currency Code
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  id="lastNameinput"
                                  name="code"
                                  value={currency.code}
                                  onChange={handleInput}
                                />
                                <label style={{ color: "red" }}>
                                  {formerror.code}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Symbol placement
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <select
                                  id="ForminputState"
                                  className="form-select"
                                  value={currency.symbolplacement}
                                  onChange={handleInput}
                                  name="symbolplacement"
                                >
                                  <option selected>Choose...</option>
                                  <option value="before" name="symbolplacement">
                                    Before
                                  </option>
                                  <option value="after" name="symbolplacement">
                                    After
                                  </option>
                                </select>
                                <label style={{ color: "red" }}>
                                  {formerror.symbolplacement}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Status{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <select
                                  id="ForminputState"
                                  className="form-select"
                                  name="status"
                                  value={currency.status}
                                  onChange={handleInput}
                                >
                                  <option selected>Choose...</option>
                                  <option name="status" value="1">
                                    Active
                                  </option>
                                  <option name="status" value="0">
                                    Inactive
                                  </option>
                                </select>
                                <label style={{ color: "red" }}>
                                  {formerror.status}
                                </label>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="appendmore"></div>
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default Currencyadd;
