import React, { useContext, useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Link, useNavigate } from "react-router-dom";
import Toastmodal from "../../Common/Toastmodal";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Config from "../../Config";
import Select from "react-select";
import { UserContext } from "../../Contexts/UserContext";
import ReactPaginate from "react-paginate";
import Loading from "../../Loading";

function Poststayemail() {
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  let navigate = useNavigate();
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
    const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
    const [hotelname, setHotelname] = useState(
    hotel_select ? hotel_select[0]?.label : ""
  );

  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [list, setList] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [Error, setError] = useState({});
  const [tab, setTab] = useState([]);
  const [multi, setMulti] = useState([]);
  const [language, setLanguage] = useState([]);
  const [existcode, setExistcode] = useState([]);
  const [selectcode, setSelectCode] = useState([]);
  const [destinationurl, setDestinationurl] = useState("");
  const [inputEnDestination, setInputEnDestination] = useState("");
  const [multiexist, setMultiexist] = useState([]);
  const [destinationurlexist, setDestinationurlexist] = useState("");
  const [inputEnDestinationexist, setInputEnDestinationexist] = useState("");
  const [selectedusage, setSelectedusage] = useState([]);
  const [loader, setLoader] = useState(false);
  const [paginate, setPaginate] = useState([]);
  const [page, setPage] = useState(1);
  const [pageindex, setPageindex] = useState(1);
  const [showFullData, setShowFullData] = useState([]);
  const [changeddata, setChangeddata] = useState([]);

  const handleDropdownClickhotel = (i) => {
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  const SingleOptions = [
    { value: "homepage", label: "Home Page" },
    { value: "reservation", label: "Reservation" },
    { value: "other", label: "Other" },
  ];
  const SingleOptionsexist = [
    { value: "homepage", label: "Home Page" },
    { value: "reservation", label: "Reservation" },
    { value: "other", label: "Other" },
  ];
  const usageoption = [
    { value: "limited", label: "Limitted" },
    { value: "unlimited", label: "Unlimitted" },
    { value: "person_limit", label: "Person Limit" },
  ];

  const handleChangeExpiration = (index, value) => {
    const updatedList = [...list];
    updatedList[index].post_stay_email.expiry_status = value;
    setList(updatedList);
  };

  function handleloyaltyToggle(index, e) {
    const { checked } = e.target;
    const updatedClub = [...list];
    updatedClub[index] = {
      ...updatedClub[index],
      post_stay_email: {
        ...updatedClub[index].post_stay_email,
        status: checked,
      },
    };
    setList(updatedClub);

    // const updatedChangedData = [...changeddata];
    // updatedChangedData[index] = updatedClub[index];
    // setChangeddata(updatedChangedData);
  }

  const handleInputChange = (index, value,name) => {
    const updatedList = [...list];
    updatedList[index].post_stay_email[name] = value;
    setList(updatedList);
  };

  const handlePromoCodeChange = (index, selectedOption) => {
    const newList = [...list];
    newList[index].post_stay_email.promo_code = selectedOption;
    setList(newList);
};

const handleUsageChange = (index, selectedOption) => {
  const updatedList = [...list];
  updatedList[index].post_stay_email.usage = selectedOption.value;
  setList(updatedList);
};

const handleDestinationUrlChange = (index, selectedOption) => {
  const updatedList = [...list];
  updatedList[index].post_stay_email.destination_url_type = selectedOption.value;
  setList(updatedList);
};

  const handleTabClick = (index, tabs) => {
    setTab(prevTabs => {
      const newTabs = [...prevTabs];
      newTabs[index] = tabs;
      return newTabs;
    });
  };

  useEffect(() => {
    if (list && list.length > 0) {
      const initialTabs = list.map(item => item.post_stay_email?.type || 'exists');
      setTab(initialTabs);
    }
  }, [list]);


  const getlanguage = async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setLanguage(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getlanguage();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getlanguage();
    } else {
      console.log("error");
    }
  };


  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}private-sale/post-stay/list?page=${currentPage}`,
      {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const data = await res.json();
    if (data.status_code === "200") {
      return data.data.data;
    } else if (data.reauth === true) {
      handleUnauthorized();
      fetchComments(currentPage);
    }
  };

  const handlePageClick = async (data) => {
    if (data === 1) {
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
      setList(commentsFormServer);
    } else {
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
      setList(commentsFormServer);
    }
  };

  const fetchPost = async () => {
    const res = await fetch(`${Config.apiurl}private-sale/post-stay/list?page=1`, {
      method: "POST",
      body: JSON.stringify({
        hotels: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setList(data.data.data);
        setLoader(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        fetchPost();
      } else {
        console.log("error");
        setLoader(true);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      fetchPost();
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    const offers = list.map((item) => {
      if (item.post_stay_email?.confidential_offers?.length > 0) {
        return {
          hotel_id: item.hotel_id,
          offers: item.post_stay_email.confidential_offers.map((offer) => ({
            value: offer.value,
            label: offer.label,
            key: offer.key,
          })),
        };
      } else {
        return { hotel_id: item.hotel_id, offers: [] };
      }
    });

    const rooms = list.map((item) => {
      if (item.post_stay_email?.confidential_rooms?.length > 0) {
        return {
          hotel_id: item.hotel_id,
          rooms: item.post_stay_email.confidential_rooms.map((room) => ({
            value: room.value,
            label: room.label,
            key: room.key,
          })),
        };
      } else {
        return { hotel_id: item.hotel_id, rooms: [] };
      }
    });

    setSelectedOffers(offers);
    setSelectedRooms(rooms);
  }, [list]);

  const handleOffersChange = (hotelIndex, selected) => {
    setSelectedOffers((prevSelectedOffers) =>
      prevSelectedOffers.map((offer, index) =>
        index === hotelIndex ? { ...offer, offers: selected } : offer
      )
    );
  };

  const handleRoomsChange = (hotelIndex, selected) => {
    setSelectedRooms((prevSelectedRooms) =>
      prevSelectedRooms.map((room, index) =>
        index === hotelIndex ? { ...room, rooms: selected } : room
      )
    );
  };
 
  const Update = async () => {
    let isFormValid = true;

    const errors = {};

    if (tab === "exists") {
      if (existcode.length > 0) {
        if (selectcode.length === 0) {
          isFormValid = false;
          errors.selectcode = "Code is required!";
        }
      }
      if (!destinationurlexist) {
        isFormValid = false;
        errors.destinationurlexist = "Destination url is required!";
      }
    } else {
      if (selectedusage.length === 0) {
        isFormValid = false;
        errors.selectedusage = "Usage is required!";
      }
      if (
        selectedusage.value === "limited" ||
        selectedusage.value === "person_limit"
      ) {
        if (!list.usage_limit) {
          isFormValid = false;
          errors.usage_limit = "This field is required!";
        }
      }
      if (list.expiry_status === true) {
        if (!list.expiry_days) {
          isFormValid = false;
          errors.expiry_days = "Expiry day is required!";
        }
      }

      if (!list.discount) {
        isFormValid = false;
        errors.discount = "Discount is required!";
      }
      if (!destinationurl) {
        isFormValid = false;
        errors.destinationurl = "Destination url is required!";
      }
    }
    setError(errors);

    if (isFormValid === true) {
      let existcode;
      if (tab === "exists") {
        existcode = selectcode.value;
      }

      let lang = [];
      if (tab === "exists") {
        let enExistsInMulti = false;
        for (let i = 0; i < multiexist.length; i++) {
          if (multiexist[i].lang_code === "en") {
            enExistsInMulti = true;
          }
        }

        if (enExistsInMulti === false) {
          if (inputEnDestinationexist) {
            let enArray = {};
            enArray.lang_code = "en";
            enArray.url = inputEnDestinationexist;
            lang.push(enArray);
          }
        }
        for (let i = 0; i < multiexist.length; i++) {
          if (multiexist[i].destination_url) {
            let multiarray = {};
            multiarray.lang_code = multiexist[i].lang_code;
            multiarray.url = multiexist[i].destination_url;
            lang.push(multiarray);
          }
        }
      } else {
        let enInMulti = false;
        for (let i = 0; i < multi.length; i++) {
          if (multi[i].lang_code === "en") {
            enInMulti = true;
          }
        }

        if (enInMulti === false) {
          if (inputEnDestination) {
            let enArray = {};
            enArray.lang_code = "en";
            enArray.url = inputEnDestination;
            lang.push(enArray);
          }
        }
        for (let i = 0; i < multi.length; i++) {
          if (multi[i].destination_url) {
            let multiarray = {};
            multiarray.lang_code = multi[i].lang_code;
            multiarray.url = multi[i].destination_url;
            lang.push(multiarray);
          }
        }
      }

      let expiry_status;
      let expiry_days;
      let usage;
      let usage_limit;
      let discount;
      let roomval = [];
      let Offerval = [];

      if (tab === "autogenerate") {
        expiry_status = list.expiry_status === true ? 1 : 0;
        usage = selectedusage.value;
        discount = list.discount;

        if (selectedRooms.length > 0) {
          for (let i = 0; i < selectedRooms.length; i++) {
            roomval.push(selectedRooms[i].value);
          }
        }

        if (selectedOffers.length > 0) {
          for (let i = 0; i < selectedOffers.length; i++) {
            Offerval.push(selectedOffers[i].value);
          }
        }
      }

      if (tab === "autogenerate") {
        if (list.expiry_status === true) {
          expiry_days = list.expiry_days;
        }
        if (selectedusage.value === "limited") {
          usage_limit = list.usage_limit;
        }
      }

      const res = await fetch(
        `${Config.apiurl}hotel/private-sale/post-stay/update`,
        {
          method: "POST",
          body: JSON.stringify({
            hotel_id: id,
            post_stay_id: list.poststay_id,
            code_type: tab,
            promocode: existcode,
            expiry_status: expiry_status,
            expiry_days: expiry_days,
            usage_status: usage,
            usage_limit: usage_limit,
            discount: discount,
            status: list.status === true ? 1 : 0,
            confidential_offers: Offerval,
            confidential_rooms: roomval,
            destination_url_type:
              tab === "exists"
                ? destinationurlexist?.value
                : destinationurl?.value,
            other_url: lang,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200" || !data.data) {
          console.log("error");
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Update();
      } else {
        console.log("error");
      }
    }
  };

  // useEffect(() => {
  //   if (list.type === "autogenerate") {
  //     const initialSelectedOption = SingleOptions.find(
  //       (option) => option.value === list?.destination_url_type
  //     );
  //     setDestinationurl(initialSelectedOption);
  //   } else {
  //     const initialSelectedOptionexist = SingleOptionsexist.find(
  //       (option) => option.value === list?.destination_url_type
  //     );
  //     setDestinationurlexist(initialSelectedOptionexist);
  //   }

  //   const newUsage = usageoption.find(
  //     (option) => option.value === selectedusagenew
  //   );
  //   setSelectedusage(newUsage);
  // }, [
  //   list?.destination_url_type,
  //   selectedusagenew?.length,
  //   selectedusage?.length,
  // ]);


  const handleLanguageChangenewexist = (index, selectedOption) => {
    const updatedLanguages = [...list];
    if (
      updatedLanguages[index].post_stay_email === null ||
      !updatedLanguages[index].post_stay_email.destination_url
    ) {
      updatedLanguages[index].post_stay_email = {
        ...updatedLanguages[index].post_stay_email,
        newlangsub: selectedOption,
        destination_url: [{ lang_code: selectedOption, destination_url: "" }],
      };
    } else {
      updatedLanguages[index].post_stay_email.newlangsub = selectedOption;
      if (
        !updatedLanguages[index].post_stay_email.destination_url.some(
          (item) => item.lang_code === selectedOption
        )
      ) {
        updatedLanguages[index].post_stay_email.destination_url.push({
          lang_code: selectedOption,
          destination_url: "",
        });
      }
    }
    setList(updatedLanguages);
  };

  const handleloyallangChangeexist = (index, Value) => {
    const updatedList = [...list];
    const matchingIndex = updatedList[
      index
    ].post_stay_email?.destination_url?.findIndex(
      (language) =>
        language.lang_code === updatedList[index].post_stay_email?.newlangsub
    );
    if (matchingIndex !== -1) {
      updatedList[index].post_stay_email.destination_url[matchingIndex]["destination_url"] =
        Value;
    } else {
      const newLanguage = {
        lang_code: updatedList[index].post_stay_email?.newlangsub,
        destination_url: Value,
      };

      updatedList[index].post_stay_email?.destination_url?.push(newLanguage);
    }
    setList(updatedList);
    // const changedHotel = updatedList[index];
    // changeddata[index] = changedHotel;
  };

  useEffect(() => {
    let newarray = [];
    let lang;
    const updatedList = [...list];
    for (let i = 0; i < updatedList.length; i++) {
      if (updatedList[i].post_stay_email) {
        for (
          let j = 0;
          j < updatedList[i].post_stay_email?.destination_url?.length;
          j++
        ) {
          let ar = {};
          ar.lang_code =
            updatedList[i].post_stay_email?.destination_url[j]?.lang_code;
          ar.title = updatedList[i].post_stay_email?.destination_url[j]?.destination_url;
          newarray.push(ar);
          lang = updatedList[i].post_stay_email?.destination_url[j]?.lang_code;
        }
        updatedList[i].post_stay_email.newlangsub = lang;
      }
    }
  }, [list.length]);

  useEffect(() => {
    fetchPost();
    getlanguage();
  }, [id]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    if (list.length > 0) {
      const initialState = list?.map((item, index) => ({
        hotel: index === 0,
        post_stay_email: Array(item.post_stay_email?.length).fill(false),
      }));
      setShowFullData(initialState);
    }
  }, [list?.length]);

console.log(list);

  return (
    <div className="main-content" style={{ marginTop: "30px" }}>
      <div className="container-fluid">
        <div className="row project-wrapper">
          <div className="row dashboard-top-tab-style">
            <div className="col-xxl-12 dashboard-main-style">
              <div className="">
                {/* <h4 className="" style={{ marginLeft: "10px" }}>
                  {hotelname}
                </h4> */}
                  <div className="align-items-center d-flex">
                    <h4>
                      CHECK-OUT EMAIL CONFIGURATION
                    </h4>
                    <div className="flex-grow-1">
                    
                    </div>
                    <div style={{ float: "right"}}>
                    
                        <Link
                          type="button"
                          to="#"
                          className="btn db-save-button"
                          style={{ height: "30px", bottom: "8px" }}
                          onClick={Update}
                        >
                          {" "}
                          Save
                        </Link>
                      
                    </div>
                  </div>
                  {loader===false?
                  <Loading/>
                  :
                  <>
                 {list?.map((li, i) => {
                   const isMatchingLanguagenewexist =
                   li.post_stay_email?.destination_url?.findIndex(
                     (language) =>
                       language.lang_code ===
                       li.post_stay_email?.newlangsub
                   );

                    return (
                      <>
                        <div
                          className={
                            list.length > 1
                              ? "row hotel_single_list"
                              : "row card"
                          }
                          key={i}
                        >
                          {list.length > 1 ? (
                            <div className="hotel_service_display hotel_service_display_12">
                              <div className="hotel_service_display_14">
                                <div className="hk_service_all_hotel">
                                  <span className="">
                                    {" "}
                                    {pageindex === 1
                                      ? i + 1
                                      : (pageindex - 1) * 10 + i + 1}
                                    .
                                  </span>
                                  <span style={{ marginLeft: "10px" }}>
                                    {li.hotel_name}
                                  </span>
                                </div>
                              </div>
                              {showFullData[i]?.hotel === true ? (
                                <span
                                  className="db-modify_toggle_btn"
                                  onClick={() => handleDropdownClickhotel(i)}
                                >
                                  <span
                                    className="bx bxs-chevron-up"
                                    style={{ fontSize: "20px" }}
                                  ></span>
                                </span>
                              ) : (
                                <span
                                  className="db-modify_toggle_btn"
                                  onClick={() => handleDropdownClickhotel(i)}
                                >
                                  <span
                                    className="bx bxs-chevron-down"
                                    style={{ fontSize: "20px" }}
                                  ></span>
                                </span>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                           <div
                            className={`row ${
                              showFullData[i]?.hotel === true ? "" : "d-none"
                            }`}
                            style={{ marginTop: "5px" }}
                          >
                        <div className="">
                          <div className="align-items-center d-flex">
                            <div className="flex-grow-1">
                              <div
                                className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                                dir="ltr"
                                style={{marginTop:"15px",marginBottom:"20px" }}
                              >
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineswitch5"
                                  style={{
                                    marginRight: "40px",
                                    marginTop: "5px",
                                    marginLeft: "5px",
                                    color: "black",
                                  }}
                                >
                                  Active
                                </label>
                                <input
                                  type="checkbox"
                                  className="form-check-input offer_checkbox1"
                                  id="inlineswitch5"
                                  value={li?.post_stay_email?.status}
                                  checked={li?.post_stay_email?.status === true ? true : false}
                                  onChange={(e) =>
                                    handleloyaltyToggle(i,e)
                                  }
                                  style={{ width: "50px", height: "25px" }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="card-body">
                            <div className="live-preview">
                              <form action="#">
                                <div className="col-md-12">
                                  <ul
                                    className="offer-create-list_rate"
                                    style={{ borderBottom: "0px" }}
                                  >
                                    <li
                                      className={
                                        tab[i] === "exists"
                                          ? "extra-step-list active"
                                          : "extra-step-list"
                                      }
                                      onClick={() => handleTabClick(i, "exists")}
                                    >
                                      EXISTING CODE
                                    </li>
                                    <li
                                      className={
                                        tab[i] === "autogenerate"
                                          ? "extra-step-list active"
                                          : "extra-step-list"
                                      }
                                      onClick={() => handleTabClick(i, "autogenerate")}
                                    >
                                      AUTOGENERATED CODE
                                    </li>
                                  </ul>
                                </div>
                                {tab[i] === "exists" ? (
                                  <>
                                    <div
                                      className="col-lg-3"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <div className="input-group">
                                        <label
                                          className="input-group-text input-joint-color"
                                          id="inputGroup-sizing-default"
                                        >
                                          Choose an Existing Code
                                        </label>
                                        <div className="col-md-3">
                                          <Select
                                            placeholder="Select..."
                                            className="form-control multiselect_extra_based"
                                            data-choices
                                            name="choices-single-default"
                                            id="choices-single-default"
                                            options={li?.promocodes}
                                            value={li?.post_stay_email?.promo_code}
                                            onChange={(e) =>
                                              handlePromoCodeChange(i, e)}
                                          ></Select>
                                        </div>
                                        <label style={{ color: "red" }}>
                                          {Error.promo_code}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div
                                        className="col-lg-4"
                                        style={{ marginTop: "20px" }}
                                      >
                                        <div className="input-group">
                                          <label
                                            className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default"
                                          >
                                            Destination URL
                                            <span className="form-validation-required">
                                              *
                                            </span>
                                          </label>
                                          <div className="col-md-3">
                                            <Select
                                              placeholder="Select..."
                                              className="form-control singleselect-url-exist"
                                              data-choices
                                              name="choices-single-default"
                                              id="choices-single-default"
                                              options={SingleOptionsexist}
                                              value={SingleOptionsexist.find((option) => option.value === li?.post_stay_email?.destination_url_type)}
                                              onChange={(selectedOption) => handleDestinationUrlChange(i, selectedOption)}
                                            ></Select>
                                          </div>
                                        </div>
                                        <label style={{ color: "red" }}>
                                          {Error.destination_url_type}
                                        </label>
                                      </div>

                                      {li?.post_stay_email?.destination_url_type === "other" ? (
                                        <>
                                          <div
                                            className="col-md-6 translation-fill-section"
                                            data-value="1"
                                          >
                                            <div className="col-lg-8">
                                              <div
                                                className="input-group"
                                                style={{
                                                  top: "20px",
                                                  right: "10px",
                                                  width: "405px",
                                                }}
                                              >
                                                <label
                                                  className="input-group-text input-joint-color"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  En
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Enter the URL"
                                                  value={
                                                    li.post_stay_email?.destination_url.find(
                                                      (item) => item.lang_code === "en"
                                                    )?.destination_url || ""
                                                  }
                                                  onChange={(e) =>
                                                    handleloyallangChangeexist(
                                                      li.post_stay_email?.destination_url.findIndex(
                                                        (item) => item.lang_code === "en"
                                                      ),
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-md-12">
                                            <div className="d-flex">
                                              <div className="col-lg-4 d-flex align-items-center">
                                                <label
                                                  className="input-group-text input-joint-color"
                                                  id="inputGroup-sizing-default"
                                                  style={{
                                                    width: "130px",
                                                    borderBottomRightRadius: "0px",
                                                    borderTopRightRadius: "0px",
                                                  }}
                                                >
                                                  Add/edit in
                                                </label>

                                                <select
                                                  className="form-control"
                                                  data-choices
                                                  data-choices-search-false
                                                  id="idPayment"
                                                  style={{
                                                    width: "235px",
                                                    height: "38px",
                                                    marginLeft: "-1px",
                                                  }}
                                                  value={li.post_stay_email?.newlangsub}
                                                  onChange={(e) =>
                                                    handleLanguageChangenewexist(i,
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  <option value="">
                                                    Choose language
                                                  </option>

                                                  {language.map((lang, inx) => {
                                                    return (
                                                      <option
                                                        key={inx}
                                                        value={lang.code}
                                                      >
                                                        {lang.label}
                                                      </option>
                                                    );
                                                  })}
                                                </select>
                                              </div>
                                              {li.post_stay_email?.newlangsub && li.post_stay_email?.newlangsub !== "en" ? (
                                                li.post_stay_email?.destination_url
                                                ?.length > 0 ? (
                                                  <>
                                                    {li.post_stay_email?.destination_url?.some(
                                                      (item) =>
                                                        item.lang_code ===
                                                        li.post_stay_email?.newlangsub
                                                    ) ? (
                                                      <div
                                                        className="col-md-6 translation-fill-section"
                                                        data-value="1"
                                                      >
                                                        <div className="col-lg-8">
                                                          <div
                                                            className="input-group"
                                                            style={{
                                                              top: "13px",
                                                              right: "5px",
                                                            }}
                                                          >
                                                            <label
                                                              className="input-group-text input-joint-color"
                                                              id="inputGroup-sizing-default"
                                                              style={{
                                                                textTransform:
                                                                  "capitalize",
                                                              }}
                                                            >
                                                              {li.post_stay_email?.newlangsub}
                                                            </label>
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Enter the URL"
                                                             
                                                              value={
                                                                li.post_stay_email
                                                                  ?.destination_url[
                                                                    isMatchingLanguagenewexist
                                                                ]?.destination_url
                                                              }
                                                              onChange={(e) =>
                                                                handleloyallangChangeexist(i,
                                                                  e.target.value
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="col-md-6 translation-fill-section"
                                                        data-value="1"
                                                      >
                                                        <div className="col-lg-8">
                                                          <div
                                                            className="input-group"
                                                            style={{
                                                              top: "13px",
                                                              right: "5px",
                                                            }}
                                                          >
                                                            <label
                                                              className="input-group-text input-joint-color"
                                                              id="inputGroup-sizing-default"
                                                              style={{
                                                                textTransform:
                                                                  "capitalize",
                                                              }}
                                                            >
                                                              {li.post_stay_email?.newlangsub}
                                                            </label>
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Enter the URL"
                                                              value={
                                                                li.post_stay_email
                                                                  ?.destination_url[
                                                                  li.post_stay_email
                                                                    ?.destination_url
                                                                    ?.length - 1
                                                                ]?.destination_url
                                                              }
                                                              onChange={(e) =>
                                                                handleloyallangChangeexist(
                                                                  li.post_stay_email
                                                                    ?.destination_url
                                                                    ?.length - 1,
                                                                  e.target.value
                                                                )
                                                              }
                                                             
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </>
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </>
                                ) : tab[i] === "autogenerate" ? (
                                  <div className="row" style={{ marginTop: "20px" }}>
                                    <div
                                      className="col-lg-3"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <span
                                        className="input-group"
                                        style={{ width: "268px" }}
                                      >
                                        <span
                                          className="input-group-text input-joint-color1"
                                          id="inputGroup-sizing-default"
                                        >
                                          Usage{" "}
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                        </span>
                                        <Select
                                          placeholder="Select..."
                                          className="form-control"
                                          data-choices
                                          name="choices-single-default"
                                          id="choices-single-default"
                                          options={usageoption}
                                          value={usageoption.find((option) => option.value === li?.post_stay_email?.usage)}
                                          onChange={(selectedOption) => handleUsageChange(i, selectedOption)}
                                        ></Select>
                                      </span>
                                      <label style={{ color: "red" }}>
                                        {Error.selectedusage}
                                      </label>
                                    </div>
                                    {li?.post_stay_email?.usage === "limited" ||
                                    li?.post_stay_email?.usage === "person_limit" ? (
                                      <>
                                        <div
                                          className="input-group"
                                          style={{ width: "190px", right: "7px" }}
                                        >
                                          <input
                                            type="text"
                                            className="new_inp_avail_code form-control"
                                            placeholder="Enter no of usages"
                                            value={li?.post_stay_email?.usage_limit}
                                            onChange={(e) =>
                                              handleInputChange(i,
                                                e.target.value,
                                                "usage_limit"
                                              )
                                            }
                                            style={{
                                              width: "175px",
                                              textAlign: "center",
                                              marginBottom: "5px",
                                              top: "15px",
                                              height: "37px",
                                              fontSize: "14px",
                                            }}
                                          />
                                          <label
                                            style={{ color: "red", marginTop: "10px" }}
                                          >
                                            {Error.usage_limit}
                                          </label>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <div
                                      className="col-md-3"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <div
                                        className="form-check form-check-inline"
                                        style={{ float: "left" }}
                                      >
                                        <label style={{ marginRight: "10px" }}>
                                          Expiration
                                        </label>

                                        <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input per_room_once_check"
                                          type="radio"
                                          id={`WithInlineRadioYes_${i}`}
                                          name={`expiry_status_${i}`}
                                          value={true}
                                          checked={li?.post_stay_email?.expiry_status === true}
                                          onChange={() => handleChangeExpiration(i, true)}
                                        />
                                        <label htmlFor={`WithInlineRadioYes_${i}`}>Yes</label>
                                      </div>

                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input per_room_once_check"
                                          type="radio"
                                          id={`WithInlineRadioNo_${i}`}
                                          name={`expiry_status_${i}`}
                                          value={false}
                                          checked={li?.post_stay_email?.expiry_status === false}
                                          onChange={() => handleChangeExpiration(i, false)}
                                        />
                                        <label htmlFor={`WithInlineRadioNo_${i}`}>No</label>
                                      </div>
                                      </div>
                                    </div>
                                    {li?.post_stay_email?.expiry_status === true ? (
                                      <>
                                        <div
                                          class="col-lg-4"
                                          style={{ marginTop: "15px", right: "5%" }}
                                        >
                                          <div
                                            className="input-group"
                                            style={{ width: "350px" }}
                                          >
                                            <label
                                              className="input-group-text input-joint-color"
                                              id="inputGroup-sizing-default"
                                            >
                                              Days after the code is sent
                                              <span className="form-validation-required">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder=""
                                              value={li?.post_stay_email?.expiry_days}
                                              onChange={(e) =>
                                                handleInputChange(i,
                                                  e.target.value,
                                                  "expiry_days"
                                                )
                                              }
                                              style={{ textAlign: "center" }}
                                            />
                                          </div>
                                          <label style={{ color: "red" }}>
                                            {Error.expiry_days}
                                          </label>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    <div className="row">
                                      <div
                                        class="col-lg-4"
                                        style={{ marginTop: "15px" }}
                                      >
                                        <div
                                          className="input-group"
                                          style={{ width: "350px" }}
                                        >
                                          <label
                                            className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default"
                                          >
                                            Extra Discount
                                            <span className="form-validation-required">
                                              *
                                            </span>
                                            {/* <span className="info-tag-p-sale" style={{left:"5px"}}>i<span className="tooltiptext"></span></span> */}
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={li?.post_stay_email?.discount}
                                            onChange={(e) =>
                                              handleInputChange(i,
                                                e.target.value,
                                                "discount"
                                              )
                                            }
                                          />
                                          <span
                                            className="input-group-text input-joint-color-loyal"
                                            id="inputGroup-sizing-default"
                                            style={{ width: "60px" }}
                                          >
                                            %
                                          </span>
                                        </div>
                                        {/* <span className="rate_modify_type_section" style={{right:"10%"}}>
                                          <select id="ForminputState" className="form-select" value={list?.discount_unit_type}
                                          onChange={(e) => handleloyaltyToggle(e.target.value, "discount_unit_type")}>
                                              <option value="currency">AED</option>
                                              <option value="percentage">%</option>
                                          </select>
                                      </span> */}
                                        <label style={{ color: "red" }}>
                                          {Error.discount}
                                        </label>
                                      </div>
                                      {li?.confidential_offers?.length > 0 ? (
                                        <div
                                          className="col-lg-4"
                                          style={{ marginTop: "10px" }}
                                        >
                                          <div className="input-group">
                                            <label
                                              className="input-group-text input-joint-color"
                                              id="inputGroup-sizing-default"
                                            >
                                              Confidential Offers
                                            </label>
                                            <MultiSelect
                                              options={li?.confidential_offers}
                                              value={selectedOffers[i].offers || []}
                                              onChange={(selected) => handleOffersChange(i, selected)}
                                              labelledBy="Select"
                                              selectionType="counter"
                                              className="multiselect-psale-email"
                                              allowCustomValue={true}
                                            />
                                          </div>
                                          <label style={{ color: "red" }}>
                                            {Error.confidential_offers}
                                          </label>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {li?.confidential_rooms?.length > 0 ? (
                                        <div
                                          className="col-lg-4"
                                          style={{ marginTop: "10px" }}
                                        >
                                          <div className="input-group">
                                            <label
                                              className="input-group-text input-joint-color"
                                              id="inputGroup-sizing-default"
                                            >
                                              Confidential Rooms
                                            </label>
                                            <div className="col-md-3">
                                              <MultiSelect
                                                options={li?.confidential_rooms}
                                                value={selectedRooms[i].rooms || []}
                                                onChange={(selected) => handleRoomsChange(i, selected)}
                                                labelledBy="Select"
                                                selectionType="counter"
                                                className="multiselect-psale-email"
                                                allowCustomValue={true}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div
                                      className="col-lg-4"
                                      style={{ marginTop: "10px" }}
                                    >
                                      <div className="input-group">
                                        <label
                                          className="input-group-text input-joint-color"
                                          id="inputGroup-sizing-default"
                                        >
                                          Destination URL
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                        </label>
                                        <div className="col-md-3">
                                          <Select
                                            placeholder="Select..."
                                            className="form-control singleselect-url"
                                            data-choices
                                            name="choices-single-default"
                                            id="choices-single-default"
                                            options={SingleOptions}
                                            value={SingleOptions.find((option) => option.value === li?.post_stay_email?.destination_url_type)}
                                            onChange={(selectedOption) => handleDestinationUrlChange(i, selectedOption)}
                                          ></Select>
                                        </div>
                                      </div>
                                      <label
                                        style={{ color: "red", marginTop: "10px" }}
                                      >
                                        {Error.destination_url_type}
                                      </label>
                                    </div>

                                    {li?.post_stay_email?.destination_url_type === "other" ? (
                                        <>
                                          <div
                                            className="col-md-6 translation-fill-section"
                                            data-value="1"
                                          >
                                            <div className="col-lg-8">
                                              <div
                                                className="input-group"
                                                style={{
                                                  top: "20px",
                                                  right: "10px",
                                                  width: "405px",
                                                }}
                                              >
                                                <label
                                                  className="input-group-text input-joint-color"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  En
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Enter the URL"
                                                  value={
                                                    li.post_stay_email?.destination_url.find(
                                                      (item) => item.lang_code === "en"
                                                    )?.destination_url || ""
                                                  }
                                                  onChange={(e) =>
                                                    handleloyallangChangeexist(
                                                      li.post_stay_email?.destination_url.findIndex(
                                                        (item) => item.lang_code === "en"
                                                      ),
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-md-12">
                                            <div className="d-flex">
                                              <div className="col-lg-4 d-flex align-items-center">
                                                <label
                                                  className="input-group-text input-joint-color"
                                                  id="inputGroup-sizing-default"
                                                  style={{
                                                    width: "130px",
                                                    borderBottomRightRadius: "0px",
                                                    borderTopRightRadius: "0px",
                                                  }}
                                                >
                                                  Add/edit in
                                                </label>

                                                <select
                                                  className="form-control"
                                                  data-choices
                                                  data-choices-search-false
                                                  id="idPayment"
                                                  style={{
                                                    width: "235px",
                                                    height: "38px",
                                                    marginLeft: "-1px",
                                                  }}
                                                  value={li.post_stay_email?.newlangsub}
                                                  onChange={(e) =>
                                                    handleLanguageChangenewexist(i,
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  <option value="">
                                                    Choose language
                                                  </option>

                                                  {language.map((lang, inx) => {
                                                    return (
                                                      <option
                                                        key={inx}
                                                        value={lang.code}
                                                      >
                                                        {lang.label}
                                                      </option>
                                                    );
                                                  })}
                                                </select>
                                              </div>
                                              {li.post_stay_email?.newlangsub && li.post_stay_email?.newlangsub !== "en" ? (
                                                li.post_stay_email?.destination_url
                                                ?.length > 0 ? (
                                                  <>
                                                    {li.post_stay_email?.destination_url?.some(
                                                      (item) =>
                                                        item.lang_code ===
                                                        li.post_stay_email?.newlangsub
                                                    ) ? (
                                                      <div
                                                        className="col-md-6 translation-fill-section"
                                                        data-value="1"
                                                      >
                                                        <div className="col-lg-8">
                                                          <div
                                                            className="input-group"
                                                            style={{
                                                              top: "13px",
                                                              right: "5px",
                                                            }}
                                                          >
                                                            <label
                                                              className="input-group-text input-joint-color"
                                                              id="inputGroup-sizing-default"
                                                              style={{
                                                                textTransform:
                                                                  "capitalize",
                                                              }}
                                                            >
                                                              {li.post_stay_email?.newlangsub}
                                                            </label>
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Enter the URL"
                                                             
                                                              value={
                                                                li.post_stay_email
                                                                  ?.destination_url[
                                                                    isMatchingLanguagenewexist
                                                                ]?.destination_url
                                                              }
                                                              onChange={(e) =>
                                                                handleloyallangChangeexist(i,
                                                                  e.target.value
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="col-md-6 translation-fill-section"
                                                        data-value="1"
                                                      >
                                                        <div className="col-lg-8">
                                                          <div
                                                            className="input-group"
                                                            style={{
                                                              top: "13px",
                                                              right: "5px",
                                                            }}
                                                          >
                                                            <label
                                                              className="input-group-text input-joint-color"
                                                              id="inputGroup-sizing-default"
                                                              style={{
                                                                textTransform:
                                                                  "capitalize",
                                                              }}
                                                            >
                                                              {li.post_stay_email?.newlangsub}
                                                            </label>
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Enter the URL"
                                                              value={
                                                                li.post_stay_email
                                                                  ?.destination_url[
                                                                  li.post_stay_email
                                                                    ?.destination_url
                                                                    ?.length - 1
                                                                ]?.destination_url
                                                              }
                                                              onChange={(e) =>
                                                                handleloyallangChangeexist(
                                                                  li.post_stay_email
                                                                    ?.destination_url
                                                                    ?.length - 1,
                                                                  e.target.value
                                                                )
                                                              }
                                                             
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </>
                                                ) : (
                                                  ""
                                                )
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="padding-20"></div>
                              </form>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                      </>
                    );
                  })}
                  </>
                }
                   {paginate > 1 ? (
                   <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={paginate}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                     ) : (
                      ""
                    )} 
            </div>
          </div>
          </div>
        </div>
      </div>

      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Poststayemail;
