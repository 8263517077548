import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../Layouts/Header';
import { UserContext } from '../Contexts/UserContext';
import Sidebarroom from './Sidebars/Sidebarroom';

function Roomheader() {
  const {updateHotel_select, hotel_select} = useContext(UserContext);

    const user_type = localStorage.getItem("user_type");
    const [showhotel,setShowhotel]=useState(false);
    const[pagename,setPagename]=useState("room");
    const [dashboard,setDashboard]=useState(true);

    useEffect(()=>{
      setShowhotel(hotel_select.length>0?true:false);
    },[hotel_select])
    return (
      <div>
        <Header setShowhotel={setShowhotel} dashboard={dashboard} pagename={pagename} user_type={user_type} showhotel={showhotel}/>
       
          <Sidebarroom showhotel={showhotel} />
         
      </div>
    )
}

export default Roomheader