import React, { useContext,useEffect,useState } from 'react'
import Header from '../../../Layouts/Header';
import HotelSidebar from './Sidebars/HoletSidebar';
import Sidebar from './Sidebars/Sidebar';
import Sidebarbasicsettings from './Sidebars/Sidebarbasicsettings';
import { UserContext } from '../Contexts/UserContext';

function Basicsettingsheader() {
  const user_type = localStorage.getItem("user_type");
  const {updateHotel_select, hotel_select} = useContext(UserContext);

  const [showhotel,setShowhotel]=useState(false);

  const [dashboard,setDashboard]=useState(true);
  const[pagename,setPagename]=useState("basic");

  useEffect(()=>{
    setShowhotel(hotel_select.length>0?true:false);
  },[hotel_select])
console.log(pagename);
  return (
    <div>
      <Header setShowhotel={setShowhotel} dashboard={dashboard} pagename={pagename} user_type={user_type}/>
     
        <Sidebarbasicsettings showhotel={showhotel} />
       
    </div>
  )
}

export default Basicsettingsheader
