import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import ReactPaginate from "react-paginate";
import { Link, useResolvedPath } from "react-router-dom";
import Alerts from "../../Alerts";
import Roomheader from "../../Common/Roomheader";
import Config from "../../Config";
import { UserContext } from "../../Contexts/UserContext";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Footer from "../../Common/Footer";
import Toastmodal from "../../Common/Toastmodal";

function Roommainfacilities() {
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  console.log(hotel_selectid);
  const [fac, setFac] = useState([]);
  const [list, setList] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [page, setPage] = useState(1);
  const [pageindex, setPageindex] = useState(1);
  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [changeddata, setChangeddata] = useState([]);
  const [showFullData, setShowFullData] = useState([]);

  const handleDropdownClickhotel = (i) => {
    // Toggle the showFullData state for the corresponding item
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };
  const handleLabelCheckboxChange = (i, isChecked) => {
    const updatedList = [...list];
    updatedList[i].selected = isChecked;
    updatedList[i].rooms.forEach((room) => {
      room.selected = isChecked;
    });
    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < list[i].rooms.length; j++) {
        if (
          list[i].rooms[j].selected &&
          list[i].rooms[j].selected === true &&
          updatedList[i]["commonfacility"]
        ) {
          updatedList[i]["rooms"][j]["facilities"] =
            updatedList[i]["commonfacility"];
        }
      }
    }
    setList(updatedList);
  };

  const handleRoomCheckboxChange = (i, index, isChecked) => {
    const updatedList = [...list];
    updatedList[i].rooms[index].selected = isChecked;

    const allRoomsSelected = updatedList[i].rooms.every(
      (room) => room.selected
    );
    updatedList[i].selected = allRoomsSelected;
    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < list[i].rooms.length; j++) {
        if (
          list[i].rooms[j].selected &&
          list[i].rooms[j].selected === true &&
          updatedList[i]["commonfacility"]
        ) {
          updatedList[i]["rooms"][j]["facilities"] =
            updatedList[i]["commonfacility"];
        }
      }
    }
    setList(updatedList);
  };

  const getFacility = async (e) => {
    const res = await fetch(`${Config.apiurl}facilities/all`, {
      method: "POST",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setFac(data);
    }
  };
  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}hotel/room-featured/list?page=${currentPage}`,
      {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );

    const data = await res.json();
    if (data.status_code === "200") {
      return data.data.data;
    } else if (data.reauth === true) {
      handleUnauthorized();
      fetchComments(currentPage);
    }
  };

  const handlePageClick = async (data) => {
    if (data === 1) {
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
      setList(commentsFormServer);
    } else {
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
      setList(commentsFormServer);
    }
  };

  const fetchPost = async () => {
    const response = await fetch(
      `${Config.apiurl}hotel/room-featured/list?page=1`,
      {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (response.status === 200) {
      const data = await response.json();
      if (data.status_code === "200") {
        setList(data.data.data);
        setPaginate(data.data.last_page);
      } else if (data.reauth === true) {
        handleUnauthorized();
        fetchPost();
      }
    } else {
      console.log("error");
    }
  };

  // let handleChange = (li, user, e) => {
  //   let newval = [...list];
  //   newval[li]["rooms"][user]["facilities"] = e;
  //   setList(newval);
  // };
  // const handleFacilityChange = (i, e) => {
  //   const updatedList = [...list];
  //   updatedList[i]["commonfacility"] = e;
  //   for (let i = 0; i < list.length; i++) {
  //     for (let j = 0; j < list[i].rooms.length; j++) {
  //       if (list[i].rooms[j].selected && list[i].rooms[j].selected === true) {
  //         updatedList[i]["rooms"][j]["facilities"] = e;
  //       }
  //     }
  //   }
  //   setList(updatedList);
  // };

  const handleChange = (li, user, e) => {
    let newList = [...list];
    newList[li]["rooms"][user]["facilities"] = e;

    const changedHotelData = {
      ...newList[li],
      rooms: newList[li].rooms.map((room) => ({
        ...room,
        changed: room.facilities !== newList[li]["rooms"][user]["facilities"],
      })),
    };

    setChangeddata((prevChangedData) => {
      const newChangedData = [...prevChangedData];
      newChangedData[li] = changedHotelData;
      const reorderedChangedData = newList.map(
        (hotel, index) => newChangedData[index] || {}
      );
      return reorderedChangedData;
    });
    setList(newList);
  };

  const handleFacilityChange = (i, e) => {
    const updatedList = [...list];
    updatedList[i]["commonfacility"] = e;

    for (let i = 0; i < updatedList.length; i++) {
      for (let j = 0; j < updatedList[i].rooms.length; j++) {
        if (
          updatedList[i].rooms[j].selected &&
          updatedList[i].rooms[j].selected === true
        ) {
          updatedList[i].rooms[j].facilities = e;
        }
      }
    }
    setList(updatedList);

    let changedDataCopy = [...changeddata];
    const changedIndexes = new Set([i]);
    changedIndexes.forEach((index) => {
      const newval = updatedList[index];
      changedDataCopy[index] = {
        ...changedDataCopy[index],
        hotel_id: newval.hotel_id,
        rooms: newval.rooms.map((room, roomIndex) => {
          if (roomIndex === index && roomIndex) {
            return {
              ...room,
              room_id: room.room_id,
            };
          } else {
            return room;
          }
        }),
      };
    });

    setChangeddata(changedDataCopy);
  };

  const PostData = async (e) => {
    e.preventDefault();

    let arr = [];
    if (changeddata?.length > 0) {
      for (let i = 0; i < changeddata?.length; i++) {
        if (changeddata[i] !== undefined) {
          if (changeddata[i].hotel_id) {
            let hotel = {
              hotel_id: changeddata[i].hotel_id,
              rooms: [],
            };
            for (let j = 0; j < changeddata[i]?.rooms?.length; j++) {
              let room = {
                room_id: changeddata[i].rooms[j].room_id,
                facilities:
                  changeddata[i].rooms[j]?.facilities?.map(
                    (facility) => facility.value
                  ) || [],
              };
              hotel.rooms.push(room);
            }

            arr.push(hotel);
          }
        }
      }
      console.log(arr);
      const res = await fetch(
        `${Config.apiurl}hotel/main-facilities/bulk-update`,
        {
          method: "POST",
          body: JSON.stringify({
            hotels: arr,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      const data = await res.json();
      if (data.status_code === "200") {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("success");
        setToastlarge(false);
        fetchPost();
      } else if (data.reauth === true) {
        handleUnauthorized();
        PostData();
      } else {
        setToastmodal(true);
        setToastmessage(data.message);
        setToasttype("error");
        setToastlarge(false);
      }
    } else {
      setToastmodal(true);
      setToastmessage("You have made no changes to save");
      setToasttype("success");
      setToastlarge(true);
    }
  };
  useEffect(() => {
    getFacility();
  }, []);

  useEffect(() => {
    fetchPost();
  }, [id]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    if (list.length > 0) {
      const initialState = list.map((item, index) => ({
        hotel: index === 0,
        rooms: Array(item.rooms.length).fill(false),
      }));
      setShowFullData(initialState);
    }
  }, [list.length]);

  console.log(list);
  return (
    <>
      <Roomheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box-in d-sm-flex align-items-center justify-content-between">
                  <Link
                    type="button"
                    to="#"
                    className="btn db-save-button "
                    onClick={PostData}
                    style={{ marginBottom: "5px" }}
                  >
                    Update
                  </Link>
                </div>
              </div>
            </div>

            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-lg-12">
                  <div className="card" id="oderlist">
                    <div className="list-top-tab-styles">
                      <div className="">
                        <div className="row align-items-center gy-3">
                          <div className="col-sm">
                            <div className="col-sm">
                              <div className="card-body pt-0">
                                <div>
                                  {/* <ul className="nav nav-tabs nav-tabs-custom nav-success mb-3" role="tablist" style={{ border: "0px" }}>
                                  </ul> */}

                                  <div className="">
                                    {list.map((li, i) => {
                                      return (
                                        <>
                                          <React.Fragment key={i}>
                                            <div
                                              className={
                                                list.length > 1
                                                  ? "row hotel_single_list"
                                                  : "row"
                                              }
                                            >
                                              {list.length > 1 ? (
                                                <div className="hotel_service_display_12">
                                                  <div className="hk_service_all_hotel">
                                                    <span className="">
                                                      {pageindex === 1
                                                        ? i + 1
                                                        : (pageindex - 1) * 10 +
                                                          i +
                                                          1}
                                                      .
                                                    </span>
                                                    <span
                                                      style={{
                                                        marginLeft: "10px",
                                                      }}
                                                    >
                                                      {li.hotel_name}
                                                    </span>
                                                  </div>
                                                  {showFullData[i]?.hotel ===
                                                  true ? (
                                                    <span
                                                      className="db-modify_toggle_btn"
                                                      onClick={() =>
                                                        handleDropdownClickhotel(
                                                          i
                                                        )
                                                      }
                                                    >
                                                      <span
                                                        className="bx bxs-chevron-up"
                                                        style={{
                                                          fontSize: "20px",
                                                        }}
                                                      ></span>
                                                    </span>
                                                  ) : (
                                                    <span
                                                      className="db-modify_toggle_btn"
                                                      onClick={() =>
                                                        handleDropdownClickhotel(
                                                          i
                                                        )
                                                      }
                                                    >
                                                      <span
                                                        className="bx bxs-chevron-down"
                                                        style={{
                                                          fontSize: "20px",
                                                        }}
                                                      ></span>
                                                    </span>
                                                  )}
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              {/* {list.length > 1 && <div className='rooms_list_hname'>{li.hotel_name}</div>} */}
                                              <table
                                                className={`table table-nowrap align-middle ${
                                                  showFullData[i]?.hotel ===
                                                  true
                                                    ? ""
                                                    : "d-none"
                                                }`}
                                                id="customerTable"
                                              >
                                                <thead className="text-muted table-light">
                                                  <tr className="text-uppercase">
                                                    <th
                                                      scope="col"
                                                      style={{ width: "50px" }}
                                                    >
                                                      <div className="form-check">
                                                        <input
                                                          className="form-check-input"
                                                          type="checkbox"
                                                          id={`checkAll-${i}`}
                                                          value="option"
                                                          checked={li.selected}
                                                          onChange={(e) =>
                                                            handleLabelCheckboxChange(
                                                              i,
                                                              e.target.checked
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </th>
                                                    <th
                                                      className=""
                                                      data-sort="status"
                                                    >
                                                      Rooms
                                                    </th>
                                                    <th
                                                      className=""
                                                      data-sort="customer_name"
                                                    >
                                                      <tr>
                                                        <td> Facilities </td>
                                                        <td>
                                                          <div
                                                            style={{
                                                              marginLeft:
                                                                "15px",
                                                              height: "33px",
                                                              width: "400px",
                                                              textTransform:
                                                                " math-auto",
                                                            }}
                                                          >
                                                            <MultiSelect
                                                              options={fac}
                                                              value={
                                                                li.commonfacility
                                                                  ? li.commonfacility
                                                                  : []
                                                              }
                                                              onChange={(e) =>
                                                                handleFacilityChange(
                                                                  i,
                                                                  e
                                                                )
                                                              }
                                                              labelledBy="Select"
                                                              selectionType="counter"
                                                              allowCustomValue={
                                                                true
                                                              }
                                                              aria-expanded="true"
                                                              name="commonfacility"
                                                            />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </th>
                                                    <th></th>
                                                  </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                  {li.rooms.map(
                                                    (user, index) => {
                                                      return (
                                                        <tr key={index}>
                                                          <td
                                                            scope="col"
                                                            style={{
                                                              width: "50px",
                                                            }}
                                                          >
                                                            <div className="form-check">
                                                              <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id={`check-${i}-${index}`}
                                                                value="option"
                                                                checked={
                                                                  user.selected
                                                                }
                                                                onChange={(e) =>
                                                                  handleRoomCheckboxChange(
                                                                    i,
                                                                    index,
                                                                    e.target
                                                                      .checked
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                          </td>
                                                          <td className="customer_name">
                                                            {user.room_name}
                                                          </td>
                                                          <td className="width-75">
                                                            <span
                                                              className="facility-form-dropdown"
                                                              style={{
                                                                width: "400px",
                                                              }}
                                                            >
                                                              <MultiSelect
                                                                options={fac}
                                                                value={
                                                                  user.facilities
                                                                }
                                                                onChange={(e) =>
                                                                  handleChange(
                                                                    i,
                                                                    index,
                                                                    e
                                                                  )
                                                                }
                                                                labelledBy="Select"
                                                                selectionType="counter"
                                                                allowCustomValue={
                                                                  true
                                                                }
                                                                aria-expanded="true"
                                                              />
                                                            </span>
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </React.Fragment>
                                        </>
                                      );
                                    })}
                                    {paginate > 1 ? (
                                      <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={paginate}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={1}
                                        onPageChange={handlePageClick}
                                        containerClassName={
                                          "pagination justify-content-end"
                                        }
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
      <Footer />
    </>
  );
}

export default Roommainfacilities;
