// contexts/UserContext.js
import jwtDecode from "jwt-decode";
import { createContext, useEffect, useState } from "react";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  //   const [hotel_select, setHotel_select] = useState([]);
  const [hotel_selectid, setHotel_selectid] = useState([]);
  const [hotellist, setHotellist] = useState([]);
  const token = localStorage.getItem("token");
  const [languageid, setLangid] = useState(localStorage.getItem("be_language"));

  const [decodetokan, setDecodetoken] = useState([]);
  const [hotel_select, setHotel_select] = useState(() => {
    const storedData = localStorage.getItem("myData");
    return storedData ? JSON.parse(storedData) : [];
  });

  console.log(hotel_select);
  // const updateHotel_select = (hotels) => {
  //   setHotel_select(hotels);
  //   if (hotels) {
  //     let idd = [];
  //     for (let i = 0; i < hotels.length; i++) {
  //       idd.push(hotels[i].value);
  //     }
  //     setHotel_selectid(idd);
  //   }
  // };

  // useEffect(() => {
  //   if (hotel_select) {
  //     let idd = [];
  //     for (let i = 0; i < hotel_select.length; i++) {
  //       idd.push(hotel_select[i].value);
  //     }
  //     setHotel_selectid(idd);
  //   }
  // }, [hotel_select]);

  const updateHotel_select = (hotels) => {
    setHotel_select(hotels);
    if (hotels) {
      let idd = [];
      for (let i = 0; i < hotels.length; i++) {
        if (hotels[i]?.isGroupChild && hotels[i]?.isGroupChild === true) {
          idd.push(hotels[i].value);
        } else if (
          !hotel_select[i]?.isGroup &&
          !hotel_select[i]?.isGroupChild
        ) {
          idd.push(hotels[i].value);
        }
      }
      setHotel_selectid(idd);
    }
  };

  useEffect(() => {
    if (hotel_select) {
      let idd = [];
      for (let i = 0; i < hotel_select.length; i++) {
        if (
          hotel_select[i]?.isGroupChild &&
          hotel_select[i]?.isGroupChild === true
        ) {
          idd.push(hotel_select[i].value);
        } else if (
          !hotel_select[i]?.isGroup &&
          !hotel_select[i]?.isGroupChild
        ) {
          idd.push(hotel_select[i].value);
        }
      }
      setHotel_selectid(idd);
    }
  }, [hotel_select]);

  const updatelanguage = (lang) => {
    setLangid(lang);
  };

  // useEffect(() => {
  //   setDecodetoken(jwtDecode(token));
  // }, []);

  // useEffect(() => {
  //   setDecodetoken(jwtDecode(token));
  // }, []);

  console.log(hotel_selectid);
  console.log(hotel_select);
  return (
    <UserContext.Provider
      value={{
        updateHotel_select,
        hotel_select,
        hotel_selectid,
        languageid,
        updatelanguage,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
