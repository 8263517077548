import React, { useContext, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useState } from "react";
import Commonheader from "../../Common/Commonheader";
import Select from "react-select";
import Footer from "../../Common/Footer";
import Config from "../../Config";
import axios from "axios";
import Alerts from "../../Alerts";
import Commonimagepath from "../../Commonimagepath";
import { UserContext } from "../../Contexts/UserContext";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Toastmodal from "../../Common/Toastmodal";

function Generalinfo() {
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const user_type = localStorage.getItem("user_type");
  const [country, setCountry] = useState([]);
  const [be_lang, setBe_lang] = useState([]);
  const [timezone, setTimezone] = useState([]);
  const [property, setProperty] = useState([]);
  const [star, setStar] = useState([]);
  const [selectedcountry, setSelectedcountry] = useState([]);
  const [hotelinfoupdatestatus, setHotelinfoupdatestatus] = useState(false);
  const [countrytime, setCountrytime] = useState([]);
  const [calling, setCalling] = useState([]);
  const [selectedtimezone, setSelectedtimezone] = useState([]);
  const [selectedExtanet, setSelectedExtranet] = useState([]);
  const [selectedBookinglang, setSelectedBookinglang] = useState([]);
  const [propertytypehotel, setPropertytypehotel] = useState([]);
  const [selectedcategory, setSelectedcategory] = useState([]);
  const [selectedcurrency, setSelectedcurrency] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [selectedchannelmanager, setSelectedchannelmanager] = useState([]);
  const [channel, setChannel] = useState([]);
  const [selectedpms, setSelectedPMS] = useState([]);
  const [pms, setPMS] = useState([]);
  const [selectedCard, setselectedCard] = useState([]);
  const [creditcard, setCreditcard] = useState([]);
  const [hoteldetails, setHoteldetails] = useState([]);
  const [id, setId] = useState(hotel_select ? hotel_select[0]?.value : "");
  const [newhotellogo, setNewhotellogo] = useState({ logo: "" });
  const [oldlogo, setOldlogo] = useState([]);
  const [selectedhotelimage, setSelectedhotelimage] = useState([]);
  const [hotelbutton, setHotelbutton] = useState(false);
  const [oldlisthotel, setoldlisthotel] = useState([]);
  const [oldimagehotel, setOldImagehotel] = useState([]);
  const [selectedoptionalimage, setSelectedoptionalimage] = useState([]);
  const [removehotel, setRemovemagehotel] = useState();
  const [oldlist, setoldlist] = useState([]);
  const [oldimage, setOldImage] = useState([]);
  const [remove, setRemovemage] = useState();
  const [optionalimage, setOptionalimage] = useState([]);
  const [hotelimage, setHotelimage] = useState([]);
  const [reviewhotel, setReviewhotel] = useState(true);
  const [status, setStatus] = useState("");
  const [board, setBoard] = useState([]);
  const [selectedboard, setSelectedboard] = useState([]);

  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);

  const [formerror1, setError1] = useState({});
  const [formerror2, setError2] = useState({});
  const [formerror3, setError3] = useState([{}]);
  const [formerror4, setError4] = useState([{}]);
  const [formerror5, setError5] = useState([{}]);
  const [checkintime, setCheckintime] = useState([]);
  const [checkouttime, setCheckouttime] = useState([]);
  const [checkintimenew, setCheckintimenew] = useState([]);
  const [checkouttimenew, setCheckouttimenew] = useState([]);

  const time = [
    { value: "00:00", label: "12 am" },
    { value: "01:00", label: "1 am" },
    { value: "02:00", label: "2 am" },
    { value: "03:00", label: "3 am" },
    { value: "04:00", label: "4 am" },
    { value: "05:00", label: "5 am" },
    { value: "06:00", label: "6 am" },
    { value: "07:00", label: "7 am" },
    { value: "08:00", label: "8 am" },
    { value: "09:00", label: "9 am" },
    { value: "10:00", label: "10 am" },
    { value: "11:00", label: "11 am" },
    { value: "12:00", label: "12 pm" },
    { value: "13:00", label: "1 pm" },
    { value: "14:00", label: "2 pm" },
    { value: "15:00", label: "3 pm" },
    { value: "16:00", label: "4 pm" },
    { value: "17:00", label: "5 pm" },
    { value: "18:00", label: "6 pm" },
    { value: "19:00", label: "7 pm" },
    { value: "20:00", label: "8 pm" },
    { value: "21:00", label: "9 pm" },
    { value: "22:00", label: "10 pm" },
    { value: "23:00", label: "11 pm" },
  ];
  const [formerrorall, setErrorall] = useState({});
  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [symbolsrate] = useState(["e", "E", "+", "-"]);

  const handlechange = (e) => {
    setHotelinfoupdatestatus(true);
    let name = e.target.name;
    let value = e.target.value;
    setHoteldetails({ ...hoteldetails, [name]: value });
  };
  function handleChangeselectcountry(selectedOption) {
    setHotelinfoupdatestatus(true);
    let value = selectedOption;
    getcountrybasetime(selectedOption);
  }
  let handleChangetogghotel = (e) => {
    setHotelinfoupdatestatus(true);
    let value = e.target.checked;
    let name = e.target.name;
    setHoteldetails({ ...hoteldetails, [name]: value });
  };
  const onChangelogo = (e) => {
    setHotelinfoupdatestatus(true);
    const file = e.target.files[0];

    let name = e.target.name;
    setNewhotellogo({ [name]: file });
  };

  const getall = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel-info/all`, {
      method: "POST",
    });
    const data = await res.json();
    if (res.status === 404 || !data) {
      console.log("error");
    } else {
      setCountry(data.data.countries);
      setTimezone(data.data.timezones);
      setProperty(data.data.property_type);
      setStar(data.data.categories);
      setBe_lang(data.data.language);
      setPMS(data.data.pms);
      setChannel(data.data.channel_managers);
      setCreditcard(data.data.credit_cards);
      setCurrency(data.data.currencies);
    }
  };

  const getBoard = async (e) => {
    const res = await fetch(`${Config.apiurl}boards`, {
      method: "POST",
    });
    const data1 = await res.json();
    const data = data1.data;
    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setBoard(data);
    }
  };

  const getcountrybasetime = async (val) => {
    const res = await fetch(`${Config.apiurl}country/time-zones/${val.value}`, {
      method: "POST",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setCountrytime(data);
    }
    const res1 = await fetch(
      `${Config.apiurl}country/calling-code/${val.value}`,
      {
        method: "POST",
      }
    );
    const data2 = await res1.json();
    const data3 = data2.data;

    if (data2.status_code !== "200" || !data3) {
      console.log("error");
    } else {
      setCalling(data3);
    }
    setSelectedtimezone(data[0]);
    setSelectedcountry(val);
    setHoteldetails({ ...hoteldetails, ["calling_code"]: data3[0] });
  };
  const onChange = (e) => {
    const file = e.target.files;
    setHotelbutton(false);
    setHotelinfoupdatestatus(true);
    setHotelimage(e.target.files);
  };
  const onChangehotelroom = (e) => {
    setHotelbutton(false);

    const file = e.target.files;
    setHotelinfoupdatestatus(true);
    setOptionalimage(e.target.files);
  };

  const removeImage = (id) => {
    // delete oldimage[id];
    setHotelbutton(false);

    setHotelinfoupdatestatus(true);
    oldlist.push(id);
    setSelectedoptionalimage((oldState) =>
      oldState.filter((item) => item !== id)
    );
    const res = oldimage.map((el1) => ({
      name: el1,
      match: selectedoptionalimage.some((el2) => el2 === el1),
    }));
    setRemovemage(res);
  };

  const removeImagehotel = (id) => {
    // delete oldimage[id];
    setHotelbutton(false);

    setHotelinfoupdatestatus(true);
    oldlisthotel.push(id);
    setSelectedhotelimage((oldState) => oldState.filter((item) => item !== id));
    const res = oldimagehotel.map((el1) => ({
      name: el1,
      match: selectedhotelimage.some((el2) => el2 === el1),
    }));
    setRemovemagehotel(res);
  };

  const getinfo = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/view/${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setHoteldetails(data.data.hotel);
        setSelectedcountry(data.data.country);
        setPropertytypehotel(data.data.property_type);
        setSelectedBookinglang(data.data.be_language);
        setSelectedcategory(data.data.category);
        setSelectedchannelmanager(data.data.channel_manager);
        setselectedCard(data.data.credit_cards);
        setSelectedcurrency(data.data.currency);
        setSelectedExtranet(data.data.extranet_languages);
        setSelectedPMS(data.data.pms);
        setSelectedtimezone(data.data.timezone);
        setOldlogo(data.data.hotel.logo);
        setSelectedhotelimage(data.data.hotel_images);
        setSelectedoptionalimage(data.data.optional_images);
        setSelectedboard(data.data.hotel_board);
        setCheckintimenew(data.data.hotel.checkin_time);
        setCheckouttimenew(data.data.hotel.checkout_time);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getinfo();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getinfo();
    } else {
      console.log("error");
    }
  };

  let PostData = () => {
    setHotelbutton(true);
    let isFormValid = true;

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const errors = {};
    if (!hoteldetails.hotel_name) {
      isFormValid = false;

      errors.hotel_name = "Hotel name is required!";
    }

    if (!hoteldetails.corporate_name) {
      isFormValid = false;

      errors.corporate_name = "Corporate name is required!";
    }

    if (!hoteldetails.calling_code) {
      isFormValid = false;

      errors.calling_code = "calling code is required!";
    }
    if (!hoteldetails.phone_no) {
      isFormValid = false;

      errors.phone_no = "Phone Number is required!";
    }
    if (!hoteldetails.registration_email) {
      isFormValid = false;

      errors.registration_email = "Registration email is required!";
    } else if (!regex.test(hoteldetails.registration_email)) {
      isFormValid = false;

      errors.registration_email = "This is not a valid email format!";
    }
    if (!hoteldetails.reservation_email) {
      isFormValid = false;

      errors.reservation_email = "Reservation email is required!";
    } else if (!regex.test(hoteldetails.reservation_email)) {
      isFormValid = false;

      errors.reservation_email = "This is not a valid email format!";
    }
    if (!hoteldetails.address) {
      isFormValid = false;

      errors.address = "Hotel address is required!";
    }
    if(user_type==="admin-user"){
    if (!hoteldetails.commission_percentage) {
      isFormValid = false;

      errors.commission_percentage = "Commission percentage is required!";
    }
  }
    if (!hoteldetails.min_room_rate) {
      isFormValid = false;

      errors.min_room_rate = "Minimum room rate is required!";
    }
    if (!hoteldetails.max_room_rate) {
      isFormValid = false;

      errors.max_room_rate = "Maximum room rate is required!";
    }
    if (!hoteldetails.no_of_rooms) {
      isFormValid = false;

      errors.no_of_rooms = "Number of rooms is required!";
    }
    if (!hoteldetails.website_url) {
      isFormValid = false;

      errors.website_url = "Website Url is required!";
    }
    if (
      newhotellogo === null ||
      newhotellogo === undefined ||
      !newhotellogo ||
      newhotellogo === "" ||
      !newhotellogo.logo ||
      newhotellogo.logo === ""
    ) {
      if (!oldlogo) {
        isFormValid = false;

        errors.hotel_logo = "Hotel logo is required!";
      }
    } else {
      if (
        newhotellogo.logo.type === "image/jpeg" ||
        newhotellogo.logo.type === "image/jpg" ||
        newhotellogo.logo.type === "image/png" ||
        newhotellogo.logo.type === "image/avif" ||
        newhotellogo.logo.type === "image/webp"
      ) {
        console.log("");
      } else {
        isFormValid = false;

        errors.hotel_logo = "Only support jpg, jpeg, png image!";
      }
    }
    setError1(errors);
    const errors1 = {};
    if (selectedExtanet.length === 0) {
      isFormValid = false;

      errors1.selectedExtanet = "Extranet language is required!";
    } else {
      if (selectedExtanet.length > 4) {
        isFormValid = false;

        errors1.selectedExtanet = "Only 4 language choose!";
      }
    }
    setError2(errors1);

    const errors2 = {};
    if (selectedBookinglang.length === 0) {
      isFormValid = false;

      errors2.selectedBookinglang = "Booking engine language is required!";
    }
    setError3(errors2);
    const errors3 = {};
    if (propertytypehotel.length === 0) {
      isFormValid = false;
      errors3.propertytypehotel = "Property type is required!";
    }
    setError4(errors3);
    const errors4 = {};
    if (hoteldetails.credit_card_status === true) {
      if (selectedCard.length === 0) {
        isFormValid = false;

        errors4.selectedCard = "Card is required!";
      }
      setError5(errors4);
    }
    const errors5 = {};
    if (hoteldetails.channel_manager_status === true) {
      if (!selectedchannelmanager || selectedchannelmanager.length === 0) {
        isFormValid = false;

        errors5.channel_manager_name = "Channel manager name is required!";
      }
    }
    if (hoteldetails.pms_status === true) {
      if (!selectedpms || selectedpms.length === 0) {
        isFormValid = false;

        errors5.pms_name = "PMS name is required!";
      }
    }
    if (!selectedcategory || selectedcategory.length === 0) {
      isFormValid = false;

      errors5.category = "Category  is required!";
    }

    if (!selectedcountry || selectedcountry.length === 0) {
      isFormValid = false;

      errors5.country = "Country is required!";
    }

    if (!selectedcurrency || selectedcurrency.length === 0) {
      isFormValid = false;

      errors5.currency = "currency is required!";
    }
    if (!selectedtimezone || selectedtimezone.length === 0) {
      isFormValid = false;

      errors5.timezone = "Timezone is required!";
    }
    if (!optionalimage || optionalimage.length === 0) {
      if (selectedoptionalimage.length === 0) {
        isFormValid = false;

        errors5.optionalimage = "Optional image is required!";
      }
    } else {
      for (var i = 0; i < optionalimage.length; i++) {
        if (
          optionalimage[i].type === "image/jpeg" ||
          optionalimage[i].type === "image/jpg" ||
          optionalimage[i].type === "image/png" ||
          optionalimage[i].type === "image/avif" ||
          optionalimage[i].type === "image/webp"
        ) {
          console.log("");
        } else {
          isFormValid = false;

          errors5.optionalimage = "Only support jpg, jpeg, png image!";
        }
      }
    }
    if (!hotelimage || hotelimage.length === 0) {
      if (selectedhotelimage.length === 0) {
        isFormValid = false;

        errors5.hotelimage = "Hotel image is required!";
      }
    } else {
      for (var i = 0; i < hotelimage.length; i++) {
        if (
          hotelimage[i].type === "image/jpeg" ||
          hotelimage[i].type === "image/jpg" ||
          hotelimage[i].type === "image/png" ||
          hotelimage[i].type === "image/avif" ||
          hotelimage[i].type === "image/webp"
        ) {
          console.log("");
        } else {
          isFormValid = false;

          errors5.hotelimage = "Only support jpg, jpeg, png image!";
        }
      }
    }
    if (selectedboard.length === 0) {
      isFormValid = false;
      errors5.selectedboard = "Hotel board is required!";
    }
    if (checkintime.length === 0) {
      isFormValid = false;
      errors5.checkintime = "Checkin time is required!";
    }
    if (checkouttime.length === 0) {
      isFormValid = false;
      errors5.checkouttime = "Checkout time is required!";
    }
    setErrorall(errors5);

    if (isFormValid === true) {
      const formData = new FormData();
      formData.append("hotel_name", hoteldetails.hotel_name);
      formData.append("corporate_name", hoteldetails.corporate_name);
      formData.append("hotel_status", hoteldetails.hotel_status);
      formData.append("country", selectedcountry.value);
      formData.append("timezone", selectedtimezone.value);
      formData.append("calling_code", hoteldetails.calling_code);
      formData.append("phone_number", hoteldetails.phone_no);
      formData.append("registration_email", hoteldetails.registration_email);
      formData.append("reservation_email", hoteldetails.reservation_email);
      formData.append("address", hoteldetails.address);
      formData.append("checkin_time", checkintime.value);
      formData.append("checkout_time", checkouttime.value);
      if(user_type==="admin-user"){
        formData.append("commission_percentage", hoteldetails.commission_percentage);
      }
      for (let i = 0; i < selectedExtanet.length; i++) {
        formData.append(`extranet_language[${i}]`, selectedExtanet[i].value);
      }
      for (let i = 0; i < selectedBookinglang.length; i++) {
        formData.append(
          `booking_engine_language[${i}]`,
          selectedBookinglang[i].value
        );
      }
      if (hoteldetails.licence_number) {
        formData.append("licence_number", hoteldetails.licence_number);
      } else {
        formData.append("licence_number", "");
      }
      if (hoteldetails.vat_registration_number) {
        formData.append(
          "vat_registration_number",
          hoteldetails.vat_registration_number
        );
      } else {
        formData.append("vat_registration_number", "");
      }
      formData.append("property_type", propertytypehotel.value);
      formData.append("category", selectedcategory.value);
      formData.append("default_currency", selectedcurrency.value);
      formData.append("min_room_rate", hoteldetails.min_room_rate);
      formData.append("max_room_rate", hoteldetails.max_room_rate);
      formData.append("no_of_rooms", hoteldetails.no_of_rooms);
      formData.append("website_url", hoteldetails.website_url);

      for (let i = 0; i < hotelimage.length; i++) {
        formData.append(`hotel_image[${i}]`, hotelimage[i]);
      }
      for (let i = 0; i < optionalimage.length; i++) {
        formData.append(`optional_images[${i}]`, optionalimage[i]);
      }
      if (newhotellogo.logo) {
        formData.append("logo", newhotellogo.logo);
      }
      // formData.append("logo", hoteldetails.hotel_logo);
      formData.append(
        "channel_manager_status",
        hoteldetails.channel_manager_status
      );
      if (hoteldetails.channel_manager_status === true) {
        formData.append("channel_manager", selectedchannelmanager.value);
      }
      formData.append("pms_status", hoteldetails.pms_status);
      if (hoteldetails.pms_status === true) {
        formData.append("pms", selectedpms.value);
      }
      if (hoteldetails.credit_card_status === true) {
        formData.append("credit_card_status", 1);
      } else {
        formData.append("credit_card_status", 0);
      }
      if (hoteldetails.credit_card_status === true) {
        for (let i = 0; i < selectedCard.length; i++) {
          formData.append(`credit_cards[${i}]`, selectedCard[i].value);
        }
      }
      for (let i = 0; i < selectedboard.length; i++) {
        formData.append(`boards[${i}]`, selectedboard[i].value);
      }
      if (oldlist.length > 0) {
        for (var y = 0; y < oldlist.length; y++) {
          formData.append(`removed_images[optional_images][${y}]`, oldlist[y]);
        }
      }
      if (oldlisthotel.length > 0) {
        for (var y = 0; y < oldlisthotel.length; y++) {
          formData.append(`removed_images[hotel_image][${y}]`, oldlisthotel[y]);
        }
      }
      axios
        .post(`${Config.apiurl}hotel/update/${id}`, formData, {})
        .then((res) => {
          if (res.status_code !== "200" || !res) {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(false);
          } else {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(false);
            setReviewhotel(false);
            getinfo();
          }
        });
    } else {
      console.log("error");
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    getinfo();
  }, [id]);

  useEffect(() => {
    getall();
    getBoard();
  }, []);

  useEffect(() => {
    if (checkintimenew) {
      const checkin = time.find((option) => option.value === checkintimenew);
      if (checkin) {
        setCheckintime(checkin);
      }
    }

    if (checkouttimenew) {
      const checkout = time.find((option) => option.value === checkouttimenew);
      if (checkout) {
        setCheckouttime(checkout);
      }
    }
  }, [checkintimenew, checkouttimenew]);

  // console.log(checkintime);
  // console.log(id);

  // useEffect(() => {
  //   setId(hotel_select[0]?.value);
  // }, [hotel_select]);

  useEffect(() => {
    if (selectedboard.length > 0) {
      const filteredBoards = board.filter((board) =>
        selectedboard.some(
          (selectedBoard) => selectedBoard.value === board.value
        )
      );
      setSelectedboard(filteredBoards);
    }
  }, [selectedboard.length, board.length]);

  return (
    <div className="page-content">
      <Commonheader />
      <div className="container-fluid">
        <div className="row project-wrapper">
          <div className="row dashboard-top-tab-style">
            <div className="col-xxl-12 dashboard-main-style">
              <div className="card">
                <div className="card-header">
                  {hotel_select.length > 1 ? (
                    <h4 className="">{hoteldetails.hotel_name}</h4>
                  ) : (
                    ""
                  )}
                  <div className="row align-items-center gy-3">
                    <div className="col-sm">
                      <div className="card-title mb-0">General Information</div>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-1 flex-wrap">
                        <button
                          className="btn db-save-button"
                          onClick={PostData}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="BasicInfo" className="tabcontent">
                  <div className="card-body">
                    <div className="live-preview">
                      <form action="#">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                for="firstNameinput"
                                className="form-label be_form_label"
                              >
                                Name of the Hotel
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    The name of the hotel
                                  </span>
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={hoteldetails.hotel_name}
                                onChange={handlechange}
                                name="hotel_name"
                              />

                              <label style={{ color: "red" }}>
                                {formerror1.hotel_name}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                for="lastNameinput"
                                className="form-label be_form_label"
                              >
                                Corporate Name
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    The legal name of the company
                                  </span>
                                </span>
                              </label>

                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={hoteldetails.corporate_name}
                                onChange={handlechange}
                                name="corporate_name"
                              />

                              <label style={{ color: "red" }}>
                                {" "}
                                {formerror1.corporate_name}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                Country{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    The name of the country where the hotel is
                                    located.
                                  </span>
                                </span>
                              </label>

                              <Select
                                className="form-control"
                                options={country}
                                value={selectedcountry}
                                onChange={(val) => {
                                  handleChangeselectcountry(val);
                                }}
                                name="country"
                              />

                              <label style={{ color: "red" }}>
                                {formerrorall.country}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                Timezone{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    The name of the timezone where the hotel is
                                    located
                                  </span>
                                </span>
                              </label>
                              {countrytime.length > 1 ? (
                                <Select
                                  className="form-control"
                                  options={countrytime}
                                  value={selectedtimezone}
                                  onChange={(val) => {
                                    setSelectedtimezone(val);
                                    setHotelinfoupdatestatus(true);
                                  }}
                                />
                              ) : (
                                <Select
                                  className="form-control"
                                  options={countrytime}
                                  selected={selectedtimezone}
                                  value={selectedtimezone}
                                  onChange={(val) => {
                                    setSelectedtimezone(val);
                                    setHotelinfoupdatestatus(true);
                                  }}
                                />
                              )}
                              <label style={{ color: "red" }}>
                                {" "}
                                {formerrorall.timezone}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                for="phonenumberInput"
                                className="form-label be_form_label"
                              >
                                Phone Number{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    The primary phone number for the hotel
                                  </span>
                                </span>
                              </label>

                              <div className="input-group" data-input-flag>
                                {hoteldetails.calling_code === null ? (
                                  <button
                                    className="btn btn-light border calling-btn"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <select
                                      value={hoteldetails.calling_code}
                                      onChange={handlechange}
                                      name="calling_code"
                                      className="calling-select"
                                    >
                                      {calling.map((calling_code, index) => (
                                        <option
                                          key={index}
                                          value={calling_code}
                                        >
                                          {calling_code}
                                        </option>
                                      ))}
                                    </select>
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-light border calling-btn"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    {hoteldetails.calling_code}
                                  </button>
                                )}
                                <input
                                  type="number"
                                  className="form-control rounded-end flag-input"
                                  placeholder="Enter number"
                                  value={hoteldetails.phone_no}
                                  onChange={handlechange}
                                  name="phone_no"
                                  onFocus={(e) =>
                                    e.target.addEventListener(
                                      "wheel",
                                      function (e) {
                                        e.preventDefault();
                                      },
                                      { passive: false }
                                    )
                                  }
                                />
                              </div>
                              <label style={{ color: "red" }}>
                                {formerror1.phone_no}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                Registration Email{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    Email address for getting in touch with the
                                    hotel directly
                                  </span>
                                </span>
                              </label>{" "}
                              <input
                                type="email"
                                className="form-control"
                                placeholder="example@gmail.com"
                                value={hoteldetails.registration_email}
                                onChange={handlechange}
                                name="registration_email"
                              />
                              <label style={{ color: "red" }}>
                                {formerror1.registration_email}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                Reservation Email{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    Email address for getting in touch with the
                                    hotel directly
                                  </span>
                                </span>
                              </label>{" "}
                              <input
                                type="email"
                                className="form-control"
                                placeholder="example@gmail.com"
                                value={hoteldetails.reservation_email}
                                onChange={handlechange}
                                name="reservation_email"
                              />
                              <label style={{ color: "red" }}>
                                {formerror1.reservation_email}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                Address of Hotel{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    Physical street address where the hotel is
                                    located.
                                  </span>
                                </span>
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={hoteldetails.address}
                                onChange={handlechange}
                                name="address"
                              />
                              <label style={{ color: "red" }}>
                                {" "}
                                {formerror1.address}{" "}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                Checkin Time{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                              </label>
                              <Select
                                className="form-control"
                                options={time}
                                value={checkintime}
                                onChange={(val) => {
                                  setCheckintime(val);
                                }}
                              />

                              <label style={{ color: "red" }}>
                                {" "}
                                {formerrorall.checkintime}{" "}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                Checkout Time{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                              </label>
                              <Select
                                className="form-control"
                                options={time}
                                value={checkouttime}
                                onChange={(val) => {
                                  setCheckouttime(val);
                                }}
                              />
                              <label style={{ color: "red" }}>
                                {" "}
                                {formerrorall.checkouttime}{" "}
                              </label>
                            </div>
                          </div>
                          {user_type==="admin-user"?
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                Commission{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                                {/* <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    Physical street address where the hotel is
                                    located.
                                  </span>
                                </span> */}
                              </label>{" "}
                              <span className="d-flex">
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={hoteldetails.commission_percentage}
                                onChange={handlechange}
                                name="commission_percentage"
                                style={{width:"80%"}}
                              />
                               <span
                                  className="input-group-text input-joint-color-loyal"
                                  id="inputGroup-sizing-default" style={{width:"20%"}}>%</span>
                                </span>
                              <label style={{ color: "red" }}>
                                {" "}
                                {formerror1.commission_percentage}{" "}
                              </label>
                            </div>
                          </div>
                          :""}
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                Extranet Languages{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    The choice of languages available for the
                                    extranet platform
                                  </span>
                                </span>
                              </label>

                              <MultiSelect
                                options={be_lang}
                                value={selectedExtanet}
                                onChange={(val) => {
                                  setSelectedExtranet(val);
                                  setHotelinfoupdatestatus(true);
                                }}
                                labelledBy="Select"
                                selectionType="counter"
                                allowCustomValue={true}
                                hasSelectAll={false}
                              />
                              <label style={{ color: "red" }}>
                                {" "}
                                {formerror2.selectedExtanet}{" "}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                Booking Engine Languages{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    The choice of languages available for the
                                    booking engine platform{" "}
                                  </span>
                                </span>
                              </label>
                              <MultiSelect
                                options={be_lang}
                                value={selectedBookinglang}
                                onChange={(val) => {
                                  setSelectedBookinglang(val);
                                  setHotelinfoupdatestatus(true);
                                }}
                                labelledBy="Select"
                                selectionType="counter"
                                allowCustomValue={true}
                              />
                              <label style={{ color: "red" }}>
                                {" "}
                                {formerror3.selectedBookinglang}{" "}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                License Number
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    The unique identification number or code
                                    assigned to the hotel by the relevant
                                    licensing or regulatory authorities.
                                  </span>
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={hoteldetails.licence_number}
                                onChange={handlechange}
                                name="licence_number"
                              />
                              <label style={{ color: "red" }}>
                                {" "}
                                {formerror1.licence_number}{" "}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                Vat Registration Number{" "}
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    The unique identification number assigned to
                                    the hotel by the relevant tax authorities
                                    for the purpose of VAT compliance.
                                  </span>
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={hoteldetails.vat_registration_number}
                                onChange={handlechange}
                                name="vat_registration_number"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                Property Type{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    Select Property Type for Your Hotel
                                  </span>
                                </span>
                              </label>

                              <Select
                                className="form-control"
                                options={property}
                                value={propertytypehotel}
                                onChange={(val) => {
                                  setPropertytypehotel(val);
                                  setHotelinfoupdatestatus(true);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                Category{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    Select Star category for Your Hotel
                                  </span>
                                </span>
                              </label>

                              <Select
                                className="form-control"
                                options={star}
                                value={selectedcategory}
                                onChange={(val) => {
                                  setSelectedcategory(val);
                                  setHotelinfoupdatestatus(true);
                                }}
                              />
                              <label style={{ color: "red" }}>
                                {" "}
                                {formerrorall.category}{" "}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                Default Currency{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    The primary currency that the hotel uses for
                                    its financial transactions
                                  </span>
                                </span>
                              </label>
                              <Select
                                className="form-control"
                                options={currency}
                                value={selectedcurrency}
                                onChange={(val) => {
                                  setSelectedcurrency(val);
                                  setHotelinfoupdatestatus(true);
                                }}
                              />
                              <label style={{ color: "red" }}>
                                {" "}
                                {formerrorall.currency}{" "}
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="mb-4">
                              <label className="form-label be_form_label">
                                Hotel Image
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag mar_1">
                                  i
                                  <span className="tooltiptext">
                                    Hotel image to be displayed on the booking
                                    confirmation for the guest
                                  </span>
                                </span>
                                <span className="span-img-size">
                                  ( 1024 px * 270px)
                                </span>
                              </label>
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                                onChange={onChange}
                                multiple
                                name="hotel_images"
                              />

                              <label style={{ color: "red" }}>
                                {" "}
                                {formerrorall.hotelimage}{" "}
                              </label>

                              <div className="row">
                                {selectedhotelimage.length !== 0
                                  ? selectedhotelimage.map((image, i) => {
                                      return (
                                        <div
                                          className="col-sm-6 col-6 col-md-6 col-lg-4 mt-2"
                                          key={i}
                                        >
                                          <img
                                            src={`${Commonimagepath.apiurl}${image}`}
                                            alt=""
                                            className="position-relative"
                                            style={{
                                              width: "100px",
                                              marginTop: "5px",
                                              marginLeft: "5px",
                                              display: "block",
                                            }}
                                          />

                                          <button
                                            className="remov-button"
                                            type="button"
                                            onClick={(e) =>
                                              removeImagehotel(image)
                                            }
                                          >
                                            X
                                          </button>
                                        </div>
                                      );
                                    })
                                  : ""}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="mb-4">
                              <label className="form-label be_form_label">
                                Optional Image
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag mar_1">
                                  i
                                  <span className="tooltiptext">
                                    Room image to be displayed on the booking
                                    confirmation for the guest, example your
                                    best room image
                                  </span>
                                </span>
                                <span className="span-img-size">
                                  ( 1024 px * 270px)
                                </span>
                              </label>
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                                onChange={onChangehotelroom}
                                name="optional_images"
                                multiple
                              />

                              <label style={{ color: "red" }}>
                                {" "}
                                {formerrorall.optionalimage}{" "}
                              </label>
                              <div className="row">
                                {selectedoptionalimage.length !== 0
                                  ? selectedoptionalimage.map((image, i) => {
                                      return (
                                        <div
                                          className="col-sm-6 col-md-6 col-lg-4 col-6 mt-2"
                                          key={i}
                                        >
                                          <img
                                            src={`${Commonimagepath.apiurl}${image}`}
                                            alt=""
                                            className="position-relative"
                                            style={{
                                              width: "100px",
                                              marginTop: "5px",
                                              marginLeft: "5px",
                                              display: "block",
                                            }}
                                          />
                                          <button
                                            className="remov-button"
                                            type="button"
                                            onClick={(e) => removeImage(image)}
                                          >
                                            X
                                          </button>
                                        </div>
                                      );
                                    })
                                  : ""}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                Logo{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag mar_1">
                                  i
                                  <span className="tooltiptext">
                                    Upload Hotel logo{" "}
                                  </span>
                                </span>
                                <span className="span-img-size">
                                  ( 140 px * 40px)
                                </span>
                              </label>
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                                onChange={onChangelogo}
                                name="logo"
                              />
                              <label style={{ color: "red" }}>
                                {formerror1.logo}
                              </label>
                              {oldlogo ? (
                                <div className="row">
                                  <div className="col-md-6">
                                    <img
                                      src={`${Commonimagepath.apiurl}${oldlogo}`}
                                      alt=""
                                      className="position-relative"
                                      style={{
                                        width: "100px",
                                        marginTop: "5px",
                                        marginLeft: "5px",
                                        display: "block",
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                Minimum Room Rate{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    The lowest price at which a hotel room can
                                    be booked for a particular date or period.
                                  </span>
                                </span>
                              </label>
                              <input
                                onKeyDown={(e) =>
                                  symbolsrate.includes(e.key) &&
                                  e.preventDefault()
                                }
                                type="number"
                                className="form-control"
                                placeholder=""
                                value={hoteldetails.min_room_rate}
                                onChange={handlechange}
                                name="min_room_rate"
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                              />
                              <label style={{ color: "red" }}>
                                {" "}
                                {formerror1.min_room_rate}{" "}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                Maximum Room Rate{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    The highest price at which a hotel room can
                                    be booked for a particular date or period
                                  </span>
                                </span>
                              </label>
                              <input
                                onKeyDown={(e) =>
                                  symbolsrate.includes(e.key) &&
                                  e.preventDefault()
                                }
                                type="number"
                                className="form-control"
                                placeholder=""
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                                value={hoteldetails.max_room_rate}
                                onChange={handlechange}
                                name="max_room_rate"
                              />
                              <label style={{ color: "red" }}>
                                {" "}
                                {formerror1.max_room_rate}{" "}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                No of Rooms
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    Total count of rooms available for booking
                                    in the hotel.
                                  </span>
                                </span>
                              </label>
                              <input
                                onKeyDown={(e) =>
                                  symbolsArr.includes(e.key) &&
                                  e.preventDefault()
                                }
                                type="number"
                                className="form-control"
                                placeholder=""
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                                value={hoteldetails.no_of_rooms}
                                onChange={handlechange}
                                name="no_of_rooms"
                              />
                              <label style={{ color: "red" }}>
                                {" "}
                                {formerror1.no_of_rooms}{" "}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label be_form_label">
                                Website URL
                                <span className="form-validation-required">
                                  *
                                </span>
                                <span className="info-tag">
                                  i
                                  <span className="tooltiptext">
                                    Hotel official website address
                                  </span>
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={hoteldetails.website_url}
                                onChange={handlechange}
                                name="website_url"
                              />
                              <label style={{ color: "red" }}>
                                {" "}
                                {formerror1.website_url}{" "}
                              </label>
                            </div>
                          </div>
                         
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label be_form_label"
                                >
                                  {" "}
                                  Hotel Status{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <select
                                  className="form-select"
                                  value={hoteldetails.hotel_status}
                                  onChange={handlechange}
                                  name="hotel_status"
                                >
                                  <option selected>Choose...</option>
                                  <option
                                    {...(hoteldetails.hotel_status === true
                                      ? "selected"
                                      : "")}
                                    value={true}
                                  >
                                    Active
                                  </option>
                                  <option
                                    {...(hoteldetails.hotel_status === false
                                      ? "selected"
                                      : "")}
                                    value={false}
                                  >
                                    Inactive
                                  </option>
                                </select>
                                <label style={{ color: "red" }}>
                                  {formerror1.hotel_status}
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label be_form_label">
                                  Hotel Boards{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                  <span className="info-tag">
                                    i
                                    <span className="tooltiptext">
                                      Select Hotel Boards
                                    </span>
                                  </span>
                                </label>
                                <MultiSelect
                                  options={board}
                                  value={selectedboard}
                                  onChange={(val) => {
                                    setSelectedboard(val);
                                    setHotelinfoupdatestatus(true);
                                  }}
                                  labelledBy="Select"
                                  selectionType="counter"
                                  allowCustomValue={true}
                                />
                                <label style={{ color: "red" }}>
                                  {" "}
                                  {formerrorall.selectedboard}{" "}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label be_form_label"
                                >
                                  Do you use a channel manager?{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <span
                                  className="form-check form-switch form-switch-lg mb-3"
                                  dir="ltr"
                                >
                                  <input
                                    type="checkbox"
                                    name="channel_manager_status"
                                    className="form-check-input loyalty_expiration"
                                    value={hoteldetails.channel_manager_status}
                                    checked={
                                      hoteldetails.channel_manager_status ===
                                      true
                                        ? true
                                        : false
                                    }
                                    onChange={handleChangetogghotel}
                                  />
                                </span>
                              </div>
                            </div>

                            {hoteldetails.channel_manager_status === true ? (
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label be_form_label">
                                    Name of Channel Manager
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>

                                  <Select
                                    className="form-control"
                                    options={channel}
                                    value={selectedchannelmanager}
                                    onChange={(val) => {
                                      setSelectedchannelmanager(val);
                                      setHotelinfoupdatestatus(true);
                                    }}
                                  />
                                  <label style={{ color: "red" }}>
                                    {" "}
                                    {formerrorall.channel_manager_name}{" "}
                                  </label>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                         
                            <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label be_form_label"
                                >
                                  Do you use a PMS{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <span
                                  className="form-check form-switch form-switch-lg mb-3"
                                  dir="ltr"
                                >
                                  <input
                                    type="checkbox"
                                    name="pms_status"
                                    className="form-check-input loyalty_expiration"
                                    value={hoteldetails.pms_status}
                                    checked={
                                      hoteldetails.pms_status === true
                                        ? true
                                        : false
                                    }
                                    onChange={handleChangetogghotel}
                                  />
                                </span>
                              </div>
                            </div>
                            {hoteldetails.pms_status === true ? (
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label be_form_label">
                                    Name of the PMS
                                    <span className="label-sub">
                                      {" "}
                                      ( Property managment system )
                                    </span>
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>

                                  <Select
                                    className="form-control"
                                    options={pms}
                                    value={selectedpms}
                                    onChange={(val) => {
                                      setSelectedPMS(val);
                                      setHotelinfoupdatestatus(true);
                                    }}
                                  />
                                  <label style={{ color: "red" }}>
                                    {" "}
                                    {formerrorall.pms_name}{" "}
                                  </label>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label be_form_label"
                                >
                                  Do you accepts credit card ?{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>

                                <span
                                  className="form-check form-switch form-switch-lg mb-3"
                                  dir="ltr"
                                >
                                  <input
                                    type="checkbox"
                                    name="credit_card_status"
                                    className="form-check-input loyalty_expiration"
                                    value={hoteldetails.credit_card_status}
                                    checked={
                                      hoteldetails.credit_card_status === true
                                        ? true
                                        : false
                                    }
                                    onChange={handleChangetogghotel}
                                  />
                                </span>
                              </div>
                            </div>
                            {hoteldetails.credit_card_status === true ? (
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label className="form-label be_form_label">
                                    Accepted credit cards{" "}
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                    <span className="info-tag">
                                      i
                                      <span className="tooltiptext">
                                        Select Accepted Credit cards
                                      </span>
                                    </span>
                                  </label>
                                  <MultiSelect
                                    options={creditcard}
                                    value={selectedCard}
                                    onChange={(val) => {
                                      setselectedCard(val);
                                      setHotelinfoupdatestatus(true);
                                    }}
                                    labelledBy="Select"
                                    selectionType="counter"
                                    allowCustomValue={true}
                                  />
                                  <label style={{ color: "red" }}>
                                    {" "}
                                    {formerror5.selectedCard}{" "}
                                  </label>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="padding-10"></div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Generalinfo;
