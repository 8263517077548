import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import Header from '../../../Layouts/Header';
import HotelSidebar from './Sidebars/HoletSidebar';
import Hotelselectsidebar from './Sidebars/Hotelselectsidebar';
import Sidebar from './Sidebars/Sidebar';
import Cookies from 'js-cookie';
import { UserContext } from '../Contexts/UserContext';

function Commonheader() {
  let navigate = useNavigate();
  const {updateHotel_select, hotel_select} = useContext(UserContext);
    const user_type = localStorage.getItem("user_type");
const [showhotel,setShowhotel]=useState(hotel_select.length>0?true:false);
const [dashboard,setDashboard]=useState(false);
const[pagename,setPagename]=useState("null");



useEffect(()=>{
  setShowhotel(hotel_select.length>0?true:false);
},[hotel_select])

  return (
    <div>
   
   <Header setShowhotel={setShowhotel} dashboard={dashboard} pagename={pagename} user_type={user_type} showhotel={showhotel}/>
      {user_type === "admin-user" ? (
        showhotel === false ? <Sidebar /> : <Hotelselectsidebar />
      ) : user_type === "hotel-user" ? (
        <HotelSidebar />
      ) : user_type==="vendor-user"?
        <Hotelselectsidebar/>
        : null}
    </div>
  )
}

export default Commonheader
