import React, { useState } from "react";
import Header from "../../../Layouts/Header";
import Sidebarprivatesale from "./Sidebars/Sidebarprivatesale";

function Privatesaleheader() {
  const user_type = localStorage.getItem("user_type");
  const [showhotel, setShowhotel] = useState(
    localStorage.getItem("selected-hotel") ? true : false
  );
  const [hotel, setHotel] = useState(localStorage.getItem("selected-hotel"));
  const [dashboard, setDashboard] = useState(true);
  const [pagename, setPagename] = useState("privatesale");

  return (
    <div>
      <Header
        setShowhotel={setShowhotel}
        dashboard={dashboard}
        pagename={pagename}
        user_type={user_type}
        showhotel={showhotel}
      />

      <Sidebarprivatesale showhotel={showhotel} />
    </div>
  );
}

export default Privatesaleheader;
