import React from 'react'
import Footer from '../../Common/Footer';
import { MultiSelect } from 'react-multi-select-component';
import Alerts from '../../Alerts';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Config from "../../Config";
import Commonheader from '../../Common/Commonheader';
import Basicsettingsheader from '../../Common/Basicsettingsheader';


function Paymentoptioncreate() {
  let navigate = useNavigate();

    const [selectedMulti, setselectedMulti] = useState([]);
    const [selectedMultilang, setselectedMultilang] = useState([]);

  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }


  function handleMultilang(selectedMultilang) {
    setselectedMultilang(selectedMultilang);
  }

  const [language, setLanguage] = useState([]);
  const [status, setStatus] = useState("");
  const [active, setActive] = useState("en");
  const [roletrans, setRoletrans] = useState({});
  const [alert, setAlert] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [open, setOpen] = useState(false);
  const [option,setOption]=useState("")
  const [payment,setPayment]=useState("")
  const [formerror, setError] = useState({});
  const [formerror1, setError1] = useState({});
  const [formerror2, setError2] = useState({});
  const[method,setMethod]=useState([])

    const getDatalang = async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setLanguage(data);
    }
  };
  const getDatamethod = async (e) => {
    const res = await fetch(`${Config.apiurl}payment-methods`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setMethod(data);
    }
  };

  const trnsarray = [];
  function handletrans(i, e, val) {
    const trnas = roletrans;
    setRoletrans({
      code: val,
      name: e.target.value,
    });
    trnas.code = val;
    trnas.name = e.target.value;
    trnsarray.push(trnas);
  }

  function Reset() {
    setLanguage("");
    setOption("");
    setStatus("");
    setselectedMulti("");
    setselectedMultilang("");

  }

  const validate = (values) => {
    const errors = {};
    if (!values) {
      errors.option = "Payment type is required!";
    }
    return errors;
  };
  
  const validate1 = (values) => {
    const errors = {};
    if (!values) {
      errors.status = "Status is required!";
    }
    return errors;
  };

  const validate2 = (values) => {
    const errors = {};
    console.log(values);
    if (values.length===0) {
      errors.selectedMulti = "Payment method is required!";
    }
    return errors;
  };

  const PostData = async (e) => {
    e.preventDefault();
    const ress = validate(option);
    const ress1= validate1(status);
    const ress2 = validate2(selectedMulti);
   
    let lang = [];
    
    for (let i = 0; i < selectedMultilang.length; i++) {
      if (selectedMultilang[i].name) {
        if (selectedMultilang[i].code !== "en") {
          let multiarray = {};
          multiarray.lang_code = selectedMultilang[i].code;
          multiarray.payment_type = selectedMultilang[i].name;

          lang.push(multiarray);
        }
      }
    }

    let paymethod = [];
    
    for (let i = 0; i < selectedMulti.length; i++) {

      paymethod.push(selectedMulti[i].value);
      }
    
    setError(ress);
    setError1(ress1);
    setError2(ress2);

    if (!ress.option && !ress1.status && !ress2.selectedMulti) {
   
      const res = await fetch(`${Config.apiurl}payment-option/add`, {
        method: "POST",
        body: JSON.stringify({
          en_payment_type: option,
          payment_methods:paymethod,
          is_active: status,
          multi_lang: lang,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await res.json();
      if (data.status_code !== "200" || !data) {
        setAlert(true);
        setAlertmessage(data.message);
        setOpen(true);
        setAlerttype("error");
      } else {
        setAlert(true);
        setAlertmessage(data.message);
        setOpen(true);
        setAlerttype("success");
        setTimeout(() => navigate("/basic_settings/payment-option-list"), 3000);

        
        Reset();
      }
    }
  };

  useEffect(() => {
    getDatalang();
    getDatamethod();
  }, []);
console.log(selectedMultilang);

  return (
    <div id="layout-wrapper">
     <Basicsettingsheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">  Payment Option Create </h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Settings</Link>
                      </li>
                      
                      <li className="breadcrumb-item ">
                        <Link to="#">Basic Settings </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="#">Payment Settings</Link>
                      </li>
                      <li className="breadcrumb-item ">
                        <Link to="/payment-option-list">  Payment Option</Link>
                      </li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>
            {alert ? (
              <Alerts type={alerttype} message={alertmessage} open={open} />
            ) : (
              ""
            )}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create New Payment Option
                      </h4>
                      <div className="flex-shrink-0">
                        <Link
                          type="button"
                          className="btn db-other-button "
                          to="/basic_settings/payment-option-list"
                          style={{ marginRight: "5px" }}
                        >
                         Payment Option
                        </Link>
                        <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to="" onClick={Reset}
                       
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          id="create-btn"
                          data-bs-target="" onClick={PostData}
                       
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-translation-style">
                                <ul>
                                  <li
                                    className={active === "en" ? "active" : ""}
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => setActive("en")}
                                      className={
                                        active === "en" ? "active" : ""
                                      }
                                    >
                                      EN
                                    </Link>
                                  </li>
                                  <li
                                    className={
                                      active === "trans" ? "active" : ""
                                    }
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => setActive("trans")}
                                      className={
                                        active === "trans" ? "active" : ""
                                      }
                                    >
                                      Translations
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {active === "en" ? (
                              <>
                                <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Payment Type
                            <span className="form-validation-required">*</span>
                              </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={option}
                                      onChange={(e) =>
                                        setOption(e.target.value)
                                      }
                                    /> </div>
                                    <label style={{ color: "red" }}>
                                      {formerror.option}
                                    </label>
                                 
                                </div>
                                <div class="col-lg-4">
                                  <div className="input-group">
                                    <label className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default" style={{height:"40px"}}>Payment Method
                                      <span className="form-validation-required">*</span></label>
                                  <div className="col-md-7">
                                  <MultiSelect
                                      options={method}
                                      value={selectedMulti}
                                      onChange={setselectedMulti}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      closeOnChangedValue={true}
                                      aria-expanded="true"
                                    /> </div>
                                     <label style={{ color: "red" }}>
                                      {formerror2.selectedMulti}
                                    </label>
                               
                                </div>
                                </div>
                              
                                <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">Status
                            <span className="form-validation-required">*</span>
                              </label>
                                    <select
                                      id="ForminputState"
                                      className="form-select"
                                      value={status}
                                      onChange={(e) =>
                                        setStatus(e.target.value)
                                      }
                                    >
                                      <option selected>Choose...</option>
                                      <option value="1">Active</option>
                                      <option value="0">Inactive</option>
                                    </select>  </div>
                                    <label style={{ color: "red" }}>
                                      {formerror1.status}
                                    </label>
                                  </div>
                              </>
                              
                            ) : (
                              <>
                              <div class="col-lg-4" style={{marginTop:"20px"}}>
                                  <div className="input-group">
                                    <label className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default" style={{width:"130px"}}> Choose Language:</label>
                                  <div className="col-md-7">
                                  <MultiSelect
                                      options={language}
                                      value={selectedMultilang}
                                      onChange={setselectedMultilang}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      closeOnChangedValue={true}
                                      aria-expanded="true"
                                    />
                                </div>
                                </div>
                                </div>
                               
                                <div className="appendmore"></div>
                                {selectedMultilang.length > 0
                                  ? selectedMultilang.map((element, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="col-md-12 translation-fill-section"
                                          id="lang_ +val"
                                          data-value="1" style={{marginTop:"20px"}}
                                        >
                                          <div className="translation-selected-lang">
                                            <p>
                                              <span className="translation-selected-lang-head">
                                                {element.code}
                                              </span>
                                            </p>
                                          </div>
                                          <div class="col-lg-4">
                                        <div className="input-group">
                                      <label className="input-group-text input-joint-color"
                                          id="inputGroup-sizing-default">Name
                                      <span className="form-validation-required">*</span>
                                        </label>
                                              <input
                                                type="text"
                                                className="form-control"
                                                value={
                                                  selectedMultilang[index].name
                                                }
                                                onChange={
                                                  (e) =>
                                                    (selectedMultilang[index].name =
                                                      e.target.value)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  : ""}
                              </>
                            )}
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
export default Paymentoptioncreate