import React, {  useLayoutEffect } from "react";

//import Scss
import "./assets/scss/themes.scss";
import { UserProvider } from "./Components/Commoncomponents/Contexts/UserContext";
//imoprt Route
import Route from "./Routes";





function App() {
  return (
    <React.Fragment>
      <UserProvider>
      <Route />
      </UserProvider>
    </React.Fragment>
  );
}

export default App;
