import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Alerts from "../../Alerts";
import Footer from "../../Common/Footer";
import Config from "../../Config";
import Select from "react-select";
import axios from "axios";
import DeleteModal from "../../DeleteModal";
import ReactPaginate from "react-paginate";
import Commonheader from "../../Common/Commonheader";

function Bookingenginelanguage() {
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const [datas, setDatas] = useState([]);
  const [language, setLanguage] = useState([]);
  const [b_language, setB_language] = useState("");
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [open, setOpen] = useState(false);
  const [formerror, setError] = useState({});
  const [paginate, setPaginate] = useState([]);
  const[page,setPage]=useState(1);
  const[pageindex,setPageindex]=useState(1);

  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const [pageid, setPageid] = useState("");

  // const [arrays, setArrays] = useState([]);
  const arrays = [];
  function tog_animationFlip(id) {
    setmodal_animationFlip(!modal_animationFlip);
    setPageid(id);
  }

  const validate = (values) => {
    const errors = {};
    if (!values) {
      errors.b_language = "Language is required!";
    }
    return errors;
  };
  const PostData = async (e) => {
    e.preventDefault();
    const ress = validate(b_language);
    setError(ress);
    if (!ress.b_language) {
      const formData = new FormData();
      formData.append("language", b_language.value);
      axios
        .post(`${Config.apiurl}admin/booking-engine/language/add`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.status_code !== "200" || !res) {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("error");
          } else {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("success");
            handlePageClick(page);

            // setTimeout(() => navigate("/dashboard"), 3000);
          }
        });
    }
  };

  const DeleteData = async (id) => {
    const formData = new FormData();
    formData.append("language", id);

    axios
      .post(`${Config.apiurl}admin/booking-engine/language/remove`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status_code !== "200" || !res) {
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("error");
          setmodal_animationFlip(false);
        } else {
          setAlert(true);
          setAlertmessage(res.message);
          setOpen(true);
          setAlerttype("success");
          setmodal_animationFlip(false);
          handlePageClick(page);

        }
      });
  };

  const getData = async (e) => {
    const res = await fetch(
      `${Config.apiurl}admin/booking-engine/language/list?page=1`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    const data = await res.json();
    if (res.status === 404 || !data) {
      console.log("error");
    } else {
      setDatas(data.data.data);
      setPaginate(data.data.last_page);
    }
  };

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}admin/booking-engine/language/list?page=${currentPage}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const data = await res.json();
    return data.data.data;
  };

  const handlePageClick = async (data) => {

    if(data===1){
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
    
      setDatas(commentsFormServer);
    
    }
    else{
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
    
      setDatas(commentsFormServer);
    }

  };
  useEffect(() => {
    const getinfo = async (e) => {
      const res = await fetch(`${Config.apiurl}language-list`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await res.json();
      if (res.status === 404 || !data) {
        console.log("error");
      } else {
        setLanguage(data.data);
      }
    };

    getinfo();
    getData();

    // for (let i = 0; i < language.length; i++) {
    //   const langval = {
    //     label: language[i].language_name,
    //     value: language[i].lang_id,
    //   };
    //   arrays.push(langval);
    //   console.log(arrays);
    // }
  }, []);
  function handleChange(selectedOption) {
    setB_language(selectedOption);
  }
  return (
    <div>
      <div id="layout-wrapper">
       <Commonheader/>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="db-page-title">Language</h4>
                    {/* <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item ">
                          <Link to="#">DR Super Admin</Link>
                        </li>
                        <li className="breadcrumb-item active">Language</li>
                      </ol>
                    </div> */}
                  </div>
                </div>
              </div>
              {alert ? (
                <Alerts type={alerttype} message={alertmessage} open={open} />
              ) : (
                ""
              )}
              <div className="row project-wrapper">
                <div className="row dashboard-top-tab-style">
                  <div className="col-xxl-12 dashboard-main-style">
                    <div className="card">
                      <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          Create Booking Engine Language
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className="live-preview">
                          <form action="#">
                            <div className="row">
                              <label
                                for="firstNameinput"
                                className="form-label"
                              >
                                Name
                                <span className="form-validation-required">
                                  *
                                </span>
                              </label>

                              <div className="col-md-4">
                                <Select
                                  className="form-control"
                                  id="exampleInputUsername1"
                                  options={language}
                                  value={b_language}
                                  onChange={handleChange}
                                  // onSelected={(e) =>
                                  //   setB_language(language.value)
                                  // }
                                >
                                  {/* {language.map((lang, index) => {
                                    return (
                                      <option key={index} value={lang.lang_id}>
                                        {" "}
                                        {lang.language_name}
                                      </option>

                                      // <MenuItem
                                      //   key={index}
                                      //   value={lang.lang_id}
                                      // >
                                      //   {lang.language_name}
                                      // </MenuItem>
                                    );
                                  })} */}
                                </Select>
                                <label style={{ color: "red" }}>
                                  {formerror.b_language}
                                </label>
                              </div>
                              <div className="col-md-4">
                                <button
                                  type="button"
                                  className="btn db-save-single-button"
                                  data-bs-toggle="modal"
                                  id="create-btn"
                                  data-bs-target=""
                                  onClick={PostData}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                            <div className="padding-20"></div>
                          </form>
                          <div className="padding-20"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="card" id="orderList">
                      <div className="card-header border-0">
                        <div className="row align-items-center gy-3">
                          <div className="col-sm d-flex justify-content-between">
                            <h5 className="card-title mb-0">
                              List Of Booking Engine Languages
                            </h5>
                          </div>
                        </div>
                      </div>
                      {datas.length > 0 ? (
                        <div className="card-body pt-0">
                          <div>
                            <ul
                              className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                              role="tablist"
                            ></ul>

                            <div className="table-responsive table-card mb-1">
                              <table
                                className="table table-nowrap align-middle"
                                id="orderTable"
                              >
                                <thead className="text-muted table-light">
                                  <tr className="text-uppercase">
                                    <th scope="col" style={{ width: "25px" }}>
                                      <div className="form-check">
                                        {/* <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    
                                    /> */}
                                      </div>
                                    </th>
                                    <th className="" data-sort="id">
                                      SL
                                    </th>
                                    <th className="" data-sort="customer_name">
                                      Language
                                    </th>
                                    <th className="" data-sort="customer_name">
                                      Code
                                    </th>
                                    <th className="" data-sort="customer_name">
                                      Status
                                    </th>
                                    <th className="" data-sort="city">
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="list form-check-all">
                                  {datas.map((element, index) => {
                                    return (
                                      <tr key={index}>
                                        <th scope="row">
                                          <div className="form-check">
                                            {/* <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="checkAll"
                                          value="option1"
                                        /> */}
                                          </div>
                                        </th>
                                        <td className="id">{pageindex===1 ? index+1: (pageindex-1) * 10 + index +1}</td>
                                        <td className="customer_name">
                                          {element.language_name}
                                        </td>
                                        <td className="customer_name">
                                          {element.language_code}
                                        </td>
                                        <td className="status">
                                          {element.status === false ? (
                                            <span class="badge badge-soft-danger text-uppercase">
                                              {" "}
                                              DISABLED
                                            </span>
                                          ) : (
                                            <span className="badge badge-soft-success text-uppercase">
                                              ACTIVE
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          <ul className="list-inline hstack gap-2 mb-1">
                                            <li
                                              className="list-inline-item"
                                              data-bs-toggle="tooltip"
                                              data-bs-trigger="hover"
                                              data-bs-placement="top"
                                              title="Remove"
                                            >
                                              <Link
                                                className="text-danger d-inline-block remove-item-btn"
                                                data-bs-toggle="modal"
                                                to="#"
                                                onClick={() =>
                                                  tog_animationFlip(
                                                    element.lang_id
                                                  )
                                                }
                                              >
                                                <i className="ri-delete-bin-5-fill fs-16"></i>
                                              </Link>
                                            </li>
                                          </ul>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                            {paginate > 1 ? (
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={paginate}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={handlePageClick}
                                containerClassName={
                                  "pagination justify-content-end"
                                }
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="card-body pt-0">
                          <div className="no-content text-center">
                            No data Found
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
        {modal_animationFlip ? (
          <DeleteModal
            modal_animationFlip={modal_animationFlip}
            tog_animationFlip={tog_animationFlip}
            pageid={pageid}
            DeleteData={DeleteData}
            message={"Booking Engine Language"}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Bookingenginelanguage;
