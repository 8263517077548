import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../Common/Footer";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import Commonheader from "../../Common/Commonheader";
import Alerts from "../../Alerts";
import Config from "../../Config";
import { useEffect } from "react";
import axios from "axios";
import Loading from "../../Loading";
import Basicsettingsheader from "../../Common/Basicsettingsheader";

function Serviceedit() {

    const location = useLocation();
    let navigate = useNavigate();

    const [ids, setId] = useState(location.state.service_id);

        const [active, setActive] = useState("en");
        const[be_language,setBe_language]=useState([])
        const language = localStorage.getItem("be_language");
        const [alert, setAlert] = useState(false);
        const [alertmessage, setAlertmessage] = useState("");
        const [alerttype, setAlerttype] = useState("");
      
        const [formerror4, setError4] = useState({});
        const [formerror5, setError5] = useState({});
        const [open, setOpen] = useState(false);
        const [image,setImage]=useState([]);
        const [status,setStatus]=useState("")
        const [service,setService]=useState([]);
     
        const[langdata,setLangdata]=useState('')
        const[countrydata,setCountrydata]=useState('')
        const[be_languagefull,setBe_languagefull]=useState([])
        const [multi,setMulti]=useState([]);

     
        const getlanguage = async (e) => {
    
          const res = await fetch(`${Config.apiurl}optional-languages`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
    
            },
          });
          const data = await res.json();
          if (data.status_code !== "200" || !data.data) {
            console.log("error");
          } else {
            setBe_language(data.data);
          }
        
        
        };
    
        const getfulllanguage = async (e) => {

          const res = await fetch(`${Config.apiurl}booking-engine-languages`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
    
            },
          });
          const data = await res.json();
          if (data.status_code !== "200" || !data.data) {
            console.log("error");
          } else {
            setBe_languagefull(data.data);
          }
        
        
        };
        
        const handleInput = (e) => {
          let name = e.target.name;
          let value = e.target.value;
          setService({ ...service, [name]: value });
        };
       
     
        
      const [selectedMulti, setselectedMulti] = useState([]);
      function handleMulti(selectedMulti) {
        setselectedMulti(selectedMulti);
      }
     
      const [selectedMulti1, setselectedMulti1] = useState([]);
      function handleMulti1(selectedMulti1) {
        setselectedMulti1(selectedMulti1);
      }
      const validate = (values) => {
        const errors = {};
        if (!values.title) {
          errors.title = "Name is required!";
        }
               
        return errors;
      };
    
      const validatestatus = (values) => {
        const errors = {};
        // if (values===true || values===false) {
        //  console.log("");
        // }
        // else{
        //     errors.status = "Status is required!";
        // }
        if (!status) {
          errors.status = "Status is required!";
        }
        return errors;
      };
      const PostData = async (e) => {
        e.preventDefault();
       
        const ress=validate(service)

        const ressstatus=validatestatus(status)
    
        setError4(ress);
        setError5(ressstatus);
    
        if (
          !ressstatus.status &&!ress.title
        ) {
    
    
          const formData = new FormData();
          formData.append("en_title", service.title);
          formData.append("is_active", status);
          formData.append('_method', 'PATCH');
                      for (let i = 0; i < multi.length; i++) {
                        if (multi[i].name) {
                          if (multi[i].code !== "en") {
                            
                          
                            formData.append(`multi_lang[${i}][lang_code]`, multi[i].code);
                            formData.append(`multi_lang[${i}][title]`, multi[i].name);
                           
                          }
                        }
                      }               
                      axios.post(`${Config.apiurl}service/update/${ids}`, formData, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                       "Content-type": "application/json; charset=UTF-8",
                  },
                })
                .then((res) => {
    
              if (res.status_code !== "200" || !res) {
                setAlert(true);
                setAlertmessage(res.message);
                setOpen(true);
                setAlerttype("error");
              } else {
                setAlert(true);
                setAlertmessage(res.message);
                setOpen(true);
                setAlerttype("success");
                Reset();
                setTimeout(() => navigate("/basic_settings/service-list"), 3000);

              }
               
        }
                )}
      };
    
      function Reset(){
        setService("");
        setLangdata("");
        setMulti([]);
        setStatus("");
      }
    
      useEffect(() => {
        const getinfo = async (e) => {
            const res = await fetch(`${Config.apiurl}service/${ids}`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            });
            const data = await res.json();
            
              setService(data.data.service);
             setMulti(data.data.multi_lang);
             
              setStatus(data.data.service.status);
            
          };
          getinfo();
          getlanguage();
          getfulllanguage();
    },[language,ids]);

      const onFileChange = (e) => {
        console.log(e);
        setImage(  e.target.files[0] );
      };

return (
    <div id="layout-wrapper">
  <Basicsettingsheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
            
            </div>
            {alert ? (
              <Alerts type={alerttype} message={alertmessage} open={open} />
            ) : (
              ""
            )}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                     Update Service
                      </h4>
                      <div className="flex-shrink-0">
                        <Link
                          type="button"
                          className="btn db-other-button"
                          to="/basic_settings/service-list"
                          style={{ marginRight: "5px" }}
                        >
                          HK Services
                        </Link>
                        {/* <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to=""
                          onClick={Reset}
                        >
                          Cancel
                        </button> */}
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"                       
                          onClick={PostData}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                   
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-translation-style">
                                <ul>
                                <li
                                    className={active === "en" ? "active" : ""}
                                  >
                                    <button
                                         type="button"
                                      onClick={() => setActive("en")}
                                      className={
                                        active === "en" ? "active" : ""
                                      }
                                      style={{border:"none",background:"transparent"}}
                                    >
                                      EN
                                    </button>
                                  </li>
                                  <li
                                    className={
                                      active === "trans" ? "active" : ""
                                    }
                                  >
                                    <button   
                                                 type="button"
                                      onClick={() => setActive("trans")}
                                      className={
                                        active === "trans" ? "active" : ""
                                      }
                                      style={{border:"none",background:"transparent"}}
                                    >
                                      Translations
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>                 

                              {service!==undefined ?


                            <>
                       {active==="en"?
                            <>
                            <div class="col-lg-4">
                                <div className="input-group">
                              <label className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default">Name
                              <span className="form-validation-required">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  value={service.title}
                                  name="title"
                                  onChange={handleInput}
                                />
                                 <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror4.title}
                                </label>
                              </div>
                            </div>
                            <div class="col-lg-4">
                                      <div className="input-group">
                                    <label className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default">Status
                                    <span className="form-validation-required">*</span>
                                      </label>
                                    <select
                                      id="ForminputState"
                                      className="form-select"
                                      value={status}
                                      onChange={(e)=>setStatus(e.target.value)}>
                                      <option selected>Choose...</option>
                                      <option {...(status === true
                                    ? "selected"
                                    : "")}
                                  value={true}  >Active</option>
                                      <option {...(status === false
                                    ? "selected"
                                    : "")}
                                  value={false} >Inactive</option>
                                    </select>
                                    <label style={{ color: "red" }}>
                                      {formerror5.status}
                                    </label>
                                  </div>
                                </div>
                            </>:
                           <>
                           <div className="col-lg-4" style={{ marginTop: "20px" }}>
                             <div className="input-group">
                               <label className="input-group-text input-joint-color" id="inputGroup-sizing-default" style={{ width: "130px" }}>
                                 Choose Language:
                               </label>
                               <div className="col-md-7">
                                 <MultiSelect
                                   options={be_language}
                                   value={multi}
                                   onChange={setMulti}
                                   labelledBy="Select"
                                   selectionType="counter"
                                   closeOnChangedValue={true}
                                   aria-expanded="true"
                                   hasSelectAll={false}
                                 />
                               </div>
                             </div>
                           </div>
                           <div className="appendmore"></div>
                           {multi.length > 0 &&
                             multi.map((element, index) => (
                               <div
                                 key={index}
                                 className="col-md-12 translation-fill-section"
                                 id={`lang_${element.value}`}
                                 data-value="1"
                                 style={{ marginTop: "20px" }}
                               >
                                 <div className="translation-selected-lang">
                                   <p>
                                     <span className="translation-selected-lang-head">
                                       {element.code}
                                     </span>
                                   </p>
                                 </div>
                                 <div className="row">
                                   <div className="col-lg-4">
                                     <div className="input-group">
                                       <label className="input-group-text input-joint-color" id="inputGroup-sizing-default">
                                         Name
                                         <span className="form-validation-required">
                                           *
                                         </span>
                                       </label>
                                       <input
                                         type="text"
                                         className="form-control"
                                         placeholder=""
                                         value={element.name}
                                         onChange={(e) =>
                                           setMulti((prevMulti) =>
                                             prevMulti.map((item, i) =>
                                               i === index ? { ...item, name: e.target.value } : item
                                             )
                                           )
                                         }
                                       />
                                     </div>
                                   </div>
                                 </div>
                               </div>
                             ))}
                         </>
                              }
                                </>:<Loading/>}
                          </div>

                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Serviceedit