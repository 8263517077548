import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../Common/Footer";

import axios from "axios";
import Config from "../../Config";
import Alerts from "../../Alerts";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import DeleteModal from "../../../Common/DeleteModal";
import LanguageEdit from "./LanguageEdit";
import ReactPaginate from "react-paginate";
import Commonheader from "../../Common/Commonheader";

function Language() {
  const navigate = useNavigate();
  const [language, setLanguage] = useState("");
  const [be_language, setBe_Language] = useState("");
  const[page,setPage]=useState(1);
  const[pageindex,setPageindex]=useState(1);

  const [font, setFont] = useState("");
  const [code, setCode] = useState("");
  const [status, setStatus] = useState();
  const [formerror, setError] = useState({});
  const [formerror1, setError1] = useState({});
  const [formerror2, setError2] = useState({});
  const [formerror3, setError3] = useState({});
  const [pageid, setPageid] = useState("");
  const [paginate, setPaginate] = useState([]);

  const [alert, setAlert] = useState(false);
  const [datas, setDatas] = useState([]);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [open, setOpen] = useState(false);
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);

  function tog_animationFlip() {
    setmodal_animationFlip(!modal_animationFlip);
  }
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  function tog_backdrop(id) {
    setmodal_backdrop(!modal_backdrop);
    setPageid(id);
  }

  const getData = async (e) => {
    const res = await fetch(`${Config.apiurl}admin/language/list?page=1`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data1 = await res.json();
    const data = data1.data;
    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setDatas(data.data);
      setPaginate(data.last_page);
    }
  };

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}admin/language/list?page=${currentPage}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const data = await res.json();
    return data.data.data;
  };

  const handlePageClick = async (data) => {
    if(data===1){
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
    
      setDatas(commentsFormServer);
    
    }
    else{
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
    
      setDatas(commentsFormServer);
    }
   
  };
  function Reset() {
    setLanguage("");
    setStatus("");
    setCode("");
    setFont("");
  }

  const validate = (values) => {
    const errors = {};
    if (!values) {
      errors.language = "Language is required!";
    }
    return errors;
  };
  const validate1 = (values) => {
    const errors = {};
    if (!values) {
      errors.font = "Font is required!";
    }
    return errors;
  };
  const validate2 = (values) => {
    const errors = {};
    if (!values) {
      errors.code = "Code is required!";
    }
    return errors;
  };
  const validates = (values) => {
    const errors = {};
    if (!values) {
      errors.status = "Status is required!";
    }
    return errors;
  };
  const PostData = async (e) => {
    e.preventDefault();
    const ress = validate(language);
    const ress1 = validates(status);
    const ress2 = validate1(font);

    const ress3 = validate2(code);

    setError(ress);
    setError1(ress1);
    setError2(ress3);
    setError3(ress2);

    if (!ress.language && !ress1.status && !ress2.font && !ress3.code) {
      const formData = new FormData();
      formData.append("name", language);
      formData.append("regional_font", font);
      formData.append("code", code);
      formData.append("is_active", status);

      axios
        .post(`${Config.apiurl}admin/language/add`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.status_code !== "200" || !res) {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("error");
          } else {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("success");
            Reset();

            handlePageClick(page);

          }
        });
    }
  };

  //   const DeleteData = async (id) => {
  //     axios
  //       .delete(`${Config.apiurl}admin/page/delete/${id}`, {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       })
  //       .then((res) => {
  //         if (res.status !== "200" || !res) {
  //           setAlert(true);
  //           setAlertmessage(res.message);
  //           setOpen(true);
  //           setAlerttype("error");
  //           setmodal_animationFlip(false);
  //         } else {
  //           setAlert(true);
  //           setAlertmessage(res.message);
  //           setOpen(true);
  //           setAlerttype("success");
  //           setmodal_animationFlip(false);
  //           getData();
  //         }
  //       });
  //   };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div id="layout-wrapper">
     <Commonheader/>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="db-page-title">Language</h4>
                    {/* <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item ">
                          <Link to="#">DR Super Admin</Link>
                        </li>
                        <li className="breadcrumb-item active">Language</li>
                      </ol>
                    </div> */}
                  </div>
                </div>
              </div>
              {alert ? (
                <Alerts type={alerttype} message={alertmessage} open={open} />
              ) : (
                ""
              )}
              <div className="row project-wrapper">
                <div className="row dashboard-top-tab-style">
                  <div className="col-xxl-12 dashboard-main-style">
                    <div className="card">
                      <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          Create Language
                        </h4>
                        <div className="flex-shrink-0">
                          <button
                            type="button"
                            className="btn db-cancel-button"
                            data-bs-toggle="offcanvas"
                            onClick={Reset}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            className="btn db-save-button"
                            data-bs-toggle="modal"
                            id="create-btn"
                            data-bs-target=""
                            onClick={PostData}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="live-preview">
                          <form action="#">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    for="firstNameinput"
                                    className="form-label"
                                  >
                                    Name{" "}
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={language}
                                    onChange={(e) =>
                                      setLanguage(e.target.value)
                                    }
                                  />
                                  <label style={{ color: "red" }}>
                                    {formerror.language}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    for="firstNameinput"
                                    className="form-label"
                                  >
                                    Code{" "}
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                  />
                                  <label style={{ color: "red" }}>
                                    {formerror2.code}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    for="lastNameinput"
                                    className="form-label"
                                  >
                                    Regional Font
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    value={font}
                                    onChange={(e) => setFont(e.target.value)}
                                  />
                                  <label style={{ color: "red" }}>
                                    {formerror3.font}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    for="lastNameinput"
                                    className="form-label"
                                  >
                                    Status
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <select
                                    id="ForminputState"
                                    className="form-select"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                  >
                                    <option selected>Choose...</option>
                                    <option value={1}>Active</option>
                                    <option value={0}>Inactive</option>
                                  </select>
                                  <label style={{ color: "red" }}>
                                    {formerror1.status}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="padding-20"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="card" id="orderList">
                      <div className="card-header border-0">
                        <div className="row align-items-center gy-3">
                          <div className="col-sm d-flex justify-content-between">
                            <h5 className="card-title mb-0">
                              List Of Languages
                            </h5>
                          </div>
                        </div>
                      </div>
                      {datas.length > 0 ? (
                        <div className="card-body pt-0">
                          <div>
                            <ul
                              className="nav nav-tabs nav-tabs-custom nav-success mb-3"
                              role="tablist"
                            ></ul>

                            <div className="table-responsive table-card mb-1">
                              <table
                                className="table table-nowrap align-middle"
                                id="orderTable"
                              >
                                <thead className="text-muted table-light">
                                  <tr className="text-uppercase">
                                    <th scope="col" style={{ width: "25px" }}>
                                      <div className="form-check">
                                        {/* <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="checkAll"
                                      value="option"
                                    
                                    /> */}
                                      </div>
                                    </th>
                                    <th className="" data-sort="id">
                                      SL
                                    </th>
                                    <th className="" data-sort="customer_name">
                                      Language
                                    </th>
                                    <th className="" data-sort="customer_name">
                                      Code
                                    </th>
                                    <th className="" data-sort="customer_name">
                                      Status
                                    </th>
                                    <th className="" data-sort="city">
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="list form-check-all">
                                  {datas.map((element, index) => {
                                    return (
                                      <tr key={index}>
                                        <th scope="row">
                                          <div className="form-check">
                                            {/* <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="checkAll"
                                          value="option1"
                                        /> */}
                                          </div>
                                        </th>
                                        <td className="id">{pageindex===1 ? index+1: (pageindex-1) * 10 + index +1}</td>
                                        <td className="customer_name">
                                          {element.language_name}
                                        </td>
                                        <td className="customer_name">
                                          {element.language_code}
                                        </td>
                                        <td className="status">
                                          {element.status === false ? (
                                            <span class="badge badge-soft-danger text-uppercase">
                                              {" "}
                                              INACTIVE
                                            </span>
                                          ) : (
                                            <span className="badge badge-soft-success text-uppercase">
                                              ACTIVE
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          <ul className="list-inline hstack gap-2 mb-1">
                                            <li
                                              className="list-inline-item edit"
                                              data-bs-toggle="tooltip"
                                              data-bs-trigger="hover"
                                              data-bs-placement="top"
                                              title="Edit"
                                            >
                                              <Link
                                                to={`/language-edit/${element.lang_id}`}
                                                data-bs-toggle="modal"
                                                className="text-primary d-inline-block edit-item-btn"
                                              >
                                                <i className="ri-pencil-fill fs-16"></i>
                                              </Link>
                                            </li>
                                            {/* <li
                                              className="list-inline-item"
                                              data-bs-toggle="tooltip"
                                              data-bs-trigger="hover"
                                              data-bs-placement="top"
                                              title="Remove"
                                            >
                                              <Link
                                                className="text-danger d-inline-block remove-item-btn"
                                                data-bs-toggle="modal"
                                                to="#"
                                                onClick={() =>
                                                  tog_animationFlip(
                                                    element.lang_id
                                                  )
                                                }
                                              >
                                                <i className="ri-delete-bin-5-fill fs-16"></i>
                                              </Link>
                                            </li> */}
                                          </ul>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                            {paginate > 1 ? (
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={paginate}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={handlePageClick}
                                containerClassName={
                                  "pagination justify-content-end"
                                }
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="card-body pt-0">
                          <div className="no-content text-center">
                            No data Found
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
        {/* {modal_animationFlip ? (
          <DeleteModal
            modal_animationFlip={modal_animationFlip}
            tog_animationFlip={tog_animationFlip}
            pageid={pageid}
            DeleteData={DeleteData}
          />
        ) : (
          ""
        )} */}
      </div>
    </>
  );
}

export default Language;
