import React, { useContext, useEffect } from 'react'
import Header from '../../../Layouts/Header';
import HotelSidebar from './Sidebars/HoletSidebar';
import Sidebar from './Sidebars/Sidebar';
import { useState } from 'react';
import Sidebarconditions from './Sidebars/Sidebarconditions';
import { UserContext } from '../Contexts/UserContext';


function Conditionsheader() {
    const user_type = localStorage.getItem("user_type");
    const {updateHotel_select, hotel_select} = useContext(UserContext);

    const [showhotel,setShowhotel]=useState(false);

    const [dashboard,setDashboard]=useState(true);
    const[pagename,setPagename]=useState("condition");


useEffect(()=>{
  setShowhotel(hotel_select.length>0?true:false);
},[hotel_select])

    return (
      <div>
        <Header setShowhotel={setShowhotel} dashboard={dashboard} pagename={pagename} user_type={user_type} showhotel={showhotel}/>
       
          <Sidebarconditions showhotel={showhotel}/>
         
      </div>
    )
}

export default Conditionsheader