import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Alerts from "../../Alerts";
import Commonheader from "../../Common/Commonheader";
import Footer from "../../Common/Footer";
import Config from "../../Config";

function LanguageEdit() {
  let { id } = useParams();
  let navigate = useNavigate();

  const [language, setLanguage] = useState([]);
  const [oldlanguage, setOldlanguage] = useState("");
  const [alert, setAlert] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [open, setOpen] = useState(false);

  const getinfo = async (e) => {
    const res = await fetch(`${Config.apiurl}admin/language/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data1 = await res.json();
    const data = data1.data;
    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setLanguage(data);
      setOldlanguage(data.language_name);
    }
  };

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setLanguage({ ...language, [name]: value });
  };
  const UpdateData = async (e) => {
    e.preventDefault();

    const res = await fetch(`${Config.apiurl}admin/language/update/${id}`, {
      method: "PATCH",
      body: JSON.stringify({
        name: language.language_name,
        regional_font: language.regional_font,
        code: language.language_code,
        is_active: language.status,
      }),

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await res.json();

    if (data.status_code !== "200" || !data) {
      setAlert(true);
      setAlertmessage(res.message);
      setOpen(true);
      setAlerttype("error");
    } else {
      setAlert(true);
      setAlertmessage(data.message);
      setOpen(true);
      setAlerttype("success");
      setTimeout(() => navigate("/languages"), 3000);
    }
  };
  useEffect(() => {
    getinfo();
  }, []);
  return (
    <>
      {" "}
      <div id="layout-wrapper">
        <Commonheader/>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="db-page-title">Language</h4>
                    {/* <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Dashboard</Link>
                        </li>
                        <li className="breadcrumb-item ">
                          <Link to="#">DR Super Admin</Link>
                        </li>
                        <li className="breadcrumb-item ">
                          <Link to="/languages">Language</Link>
                        </li>
                        <li className="breadcrumb-item active">
                          {oldlanguage}
                        </li>
                      </ol>
                    </div> */}
                  </div>
                </div>
              </div>
              {alert ? (
                <Alerts type={alerttype} message={alertmessage} open={open} />
              ) : (
                ""
              )}
              <div className="row project-wrapper">
                <div className="row dashboard-top-tab-style">
                  <div className="col-xxl-12 dashboard-main-style">
                    <div className="card">
                      <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          Edit Language {oldlanguage}
                        </h4>
                        <div className="flex-shrink-0">
                          <button
                            type="button"
                            className="btn db-save-button"
                            data-bs-toggle="modal"
                            id="create-btn"
                            data-bs-target=""
                            onClick={UpdateData}
                          >
                            UPDATE
                          </button>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="live-preview">
                          <form action="#">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    for="firstNameinput"
                                    className="form-label"
                                  >
                                    Name{" "}
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="language_name"
                                    value={language.language_name}
                                    onChange={handleInput}
                                  />
                                  {/* <label style={{ color: "red" }}>
                                    {formerror.language}
                                  </label> */}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    for="firstNameinput"
                                    className="form-label"
                                  >
                                    Code{" "}
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="language_code"
                                    value={language.language_code}
                                    onChange={handleInput}
                                  />
                                  {/* <label style={{ color: "red" }}>
                                    {formerror2.code}
                                  </label> */}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    for="lastNameinput"
                                    className="form-label"
                                  >
                                    Regional Font
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="regional_font"
                                    value={language.regional_font}
                                    onChange={handleInput}
                                  />
                                  {/* <label style={{ color: "red" }}>
                                    {formerror3.font}
                                  </label> */}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    for="lastNameinput"
                                    className="form-label"
                                  >
                                    Status
                                    <span className="form-validation-required">
                                      *
                                    </span>
                                  </label>
                                  <select
                                    id="ForminputState"
                                    className="form-select"
                                    value={language.status}
                                    onChange={handleInput}
                                    name="status"
                                  >
                                    <option
                                      name="status"
                                      {...(language.status === true
                                        ? "selected"
                                        : "")}
                                      value={true}
                                    >
                                      Active
                                    </option>
                                    <option
                                      name="status"
                                      {...(language.status === false
                                        ? "selected"
                                        : "")}
                                      value={false}
                                    >
                                      InActive
                                    </option>
                                  </select>
                                  {/* <label style={{ color: "red" }}>
                                    {formerror1.status}
                                  </label> */}
                                </div>
                              </div>
                            </div>
                            <div className="padding-20"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
        {/* {modal_animationFlip ? (
    <DeleteModal
      modal_animationFlip={modal_animationFlip}
      tog_animationFlip={tog_animationFlip}
      pageid={pageid}
      DeleteData={DeleteData}
    />
  ) : (
    ""
  )} */}
      </div>
    </>
  );
}

export default LanguageEdit;
