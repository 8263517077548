import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Toastmodal from "../../Common/Toastmodal";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Config from "../../Config";
import moment from "moment";
import { MultiSelect } from "react-multi-select-component";
import Privatesaleheader from "../../Common/Privatesaleheader";
import Footer from "../../Common/Footer";
import Commontab from "./Commontab";

function Codeedit() {
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  let navigate = useNavigate();
  let params = useParams();

  const [promocode_id, setPromocode_id] = useState(params.promocode_id);
  const [id, setId] = useState(params.hotel_id);

  const [tab, setTab] = useState("available");
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [list, setList] = useState([]);
  const [offers, setOffers] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [rates, setRates] = useState([]);
  const [selectedRates, setSelectedRates] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [inputFields, setInputFields] = useState([
    {
      form: [],
      until: [],
      discount: "",
      unit_value: "percentage",
    },
  ]);
  const [exceptionstay, setExceptionstay] = useState(false);
  const [company, setCompany] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [agency, setAgency] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState([]);
  const [selectedRatesnew, setSelectedRatesnew] = useState([]);
  const [selectedOffersnew, setSelectedOffersnew] = useState([]);
  const [selectedRoomsnew, setSelectedRoomsnew] = useState([]);
  const [d_stay_status, setDstay_status] = useState([]);
  const [d_checkin_status, setDcheckin_status] = useState([]);
  const [d_checkout_status, setDcheckout_status] = useState([]);
  const [d_reserve_status, setDreserve_status] = useState([]);
  const [newtype, setNewtype] = useState("");
  const [selectedusage, setSelectedusage] = useState([]);
  const [selectedprevail, setSelectedprevail] = useState([]);
  const [selectedusagenew, setSelectedusagenew] = useState([]);
  const [selectedprevailnew, setSelectedprevailnew] = useState([]);

  const [exp_date, setExp_date] = useState(null);
  const fpstay = useRef(null);
  const [selectedDatesstay, setSelectedDatesstay] = useState([]);
  const [activestaydateopen, setActivestaydateopen] = useState(false);
  const [datefromstay, setDatefromstay] = useState("");
  const [datetostay, setDatetostay] = useState("");
  const [openclosestay, setOpenclosestay] = useState("open");
  const [selectedWeekdaysstay, setSelectedWeekdaysstay] = useState([]);
  const [clickedDaysstay, setClickedDaysstay] = useState([]);
  const fpcheckin = useRef(null);
  const fpcheckout = useRef(null);
  const fpreserve = useRef(null);

  const [selectedWeekdayscheckin, setSelectedWeekdayscheckin] = useState([]);
  const [selectedWeekdayscheckout, setSelectedWeekdayscheckout] = useState([]);
  const [selectedWeekdaysreserve, setSelectedWeekdaysreserve] = useState([]);

  const [clickedDayscheckin, setClickedDayscheckin] = useState([]);
  const [clickedDayscheckout, setClickedDayscheckout] = useState([]);
  const [clickedDaysreserve, setClickedDaysreserve] = useState([]);

  const [openclosecheckin, setOpenclosecheckin] = useState("open");
  const [openclosecheckout, setOpenclosecheckout] = useState("open");
  const [openclosereserve, setOpenclosereserve] = useState("open");

  const [selectedDatescheckin, setSelectedDatescheckin] = useState([]);
  const [selectedDatescheckout, setSelectedDatescheckout] = useState([]);
  const [selectedDatesreserve, setSelectedDatesreserve] = useState([]);
  const [activecheckindateopen, setActivecheckindateopen] = useState(false);
  const [activecheckoutdateopen, setActivecheckoutdateopen] = useState(false);
  const [activereservedateopen, setActivereservedateopen] = useState(false);
  const [datefromcheckin, setDatefromcheckin] = useState("");
  const [datetocheckin, setDatetocheckin] = useState("");

  const [datefromcheckout, setDatefromcheckout] = useState("");
  const [datetocheckout, setDatetocheckout] = useState("");

  const [datefromreserve, setDatefromreserve] = useState("");
  const [datetoreserve, setDatetoreserve] = useState("");
  const [Error, setError] = useState({});
  const [newOffer, setNewOffer] = useState([]);
  const [newRate, setNewRate] = useState([]);

  const SingleOptions = [
    { value: "normal", label: "Normal" },
    { value: "company", label: "Company" },
    { value: "travel_agency", label: "Travel Agency" },
    { value: "loyalty", label: "Loyalty" },
  ];

  const usageoption = [
    { value: "limited", label: "Limited" },
    { value: "unlimited", label: "Unlimited" },
    { value: "person_limit", label: "Person Limit" },
  ];
  const prevailoption = [
    { value: "loyalty_program", label: "Loyalty Discount" },
    { value: "promocode", label: "Promotional Code" },
  ];

  const handleOpenclosestay = (e) => {
    setActivestaydateopen(false);
  };

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        form: [],
        until: [],
        discount: "",
        unit_value: "percentage",
      },
    ]);
  };

  const removeInputFields = (index) => {
    // if (inputFields.length === 1) {
    //   setExceptionstay(false);
    // }
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const handleCalendarChangeFrom = (selectedDates) => {
    setDatefromstay(selectedDates[0]);
  };

  const handleCalendarChangeTo = (selectedDates) => {
    setDatetostay(selectedDates[0]);
  };
  const handleloyaltyToggle = (isChecked, name) => {
    setList({ ...list, [name]: isChecked });
  };

  const handleChangeExpiration = (name, value) => {
    setList((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCalendarChange = (selectedDates) => {
    setExp_date(selectedDates[0]);
  };
  const handleOpenclosecheckin = (e) => {
    setActivecheckindateopen(false);
  };

  const handleOpenclosecheckout = (e) => {
    setActivecheckoutdateopen(false);
  };

  const handleOpenclosereserve = (e) => {
    setActivereservedateopen(false);
  };
  const handleCalendarChangeFromcheckin = (selectedDates) => {
    setDatefromcheckin(selectedDates[0]);
  };

  const handleCalendarChangeTocheckin = (selectedDates) => {
    setDatetocheckin(selectedDates[0]);
  };

  const handleCalendarChangeFromcheckout = (selectedDates) => {
    setDatefromcheckout(selectedDates[0]);
  };

  const handleCalendarChangeTocheckout = (selectedDates) => {
    setDatetocheckout(selectedDates[0]);
  };

  const handleCalendarChangeFromreserve = (selectedDates) => {
    setDatefromreserve(selectedDates[0]);
  };

  const handleCalendarChangeToreserve = (selectedDates) => {
    setDatetoreserve(selectedDates[0]);
  };
  const toggleSelectedDaycheckin = (dayIndex) => {
    if (selectedWeekdayscheckin.includes(dayIndex)) {
      if (clickedDayscheckin.includes(dayIndex)) {
        setClickedDayscheckin((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayIndex)
        );
      } else {
        setClickedDayscheckin((prevClickedDays) => [
          ...prevClickedDays,
          dayIndex,
        ]);
      }
    }
  };
  const toggleSelectedDaycheckout = (dayIndex) => {
    if (selectedWeekdayscheckout.includes(dayIndex)) {
      if (clickedDayscheckout.includes(dayIndex)) {
        setClickedDayscheckout((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayIndex)
        );
      } else {
        setClickedDayscheckout((prevClickedDays) => [
          ...prevClickedDays,
          dayIndex,
        ]);
      }
    }
  };
  const toggleSelectedDayreserve = (dayIndex) => {
    if (selectedWeekdaysreserve.includes(dayIndex)) {
      if (clickedDaysreserve.includes(dayIndex)) {
        setClickedDaysreserve((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayIndex)
        );
      } else {
        setClickedDaysreserve((prevClickedDays) => [
          ...prevClickedDays,
          dayIndex,
        ]);
      }
    }
  };

  let handleChangedatefrom = (i, data) => {
    let newval = [...inputFields];
    inputFields[i]["form"] = data;
    setInputFields(newval);
  };

  let handleChangetodate = (i, data) => {
    let newval = [...inputFields];
    inputFields[i]["until"] = data;
    setInputFields(newval);
  };
  let handleChangediscount = (index, val, name) => {
    let newInputFields = [...inputFields];
    newInputFields[index][name] = val;
    setInputFields(newInputFields);
  };

  const getOffer = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/offers/confidential`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setOffers(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getOffer();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getOffer();
    } else {
      console.log("error");
    }
    // if(res.status===200){
    //   const data = await res.json();
    //   if (data.status_code !== "200" || !data.data) {
    //     console.log("error");
    //   } else {
    //     setOffers(data.data);
    //    }
    // }
    // else if(res.status === 401){
    //   handleUnauthorized();
    //   getOffer();
    // }
    // else{
    // console.log("error");
    // }
  };

  // const newOffer = offers.map((offer) => ({
  //   label: offer.title,
  //   value: offer.offer_id,
  // }));
  useEffect(() => {
    for (let i = 0; i < offers.length; i++) {
      newOffer.push({
        label: offers[i].title,
        value: offers[i].offer_id,
      });
    }
  }, [offers.length]);

  useEffect(() => {
    for (let i = 0; i < rates.length; i++) {
      newRate.push({
        label: rates[i].internal_name,
        value: rates[i].rate_id,
      });
    }
  }, [rates.length]);

  const getRate = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/rate-list/confidential`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setRates(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getRate();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getRate();
    } else {
      console.log("error");
    }
    // if(res.status===200){
    //   const data = await res.json();
    //   if (data.status_code !== "200" || !data.data) {
    //     console.log("error");
    //   } else {
    //     setRates(data.data);
    //    }
    // }
    // else if(res.status === 401){
    //   handleUnauthorized();
    //   getRate();
    // }
    // else{
    // console.log("error");
    // }
  };

  const getRoom = async (e) => {
    const res = await fetch(`${Config.apiurl}confidential-rooms`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setRooms(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getRoom();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getRoom();
    } else {
      console.log("error");
    }
  };

  const getCompany = async (e) => {
    const res = await fetch(
      `${Config.apiurl}hotel/private-sale/companies/list`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setCompany(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getCompany();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getCompany();
    } else {
      console.log("error");
    }
  };

  const getAgency = async (e) => {
    const res = await fetch(
      `${Config.apiurl}hotel/private-sale/agencies/list`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setAgency(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getAgency();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getAgency();
    } else {
      console.log("error");
    }
  };

  const getData = async (e) => {
    const res = await fetch(
      `${Config.apiurl}hotel/private-sale/promo-code/view`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
          promocode_id: promocode_id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setList(data.data[0]?.promocode);
        setSelectedusagenew(data.data[0]?.promocode?.usage);
        setSelectedprevailnew(data.data[0]?.promocode?.privilege_type);
        setSelectedOffersnew(data.data[0]?.promocode?.confidential_offers);
        setSelectedRatesnew(data.data[0]?.promocode?.confidential_offers);
        setSelectedRoomsnew(data.data[0]?.promocode?.confidential_offers);
        if (data.data[0]?.exception?.length > 0) {
          setExceptionstay(true);
          setInputFields(data.data[0]?.exception);
        }
        setSelectedDatesstay(data.data[0]?.availabilities?.d_stay?.dates);
        setSelectedDatescheckin(data.data[0]?.availabilities?.d_checkin?.dates);
        setSelectedDatescheckout(
          data.data[0]?.availabilities?.d_checkout?.dates
        );
        setSelectedDatesreserve(data.data[0]?.availabilities?.d_reserve?.dates);
        setDstay_status(data.data[0]?.availabilities?.d_stay?.status);
        setDcheckin_status(data.data[0]?.availabilities?.d_checkin?.status);
        setDcheckout_status(data.data[0]?.availabilities?.d_checkout?.status);
        setDreserve_status(data.data[0]?.availabilities?.d_reserve?.status);
        setExp_date(data.data[0]?.promocode?.expiry_date);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getData();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getData();
    } else {
      console.log("error");
    }
  };

  const Update = async () => {
    let isFormValid = true;

    const errors = {};

    if (
      selectedusage.value === "limited" ||
      selectedusage.value === "person_limit"
    ) {
      if (!list.usage_limit) {
        isFormValid = false;
        errors.usage_limit = "This field is required!";
      }
    }
    if (list.expiry_status === true) {
      if (!exp_date) {
        isFormValid = false;
        errors.exp_date = "Date is required!";
      }
    }
    if (!list.minimum_stay) {
      isFormValid = false;
      errors.minimum_stay = "Minimum stay night is required!";
    }

    if (!list.discount) {
      isFormValid = false;
      errors.discount = "Discount is required!";
    }
    if (list.apply_with_loyalty_program === false) {
      if (selectedprevail.length === 0) {
        isFormValid = false;
        errors.selectedprevail = "This field is required!";
      }
    }
    setError(errors);

    if (isFormValid === true) {
      let roomval = [];
      if (selectedRooms.length > 0) {
        for (let i = 0; i < selectedRooms.length; i++) {
          roomval.push(selectedRooms[i].value);
        }
      }

      let rateVal = [];
      if (selectedRates.length > 0) {
        for (let i = 0; i < selectedRates.length; i++) {
          rateVal.push(selectedRates[i].value);
        }
      }

      let Offerval = [];
      if (selectedOffers.length > 0) {
        for (let i = 0; i < selectedOffers.length; i++) {
          Offerval.push(selectedOffers[i].value);
        }
      }
      const stayDate = [];
      if (selectedDatesstay?.length > 0) {
        for (let i = 0; i < selectedDatesstay.length; i++) {
          const formattedDate = moment(selectedDatesstay[i]).format(
            "DD-MM-YYYY"
          );
          stayDate.push(formattedDate);
        }
      }
      const checkinDate = [];
      if (selectedDatescheckin?.length > 0) {
        for (let i = 0; i < selectedDatescheckin.length; i++) {
          const formattedDate = moment(selectedDatescheckin[i]).format(
            "DD-MM-YYYY"
          );
          checkinDate.push(formattedDate);
        }
      }
      const checkoutDate = [];
      if (selectedDatescheckout?.length > 0) {
        for (let i = 0; i < selectedDatescheckout.length; i++) {
          const formattedDate = moment(selectedDatescheckout[i]).format(
            "DD-MM-YYYY"
          );
          checkoutDate.push(formattedDate);
        }
      }
      const reservedDate = [];
      if (selectedDatesreserve?.length > 0) {
        for (let i = 0; i < selectedDatesreserve.length; i++) {
          const formattedDate = moment(selectedDatesreserve[i]).format(
            "DD-MM-YYYY"
          );
          reservedDate.push(formattedDate);
        }
      }
      let disExp = [];
      for (let i = 0; i < inputFields.length; i++) {
        let multiarray = {};

        if (inputFields[i].form?.length > 0) {
          for (let j = 0; j < inputFields[i].form.length; j++) {
            const fromdate = moment(inputFields[i].form[j]).format(
              "DD-MM-YYYY"
            );
            multiarray.from = fromdate;
          }
        }
        if (inputFields[i].until?.length > 0) {
          for (let j = 0; j < inputFields[i].until.length; j++) {
            const todate = moment(inputFields[i].until[j]).format("DD-MM-YYYY");
            multiarray.until = todate;
          }
        }
        multiarray.discount = inputFields[i].discount;
        multiarray.unit_value = inputFields[i].unit_value;
        if (
          inputFields[i].form?.length > 0 ||
          inputFields[i].until?.length > 0
        ) {
          disExp.push(multiarray);
        }
        console.log(disExp);
      }
      let typeNew;
      if (list.type === "company") {
        typeNew = selectedCompany.value;
      } else if (list.type === "travel_agency") {
        typeNew = selectedAgency.value;
      }

      let prevNew;
      if (list.apply_with_loyalty_program === false) {
        prevNew = selectedprevail.value;
      }
      let Expdate;
      if (list.expiry_status === true) {
        Expdate = moment(exp_date).format("DD-MM-YYYY");
      }

      let usagelimit;
      if (
        selectedusage.value === "limited" ||
        selectedusage.value === "person_limit"
      ) {
        usagelimit = list.usage_limit;
      }

      const res = await fetch(
        `${Config.apiurl}hotel/private-sale/promo-code/update`,
        {
          method: "POST",
          body: JSON.stringify({
            hotel_id: id,
            promocode_id: promocode_id,
            type: list.type,
            type_id: typeNew,
            expiry_status: list.expiry_status === true ? 1 : 0,
            expiry_date: Expdate,
            usage: selectedusage.value,
            usage_limit: usagelimit,
            minimum_stay: list.minimum_stay,
            common_name: list.common_name,
            stay_date_status: list.stay_date_status === true ? 1 : 0,
            discount: list.discount,
            discount_unit_type: list.unit_value,
            discount_exception: disExp,
            apply_with_loyalty_program:
              list.apply_with_loyalty_program === true ? 1 : 0,
            privilege_type: prevNew,
            apply_with_offers: list.apply_with_offers === true ? 1 : 0,
            apply_with_rates: list.apply_with_rates === true ? 1 : 0,
            status: list.status === true ? 1 : 0,
            confidential_offers: Offerval,
            confidential_rates: rateVal,
            confidential_rooms: roomval,
            d_stay_status: d_stay_status === true ? 1 : 0,
            stay_dates: d_stay_status === true ? stayDate : [],
            d_checkin_status: d_checkin_status === true ? 1 : 0,
            checkin_dates: d_checkin_status === true ? checkinDate : [],
            d_checkout_status: d_checkout_status === true ? 1 : 0,
            checkout_dates: d_checkout_status === true ? checkoutDate : [],
            d_reserve_status: d_reserve_status === true ? 1 : 0,
            reserve_dates: d_reserve_status === true ? reservedDate : [],
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200" || !data.data) {
          console.log("error");
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          setTimeout(() => navigate("/codes"), 3000);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Update();
      } else {
        console.log("error");
      }
    }
  };

  const handleCalendarIconClick = (calendarId) => {
    const calendar = document.querySelector(`#flatpickr-${calendarId}`);
    if (calendar) {
      const isCalendarOpen = calendar._flatpickr.isOpen;
      const icon = document.querySelector(`#calendar-icon-${calendarId}`);
      if (icon) {
        icon.style.display = isCalendarOpen ? "none" : "inline-block";
      }
      calendar._flatpickr[isCalendarOpen ? "close" : "open"]();
    }
  };

  const toggleSelectedDaystay = (dayIndex) => {
    if (selectedWeekdaysstay.includes(dayIndex)) {
      if (clickedDaysstay.includes(dayIndex)) {
        setClickedDaysstay((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayIndex)
        );
      } else {
        setClickedDaysstay((prevClickedDays) => [...prevClickedDays, dayIndex]);
      }
    }
  };

  const handleApplyToCalendarstay = () => {
    if (openclosestay === "open") {
      if (fpstay.current) {
        fpstay.current.flatpickr.clear();
      }
      if (datefromstay && datetostay && selectedWeekdaysstay.length > 0) {
        const dates = [];
        let currentDate = new Date(datefromstay);

        while (currentDate <= datetostay) {
          const dayOfWeek = currentDate.getDay();
          if (!clickedDaysstay.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatesstay(dates);
      }
    } else {
      if (fpstay.current) {
        fpstay.current.flatpickr.clear(); // Clear the selected dates in Flatpickr
      }

      if (
        datefromstay &&
        datetostay &&
        selectedWeekdaysstay.length > 0 &&
        clickedDaysstay.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromstay);

        while (currentDate <= datetostay) {
          const dayOfWeek = currentDate.getDay();
          if (clickedDaysstay.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
        setSelectedDatesstay(dates);
      }
    }
  };

  const handleApplyToCalendarcheckin = () => {
    if (openclosecheckin === "open") {
      if (
        datefromcheckin &&
        datetocheckin &&
        selectedWeekdayscheckin.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromcheckin);

        while (currentDate <= datetocheckin) {
          const dayOfWeek = currentDate.getDay();
          if (!clickedDayscheckin.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatescheckin(dates);
      }
    } else {
      if (fpcheckin.current) {
        fpcheckin.current.flatpickr.clear(); // Clear the selected dates in Flatpickr
      }

      if (
        datefromcheckin &&
        datetocheckin &&
        selectedWeekdayscheckin.length > 0 &&
        clickedDayscheckin.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromcheckin);

        while (currentDate <= datetocheckin) {
          const dayOfWeek = currentDate.getDay();
          if (clickedDayscheckin.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatescheckin(dates);
      }
    }
  };

  const handleApplyToCalendarcheckout = () => {
    if (openclosecheckout === "open") {
      if (
        datefromcheckout &&
        datetocheckout &&
        selectedWeekdayscheckout.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromcheckout);

        while (currentDate <= datetocheckout) {
          const dayOfWeek = currentDate.getDay();
          if (!clickedDayscheckout.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatescheckout(dates);
      }
    } else {
      if (fpcheckout.current) {
        fpcheckout.current.flatpickr.clear(); // Clear the selected dates in Flatpickr
      }
      if (
        datefromcheckout &&
        datetocheckout &&
        selectedWeekdayscheckout.length > 0 &&
        clickedDayscheckout.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromcheckout);

        while (currentDate <= datetocheckout) {
          const dayOfWeek = currentDate.getDay();
          if (clickedDayscheckout.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
        setSelectedDatescheckout(dates);
      }
    }
  };

  const handleApplyToCalendareserve = () => {
    if (openclosereserve === "open") {
      if (
        datefromreserve &&
        datetoreserve &&
        selectedWeekdaysreserve.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromreserve);

        while (currentDate <= datetoreserve) {
          const dayOfWeek = currentDate.getDay();
          if (!clickedDaysreserve.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
        setSelectedDatesreserve(dates);
      }
    } else {
      if (fpreserve.current) {
        fpreserve.current.flatpickr.clear(); // Clear the selected dates in Flatpickr
      }
      if (
        datefromreserve &&
        datetoreserve &&
        selectedWeekdaysreserve.length > 0 &&
        clickedDaysreserve.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromreserve);

        while (currentDate <= datetoreserve) {
          const dayOfWeek = currentDate.getDay();
          if (clickedDaysreserve.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
        setSelectedDatesreserve(dates);
      }
    }
  };

  useEffect(() => {
    getData();
    getOffer();
    getRate();
    getRoom();
    getCompany();
    getAgency();

    const initialSelectedOption = SingleOptions?.find(
      (option) => option?.value === list?.type
    );
    setNewtype(initialSelectedOption?.label || "");

    const newUsage = usageoption?.find(
      (option) => option?.value === selectedusagenew
    );
    setSelectedusage(newUsage);

    const newPrevail = prevailoption?.find(
      (option) => option.value === selectedprevailnew
    );
    setSelectedprevail(newPrevail);
  }, [
    list?.type,
    selectedprevailnew?.length,
    selectedusagenew?.length,
    selectedusage?.length,
    selectedprevail?.length,
  ]);

  useEffect(() => {
    const getWeekdays = (startDate, endDate) => {
      const weekdays = [];
      const currentDay = new Date(startDate);
      const lastDay = new Date(endDate);

      while (currentDay <= lastDay) {
        const dayOfWeek = currentDay.getDay();
        if (dayOfWeek >= 0 && dayOfWeek <= 6) {
          weekdays.push(dayOfWeek);
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }
      return weekdays;
    };

    const getWeekdaysBetweenSelectedDates = (datefromstay, datetostay) => {
      const weekdays = getWeekdays(datefromstay, datetostay);
      setSelectedWeekdaysstay(weekdays);
      setClickedDaysstay([]);
    };

    if (datefromstay && datetostay) {
      getWeekdaysBetweenSelectedDates(datefromstay, datetostay);
    } else {
      setSelectedWeekdaysstay([]);
    }
  }, [datefromstay, datetostay]);

  useEffect(() => {
    const getWeekdays = (startDate, endDate) => {
      const weekdays = [];
      const currentDay = new Date(startDate);
      const lastDay = new Date(endDate);

      while (currentDay <= lastDay) {
        const dayOfWeek = currentDay.getDay();
        if (dayOfWeek >= 0 && dayOfWeek <= 6) {
          weekdays.push(dayOfWeek);
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }

      return weekdays;
    };

    const getWeekdaysBetweenSelectedDates = (
      datefromcheckin,
      datetocheckin
    ) => {
      const weekdays = getWeekdays(datefromcheckin, datetocheckin);
      setSelectedWeekdayscheckin(weekdays);
      setClickedDayscheckin([]);
    };

    if (datefromcheckin && datetocheckin) {
      getWeekdaysBetweenSelectedDates(datefromcheckin, datetocheckin);
    } else {
      setSelectedWeekdayscheckin([]);
    }
  }, [datefromcheckin, datetocheckin]);

  useEffect(() => {
    const getWeekdays = (startDate, endDate) => {
      const weekdays = [];
      const currentDay = new Date(startDate);
      const lastDay = new Date(endDate);

      while (currentDay <= lastDay) {
        const dayOfWeek = currentDay.getDay();
        if (dayOfWeek >= 0 && dayOfWeek <= 6) {
          weekdays.push(dayOfWeek);
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }

      return weekdays;
    };

    const getWeekdaysBetweenSelectedDates = (
      datefromcheckout,
      datetocheckout
    ) => {
      const weekdays = getWeekdays(datefromcheckout, datetocheckout);
      setSelectedWeekdayscheckout(weekdays);
      setClickedDayscheckout([]);
    };

    if (datefromcheckout && datetocheckout) {
      getWeekdaysBetweenSelectedDates(datefromcheckout, datetocheckout);
    } else {
      setSelectedWeekdayscheckout([]);
    }
  }, [datefromcheckout, datetocheckout]);

  useEffect(() => {
    const getWeekdays = (startDate, endDate) => {
      const weekdays = [];
      const currentDay = new Date(startDate);
      const lastDay = new Date(endDate);

      while (currentDay <= lastDay) {
        const dayOfWeek = currentDay.getDay();
        if (dayOfWeek >= 0 && dayOfWeek <= 6) {
          weekdays.push(dayOfWeek);
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }

      return weekdays;
    };

    const getWeekdaysBetweenSelectedDates = (
      datefromreserve,
      datetoreserve
    ) => {
      const weekdays = getWeekdays(datefromreserve, datetoreserve);
      setSelectedWeekdaysreserve(weekdays);
      setClickedDaysreserve([]);
    };

    if (datefromreserve && datetoreserve) {
      getWeekdaysBetweenSelectedDates(datefromreserve, datetoreserve);
    } else {
      setSelectedWeekdaysreserve([]);
    }
  }, [datefromreserve, datetoreserve]);

  useEffect(() => {
    if (selectedRatesnew) {
      for (let i = 0; i < rates.length; i++) {
        if (selectedRatesnew.some((item) => item === rates[i].value)) {
          selectedRates.push({
            value: rates[i].value,
            label: rates[i].label,
          });
        }
      }
    }
  }, [selectedRatesnew?.length, rates.length]);

  useEffect(() => {
    if (selectedOffersnew) {
      for (let i = 0; i < newOffer.length; i++) {
        if (selectedOffersnew.some((item) => item === newOffer[i].value)) {
          selectedOffers.push({
            value: newOffer[i].value,
            label: newOffer[i].label,
          });
        }
      }
    }
  }, [selectedOffersnew?.length, newOffer.length]);

  useEffect(() => {
    if (selectedRoomsnew) {
      for (let i = 0; i < rooms.length; i++) {
        if (selectedRoomsnew.some((item) => item === rooms[i].value)) {
          selectedRooms.push({
            value: rooms[i].value,
            label: rooms[i].label,
          });
        }
      }
    }
  }, [selectedRoomsnew?.length, rooms.length]);

  return (
    <div className="main-content" style={{ marginTop: "10%" }}>
      <Privatesaleheader />
      <div className="container-fluid">
        <div className="row project-wrapper">
          <div className="row dashboard-top-tab-style">
            <div className="col-xxl-12 dashboard-main-style">
              <div className="">
                <div className="card header">
                  <div
                    className="align-items-center d-flex"
                    style={{
                      borderBottom: "1px solid #d2d2d2",
                      marginTop: "25px",
                    }}
                  >
                    <h4 style={{ marginLeft: "15px" }}>EDIT CODE</h4>
                    <div className="flex-grow-1">
                      <div
                        className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                        dir="ltr"
                        style={{ float: "right", bottom: "8px" }}
                      >
                        <label
                          className="form-check-label"
                          htmlFor="inlineswitch5"
                          style={{
                            marginRight: "40px",
                            marginTop: "5px",
                            marginLeft: "5px",
                            color: "black",
                          }}
                        >
                          Active
                        </label>
                        <input
                          type="checkbox"
                          className="form-check-input offer_checkbox1"
                          id="inlineswitch5"
                          value={list?.status}
                          checked={list?.status === true ? true : false}
                          onChange={(e) =>
                            handleloyaltyToggle(e.target.checked, "status")
                          }
                          style={{ width: "50px", height: "25px" }}
                        />
                      </div>
                    </div>
                    <div style={{ float: "right", paddingRight: "15px" }}>
                      <Link
                        type="button"
                        className="btn db-other-button"
                        to="/codes"
                        style={{ bottom: "8px" }}
                      >
                        Available Code
                      </Link>
                      <Link
                        type="button"
                        to="#"
                        className="btn db-save-button"
                        style={{ height: "30px", bottom: "8px" }}
                        onClick={Update}
                      >
                        {" "}
                        Update
                      </Link>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="live-preview">
                      <form action="#">
                        <div className="row">
                          {/* <div className="col-lg-2" style={{marginTop:"15px"}}>
                        <span className="input-group" style={{width:"200px"}}>
                            <span className="input-group-text input-joint-color1"
                              id="inputGroup-sizing-default">
                              Type <span className="form-validation-required">*</span>
                            </span>
                              <select className="form-select" id="idPayment" value={list.type}
                              // onChange={(e) => handleloyaltyToggle(e.target.value, "type")}
                              >
                                <option value="">Select...</option>
                                <option value="normal">Normal</option>
                                {company.length>0?
                                <option value="company">Company</option>
                                :""}
                                {agency.length>0?
                                <option value="travel_agency">Agencies</option>
                                :""}
                                <option value="loyalty">Loyalty</option>
                            </select>
                            </span>
                          </div> */}
                          <div className="col-md-2" style={{ top: "15px" }}>
                            <div
                              className="input-group"
                              style={{ width: "190px" }}
                            >
                              <label
                                className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default"
                              >
                                Type
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={newtype}
                                style={{ textAlign: "center" }}
                              />
                            </div>
                          </div>
                          <div className="col-md-2" style={{ top: "15px" }}>
                            <div
                              className="input-group"
                              style={{ width: "190px" }}
                            >
                              <label
                                className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default"
                              >
                                Code
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={list?.promocode}
                                style={{ textAlign: "center" }}
                              />
                            </div>
                          </div>

                          <div
                            className="col-lg-3"
                            style={{ marginTop: "15px" }}
                          >
                            <span
                              className="input-group"
                              style={{ width: "210px" }}
                            >
                              <span
                                className="input-group-text input-joint-color1"
                                id="inputGroup-sizing-default"
                              >
                                Usage{" "}
                                <span className="form-validation-required">
                                  *
                                </span>
                              </span>
                              <Select
                                placeholder="Select..."
                                className="form-control"
                                data-choices
                                name="choices-single-default"
                                id="choices-single-default"
                                value={selectedusage}
                                onChange={setSelectedusage}
                                options={usageoption}
                              ></Select>
                            </span>
                            <label style={{ color: "red" }}>
                              {Error.selectedusage}
                            </label>
                          </div>
                          {selectedusage?.value === "limited" ||
                          selectedusage?.value === "person_limit" ? (
                            <>
                              <div
                                className="input-group"
                                style={{ width: "170px", right: "8%" }}
                              >
                                <input
                                  type="text"
                                  className="new_inp_avail_code form-control"
                                  placeholder="Enter no of usages"
                                  value={list?.usage_limit}
                                  onChange={(e) =>
                                    handleloyaltyToggle(
                                      e.target.value,
                                      "usage_limit"
                                    )
                                  }
                                  style={{
                                    width: "150px",
                                    textAlign: "center",
                                    marginBottom: "5px",
                                    top: "15px",
                                    height: "40px",
                                    fontSize: "14px",
                                  }}
                                />
                                <label
                                  style={{ color: "red", marginTop: "10px" }}
                                >
                                  {Error.usage_limit}
                                </label>
                                {/* <span className="info-tag-p-sale">i<span className="tooltiptext">Enter number of usages</span></span> */}
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          <div
                            className="col-lg-2"
                            style={{ marginTop: "20px", right: "9%" }}
                          >
                            <div
                              className="form-check form-check-inline"
                              style={{ float: "left", width: "225px" }}
                            >
                              <label style={{ marginRight: "10px" }}>
                                Expiration
                              </label>

                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input per_room_once_check"
                                  type="radio"
                                  id="WithInlineRadioNo"
                                  name="expiry_status"
                                  value={true}
                                  checked={list?.expiry_status === true}
                                  onChange={(e) =>
                                    handleChangeExpiration(
                                      "expiry_status",
                                      true
                                    )
                                  }
                                />
                                <label>Yes</label>
                              </div>

                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input per_room_once_check"
                                  type="radio"
                                  id="WithInlineRadioNo"
                                  name="expiry_status"
                                  value={false}
                                  checked={list?.expiry_status === false}
                                  onChange={(e) =>
                                    handleChangeExpiration(
                                      "expiry_status",
                                      false
                                    )
                                  }
                                />
                                <label>No</label>
                              </div>
                            </div>
                          </div>
                          {list?.expiry_status === true ? (
                            <>
                              <div
                                className="col-xxl-1 col-sm-4 form-calender-add"
                                style={{ marginTop: "15px", right: "9%" }}
                              >
                                <form action="#">
                                  <div className="input-group">
                                    <Flatpickr
                                      className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                      placeholder="Choose date"
                                      id="flatpickr-from"
                                      options={{
                                        mode: "single",
                                        dateFormat: "d M, Y",
                                      }}
                                      value={new Date(exp_date)}
                                      onChange={handleCalendarChange}
                                    />
                                    <label
                                      style={{
                                        color: "red",
                                        marginLeft: "28%",
                                      }}
                                    >
                                      {Error.exp_date}
                                    </label>
                                    <span
                                      className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                      onClick={() =>
                                        handleCalendarIconClick("from")
                                      }
                                      style={{ left: "160px" }}
                                    ></span>
                                  </div>
                                </form>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          <div className="row">
                            <div class="col-lg-3" style={{ marginTop: "10px" }}>
                              <div
                                className="input-group"
                                style={{ width: "310px" }}
                              >
                                <label
                                  className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default"
                                >
                                  Internal Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  value={list?.common_name}
                                  onChange={(e) =>
                                    handleloyaltyToggle(
                                      e.target.value,
                                      "common_name"
                                    )
                                  }
                                />
                              </div>
                              <span
                                className="info-tag-p-sale"
                                style={{
                                  float: "right",
                                  bottom: "25px",
                                  right: "0px",
                                }}
                              >
                                i
                                <span className="tooltiptext">
                                  (Eg. Companies, IBM, January mailing...)
                                </span>
                              </span>
                            </div>
                            <div
                              class="col-lg-3"
                              style={{ left: "2%", marginTop: "10px" }}
                            >
                              <div className="d-flex align-items-center">
                                <label
                                  className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default"
                                  style={{
                                    borderBottomRightRadius: "0px",
                                    borderTopRightRadius: "0px",
                                  }}
                                >
                                  Discount
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                  {/* <span
                                    className="info-tag-p-sale"
                                    style={{ left: "5px" }}
                                  >
                                    i<span className="tooltiptext"></span>
                                  </span> */}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  value={list?.discount}
                                  onChange={(e) =>
                                    handleloyaltyToggle(
                                      e.target.value,
                                      "discount"
                                    )
                                  }
                                  style={{
                                    borderBottomLeftRadius: "0px",
                                    borderTopLeftRadius: "0px",
                                  }}
                                />
                                <span className="rate_modify_type_section">
                                  <select
                                    id="ForminputState"
                                    className="form-select"
                                    value={list?.unit_value}
                                    onChange={(e) =>
                                      handleloyaltyToggle(
                                        e.target.value,
                                        "unit_value"
                                      )
                                    }
                                  >
                                    <option value="currency">AED</option>
                                    <option value="percentage">%</option>
                                  </select>
                                </span>
                              </div>

                              <label style={{ color: "red" }}>
                                {Error.discount}
                              </label>
                            </div>

                            <div
                              class="col-lg-5"
                              style={{ left: "4%", marginTop: "10px" }}
                            >
                              <div className="input-group">
                                <label
                                  className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default"
                                >
                                  Minimum Stay Nights
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <select
                                  className="form-control form-select"
                                  value={list?.minimum_stay}
                                  onChange={(e) =>
                                    handleloyaltyToggle(
                                      e.target.value,
                                      "minimum_stay"
                                    )
                                  }
                                  style={{
                                    textAlign: "center",
                                    height: "38px",
                                  }}
                                >
                                  {[...Array(100).keys()].map((number) => (
                                    <option key={number} value={number + 1}>
                                      {number + 1}
                                    </option>
                                  ))}
                                </select>
                                {/* <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  value={list.minimum_stay}
                                  onChange={(e) =>
                                    handleloyaltyToggle(
                                      e.target.value,
                                      "minimum_stay"
                                    )
                                  }
                                  style={{ textAlign: "center" }}
                                /> */}
                                <ul className="modify-open-conditon-list">
                                  <li style={{ height: "38px" }}>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck1"
                                        value={list?.stay_date_status}
                                        checked={
                                          list?.stay_date_status === true
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleChangeExpiration(
                                            "stay_date_status",
                                            e.target.checked
                                          )
                                        }
                                      />
                                      <label style={{ color: "#111" }}>
                                        Take days into account
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                                <label style={{ color: "red" }}>
                                  {Error.minimum_stay}
                                </label>
                              </div>
                            </div>
                          </div>
                          {newOffer.length > 0 ? (
                            <div
                              className="col-lg-4"
                              style={{ marginTop: "10px" }}
                            >
                              <div className="input-group">
                                <label
                                  className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default"
                                >
                                  Confidential Offers
                                </label>
                                <div className="col-lg-7">
                                  <MultiSelect
                                    options={newOffer}
                                    value={selectedOffers}
                                    onChange={setSelectedOffers}
                                    labelledBy="Select"
                                    selectionType="counter"
                                    allowCustomValue={true}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {rates.length > 0 ? (
                            <div
                              className="col-lg-4"
                              style={{ marginTop: "10px", right: "3%" }}
                            >
                              <div className="input-group">
                                <label
                                  className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default"
                                >
                                  Confidential Rates
                                </label>
                                <div className="col-lg-7">
                                  <MultiSelect
                                    options={newRate}
                                    value={selectedRates}
                                    onChange={setSelectedRates}
                                    labelledBy="Select"
                                    selectionType="counter"
                                    allowCustomValue={true}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {rooms.length > 0 ? (
                            <div
                              className="col-lg-4"
                              style={{ marginTop: "10px", right: "4%" }}
                            >
                              <div className="input-group">
                                <label
                                  className="input-group-text input-joint-color"
                                  id="inputGroup-sizing-default"
                                >
                                  Confidential Rooms
                                </label>
                                <div className="col-md-7">
                                  <MultiSelect
                                    options={rooms}
                                    value={selectedRooms}
                                    onChange={setSelectedRooms}
                                    labelledBy="Select"
                                    selectionType="counter"
                                    allowCustomValue={true}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div style={{ marginTop: "20px" }}>
                            <span
                              style={{ color: "#5ea3cb", cursor: "pointer" }}
                              onClick={(e) => setExceptionstay(!exceptionstay)}
                            >
                              Exceptions (stay dates)
                            </span>
                          </div>
                          {exceptionstay === true ? (
                            <>
                              {inputFields.map((data, index) => {
                                return (
                                  <>
                                    <div
                                      className="col-md-4"
                                      key={index}
                                      style={{ marginTop: "20px" }}
                                    >
                                      <div
                                        className="input-group"
                                        style={{ width: "300px" }}
                                      >
                                        <label
                                          className="input-group-text input-joint-color"
                                          id="inputGroup-sizing-default"
                                        >
                                          From
                                        </label>
                                        <Flatpickr
                                          id={`flatpickr-from${index}`}
                                          className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                          options={{
                                            mode: "single",
                                            dateFormat: "d M, Y",
                                          }}
                                          value={new Date(data.form)}
                                          placeholder="Choose date"
                                          onChange={(e) =>
                                            handleChangedatefrom(index, e)
                                          }
                                        />
                                        <span
                                          className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                          onClick={() =>
                                            handleCalendarIconClick(
                                              `from${index}`
                                            )
                                          }
                                          style={{ marginTop: "-3px" }}
                                        ></span>
                                      </div>
                                    </div>
                                    <div
                                      className="col-md-3"
                                      style={{ marginTop: "20px", right: "3%" }}
                                    >
                                      <div className="input-group">
                                        <label
                                          className="input-group-text input-joint-color"
                                          id="inputGroup-sizing-default"
                                        >
                                          To
                                        </label>
                                        <Flatpickr
                                          id={`flatpickr-to${index}`}
                                          className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                          options={{
                                            mode: "single",
                                            dateFormat: "d M, Y",
                                          }}
                                          value={new Date(data.until)}
                                          placeholder="Choose date"
                                          onChange={(e) =>
                                            handleChangetodate(index, e)
                                          }
                                        />
                                        <span
                                          className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                          onClick={() =>
                                            handleCalendarIconClick(
                                              `to${index}`
                                            )
                                          }
                                          style={{ marginTop: "-3px" }}
                                        ></span>
                                      </div>
                                    </div>
                                    <div
                                      class="col-lg-4"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <div
                                        className="input-group"
                                        style={{ width: "260px" }}
                                      >
                                        <label
                                          className="input-group-text input-joint-color"
                                          id="inputGroup-sizing-default"
                                        >
                                          Discount
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder=""
                                          value={data.discount}
                                          onChange={(e) =>
                                            handleChangediscount(
                                              index,
                                              e.target.value,
                                              "discount"
                                            )
                                          }
                                          style={{ textAlign: "center" }}
                                        />
                                      </div>
                                      <span
                                        className="rate_modify_type_section"
                                        style={{ right: "15%" }}
                                      >
                                        <select
                                          id="ForminputState"
                                          className="form-select"
                                          name="unit_value"
                                          value={data.unit_value}
                                          onChange={(e) =>
                                            handleChangediscount(
                                              index,
                                              e.target.value,
                                              "unit_value"
                                            )
                                          }
                                        >
                                          <option value="currency" selected>
                                            AED
                                          </option>
                                          <option value="percentage">%</option>
                                        </select>
                                      </span>
                                    </div>
                                    <div className="col-md-1">
                                      <span
                                        className={
                                          index === inputFields.length - 1
                                            ? "extrarate-room-link extra_link_padding"
                                            : "extrarate-room-link d-none"
                                        }
                                      >
                                        <Link
                                          className="extra-rate-add"
                                          to="#"
                                          onClick={addInputField}
                                        >
                                          +
                                        </Link>
                                      </span>

                                      <Link
                                        className={
                                          index === 0
                                            ? "remove_more d-none"
                                            : "remove_more extra_link_padding"
                                        }
                                        to="#"
                                        onClick={(e) =>
                                          removeInputFields(index)
                                        }
                                      >
                                        X
                                      </Link>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            ""
                          )}
                          {list?.type === "company" ? (
                            <div
                              className="col-lg-3"
                              style={{ marginTop: "20px" }}
                            >
                              <span
                                className="input-group"
                                style={{ width: "300px" }}
                              >
                                <span
                                  className="input-group-text input-joint-color1"
                                  id="inputGroup-sizing-default"
                                >
                                  Company
                                </span>
                                <Select
                                  placeholder="Select..."
                                  className="form-control multiselect_extra_based"
                                  data-choices
                                  name="choices-single-default"
                                  id="choices-single-default"
                                  value={selectedCompany}
                                  onChange={setSelectedCompany}
                                  options={company}
                                ></Select>
                              </span>
                            </div>
                          ) : list?.type === "travel_agency" ? (
                            <div
                              className="col-lg-3"
                              style={{ marginTop: "20px" }}
                            >
                              <span
                                className="input-group"
                                style={{ width: "300px" }}
                              >
                                <span
                                  className="input-group-text input-joint-color1"
                                  id="inputGroup-sizing-default"
                                >
                                  Agency
                                </span>
                                <Select
                                  placeholder="Select..."
                                  className="form-control multiselect_extra_based"
                                  data-choices
                                  name="choices-single-default"
                                  id="choices-single-default"
                                  value={selectedAgency}
                                  onChange={setSelectedAgency}
                                  options={agency}
                                ></Select>
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            className="col-md-12"
                            style={{ marginTop: "20px" }}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="formCheck1"
                              value={list?.apply_with_loyalty_program}
                              checked={
                                list?.apply_with_loyalty_program === true
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                handleChangeExpiration(
                                  "apply_with_loyalty_program",
                                  e.target.checked
                                )
                              }
                            />
                            <span className="room-check">
                              Apply even with club price modifiers
                            </span>
                          </div>
                          {list?.apply_with_loyalty_program === false ? (
                            <div
                              className="col-lg-3"
                              style={{ marginTop: "8px" }}
                            >
                              <span
                                className="input-group"
                                style={{ width: "320px" }}
                              >
                                <span
                                  className="input-group-text input-joint-color1"
                                  id="inputGroup-sizing-default"
                                >
                                  Prevailing Discount
                                </span>
                                <Select
                                  placeholder="Select..."
                                  className="form-control"
                                  data-choices
                                  name="choices-single-default"
                                  id="choices-single-default"
                                  value={selectedprevail}
                                  onChange={setSelectedprevail}
                                  options={prevailoption}
                                ></Select>
                              </span>
                              <label style={{ color: "red" }}>
                                {Error.selectedprevail}
                              </label>
                            </div>
                          ) : (
                            ""
                          )}

                          <div
                            className="col-md-12"
                            style={{ marginTop: "10px" }}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="formCheck1"
                              value={list?.apply_with_rates}
                              checked={
                                list?.apply_with_rates === true ? true : false
                              }
                              onChange={(e) =>
                                handleChangeExpiration(
                                  "apply_with_rates",
                                  e.target.checked
                                )
                              }
                            />
                            <span className="room-check">
                              Apply discount also on club exclusive rates
                            </span>
                          </div>

                          <div
                            className="col-md-12"
                            style={{ marginTop: "10px" }}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="formCheck1"
                              value={list?.apply_with_offers}
                              checked={
                                list?.apply_with_offers === true ? true : false
                              }
                              onChange={(e) =>
                                handleChangeExpiration(
                                  "apply_with_offers",
                                  e.target.checked
                                )
                              }
                            />
                            <span className="room-check">
                              Apply discount also on club exclusive offers
                            </span>
                          </div>

                          <div
                            className="col-md-12"
                            style={{ marginTop: "10px" }}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="formCheck1"
                              value={d_stay_status}
                              checked={d_stay_status === true ? true : false}
                              onChange={(e) =>
                                setDstay_status(e.target.checked)
                              }
                            />
                            <span className="room-check">
                              Also, it must match these dates of STAY...
                            </span>
                          </div>

                          <div
                            className={
                              d_stay_status === true
                                ? "offer-condition-calendar form-calender-add form-stay-check-show"
                                : "offer-condition-calendar form-calender-add form-stay-check-show d-none"
                            }
                            style={{
                              width: "310px",
                              height: "315px",
                              marginTop: "20px",
                            }}
                          >
                            <Flatpickr
                              ref={fpstay}
                              className="offer_flat"
                              value={selectedDatesstay}
                              onChange={(date) => setSelectedDatesstay(date)}
                              options={{ mode: "multiple", inline: true }}
                            />
                          </div>

                          <div
                            className={
                              d_stay_status === true
                                ? "offer-condition-calendar-pic form-stay-check-show"
                                : "offer-condition-calendar-pic form-stay-check-show d-none"
                            }
                            style={{ display: "contents" }}
                          >
                            <div
                              className="offer-condition-head-sec"
                              style={{ marginTop: "20px" }}
                            >
                              <span className="info-tag">
                                i
                                <span className="tooltiptext">
                                  If you want to make the offer that available
                                  for users only for particular dates of stay?
                                  Enable and choose the stay dates
                                </span>
                              </span>
                              <p
                                className="offer-condition-calendar-choose stay_choose_status"
                                onClick={(e) =>
                                  setActivestaydateopen(!activestaydateopen)
                                }
                              >
                                Open/Close period
                              </p>
                            </div>

                            <div className="clearfix"></div>
                            <div
                              className={
                                activestaydateopen === true
                                  ? "offer-condition-apply stay_choose_open"
                                  : "offer-condition-apply stay_choose_open d-none"
                              }
                              style={{ height: "170px", marginTop: "20px" }}
                            >
                              <span className="offer-condition-close">
                                <Link to="" onClick={handleOpenclosestay}>
                                  X
                                </Link>
                              </span>
                              <div className="offer-condition-apply-inner-section">
                                <select
                                  className="form-select"
                                  value={openclosestay}
                                  onChange={(e) =>
                                    setOpenclosestay(e.target.value)
                                  }
                                >
                                  <option value="open">Open</option>
                                  <option value="close">Close</option>
                                </select>
                                <div className="offer-condition-apply-calender">
                                  <div className="offer-condition-check">
                                    <form action="#">
                                      <div className="input-group">
                                        <Flatpickr
                                          id="flatpickr-from"
                                          className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                          placeholder="FROM"
                                          options={{
                                            mode: "single",
                                            dateFormat: "d M, Y",
                                          }}
                                          value={datefromstay}
                                          onChange={handleCalendarChangeFrom}
                                        />
                                        <span
                                          className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                          onClick={() =>
                                            handleCalendarIconClick("from")
                                          }
                                        ></span>
                                      </div>
                                    </form>
                                  </div>
                                  <div className="offer-condition-check">
                                    <form action="#">
                                      <div className="input-group">
                                        <Flatpickr
                                          id="flatpickr-to"
                                          className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                          placeholder="UNTIL"
                                          options={{
                                            mode: "single",
                                            dateFormat: "d M, Y",
                                          }}
                                          value={datetostay}
                                          onChange={handleCalendarChangeTo}
                                        />
                                        <span
                                          className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                          onClick={() =>
                                            handleCalendarIconClick("to")
                                          }
                                        ></span>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <div className="offer-condition-week">
                                  <ul>
                                    <li>
                                      <p>Mon</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdaysstay.includes(1) &&
                                          !clickedDaysstay.includes(1)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaystay(1)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Tue</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdaysstay.includes(2) &&
                                          !clickedDaysstay.includes(2)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaystay(2)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Wed</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdaysstay.includes(3) &&
                                          !clickedDaysstay.includes(3)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaystay(3)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Thu</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdaysstay.includes(4) &&
                                          !clickedDaysstay.includes(4)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaystay(4)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Fri</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdaysstay.includes(5) &&
                                          !clickedDaysstay.includes(5)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaystay(5)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Sat</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdaysstay.includes(6) &&
                                          !clickedDaysstay.includes(6)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaystay(6)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Sun</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdaysstay.includes(0) &&
                                          !clickedDaysstay.includes(0)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaystay(0)
                                        }
                                      />
                                    </li>
                                  </ul>
                                </div>
                                <div className="condition-apply-calendar-btn-sec">
                                  <Link
                                    className="condition-apply-calendar-btn"
                                    onClick={handleApplyToCalendarstay}
                                  >
                                    Apply to calendar
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-md-12"
                            style={{ marginTop: "10px" }}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="formCheck1"
                              value={d_checkin_status}
                              checked={d_checkin_status === true ? true : false}
                              onChange={(e) =>
                                setDcheckin_status(e.target.checked)
                              }
                            />
                            <span className="room-check">
                              It must coincide with these CHECK-IN dates ...
                            </span>
                          </div>

                          <div
                            className={
                              d_checkin_status === true
                                ? "offer-condition-calendar form-calender-add form-checkin-check-show"
                                : "offer-condition-calendar form-calender-add form-checkin-check-show d-none"
                            }
                            style={{
                              width: "310px",
                              height: "315px",
                              marginTop: "20px",
                            }}
                          >
                            <Flatpickr
                              ref={fpcheckin}
                              className="offer_flat"
                              value={selectedDatescheckin}
                              onChange={(date) => setSelectedDatescheckin(date)}
                              options={{
                                mode: "multiple",
                                inline: true,
                              }}
                            />
                          </div>
                          <div
                            className={
                              d_checkin_status === true
                                ? "offer-condition-calendar-pic form-checkin-check-show"
                                : "offer-condition-calendar-pic form-checkin-check-show d-none"
                            }
                            style={{ display: "contents" }}
                          >
                            <div
                              className="offer-condition-head-sec"
                              style={{ marginTop: "20px" }}
                            >
                              <span className="info-tag">
                                i
                                <span className="tooltiptext">
                                  Would you like to make the offer available for
                                  users only if it coincides with particular
                                  check-in dates? Enable and choose the check-in
                                  dates.
                                </span>
                              </span>
                              <p
                                className="offer-condition-calendar-choose checkin_choose_status"
                                onClick={(e) =>
                                  setActivecheckindateopen(
                                    !activecheckindateopen
                                  )
                                }
                              >
                                Open/Close period
                              </p>
                            </div>
                            <div className="clearfix"></div>
                            <div
                              className={
                                activecheckindateopen === true
                                  ? "offer-condition-apply checkin_choose_open"
                                  : "offer-condition-apply checkin_choose_open d-none"
                              }
                              style={{ height: "170px", marginTop: "20px" }}
                            >
                              <span className="offer-condition-close">
                                <Link to="" onClick={handleOpenclosecheckin}>
                                  X
                                </Link>
                              </span>
                              <div className="offer-condition-apply-inner-section">
                                <select
                                  className="form-select"
                                  value={openclosecheckin}
                                  onChange={(e) =>
                                    setOpenclosecheckin(e.target.value)
                                  }
                                >
                                  <option value="open">Open</option>
                                  <option value="close">Close</option>
                                </select>
                                <div className="offer-condition-apply-calender">
                                  <div className="offer-condition-check">
                                    <form action="#">
                                      <div className="input-group">
                                        <Flatpickr
                                          id="flatpickr-to1"
                                          className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                          placeholder="FROM"
                                          options={{
                                            mode: "single",
                                            dateFormat: "d M, Y",
                                          }}
                                          value={datefromcheckin}
                                          onChange={
                                            handleCalendarChangeFromcheckin
                                          }
                                        />
                                        <span
                                          className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                          onClick={() =>
                                            handleCalendarIconClick("to1")
                                          }
                                        ></span>
                                      </div>
                                    </form>
                                  </div>
                                  <div className="offer-condition-check">
                                    <form action="#">
                                      <div className="input-group">
                                        <Flatpickr
                                          id="flatpickr-to2"
                                          className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                          placeholder="UNTIL"
                                          options={{
                                            mode: "single",
                                            dateFormat: "d M, Y",
                                          }}
                                          value={datetocheckin}
                                          onChange={
                                            handleCalendarChangeTocheckin
                                          }
                                        />
                                        <span
                                          className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                          onClick={() =>
                                            handleCalendarIconClick("to2")
                                          }
                                        ></span>
                                      </div>
                                    </form>
                                    {/* <span className="form_calendar-style fon mdi mdi-calendar-month-outline"></span> */}
                                  </div>
                                </div>
                                <div className="offer-condition-week">
                                  <ul>
                                    <li>
                                      <p>Mon</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdayscheckin.includes(1) &&
                                          !clickedDayscheckin.includes(1)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaycheckin(1)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Tue</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdayscheckin.includes(2) &&
                                          !clickedDayscheckin.includes(2)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaycheckin(2)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Wed</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdayscheckin.includes(3) &&
                                          !clickedDayscheckin.includes(3)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaycheckin(3)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Thu</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdayscheckin.includes(4) &&
                                          !clickedDayscheckin.includes(4)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaycheckin(4)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Fri</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdayscheckin.includes(5) &&
                                          !clickedDayscheckin.includes(5)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaycheckin(5)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Sat</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdayscheckin.includes(6) &&
                                          !clickedDayscheckin.includes(6)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaycheckin(6)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Sun</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdayscheckin.includes(0) &&
                                          !clickedDayscheckin.includes(0)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaycheckin(0)
                                        }
                                      />
                                    </li>
                                  </ul>
                                </div>
                                <div className="condition-apply-calendar-btn-sec">
                                  <Link
                                    className="condition-apply-calendar-btn"
                                    onClick={handleApplyToCalendarcheckin}
                                  >
                                    Apply to calendar
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-md-12"
                            style={{ marginTop: "10px" }}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="formCheck1"
                              value={d_checkout_status}
                              checked={
                                d_checkout_status === true ? true : false
                              }
                              onChange={(e) =>
                                setDcheckout_status(e.target.checked)
                              }
                            />
                            <span className="room-check">
                              It must also coincide with the CHECK-OUT dates ...
                            </span>
                          </div>
                          <div
                            className={
                              d_checkout_status === true
                                ? "offer-condition-calendar form-calender-add form-checkout-check-show"
                                : "offer-condition-calendar form-calender-add form-checkout-check-show d-none"
                            }
                            style={{
                              width: "310px",
                              height: "315px",
                              marginTop: "20px",
                            }}
                          >
                            <Flatpickr
                              ref={fpcheckout}
                              className="offer_flat"
                              value={selectedDatescheckout}
                              onChange={(date) =>
                                setSelectedDatescheckout(date)
                              }
                              options={{
                                mode: "multiple",
                                inline: true,
                              }}
                            />
                          </div>
                          <div
                            className={
                              d_checkout_status === true
                                ? "offer-condition-calendar-pic form-checkout-check-show"
                                : "offer-condition-calendar-pic form-checkout-check-show d-none"
                            }
                            style={{ display: "contents" }}
                          >
                            <div
                              className="offer-condition-head-sec"
                              style={{ marginTop: "20px" }}
                            >
                              <span className="info-tag">
                                i
                                <span className="tooltiptext">
                                  Would you like to make the offer available for
                                  users only if it coincides with particular
                                  check-out dates? Enable and choose the
                                  check-out dates.
                                </span>
                              </span>
                              <p
                                className="offer-condition-calendar-choose checkout_choose_status"
                                onClick={(e) =>
                                  setActivecheckoutdateopen(
                                    !activecheckoutdateopen
                                  )
                                }
                              >
                                Open/Close period
                              </p>
                            </div>
                            <div className="clearfix"></div>
                            <div
                              className={
                                activecheckoutdateopen === true
                                  ? "offer-condition-apply checkout_choose_open"
                                  : "offer-condition-apply checkout_choose_open d-none"
                              }
                              style={{ height: "170px", marginTop: "20px" }}
                            >
                              <span className="offer-condition-close">
                                <Link to="" onClick={handleOpenclosecheckout}>
                                  X
                                </Link>
                              </span>
                              <div className="offer-condition-apply-inner-section">
                                <select
                                  className="form-select"
                                  value={openclosecheckout}
                                  onChange={(e) =>
                                    setOpenclosecheckout(e.target.value)
                                  }
                                >
                                  <option value="open">Open</option>
                                  <option value="close">Close</option>
                                </select>
                                <div className="offer-condition-apply-calender">
                                  <div className="offer-condition-check">
                                    <form action="#">
                                      <div className="input-group">
                                        <Flatpickr
                                          id="flatpickr-to3"
                                          className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                          placeholder="FROM"
                                          options={{
                                            mode: "single",
                                            dateFormat: "d M, Y",
                                          }}
                                          value={datefromcheckout}
                                          onChange={
                                            handleCalendarChangeFromcheckout
                                          }
                                        />
                                        <span
                                          className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                          onClick={() =>
                                            handleCalendarIconClick("to3")
                                          }
                                        ></span>
                                      </div>
                                    </form>
                                  </div>
                                  <div className="offer-condition-check">
                                    <form action="#">
                                      <div className="input-group">
                                        <Flatpickr
                                          id="flatpickr-to4"
                                          className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                          placeholder="UNTIL"
                                          options={{
                                            mode: "single",
                                            dateFormat: "d M, Y",
                                          }}
                                          value={datetocheckout}
                                          onChange={
                                            handleCalendarChangeTocheckout
                                          }
                                        />
                                        <span
                                          className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                          onClick={() =>
                                            handleCalendarIconClick("to4")
                                          }
                                        ></span>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <div className="offer-condition-week">
                                  <ul>
                                    <li>
                                      <p>Mon</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdayscheckout.includes(
                                            1
                                          ) && !clickedDayscheckout.includes(1)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaycheckout(1)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Tue</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdayscheckout.includes(
                                            2
                                          ) && !clickedDayscheckout.includes(2)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaycheckout(2)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Wed</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdayscheckout.includes(
                                            3
                                          ) && !clickedDayscheckout.includes(3)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaycheckout(3)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Thu</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdayscheckout.includes(
                                            4
                                          ) && !clickedDayscheckout.includes(4)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaycheckout(4)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Fri</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdayscheckout.includes(
                                            5
                                          ) && !clickedDayscheckout.includes(5)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaycheckout(5)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Sat</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdayscheckout.includes(
                                            6
                                          ) && !clickedDayscheckout.includes(6)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaycheckout(6)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Sun</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdayscheckout.includes(
                                            0
                                          ) && !clickedDayscheckout.includes(0)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDaycheckout(0)
                                        }
                                      />
                                    </li>
                                  </ul>
                                </div>
                                <div className="condition-apply-calendar-btn-sec">
                                  <Link
                                    className="condition-apply-calendar-btn"
                                    to=""
                                    onClick={handleApplyToCalendarcheckout}
                                  >
                                    Apply to calendar
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-md-12"
                            style={{ marginTop: "10px" }}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="formCheck1"
                              value={d_reserve_status}
                              checked={d_reserve_status === true ? true : false}
                              onChange={(e) =>
                                setDreserve_status(e.target.checked)
                              }
                            />
                            <span className="room-check">
                              Furthermore, it must coincide with these
                              RESERVATION DATES...
                            </span>
                          </div>
                          <div
                            className={
                              d_reserve_status === true
                                ? "offer-condition-calendar form-calender-add form-reservation-check-show"
                                : "offer-condition-calendar form-calender-add form-reservation-check-show d-none"
                            }
                            style={{
                              width: "310px",
                              height: "315px",
                              marginTop: "20px",
                            }}
                          >
                            <Flatpickr
                              ref={fpreserve}
                              className="offer_flat"
                              value={selectedDatesreserve}
                              onChange={(date) => setSelectedDatesreserve(date)}
                              options={{
                                mode: "multiple",
                                inline: true,
                              }}
                            />
                          </div>
                          <div
                            className={
                              d_reserve_status === true
                                ? "offer-condition-calendar-pic form-reservation-check-show"
                                : "offer-condition-calendar-pic form-reservation-check-show d-none"
                            }
                            style={{ display: "contents" }}
                          >
                            <div
                              className="offer-condition-head-sec"
                              style={{ marginTop: "20px" }}
                            >
                              <span className="info-tag">
                                i
                                <span className="tooltiptext">
                                  Would you like to make the offer available for
                                  users only if it coincides with particular
                                  reservation dates? Enable and choose the
                                  reservation dates.
                                </span>
                              </span>
                              <p
                                className="offer-condition-calendar-choose reservation_choose_status"
                                onClick={(e) =>
                                  setActivereservedateopen(
                                    !activereservedateopen
                                  )
                                }
                              >
                                Open/Close period
                              </p>
                            </div>
                            <div className="clearfix"></div>
                            <div
                              className={
                                activereservedateopen === true
                                  ? "offer-condition-apply reservation_choose_open"
                                  : "offer-condition-apply reservation_choose_open d-none"
                              }
                              style={{ height: "170px", marginTop: "20px" }}
                            >
                              <span className="offer-condition-close">
                                <Link to="" onClick={handleOpenclosereserve}>
                                  X
                                </Link>
                              </span>
                              <div className="offer-condition-apply-inner-section">
                                <select
                                  className="form-select"
                                  value={openclosereserve}
                                  onChange={(e) =>
                                    setOpenclosereserve(e.target.value)
                                  }
                                >
                                  <option value="open">Open</option>
                                  <option value="close">Close</option>
                                </select>
                                <div className="offer-condition-apply-calender">
                                  <div className="offer-condition-check">
                                    <form action="#">
                                      <div className="input-group">
                                        <Flatpickr
                                          id="flatpickr-to5"
                                          className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                          placeholder="FROM"
                                          options={{
                                            mode: "single",
                                            dateFormat: "d M, Y",
                                          }}
                                          value={datefromreserve}
                                          onChange={
                                            handleCalendarChangeFromreserve
                                          }
                                        />
                                        <span
                                          className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                          onClick={() =>
                                            handleCalendarIconClick("to5")
                                          }
                                        ></span>
                                      </div>
                                    </form>
                                  </div>
                                  <div className="offer-condition-check">
                                    <form action="#">
                                      <div className="input-group">
                                        <Flatpickr
                                          id="flatpickr-to6"
                                          className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                          placeholder="UNTIL"
                                          options={{
                                            mode: "single",
                                            dateFormat: "d M, Y",
                                          }}
                                          value={datetoreserve}
                                          onChange={
                                            handleCalendarChangeToreserve
                                          }
                                        />
                                        <span
                                          className="form_calendar-style fon mdi mdi-calendar-month-outline"
                                          onClick={() =>
                                            handleCalendarIconClick("to6")
                                          }
                                        ></span>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <div className="offer-condition-week">
                                  <ul>
                                    <li>
                                      <p>Mon</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdaysreserve.includes(1) &&
                                          !clickedDaysreserve.includes(1)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDayreserve(1)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Tue</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdaysreserve.includes(2) &&
                                          !clickedDaysreserve.includes(2)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDayreserve(2)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Wed</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdaysreserve.includes(3) &&
                                          !clickedDaysreserve.includes(3)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDayreserve(3)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Thu</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdaysreserve.includes(4) &&
                                          !clickedDaysreserve.includes(4)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDayreserve(4)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Fri</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdaysreserve.includes(5) &&
                                          !clickedDaysreserve.includes(5)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDayreserve(5)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Sat</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdaysreserve.includes(6) &&
                                          !clickedDaysreserve.includes(6)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDayreserve(6)
                                        }
                                      />
                                    </li>
                                    <li>
                                      <p>Sun</p>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox6"
                                        value="option1"
                                        checked={
                                          selectedWeekdaysreserve.includes(0) &&
                                          !clickedDaysreserve.includes(0)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          toggleSelectedDayreserve(0)
                                        }
                                      />
                                    </li>
                                  </ul>
                                </div>
                                <div className="condition-apply-calendar-btn-sec">
                                  <Link
                                    className="condition-apply-calendar-btn"
                                    to=""
                                    onClick={handleApplyToCalendareserve}
                                  >
                                    Apply to calendar
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="padding-20"></div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Codeedit;
