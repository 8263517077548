import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Link, useNavigate, useParams } from "react-router-dom";
import Alerts from "../../../Alerts";
import Footer from "../../../Common/Footer";
import Loyaltyheader from "../../../Common/Loyaltyheader";
import Select from "react-select";
import { useAuthHandling } from "../../../Common/useAuthHandling";
import Config from "../../../Config";
import Flatpickr from "react-flatpickr";
import axios from "axios";
import Toastmodal from "../../../Common/Toastmodal";
import moment from "moment";

function Loyaltymeberadd() {
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  let { selecthotelid } = useParams();

  let navigate = useNavigate();
  const [role, setRole] = useState([]);
  const [be_languagefull, setBe_languagefull] = useState([]);
  const [selectedMulti, setselectedMulti] = useState([]);

  const language = localStorage.getItem("be_language");
  const [alert, setAlert] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [formerror, setError] = useState({});
  const [formerror1, setError1] = useState({});
  const [formerror2, setError2] = useState({});
  const [formerror3, setError3] = useState({});
  const [formerror4, setError4] = useState({});
  const [formerror5, setError5] = useState({});
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [loyaluser, setLoyaluser] = useState({
    user_name: "",
    surname: "",
    email: "",
    password: "",
    image: "",
    c_password: "",
  });
  const [country, setCountry] = useState([]);
  const [roledata, setRoledata] = useState("");
  const [langdata, setLangdata] = useState("");
  const [countrydata, setCountrydata] = useState([]);
  const [calling, setCalling] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedcategory, setSelectedCategory] = useState([]);
  const [dob, setDob] = useState(null);
  const [selectedlanguage, setSelectedlanguage] = useState([]);
  const [subscribe, setSubscribe] = useState([]);

  // const getlanguage = async (e) => {

  //     const res = await fetch(`${Config.apiurl}optional-languages`, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,

  //       },
  //     });
  //     if(res.status===200){
  //         const data = await res.json();
  //         if (data.status_code !== "200" || !data.data) {
  //         console.log("error");
  //         } else {
  //         setBe_language(data.data);
  //         }

  //     } else if(res.status === 401){
  //         handleUnauthorized();
  //         getlanguage();
  //      }
  //     else{
  //         console.log("error");
  //     }
  //   };

  const getfulllanguage = async (e) => {
    const res = await fetch(`${Config.apiurl}booking-engine-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200" || !data.data) {
        console.log("error");
      } else {
        setBe_languagefull(data.data);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getfulllanguage();
    } else {
      console.log("error");
    }
  };

  const getcountry = async (e) => {
    const res = await fetch(`${Config.apiurl}country-list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200" || !data.data) {
        console.log("error");
      } else {
        setCountry(data.data);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getcountry();
    } else {
      console.log("error");
    }
  };

  const getcategory = async (e) => {
    const res = await fetch(`${Config.apiurl}loyalty-clubs`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: selecthotelid,
      }),
      headers: {
        // Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200" || !data.data) {
        console.log("error");
      } else {
        setCategory(data.data);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getcategory();
    } else {
      console.log("error");
    }
  };

  function handleChangeselectcountry(selectedOption) {
    getcountrybasetime(selectedOption);
  }

  const getcountrybasetime = async (val) => {
    const res1 = await fetch(
      `${Config.apiurl}country/calling-code/${val.value}`,
      {
        method: "POST",
      }
    );
    const data2 = await res1.json();
    const data3 = data2.data;

    if (data2.status_code !== "200" || !data3) {
      console.log("error");
    } else {
      setCalling(data3);
    }
    setCountrydata(val);
    setLoyaluser({ ...loyaluser, ["calling_code"]: data3[0] });
  };

  const handlechange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setLoyaluser({ ...loyaluser, [name]: value });
  };

  const handleCalendarChangeTocheckout = (selectedDates) => {
    setDob(selectedDates[0]);
  };

  const onFileChange = (e) => {
    console.log(e);
    let name = e.target.name;
    setLoyaluser({ ...loyaluser, [name]: e.target.files[0] });
  };

  const Add = async () => {
    let isFormValid = true;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regex1 = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[~`¿¡!#$%^&*€£@+÷=\-\[\]\\';,/{}|\\":<>\?\._])./;

    const errors = {};
    if (!loyaluser.user_name) {
      isFormValid = false;
      errors.user_name = "Name is required!";
    }
    if (!loyaluser.surname) {
      isFormValid = false;
      errors.surname = "Surname is required!";
    }
    if (!loyaluser.email) {
      isFormValid = false;

      errors.email = "Email is required!";
    } else if (!regex.test(loyaluser.email)) {
      isFormValid = false;

      errors.email = "This is not a valid email format!";
    }
    if (!dob) {
      isFormValid = false;
      errors.dob = "DOB is required!";
    }
    if (!loyaluser.password) {
      isFormValid = false;
      errors.password = "Password is required!";
    } 
    else if (!regex1.test(loyaluser.password)) {
      isFormValid = false;
      errors.password = "Password must contain at least one number, one lowercase letter, one uppercase letter and one special character!";
    }
    else if (loyaluser.password.length < 8) {
      isFormValid = false;
      errors.password = "Password must contain at least 8 characters!";
    }
    if (!loyaluser.c_password) {
      isFormValid = false;
      errors.c_password = "Confirmation password is required!";
    } else if (loyaluser.password !== loyaluser.c_password) {
      isFormValid = false;
      errors.c_password = "The passwords do not match!";
    }
    if (selectedlanguage.length === 0) {
      isFormValid = false;
      errors.selectedlanguage = "Language is required!";
    }
    if (selectedcategory.length === 0) {
      isFormValid = false;
      errors.selectedcategory = "Category is required!";
    }
    if (countrydata.length === 0) {
      isFormValid = false;
      errors.countrydata = "Country is required!";
    }
    if (!loyaluser.phone_no) {
      isFormValid = false;
      errors.phone_no = "Phone number is required!";
    }
    if (subscribe.length === 0) {
      isFormValid = false;
      errors.subscribe = "This field is required!";
    }
    if (status.length === 0) {
      isFormValid = false;
      errors.status = "Status is required!";
    }
    setError(errors);

    if (isFormValid === true) {
      const formData = new FormData();
      formData.append("hotel_id", selecthotelid);
      formData.append("name", loyaluser.user_name);
      formData.append("surname", loyaluser.surname);
      formData.append("status", status);
      formData.append("is_subscribed", subscribe);
      formData.append("email", loyaluser.email);
      formData.append("calling_code", loyaluser.calling_code);
      formData.append("phone_no", loyaluser.phone_no);
      formData.append("country", countrydata.value);
      formData.append("language", selectedlanguage.value);
      formData.append("category", selectedcategory.value);
      formData.append("dob", moment(dob).format("DD-MM-YYYY"));
      if (loyaluser.image) {
        formData.append("image", loyaluser.image);
      }
      formData.append("source", "website");
      formData.append("password", loyaluser.password);
      formData.append("password_confirmation", loyaluser.c_password);

      axios
        .post(`${Config.apiurl}hotel/loyalty/members/create`, formData, {})
        .then((res) => {
          if (res.status !== 200 || !res) {
            console.log("error");
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(true);
          } else {
            console.log("success");
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(true);
            setTimeout(() => navigate("/loyalty-member"), 3000);
          }
        });
    }
  };

  useEffect(() => {
    // getlanguage();
    getcountry();
    getfulllanguage();
    getcategory();
  }, [language]);

  console.log(loyaluser);

  return (
    <div>
      <Loyaltyheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Loyalty Member</h4>
                </div>
              </div>
            </div>
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create New Loyalty Member
                      </h4>
                      <div className="flex-shrink-0">
                        <Link
                          type="button"
                          className="btn db-other-button"
                          to="/loyalty-member"
                          style={{ marginRight: "5px" }}
                        >
                          Loyalty Member
                        </Link>
                        {/* <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to=""
                        >
                          Cancel
                        </button> */}
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          onClick={Add}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Name
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  value={loyaluser.user_name}
                                  name="user_name"
                                  onChange={handlechange}
                                />
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.user_name}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Surname
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  value={loyaluser.surname}
                                  name="surname"
                                  onChange={handlechange}
                                />
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.surname}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Email
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="example@gmail.com"
                                  value={loyaluser.email}
                                  name="email"
                                  onChange={handlechange}
                                />
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.email}
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Date of Birth
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <Flatpickr
                                  id="flatpickr-to4"
                                  className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                  placeholder="DOB"
                                  options={{
                                    mode: "single",
                                    dateFormat: "d M, Y",
                                  }}
                                  value={dob}
                                  onChange={handleCalendarChangeTocheckout}
                                />
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.dob}
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Password
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder=""
                                  value={loyaluser.password}
                                  name="password"
                                  onChange={handlechange}
                                />
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.password}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Confirm Password
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder=""
                                  value={loyaluser.c_password}
                                  name="c_password"
                                  onChange={handlechange}
                                />
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.c_password}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Profile Image
                                  {/* <span className="form-validation-required">*</span> */}
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  placeholder=""
                                  id="lastNameinput"
                                  name="image"
                                  onChange={onFileChange}
                                />
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.image}
                                </label>
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                              <div className="mb-3">
                                <label for="firstNameinput" className="form-label">
                               Source
                                  <span className="form-validation-required">*</span>
                                </label>
                                <Select
                                  id="ForminputState"
                                  className="form-select1"
                                  options={SingleOptions}
                                  value={selectedsource}
                                  onChange={setSelectedsource}></Select>
                                 <label
                                  style={{ color: "red", marginTop: "3px" }}>
                                  {formerror.selectedsource}
                                </label>
                              </div>
                            </div> */}
                            {/* <div className="col-md-4">
                              <div className="mb-3">
                                <label for="firstNameinput" className="form-label">
                                  Role
                                </label>
                                <input
                                  className="form-select1"
                                  data-trigger
                                  value="Loyalty User"
                                />
                              </div>
                            </div> */}
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Default Language
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <Select
                                  id="ForminputState"
                                  className="form-select1"
                                  options={be_languagefull}
                                  value={selectedlanguage}
                                  onChange={setSelectedlanguage}
                                ></Select>
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {/* {formerror2.selectedlanguage} */}
                                  {formerror.selectedlanguage}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Category
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <Select
                                  options={category}
                                  value={selectedcategory}
                                  onChange={setSelectedCategory}
                                  className="form-control"
                                  data-choices
                                  name="choices-single-default"
                                  id="choices-single-default"
                                />
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {/* {formerror4.selectedcategory} */}
                                  {formerror.selectedcategory}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Country{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <Select
                                  className="form-control"
                                  data-choices
                                  name="choices-single-default"
                                  id="choices-single-default"
                                  options={country}
                                  value={countrydata}
                                  onChange={(val) => {
                                    handleChangeselectcountry(val);
                                  }}
                                ></Select>
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {/* {formerror3.country} */}
                                  {formerror.countrydata}
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="phonenumberInput"
                                  className="form-label"
                                >
                                  Phone Number{" "}
                                  <span class="form-validation-required">
                                    *
                                  </span>
                                  {/* <div class="info-tag-new">i</div>
                        <span class="tooltiptext-new">The primary phone number for the hotel</span> */}
                                </label>
                                <div className="input-group" data-input-flag>
                                  {loyaluser.calling_code === null ? (
                                    <button
                                      class="btn btn-light border calling-btn"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <select
                                        value={loyaluser.calling_code}
                                        onChange={handlechange}
                                        name="calling_code"
                                        className="calling-select"
                                      >
                                        {calling.map((calling_code, index) => (
                                          <option
                                            key={index}
                                            value={calling_code}
                                          >
                                            {calling_code}
                                          </option>
                                        ))}
                                      </select>
                                    </button>
                                  ) : (
                                    <button
                                      class="btn btn-light border calling-btn"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      {loyaluser.calling_code}
                                    </button>
                                  )}
                                  <input
                                    type="number"
                                    class="form-control rounded-end flag-input"
                                    placeholder="Enter number"
                                    value={loyaluser.phone_no}
                                    onChange={handlechange}
                                    name="phone_no"
                                    onFocus={(e) =>
                                      e.target.addEventListener(
                                        "wheel",
                                        function (e) {
                                          e.preventDefault();
                                        },
                                        { passive: false }
                                      )
                                    }
                                  />
                                </div>
                                <label style={{ color: "red" }}>
                                  {/* {formerror3.phone_no} */}
                                  {formerror.phone_no}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Subscribed{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <select
                                  id="ForminputState"
                                  className="form-select"
                                  value={subscribe}
                                  onChange={(e) => setSubscribe(e.target.value)}
                                >
                                  <option selected>Choose...</option>
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option>
                                </select>
                                <label style={{ color: "red" }}>
                                  {/* {formerror5.subscribe} */}
                                  {formerror.subscribe}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Status{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <select
                                  id="ForminputState"
                                  className="form-select"
                                  value={status}
                                  onChange={(e) => setStatus(e.target.value)}
                                >
                                  <option selected>Choose...</option>
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option>
                                </select>
                                <label style={{ color: "red" }}>
                                  {/* {formerror5.status} */}
                                  {formerror.status}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Loyaltymeberadd;
