import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../../Common/Footer";
import { Button, Modal, ModalBody } from "reactstrap";
import Roomheader from "../../Common/Roomheader";
import Config from "../../Config";
import Commonimagepath from "../../Commonimagepath";
import axios from "axios";
import Alerts from "../../Alerts";
import { FaArrowTurnDown, FaArrowTurnUp } from "react-icons/fa6";
import ReactPaginate from "react-paginate";
import { UserContext } from "../../Contexts/UserContext";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Toastmodal from "../../Common/Toastmodal";

function Roomphotos() {
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);

  console.log(hotel_selectid);
  const [list, setList] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [page, setPage] = useState(1);
  const [pageindex, setPageindex] = useState(1);
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [changeddata, setChangeddata] = useState([]);

  const [image, setImage] = useState([]);
  const [formerror, setError] = useState({});

  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);

  const [ids, setIds] = useState("");
  const [hotelid, setHotelid] = useState("");
  const [imageActive, setImageActive] = useState([]);
  // const [imageActive, setImageActive] = useState(false);
  const [visiblePhotos, setVisiblePhotos] = useState(4);
  const [removeRoom, setRemoveRoom] = useState([]);
  const [buttondis, setButtondis] = useState(false);

  function tog_animationFlip(id, hotelid) {
    setError({});
    setmodal_animationFlip(!modal_animationFlip);
    setIds(id);
    setHotelid(hotelid);
  }

  const [showFullData, setShowFullData] = useState([]);
  const [photoData, setPhotodata] = useState([]);

  const handleDropdownClickhotel = (i) => {
    // Toggle the showFullData state for the corresponding item
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  // const removeImage1 = (roomId, rowIndex, photoIndex, pic, i) => {
  //   let newva = [...removeRoom];
  //   let news = newva[rowIndex] ? [...newva[rowIndex].remove] : [];

  //   news.push(pic);
  //   newva[rowIndex] = {
  //     remove: news,
  //   };

  //   setRemoveRoom(newva);
  //   const updatedList = [...list];
  //   const roomToUpdate = updatedList[i].rooms[rowIndex];
  //   if (roomToUpdate) {
  //     const updatedPhotos = [...roomToUpdate.photos];
  //     updatedPhotos.splice(photoIndex, 1);

  //     roomToUpdate.photos = updatedPhotos;
  //     setList(updatedList);
  //   }
  // };
  const removeImage1 = (roomId, rowIndex, photoIndex, pic, i) => {
    let newva = [...removeRoom];
    let news = newva[rowIndex] ? [...newva[rowIndex].remove] : [];

    news.push(pic);
    newva[rowIndex] = {
      remove: news,
    };
    setRemoveRoom(newva);

    const updatedList = [...list];
    const roomToUpdate = updatedList[i].rooms[rowIndex];

    if (roomToUpdate) {
      const updatedPhotos = [...roomToUpdate.photos];
      updatedPhotos.splice(photoIndex, 1);
      roomToUpdate.photos = updatedPhotos;

      // Adding removed photo to the room's removephoto field
      roomToUpdate.removephoto = roomToUpdate.removephoto
        ? [...roomToUpdate.removephoto, pic]
        : [pic];

      setList(updatedList);

      let changedDataCopy = [...changeddata];
      changedDataCopy[i] = {
        ...changedDataCopy[i],
        hotel_id: updatedList[i].hotel_id,
        rooms: updatedList[i].rooms.map((room, roomIndex) => {
          if (roomIndex === rowIndex) {
            return {
              ...room,
              removephoto: roomToUpdate.removephoto,
            };
          } else {
            return {
              ...null,
            };
          }
        }),
      };
      setChangeddata(changedDataCopy);
    }
  };

  const handleChange = (i, index, e) => {
    let name = e.target.name;
    let value = e.target.value;
    let newval = [...list];
    list[i]["rooms"][index][name] = value;
    setList(newval);

    let changedDataCopy = [...changeddata];

    changedDataCopy[i] = {
      ...changedDataCopy[i],
      hotel_id: newval[i].hotel_id,
      rooms: newval[i]["rooms"].map((room, roomIndex) => {
        if (roomIndex === index) {
          return {
            ...room,
            room_id: room.room_id,
            [name]: value,
          };
        } else {
          return room;
        }
      }),
    };
    setChangeddata(changedDataCopy);
  };

  const toggleImageActive = (i, index) => {
    const updatedImageActive = [...imageActive];
    updatedImageActive[i].rooms[index] = !updatedImageActive[i].rooms[index];
    setImageActive(updatedImageActive);
    if (!updatedImageActive[i].rooms[index]) {
      setVisiblePhotos(4);
    }
  };

  const onFileChange = (e) => {
    setButtondis(false);
    setImage(e.target.files);
  };

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}hotel/room-photo/list?page=${currentPage}`,
      {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );

    const data = await res.json();
    return data.data;
  };

  const handlePageClick = async (data) => {
    if (data === 1) {
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
      setList(commentsFormServer);
    } else {
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
      setList(commentsFormServer);
    }
  };

  const fetchPost = async () => {
    if (id) {
      const response = await fetch(
        `${Config.apiurl}hotel/room-photo/list?page=1`,
        {
          method: "POST",
          body: JSON.stringify({
            hotels: id,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();

        setList(data.data);
        setPaginate(data.last_page);
      } else {
        console.log("error");
      }
    }
  };

  const imageUpload = async (e) => {
    e.preventDefault();
    setButtondis(true);

    let isFormValid = true;
    let error = {};
    for (var i = 0; i < image.length; i++) {
      if (
        image[i].type === "image/jpeg" ||
        image[i].type === "image/jpg" ||
        image[i].type === "image/png" ||
        image[i].type === "image/webp" ||
        image[i].type === "image/avif"
      ) {
        console.log("");
      } else {
        isFormValid = false;
        error.image = "Only support jpg, jpeg, png, webp image!";
      }
    }
    setError(error);

    if (isFormValid === true) {
      let formdata = new FormData();
      formdata.append("room_id", ids);
      formdata.append("hotel", hotelid);

      for (let i = 0; i < image.length; i++) {
        formdata.append(`images[${i}]`, image[i]);
      }

      axios
        .post(`${Config.apiurl}hotel/room/photo/add`, formdata, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((res) => {
          if (res.status_code !== "200" || !res) {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(false);
          } else {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(false);

            setmodal_animationFlip(!modal_animationFlip);
            fetchPost();
            setButtondis(false);
          }
        });
    }
  };

  const PostData = async (e) => {
    e.preventDefault();
    if (changeddata?.length > 0) {
      let formdata = new FormData();
      for (let i = 0; i < changeddata.length; i++) {
        if (changeddata[i] !== undefined) {
          formdata.append(`hotels[${i}][hotel_id]`, changeddata[i].hotel_id);
          for (let j = 0; j < changeddata[i].rooms.length; j++) {
            if (changeddata[i].rooms[j].room_id) {
              formdata.append(
                `hotels[${i}][room][${j}][room_id]`,
                changeddata[i].rooms[j].room_id
              );
              if (changeddata[i].rooms[j].url_3d) {
                formdata.append(
                  `hotels[${i}][room][${j}][url_3d]`,
                  changeddata[i].rooms[j].url_3d
                );
              } else {
                formdata.append(`hotels[${i}][room][${j}][url_3d]`, "");
              }
              if (changeddata[i].rooms[j].url_video) {
                formdata.append(
                  `hotels[${i}][room][${j}][url_video]`,
                  changeddata[i].rooms[j].url_video
                );
              } else {
                formdata.append(`hotels[${i}][room][${j}][url_video]`, "");
              }
            }

            for (
              let k = 0;
              k < changeddata[i].rooms[j]?.removephoto?.length;
              k++
            ) {
              formdata.append(
                `hotels[${i}][room][${j}][removed_images][${k}]`,
                changeddata[i].rooms[j].removephoto[k]
              );
            }
          }
        }
      }

      axios
        .post(`${Config.apiurl}hotel/room-photo/bulk-update`, formdata, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.status_code !== "200" || !res) {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(false);
          } else {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(false);
            fetchPost();
          }
        });
    } else {
      setToastmodal(true);
      setToastmessage("You have made no changes to save");
      setToasttype("success");
      setToastlarge(true);
    }
  };

  useEffect(() => {
    fetchPost();
  }, [id]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    if (list.length > 0) {
      const initialState = list.map((item, index) => ({
        hotel: index === 0,
        rooms: Array(item.rooms.length).fill(false),
      }));
      setShowFullData(initialState);
    }
  }, [list.length]);

  useEffect(() => {
    if (list.length > 0) {
      const initialState = list.map((item, index) => ({
        rooms: Array(item.rooms.length).fill(false),
      }));
      setPhotodata(initialState);
    }
  }, [list.length]);

  useEffect(() => {
    if (list.length > 0) {
      const initialState = list.map((item, index) => ({
        rooms: Array(item.rooms.length).fill(false),
      }));
      setImageActive(initialState);
    }
  }, [list.length]);

  console.log(id);
  console.log(imageActive);
  return (
    <>
      <div id="layout-wrapper">
        <Roomheader />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box-in d-sm-flex align-items-center justify-content-between">
                    <Link
                      type="button"
                      to="#"
                      className="btn db-save-button mb-2"
                      onClick={PostData}
                    >
                      Update
                    </Link>
                  </div>
                </div>
              </div>

              <div className="row project-wrapper">
                <div className="row dashboard-top-tab-style">
                  <div className="col-xxl-12 dashboard-main-style">
                    <div className="card">
                      <div className="card-body">
                        <div>
                          {/* <ul className="nav nav-tabs nav-tabs-custom nav-success mb-3" role="tablist">
                        </ul> */}

                          <div className="">
                            {list?.map((li, i) => {
                              return (
                                <>
                                  {list?.length > 0 ? (
                                    <>
                                      <div
                                        className={
                                          list.length > 1
                                            ? "row hotel_single_list"
                                            : "row"
                                        }
                                      >
                                        {list.length > 1 ? (
                                          <div className="hotel_service_display_12">
                                            <div className="hk_service_all_hotel">
                                              <span className="">
                                                {pageindex === 1
                                                  ? i + 1
                                                  : (pageindex - 1) * 10 +
                                                    i +
                                                    1}
                                                .
                                              </span>
                                              <span
                                                style={{ marginLeft: "10px" }}
                                              >
                                                {li.hotel_name}
                                              </span>
                                            </div>
                                            {showFullData[i]?.hotel === true ? (
                                              <span
                                                className="db-modify_toggle_btn"
                                                onClick={() =>
                                                  handleDropdownClickhotel(i)
                                                }
                                              >
                                                <span
                                                  className="bx bxs-chevron-up"
                                                  style={{ fontSize: "20px" }}
                                                ></span>
                                              </span>
                                            ) : (
                                              <span
                                                className="db-modify_toggle_btn"
                                                onClick={() =>
                                                  handleDropdownClickhotel(i)
                                                }
                                              >
                                                <span
                                                  className="bx bxs-chevron-down"
                                                  style={{ fontSize: "20px" }}
                                                ></span>
                                              </span>
                                            )}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <table
                                          id="fixed-header orderTable"
                                          className={`table table-nowrap align-middle ${
                                            showFullData[i]?.hotel === true
                                              ? ""
                                              : "d-none"
                                          }`}
                                        >
                                          <thead className="text-muted table-light">
                                            <tr className="text-uppercase">
                                              <th
                                                scope="col"
                                                style={{ width: "25px" }}
                                              ></th>
                                              <th
                                                className=""
                                                data-sort="status"
                                              >
                                                SL No
                                              </th>
                                              <th
                                                className=""
                                                data-sort="customer_name"
                                              >
                                                Room Name
                                              </th>
                                              <th
                                                className=""
                                                data-sort="status"
                                              >
                                                Photos
                                              </th>
                                              <th
                                                className=""
                                                data-sort="status"
                                              ></th>
                                              <th
                                                className=""
                                                data-sort="status"
                                              ></th>
                                            </tr>
                                          </thead>
                                          <tbody className="list form-check-all">
                                            {li.rooms.map((user, index) => {
                                              return (
                                                <>
                                                  <tr
                                                    key={index}
                                                    className="row_bord"
                                                  >
                                                    <td></td>
                                                    <td className="id">
                                                      {pageindex === 1
                                                        ? index + 1
                                                        : (pageindex - 1) * 10 +
                                                          index +
                                                          1}
                                                    </td>
                                                    <td className="customer_name">
                                                      {user.room_name}
                                                    </td>
                                                    <td className="db-photosList">
                                                      <div className="db-photos-list-container">
                                                        <ul className="db-photos-list">
                                                          {user.photos
                                                            .slice(
                                                              0,
                                                              visiblePhotos
                                                            )
                                                            .map(
                                                              (pic, indx) => (
                                                                <li key={i}>
                                                                  <span
                                                                    className="list-item-delete-new"
                                                                    onClick={() =>
                                                                      removeImage1(
                                                                        user.room_id,
                                                                        index,
                                                                        indx,
                                                                        pic.thumb_image,
                                                                        i
                                                                      )
                                                                    }
                                                                  >
                                                                    X
                                                                  </span>
                                                                  <img
                                                                    src={`${Commonimagepath.apiurl}${pic.thumb_image}`}
                                                                    alt=""
                                                                  />
                                                                </li>
                                                              )
                                                            )}
                                                        </ul>
                                                        {user.photos.length >
                                                          visiblePhotos &&
                                                          !imageActive[i]
                                                            ?.rooms[index] && (
                                                            <button
                                                              className="btn next"
                                                              style={{
                                                                color:
                                                                  "rgb(51 ,190,51)",
                                                              }}
                                                              onClick={() =>
                                                                toggleImageActive(
                                                                  i,
                                                                  index
                                                                )
                                                              }
                                                              // onClick={(e) =>
                                                              //   setImageActive(
                                                              //     !imageActive
                                                              //   )
                                                              // }
                                                            >
                                                              <FaArrowTurnDown />
                                                            </button>
                                                          )}
                                                        {imageActive[i]?.rooms[
                                                          index
                                                        ] && (
                                                          <>
                                                            <ul className="db-photos-list">
                                                              {user.photos
                                                                .slice(
                                                                  visiblePhotos
                                                                )
                                                                .map(
                                                                  (
                                                                    pic,
                                                                    indx
                                                                  ) => (
                                                                    <li
                                                                      key={indx}
                                                                    >
                                                                      <span
                                                                        className="list-item-delete-new"
                                                                        onClick={() =>
                                                                          removeImage1(
                                                                            user.room_id,
                                                                            index,
                                                                            indx +
                                                                              visiblePhotos,
                                                                            pic.thumb_image,
                                                                            i
                                                                          )
                                                                        }
                                                                      >
                                                                        X
                                                                      </span>
                                                                      <img
                                                                        src={`${Commonimagepath.apiurl}${pic.thumb_image}`}
                                                                        alt=""
                                                                      />
                                                                    </li>
                                                                  )
                                                                )}
                                                            </ul>
                                                            <button
                                                              className="btn next"
                                                              style={{
                                                                color:
                                                                  "rgb(51 ,190,51)",
                                                              }}
                                                              onClick={() =>
                                                                toggleImageActive(
                                                                  i,
                                                                  index
                                                                )
                                                              }
                                                              // onClick={(e) =>
                                                              //   setImageActive(
                                                              //     !imageActive
                                                              //   )
                                                              // }
                                                            >
                                                              <FaArrowTurnUp />
                                                            </button>
                                                          </>
                                                        )}
                                                      </div>
                                                    </td>
                                                    {/* {imageActive[i] ===
                                                    false ? ( */}
                                                    <td className="db-photos-list_td">
                                                      <div className="">
                                                        <div className="mb-2">
                                                          <div className="input-group">
                                                            <label
                                                              for="firstNameinput"
                                                              className="input-group-text input-joint-color"
                                                              style={{
                                                                width: "90px",
                                                                height: "30px",
                                                              }}
                                                            >
                                                              URL 360&#176;
                                                            </label>
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              name="url_3d"
                                                              value={
                                                                user.url_3d
                                                              }
                                                              onChange={(e) =>
                                                                handleChange(
                                                                  i,
                                                                  index,
                                                                  e
                                                                )
                                                              }
                                                              style={{
                                                                height: "30px",
                                                              }}
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="">
                                                        <div className="mb-2">
                                                          <div className="input-group">
                                                            <label
                                                              for="firstNameinput"
                                                              className="input-group-text input-joint-color"
                                                              style={{
                                                                height: "30px",
                                                              }}
                                                            >
                                                              URL Video
                                                            </label>
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              name="url_video"
                                                              value={
                                                                user.url_video
                                                              }
                                                              onChange={(e) =>
                                                                handleChange(
                                                                  i,
                                                                  index,
                                                                  e
                                                                )
                                                              }
                                                              style={{
                                                                height: "30px",
                                                              }}
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    {/* ) : (
                                                      ""
                                                    )} */}
                                                    <td className="status"></td>
                                                    <td>
                                                      <div className="db-photo-add-more">
                                                        <Link
                                                          className="db-photo-add-btn next"
                                                          to=""
                                                          onClick={() =>
                                                            tog_animationFlip(
                                                              user.room_id,
                                                              li.hotel_id
                                                            )
                                                          }
                                                        >
                                                          +
                                                        </Link>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr
                                                    style={{
                                                      borderBottom:
                                                        "1px solid #f1f1f1",
                                                    }}
                                                  >
                                                    {/* {imageActive[i] === true ? (
                                                      <>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="room_photo_td">
                                                          <div className="col-lg-5 mr-2">
                                                            <div className="input-group">
                                                              <label
                                                                for="firstNameinput"
                                                                className="input-group-text input-joint-color"
                                                                style={{
                                                                  width: "90px",
                                                                  height:
                                                                    "30px",
                                                                }}
                                                              >
                                                                URL 360&#176;
                                                              </label>
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                name="url_3d"
                                                                value={
                                                                  user.url_3d
                                                                }
                                                                onChange={(e) =>
                                                                  handleChange(
                                                                    i,
                                                                    index,
                                                                    e
                                                                  )
                                                                }
                                                                style={{
                                                                  height:
                                                                    "30px",
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                          <br></br>
                                                          <div className="col-lg-5">
                                                            <div className="input-group">
                                                              <label
                                                                for="firstNameinput"
                                                                className="input-group-text input-joint-color"
                                                                style={{
                                                                  height:
                                                                    "30px",
                                                                }}
                                                              >
                                                                URL Video
                                                              </label>
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                name="url_video"
                                                                value={
                                                                  user.url_video
                                                                }
                                                                onChange={(e) =>
                                                                  handleChange(
                                                                    i,
                                                                    index,
                                                                    e
                                                                  )
                                                                }
                                                                style={{
                                                                  height:
                                                                    "30px",
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                        </td>
                                                      </>
                                                    ) : (
                                                      ""
                                                    )} */}
                                                  </tr>
                                                </>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })}

                            {paginate > 1 ? (
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={paginate}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={handlePageClick}
                                containerClassName={
                                  "pagination justify-content-end"
                                }
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Modal
                      id="flipModal"
                      isOpen={modal_animationFlip}
                      toggle={() => {
                        tog_animationFlip();
                      }}
                      modalClassName="flip"
                      centered
                      style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
                    >
                      <ModalBody className="modal-body p-5 text-center">
                        <lord-icon
                          src="https://cdn.lordicon.com/gsqxdxog.json"
                          trigger="loop"
                          colors="primary:#405189,secondary:#f06548"
                          style={{ width: "90px", height: "90px" }}
                        ></lord-icon>

                        <div className="mt-4 text-center">
                          <h4 style={{ marginBottom: "20px" }}>Upload image</h4>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              for="lastNameinput"
                              className="form-label"
                              style={{ float: "left" }}
                            >
                              Image
                              <span className="form-validation-required">
                                *
                              </span>
                            </label>
                            <input
                              multiple
                              type="file"
                              className="form-control"
                              placeholder=""
                              id="lastNameinput"
                              onChange={onFileChange}
                            />
                          </div>
                          <label style={{ color: "red" }}>
                            {formerror.image}
                          </label>
                        </div>
                        <button
                          className="btn btn-link fw-medium text-decoration-none"
                          onClick={() => {
                            tog_animationFlip();
                          }}
                          style={{ color: "#FF7979" }}
                        >
                          {" "}
                          <i className="ri-close-line me-1 align-middle"></i>
                          Close{" "}
                        </button>
                        {buttondis === false ? (
                          <Button
                            onClick={imageUpload}
                            style={{
                              backgroundColor: "rgb(51 ,190,51)",
                              border: "rgb(51 ,190,51)",
                            }}
                          >
                            {" "}
                            Upload{" "}
                          </Button>
                        ) : (
                          <Button
                            onClick={imageUpload}
                            style={{
                              backgroundColor: "rgb(51 ,190,51)",
                              border: "rgb(51 ,190,51)",
                            }}
                            disabled
                          >
                            {" "}
                            Upload{" "}
                          </Button>
                        )}
                      </ModalBody>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />

        {toastmodal === true ? (
          <Toastmodal
            setToast={setToastmodal}
            message={toastmessage}
            type={toasttype}
            large={toastlarge}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Roomphotos;
