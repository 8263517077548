import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Config from "../Config";
import Alerts from "../Alerts";
import Loading from "../Loading";
import Cookies from "js-cookie";
import { UserContext } from "../Contexts/UserContext";
function Verify() {
  let navigate = useNavigate();

  const location = useLocation();
  const exampleParameter = location.state || {};
  const { updateHotel_select, hotel_select, languageid, updatelanguage } =
    useContext(UserContext);

  console.log(exampleParameter);
  const email = localStorage.getItem("email");
  const pass = localStorage.getItem("pass");

  const [otp, setOtp] = useState("");
  const [alert, setAlert] = useState(false);
  const [formerror, setError] = useState({});
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [languagedata, setLanguagedata] = useState([]);
  const [loading, setLoading] = useState(false);

  var arr = email.split("@");
  const v1 =
    arr[0][0] +
    arr[0][1] +
    "*".repeat(arr[0].length - 2) +
    "@" +
    arr[1][0] +
    "*".repeat(arr[1].length - 2) +
    arr[1][arr[1].length - 1];

  const validate1 = (values) => {
    const errors = {};
    if (!values) {
      errors.otp = "Otp is required!";
    }
    return errors;
  };

  const getinfo = async (e) => {
    if (languageid === null || undefined) {
      const res = await fetch(`${Config.apiurl}get-page-contents/verify_otp`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await res.json();
      if (data.status === 404 || !data) {
        console.log("error");
      } else {
        setLanguagedata(data.data);
        setLoading(true);
      }
    } else {
      const res = await fetch(`${Config.apiurl}get-page-contents/verify_otp`, {
        method: "POST",
        body: JSON.stringify({
          language: languageid,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await res.json();
      if (data.status === 404 || !data) {
        console.log("error");
      } else {
        setLanguagedata(data.data);
        setLoading(true);
      }
    }
  };

  const Verify = async (e) => {
    e.preventDefault();
    const ress1 = validate1(otp);

    setError(ress1);

    if (exampleParameter === "forgot") {
      if (!ress1.otp) {
        const res = await fetch(`${Config.apiurl}forgot-password/verify_otp`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            otp: otp,
          }),
        });
        const data1 = await res.json();
        if (data1.token) {
          localStorage.setItem("user_name", data1.user.name);
          localStorage.setItem("user_type", data1.user.user_type);
          localStorage.removeItem("pass");
          let token = data1.token;
          localStorage.setItem("token", token);
          localStorage.setItem("refreshtoken", data1.refresh_token);

          // setTokenSegmentsInCookies(token, 1500);

          localStorage.removeItem("selected-hotel-id");
          localStorage.removeItem("selected-hotel");

          setAlert(true);

          setAlertmessage(data1.message);

          setAlerttype("success");

          setTimeout(() => navigate("/resetpassword"), 4000);
        } else {
          setAlertmessage(data1.message);

          setAlerttype("error");
        }
      }
    } else {
      if (!ress1.otp) {
        const res = await fetch(`${Config.apiurl}admin/verify_otp`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            otp: otp,
          }),
        });
        const data1 = await res.json();
        console.log(data1);
        if (data1.token) {
          localStorage.setItem("user_name", data1.user.name);
          localStorage.setItem("user_type", data1.user.user_type);
          localStorage.removeItem("pass");

          let token = data1.token;
          localStorage.setItem("token", token);
          localStorage.setItem("refreshtoken", data1.refresh_token);

          // setTokenSegmentsInCookies(token, 1500);

          localStorage.removeItem("selected-hotel-id");
          localStorage.removeItem("selected-hotel");

          setAlert(true);
          if (data1.message === "User login successfully") {
            setAlertmessage(
              !languagedata.lang_array || languagedata.lang_array.length === 0
                ? languagedata.en.login_success
                : languagedata.lang_array.login_success
                  ? languagedata.lang_array.login_success
                  : languagedata.en.login_success
            );
          }
          setAlerttype("success");

          setTimeout(() => navigate("/dashboard"), 4000);
        } else {
          setAlert(true);
          if (data1.message === "error" || data1.message === "Invalid") {
            setAlertmessage(
              !languagedata.lang_array || languagedata.lang_array.length === 0
                ? languagedata.en.error
                : languagedata.lang_array.error
                  ? languagedata.lang_array.error
                  : languagedata.en.error
            );
          } else if (data1.message === "The Time Expired") {
            setAlertmessage(
              !languagedata.lang_array || languagedata.lang_array.length === 0
                ? languagedata.en.time_expired
                : languagedata.lang_array.time_expired
                  ? languagedata.lang_array.time_expired
                  : languagedata.en.time_expired
            );
          } else {
            setAlertmessage(
              !languagedata.lang_array || languagedata.lang_array.length === 0
                ? languagedata.en.invalid
                : languagedata.lang_array.invalid
                  ? languagedata.lang_array.invalid
                  : languagedata.en.invalid
            );
          }
          setAlerttype("error");
        }
      }
    }
  };

  function splitStringIntoSegments(str, segmentSize) {
    const segments = [];
    for (let i = 0; i < str.length; i += segmentSize) {
      segments.push(str.slice(i, i + segmentSize));
    }
    return segments;
  }

  // Function to set token segments in cookies
  function setTokenSegmentsInCookies(token, segmentSize) {
    const segments = splitStringIntoSegments(token, segmentSize);

    segments.forEach((segment, index) => {
      Cookies.set(`tokenSegment${index}`, segment);
    });
    Cookies.set("size", segments.length);
  }

  useEffect(() => {
    getinfo();
  }, [languageid]);

  const Resend = async (e) => {
    const res = await fetch(`${Config.apiurl}resend-otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: pass,
      }),
    });
    const data = await res.json();
    if (data.status !== 200 || !data) {
      console.log("error");
    } else {
      setAlert(true);
      setAlertmessage(data.message);
      setAlerttype("success");
    }
  };

  return (
    <>
      {loading === false ? (
        <Loading />
      ) : (
        <div className="wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="login-main">
                <div className="login-box-style">
                  <div className="login-box">
                    <div className="login-inner-box">
                      {alert ? (
                        <Alerts type={alerttype} message={alertmessage} />
                      ) : (
                        ""
                      )}
                      <div className="login-logo">
                        {/* <img src="assets/images/small-logo.png" alt="" /> */}
                        <img src="/images/IconDR.png" alt="" />
                      </div>
                      {exampleParameter === "forgot" ? (
                        <div className="login-content">
                          <h2>Forgot Password Verification</h2>
                        </div>
                      ) : (
                        <div className="login-content">
                          <h2>
                            {!languagedata.lang_array ||
                            languagedata.lang_array.length === 0
                              ? languagedata.en.verify_title
                              : languagedata.lang_array.verify_title
                                ? languagedata.lang_array.verify_title
                                : languagedata.en.verify_title}
                          </h2>
                        </div>
                      )}

                      <div className="verify-content">
                        <p>
                          {!languagedata.lang_array ||
                          languagedata.lang_array.length === 0
                            ? languagedata.en.send_code_content
                            : languagedata.lang_array.send_code_content
                              ? languagedata.lang_array.send_code_content
                              : languagedata.en.send_code_content}
                          <br />
                          {v1}
                        </p>
                        <p>
                          {!languagedata.lang_array ||
                          languagedata.lang_array.length === 0
                            ? languagedata.en.pls_enter_code_content
                            : languagedata.lang_array.pls_enter_code_content
                              ? languagedata.lang_array.pls_enter_code_content
                              : languagedata.en.pls_enter_code_content}
                        </p>
                      </div>
                      <div className="login-form-field">
                        <div className="login-form">
                          <input
                            type="text"
                            className="verify-text-field"
                            placeholder={
                              !languagedata.lang_array ||
                              languagedata.lang_array.length === 0
                                ? languagedata.en.code_placeholder
                                : languagedata.lang_array.code_placeholder
                                  ? languagedata.lang_array.code_placeholder
                                  : languagedata.en.code_placeholder
                            }
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                          />
                          <label style={{ color: "red" }}>
                            {formerror.otp}
                          </label>
                        </div>
                        <div className="login-verify-section">
                          <p onClick={Resend}>
                            {!languagedata.lang_array ||
                            languagedata.lang_array.length === 0
                              ? languagedata.en.resend
                              : languagedata.lang_array.resend
                                ? languagedata.lang_array.resend
                                : languagedata.en.resend}
                          </p>
                        </div>

                        <Link
                          style={{ color: "white" }}
                          to="#"
                          onClick={Verify}
                        >
                          <div className="login-submit dr_login_btn">
                            {!languagedata.lang_array ||
                            languagedata.lang_array.length === 0
                              ? languagedata.en.go_button
                              : languagedata.lang_array.go_button
                                ? languagedata.lang_array.go_button
                                : languagedata.en.go_button}{" "}
                          </div>
                        </Link>

                        <div className="login-bottom">
                          <Link to="/" className="login-bottom-back">
                            {!languagedata.lang_array ||
                            languagedata.lang_array.length === 0
                              ? languagedata.en.return_login
                              : languagedata.lang_array.return_login
                                ? languagedata.lang_array.return_login
                                : languagedata.en.return_login}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Verify;
