import Commonheader from "./Commonheader";
import Footer from "./Footer";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Date from "../Common/Date";
import HotelOverviews from "./HotelDashboard/HotelOverviews";
import { useAuthHandling } from "./useAuthHandling";
import Config from "../Config";
import { UserContext } from "../Contexts/UserContext";

function HotelDashboard() {
  let navigate = useNavigate();
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [list, setList] = useState([]);

  const token = localStorage.getItem("token");
  if (!token) {
    navigate("/");
  }

  const getData = async (e) => {
    const res = await fetch(`${Config.apiurl}reservation-list`, {
      method: "POST",
      body: JSON.stringify({
        hotels: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setList(data?.data[0]);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getData();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getData();
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    getData();
  }, [id]);

  function processDBValue(dbValue) {
    // Ensure dbValue is not null or undefined
    if (dbValue === null || dbValue === undefined) {
      return "";
    }
    dbValue = dbValue.toString();
    const numericValue = parseFloat(dbValue.replace(/,/g, ""));

    const result = numericValue !== 0 ? numericValue.toFixed(2) : dbValue;

    return result.endsWith(".00") ? result.slice(0, -3) : result;
  }

  return (
    <>
      <div id="layout-wrapper">
        <Commonheader />

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row project-wrapper">
                <div className="row dashboard-top-tab-style">
                  <div className="col-xxl-9 dashboard-main-style">
                    <div className="col-xxl-8">
                      <HotelOverviews />
                    </div>
                  </div>
                  <div className="col-xxl-3 dashboard-main-style">
                    <div className="col-sm-auto">{/* <Date /> */}</div>
                  </div>
                  <div class="col-xxl-9 dashboard-main-style">
                    <div class="row">
                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #A48BCF" }}
                          >
                            <div class="align-items-end justify-content-between">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  INITIAL RESERVATIONS/{" "}
                                  <span className="rev-plus">+</span> REVENUE
                                </p>
                                <div class="overview-today-sec">
                                  <p class="dashboard-date-style">Today</p>
                                  <div class="flex-shrink-0 icon_body dashboard-date-icon">
                                    <span class="avatar-title bg-soft-danger rounded fs-3">
                                      <i class="bx bx-calendar-x text-danger"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="align-items-end justify-content-between mt-2">
                              <h4 class="fs-20 fw-semibold ff-secondary mb-2">
                                {list.total_reservation_count}
                                <span class="value-specify-span">
                                  Reservations
                                </span>
                                / <span className="rev-plus">+</span> AED{" "}
                                {processDBValue(list.total_reservation_rate)}{" "}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #E06F95" }}
                          >
                            <div class="align-items-end justify-content-between">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  CANCELLATIONS /
                                  <span className="rev-minus"> -</span> REVENUE
                                </p>
                                <div class="overview-today-sec">
                                  <p class="dashboard-date-style">Today</p>
                                  <div class="flex-shrink-0 icon_body dashboard-date-icon">
                                    <span class="avatar-title bg-soft-danger rounded fs-3">
                                      <i class="bx bx-calendar-x text-danger"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="align-items-end justify-content-between mt-2">
                              <h4 class="fs-20 fw-semibold ff-secondary mb-2">
                                {list.cancelled_reservation_count}
                                <span class="value-specify-span">
                                  Cancellations
                                </span>
                                / <span className="rev-minus"> -</span> AED{" "}
                                {processDBValue(
                                  list.cancelled_reservation_rate
                                )}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #E5A43D" }}
                          >
                            <div class="align-items-end justify-content-between">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  ROOM NIGHTS /ARR
                                </p>
                                <div class="overview-today-sec">
                                  <p class="dashboard-date-style">Today</p>
                                  <div class="flex-shrink-0 icon_body dashboard-date-icon">
                                    <span class="avatar-title bg-soft-info rounded fs-3">
                                      <i class="bx bxs-edit text-info"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="align-items-end justify-content-between mt-2">
                              <h4 class="fs-20 fw-semibold ff-secondary mb-2">
                                {list.original_room_nights}
                                <span class="value-specify-span">
                                  Room Nights
                                </span>
                                /AED {processDBValue(list.average_room_rate)}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #EBCF54" }}
                          >
                            <div class="align-items-end justify-content-between">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  UPSELLS /<span className="rev-plus">+</span>{" "}
                                  REVENUE
                                </p>
                                <div class="overview-today-sec">
                                  <p class="dashboard-date-style">Today</p>
                                  <div class="flex-shrink-0 icon_body dashboard-date-icon">
                                    <span class="avatar-title bg-soft-success rounded fs-3">
                                      <i class="bx bxs-edit text-success"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="align-items-end justify-content-between mt-2">
                              <h4 class="fs-20 fw-semibold ff-secondary mb-2">
                                0<span class="value-specify-span">Upsells</span>
                                / <span className="rev-plus">+</span> AED 0
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #81C784" }}
                          >
                            <div class="align-items-end justify-content-between">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  ORIGINAL RESERVATIONS /
                                  <span className="rev-plus">+</span> REVENUE
                                </p>
                                <div class="overview-today-sec">
                                  <p class="dashboard-date-style">Today</p>
                                  <div class="flex-shrink-0 icon_body dashboard-date-icon">
                                    <span class="avatar-title bg-soft-success rounded fs-3">
                                      <i class="bx bxs-edit text-success"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="align-items-end justify-content-between mt-2">
                              <h4 class="fs-20 fw-semibold ff-secondary mb-2">
                                {list.original_reservation_count}
                                <span class="value-specify-span">
                                  Reservations
                                </span>
                                / <span className="rev-plus">+</span> AED{" "}
                                {processDBValue(list.original_reservation_rate)}{" "}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #95C3BF" }}
                          >
                            <div class="align-items-end justify-content-between">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  RETRIEVED RESERVATIONS /{" "}
                                  <span className="rev-plus">+</span> REVENUE
                                </p>
                                <div class="overview-today-sec">
                                  <p class="dashboard-date-style">Today</p>
                                  <div class="flex-shrink-0 icon_body dashboard-date-icon">
                                    <span class="avatar-title bg-soft-success rounded fs-3">
                                      <i class="bx bxs-edit text-success"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="align-items-end justify-content-between mt-2">
                              <h4 class="fs-20 fw-semibold ff-secondary mb-2">
                                0
                                <span class="value-specify-span">
                                  Reservations
                                </span>
                                / <span className="rev-plus">+</span> AED 0
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #8DB6D8" }}
                          >
                            <div class="align-items-end justify-content-between">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  DISPARITY /
                                  <span className="rev-minus"> -</span> REVENUE
                                </p>
                                <div class="overview-today-sec">
                                  <p class="dashboard-date-style">Today</p>
                                  <div class="flex-shrink-0 icon_body dashboard-date-icon">
                                    <span class="avatar-title bg-soft-danger rounded fs-3">
                                      <i class="bx bxs-edit text-danger"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="align-items-end justify-content-between mt-2">
                              <h4 class="fs-20 fw-semibold ff-secondary mb-2">
                                0
                                <span class="value-specify-span">
                                  Disparities
                                </span>
                                / <span className="rev-minus"> -</span> AED 0
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #BCBF64" }}
                          >
                            <div class="align-items-end justify-content-between">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  COMMISSION
                                </p>
                                <div class="overview-today-sec">
                                  <p class="dashboard-date-style">Today</p>
                                  <div class="flex-shrink-0 icon_body dashboard-date-icon">
                                    <span class="avatar-title bg-soft-info rounded fs-3">
                                      <i class="bx bxs-edit text-info"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="align-items-end justify-content-between mt-2">
                              <h4 class="fs-20 fw-semibold ff-secondary mb-2">
                                AED 0/ 0
                                {/* <span class="value-specify-span">Hotels</span> */}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #F0E687" }}
                          >
                            <div class="align-items-end justify-content-between">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  LOYALTY
                                </p>
                                <div class="overview-today-sec">
                                  <p class="dashboard-date-style">Today</p>
                                  <div class="flex-shrink-0 icon_body dashboard-date-icon">
                                    <span class="avatar-title bg-soft-danger rounded fs-3">
                                      <i class="bx bxs-edit text-danger"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex align-items-end justify-content-between mt-2">
                              <h4 class="fs-20 fw-semibold ff-secondary mb-2">
                                0
                              </h4>
                              <p class="box-expand-content">
                                <span class="loyalty-base">Base - 0</span> ,{" "}
                                <span class="loyalty-bronze">Bronze - 0</span> ,{" "}
                                <span class="loyalty-silver">Silver - 0</span> ,{" "}
                                <span class="loyalty-gold">Gold - 0</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-3 dashboard-main-style">
                    <div class="row">
                      <div class="col-xl-12 col-md-12">
                        <div class="card">
                          <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">
                              Latest News
                            </h4>
                          </div>
                          <div class="card-body">
                            <h5 class="news-head">
                              Discover the new “Price comparison and Parity”
                              module integrated in our Booking Engine
                            </h5>
                            <p class="news-content">
                              Display the prices of the main OTAs without the
                              need to leave your website. Read more..
                            </p>
                            <h5 class="news-head">
                              Discover the new “Price comparison and Parity”
                              module integrated in our Booking Engine
                            </h5>
                            <p class="news-content">
                              Display the prices of the main OTAs without the
                              need to leave your website. Read more..
                            </p>
                            <h5 class="news-head">
                              Discover the new “Price comparison and Parity”
                              module integrated in our Booking Engine
                            </h5>
                            <p class="news-content">
                              Display the prices of the main OTAs without the
                              need to leave your website. In addition, Read
                              more..
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
}

export default HotelDashboard;
