import React, { useEffect, useState } from "react";
import Footer from "../../../Common/Footer";
import Toastmodal from "../../../Common/Toastmodal";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import Loyaltyheader from "../../../Common/Loyaltyheader";
import Config from "../../../Config";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuthHandling } from "../../../Common/useAuthHandling";
import Commonimagepath from "../../../Commonimagepath";
import axios from "axios";
import moment from "moment";

function Loyaltymemberedit() {
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  let params = useParams();
  const [memberid, setMemberid] = useState(params.user_id);
  const [hotelid, setHotelid] = useState(params.hotel_id);

  let navigate = useNavigate();
  const [role, setRole] = useState([]);
  const [be_languagefull, setBe_languagefull] = useState([]);
  const [selectedMulti, setselectedMulti] = useState([]);

  const language = localStorage.getItem("be_language");
  const [formerror, setError] = useState({});
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [loyaluser, setLoyaluser] = useState([]);
  const [country, setCountry] = useState([]);
  const [countrydata, setCountrydata] = useState([]);
  const [calling, setCalling] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedcategory, setSelectedCategory] = useState([]);
  const [dob, setDob] = useState("");
  const [selectedlanguage, setSelectedlanguage] = useState([]);
  const [newlogo, setNewlogo] = useState("");

  const handleimageChange = (event) => {
    const selectedFile = event.target.files[0];
    setNewlogo(selectedFile);
  };

  const handlechange = (e) => {
    console.log(e.target.value);
    let name = e.target.name;
    let value = e.target.value;
    setLoyaluser({ ...loyaluser, [name]: value });
  };

  const handlechangeDOB = (selectedDates) => {
    setDob(selectedDates[0]);
  };
  const getfulllanguage = async (e) => {
    const res = await fetch(`${Config.apiurl}booking-engine-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200" || !data.data) {
        console.log("error");
      } else {
        setBe_languagefull(data.data);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getfulllanguage();
    } else {
      console.log("error");
    }
  };

  const getcountry = async (e) => {
    const res = await fetch(`${Config.apiurl}country-list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200" || !data.data) {
        console.log("error");
      } else {
        setCountry(data.data);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getcountry();
    } else {
      console.log("error");
    }
  };

  const getcategory = async (e) => {
    const res = await fetch(`${Config.apiurl}loyalty-clubs`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: hotelid,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code !== "200" || !data.data) {
        console.log("error");
      } else {
        setCategory(data.data);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getcategory();
    } else {
      console.log("error");
    }
  };

  function handleChangeselectcountry(selectedOption) {
    getcountrybasetime(selectedOption);
  }

  const getcountrybasetime = async (val) => {
    const res1 = await fetch(
      `${Config.apiurl}country/calling-code/${val.value}`,
      {
        method: "POST",
      }
    );
    const data2 = await res1.json();
    const data3 = data2.data;

    if (data2.status_code !== "200" || !data3) {
      console.log("error");
    } else {
      setCalling(data3);
    }
    setCountrydata(val);
    setLoyaluser({ ...loyaluser, ["calling_code"]: data3[0] });
  };

  const getList = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/loyalty/members/view`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: hotelid,
        member_id: memberid,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status !== 200 || !data.data) {
        console.log("error");
      } else {
        setLoyaluser(data.data.member);
        setCountrydata(data.data.country);
        setSelectedlanguage(data.data.language);
        setSelectedCategory(data.data.level);
        setDob(data.data.member.date_of_birth);
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getList();
    } else {
      console.log("error");
    }
  };

  const Update = async () => {
    let isFormValid = true;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    const errors = {};
    if (!loyaluser.name) {
      isFormValid = false;
      errors.name = "Name is required!";
    }
    if (!loyaluser.surname) {
      isFormValid = false;
      errors.surname = "Surname is required!";
    }
    if (!loyaluser.email) {
      isFormValid = false;

      errors.email = "Email is required!";
    } else if (!regex.test(loyaluser.email)) {
      isFormValid = false;

      errors.email = "This is not a valid email format!";
    }
    if (!dob) {
      isFormValid = false;
      errors.dob = "DOB is required!";
    }
    if (selectedlanguage.length === 0) {
      isFormValid = false;
      errors.selectedlanguage = "Language is required!";
    }
    if (selectedcategory.length === 0) {
      isFormValid = false;
      errors.selectedcategory = "Category is required!";
    }
    if (countrydata.length === 0) {
      isFormValid = false;
      errors.countrydata = "Country is required!";
    }
    if (!loyaluser.phone_no) {
      isFormValid = false;
      errors.phone_no = "Phone number is required!";
    }
    if (loyaluser.is_subscribed === null || loyaluser.is_subscribed === undefined) {
      isFormValid = false;
      errors.is_subscribed = "This field is required!";
    }
    if (!loyaluser.status) {
      isFormValid = false;
      errors.status = "Status is required!";
    }
    setError(errors);

    if (isFormValid === true) {
      const formData = new FormData();
      formData.append("hotel_id", hotelid);
      formData.append("member_id", memberid);
      formData.append("name", loyaluser.name);
      formData.append("surname", loyaluser.surname);
      if (loyaluser.status === true || loyaluser.status === "true") {
        formData.append("status", 1);
      } else {
        formData.append("status", 0);
      }
      if (
        loyaluser.is_subscribed === true ||
        loyaluser.is_subscribed === "true"
      ) {
        formData.append("is_subscribed", 1);
      } else {
        formData.append("is_subscribed", 0);
      }
      formData.append("email", loyaluser.email);
      formData.append("calling_code", loyaluser.calling_code);
      formData.append("phone_no", loyaluser.phone_no);
      formData.append("country", countrydata.value);
      formData.append("language", selectedlanguage.value);
      formData.append("category", selectedcategory.value);
      formData.append("dob", moment(dob).format("DD-MM-YYYY"));
      if (newlogo) {
        if (newlogo instanceof File) {
          formData.append("image", newlogo);
        }
      }
      formData.append("source", "website");

      axios
        .post(`${Config.apiurl}hotel/loyalty/members/update`, formData, {})
        .then((res) => {
          if (res.status !== 200 || !res) {
            console.log("error");
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(true);
          } else {
            console.log("success");
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(true);
            setTimeout(() => navigate("/loyalty-member"), 3000);
          }
        });
    }
  };

  useEffect(() => {
    getList();
    getcountry();
    getfulllanguage();
    getcategory();
  }, [language, loyaluser.source]);

  console.log(loyaluser);

  return (
    <div>
      <Loyaltyheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">Loyalty Member</h4>
                </div>
              </div>
            </div>
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Create New Loyalty Member
                      </h4>
                      <div className="flex-shrink-0">
                        <Link
                          type="button"
                          className="btn db-other-button"
                          to="/loyalty-member"
                          style={{ marginRight: "5px" }}
                        >
                          Loyalty Member
                        </Link>
                        {/* <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to=""
                        >
                          Cancel
                        </button> */}
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          onClick={Update}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Name
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  value={loyaluser.name}
                                  name="name"
                                  onChange={handlechange}
                                />
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.name}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Surname
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  value={loyaluser.surname}
                                  name="surname"
                                  onChange={handlechange}
                                />
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.surname}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Email
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="example@gmail.com"
                                  value={loyaluser.email}
                                  name="email"
                                  onChange={handlechange}
                                />
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.email}
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Date of Birth
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <Flatpickr
                                  id="flatpickr-to4"
                                  className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                                  placeholder="DOB"
                                  options={{
                                    mode: "single",
                                    dateFormat: "d M, Y",
                                  }}
                                  value={dob ? new Date(dob) : ""}
                                  onChange={handlechangeDOB}
                                />
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.dob}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Profile Image
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  placeholder=""
                                  id="lastNameinput"
                                  name="profile_image"
                                  onChange={handleimageChange}
                                />

                                {loyaluser && loyaluser.profile_image ? (
                                  <div
                                    className="col-sm-4 col-6 col-md-3 col-lg-2"
                                    style={{ float: "left" }}
                                  >
                                    <img
                                      src={`${Commonimagepath.apiurl}${loyaluser.profile_image}`}
                                      alt=""
                                      className="position-absolute"
                                      style={{
                                        width: "45px",
                                        marginTop: "3px",
                                        height: "40px",
                                        marginLeft: "2px",
                                      }}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div
                              className="col-md-6"
                              // style={{ marginTop: "10px" }}
                            >
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Default Language
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <Select
                                  id="ForminputState"
                                  className="form-select1"
                                  options={be_languagefull}
                                  value={selectedlanguage}
                                  onChange={setSelectedlanguage}
                                ></Select>
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.selectedlanguage}
                                </label>
                              </div>
                            </div>
                            <div
                              className="col-md-6"
                              // style={{ marginTop: "10px" }}
                            >
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Category
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <Select
                                  options={category}
                                  value={selectedcategory}
                                  onChange={setSelectedCategory}
                                  className="form-control"
                                  data-choices
                                  name="choices-single-default"
                                  id="choices-single-default"
                                />
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.selectedcategory}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Country{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <Select
                                  className="form-control"
                                  data-choices
                                  name="choices-single-default"
                                  id="choices-single-default"
                                  options={country}
                                  value={countrydata}
                                  onChange={(val) => {
                                    handleChangeselectcountry(val);
                                  }}
                                ></Select>
                                <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror.countrydata}
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="phonenumberInput"
                                  className="form-label"
                                >
                                  Phone Number{" "}
                                  <span class="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <div className="input-group" data-input-flag>
                                  {loyaluser.calling_code === null ? (
                                    <button
                                      class="btn btn-light border calling-btn"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <select
                                        value={loyaluser.calling_code}
                                        name="calling_code"
                                        className="calling-select"
                                      >
                                        {calling.map((calling_code, index) => (
                                          <option
                                            key={index}
                                            value={calling_code}
                                          >
                                            {calling_code}
                                          </option>
                                        ))}
                                      </select>
                                    </button>
                                  ) : (
                                    <button
                                      class="btn btn-light border calling-btn"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      {loyaluser.calling_code}
                                    </button>
                                  )}
                                  <input
                                    type="number"
                                    class="form-control rounded-end flag-input"
                                    placeholder="Enter number"
                                    value={loyaluser.phone_no}
                                    onChange={handlechange}
                                    name="phone_no"
                                    onFocus={(e) =>
                                      e.target.addEventListener(
                                        "wheel",
                                        function (e) {
                                          e.preventDefault();
                                        },
                                        { passive: false }
                                      )
                                    }

                                  />
                                </div>
                                <label style={{ color: "red" }}>
                                  {formerror.phone_no}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Subscribed{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <select
                                  id="ForminputState"
                                  className="form-select"
                                  value={loyaluser.is_subscribed}
                                  name="is_subscribed"
                                  onChange={handlechange}
                                >
                                  <option value="">Choose...</option>
                                  <option
                                    {...(loyaluser.is_subscribed === true
                                      ? "selected"
                                      : "")}
                                    value={true}
                                  >
                                    Active
                                  </option>
                                  <option
                                    {...(loyaluser.is_subscribed === false
                                      ? "selected"
                                      : "")}
                                    value={false}
                                  >
                                    Inactive
                                  </option>
                                </select>
                                <label style={{ color: "red" }}>
                                  {formerror.is_subscribed}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  for="firstNameinput"
                                  className="form-label"
                                >
                                  Status{" "}
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <select
                                  id="ForminputState"
                                  className="form-select"
                                  value={loyaluser.status}
                                  name="status"
                                  onChange={handlechange}
                                >
                                  <option selected>Choose...</option>
                                  <option
                                    {...(loyaluser.status === true
                                      ? "selected"
                                      : "")}
                                    value={true}
                                  >
                                    Active
                                  </option>
                                  <option
                                    {...(loyaluser.status === false
                                      ? "selected"
                                      : "")}
                                    value={false}
                                  >
                                    Inactive
                                  </option>
                                </select>
                                <label style={{ color: "red" }}>
                                  {formerror.status}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default Loyaltymemberedit;
