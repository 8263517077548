import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import Footer from "../../Common/Footer";
import Privatesaleheader from "../../Common/Privatesaleheader";
import Toastmodal from "../../Common/Toastmodal";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Commonimagepath from "../../Commonimagepath";
import Config from "../../Config";
import Loading from "../../Loading";
import Select from "react-select";
import { UserContext } from "../../Contexts/UserContext";

function Upselling() {
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const [id, setId] = useState(hotel_select ? hotel_select[0]?.value : "");
  const [hotelname, setHotelname] = useState(
    hotel_select ? hotel_select[0]?.label : ""
  );
  let navigate = useNavigate();
  const [rooms, setRooms] = useState([]);
  const [upsell, setUpsell] = useState([]);
  const [selectedUpsell, setSelectedUpsell] = useState([]);
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [list, setList] = useState({ status: true });
  const [loading, setLoading] = useState(false);
  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const [loop, setLoop] = useState("");
  const [roomdata, setRoomdata] = useState([]);
  const [formerror, setError] = useState({});
  const [days, setDays] = useState("");

  function tog_animationFlip(i) {
    setLoop(i);
    setmodal_animationFlip(!modal_animationFlip);
  }

  const handleloyaltyToggle = (isChecked, name) => {
    setList({ ...list, [name]: isChecked });
  };

  const handleConditionChange = (name, value) => {
    const updatedValues = { ...list, [name]: value };
    setList(updatedValues);
  };

  const handleUpsellChange = (val) => {
    const updatedValues = { ...selectedUpsell };
    updatedValues[loop] = val;
    setSelectedUpsell(updatedValues);
    tog_animationFlip();
  };

  const getRooms = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/active-rooms/${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setRooms(data.data);
        setLoading(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getRooms();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getRooms();
    } else {
      console.log("error");
    }
  };

  const getUpsells = async (e) => {
    const res = await fetch(
      `${Config.apiurl}hotel/upselling/active-rooms/${id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        setUpsell(data.data);
        setLoading(true);
      } else if (data.reauth === true) {
        handleUnauthorized();
        getUpsells();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getUpsells();
    } else {
      console.log("error");
    }
  };

  const getData = async (e) => {
    const res = await fetch(
      `${Config.apiurl}hotel/private-sale/upselling/view`,
      {
        method: "POST",
        body: JSON.stringify({
          hotel_id: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      if (data.status_code === "200") {
        if (data.data.length !== 0) {
          setList(data.data?.upselling);
          setRoomdata(data.data?.room_data);
          setDays(data.data?.upselling_days);
        }
      } else if (data.reauth === true) {
        handleUnauthorized();
        getData();
      } else {
        console.log("error");
      }
    } else if (res.status === 401) {
      handleUnauthorized();
      getData();
    } else {
      console.log("error");
    }
  };

  const Add = async () => {
    let isFormValid = true;
    const errors = {};

    if (!list.cancel_penalty_type) {
      isFormValid = false;
      errors.cancel_penalty_type = "This field is required!";
    }
    if (!list.prepayment_type) {
      isFormValid = false;
      errors.prepayment_type = "This field is required!";
    }
    if (!list.member_type) {
      isFormValid = false;
      errors.member_type = "This field is required!";
    }

    setError(errors);

    if (isFormValid === true) {
      let newRoom = [];
      for (let i = 0; i < rooms.length; i++) {
        if (selectedUpsell[i]?.value) {
          let multiarray = {
            upsell_room: selectedUpsell[i].value,
            room: rooms[i].value,
          };
          newRoom.push(multiarray);
        }
      }

      const res = await fetch(
        `${Config.apiurl}hotel/private-sale/upselling/create`,
        {
          method: "POST",
          body: JSON.stringify({
            hotel_id: id,
            status: list.status,
            cancel_penalty_type: list.cancel_penalty_type,
            prepayment_type: list.prepayment_type,
            member_type: list.member_type,
            upsell_rooms: newRoom,
            send_days_before: days,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200" || !data.data) {
          console.log("error");
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          getData();
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Add();
      } else {
        console.log("error");
      }
    }
  };

  const Update = async () => {
    let isFormValid = true;
    const errors = {};

    if (!list.cancel_penalty_type) {
      isFormValid = false;
      errors.cancel_penalty_type = "This field is required!";
    }
    if (!list.prepayment_type) {
      isFormValid = false;
      errors.prepayment_type = "This field is required!";
    }
    if (!list.member_type) {
      isFormValid = false;
      errors.member_type = "This field is required!";
    }
    if (!days) {
      isFormValid = false;
      errors.days = "This field is required!";
    }
    setError(errors);

    if (isFormValid === true) {
      let newRoom = [];
      for (let i = 0; i < rooms.length; i++) {
        if (selectedUpsell[i]?.value) {
          let multiarray = {
            upsell_room: selectedUpsell[i].value,
            room: rooms[i].value,
          };
          newRoom.push(multiarray);
        }
      }

      const res = await fetch(
        `${Config.apiurl}hotel/private-sale/upselling/update`,
        {
          method: "POST",
          body: JSON.stringify({
            hotel_id: id,
            upsell_id: list.upsell_id,
            status: list.status,
            cancel_penalty_type: list.cancel_penalty_type,
            prepayment_type: list.prepayment_type,
            member_type: list.member_type,
            upsell_rooms: newRoom,
            send_days_before: days,
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200" || !data.data) {
          console.log("error");
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Update();
      } else {
        console.log("error");
      }
    }
  };

  useEffect(() => {

    getRooms();
    getUpsells();
    getData();
    
    let newUpsell;

    for (let i = 0; i < rooms?.length; i++) {
      for (let j = 0; j < roomdata?.length; j++) {
        if (roomdata[j]?.room === rooms[i]?.value) {
          newUpsell = roomdata[j]?.upsell_room?.value;

          for (let k = 0; k < upsell?.length; k++) {
            if (newUpsell === upsell[k]?.value) {
              selectedUpsell[i] = {
                value: upsell[k]?.value,
                label: upsell[k]?.label,
                room_photo: upsell[k]?.room_photo,
              };
            }
          }
        }
      }
    }
  }, [rooms?.length, roomdata?.length, upsell?.length,id]);

  // useEffect(() => {
  //   getRooms();
  //   getUpsells();
  //   getData();
  // }, [id]);

  useEffect(() => {
    setId(hotel_select[0]?.value);
  }, [hotel_select]);

  useEffect(() => {
    setHotelname(hotel_select[0]?.label);
  }, [hotel_select]);

  console.log(selectedUpsell);
  console.log(upsell);
  return (
    <div>
      <Privatesaleheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <h4 className="" style={{ marginLeft: "10px" }}>
              {hotelname}
            </h4>
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  {loading === false ? (
                    <Loading />
                  ) : (
                    <div className="card">
                      <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                          UPSELLING
                        </h4>
                        <div className="flex-shrink-0">
                          <div
                            className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                            dir="ltr"
                          >
                            <label
                              className="form-check-label"
                              htmlFor="inlineswitch5"
                              style={{
                                marginRight: "40px",
                                marginTop: "5px",
                                marginLeft: "5px",
                                color: "black",
                              }}
                            >
                              Active
                            </label>
                            <input
                              type="checkbox"
                              className="form-check-input offer_checkbox1"
                              id="inlineswitch5"
                              value={list?.status}
                              checked={list?.status === true ? true : false}
                              onChange={(e) =>
                                handleloyaltyToggle(e.target.checked, "status")
                              }
                              style={{ width: "50px", height: "25px" }}
                            />
                          </div>
                          {list?.upsell_id ? (
                            <button
                              type="button"
                              className="btn db-save-button"
                              data-bs-toggle="modal"
                              onClick={Update}
                            >
                              Save
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn db-save-button"
                              data-bs-toggle="modal"
                              onClick={Add}
                            >
                              Save
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="live-preview">
                          <form action="#">
                            <div className="" style={{ marginTop: "15px" }}>
                              <div className="row">
                                <div>
                                  Enable this feature to ensure your clients
                                  receive an email{" "}
                                  <input
                                    type="number"
                                    value={days}
                                    onChange={(e) => setDays(e.target.value)}
                                    style={{
                                      width: "55px",
                                      border: "1px solid #d2d2d2",
                                      textAlign: "center",
                                    }}
                                    onFocus={(e) =>
                                      e.target.addEventListener(
                                        "wheel",
                                        function (e) {
                                          e.preventDefault();
                                        },
                                        { passive: false }
                                      )
                                    }
                                  />{" "}
                                  <label
                                    style={{
                                      color: "red",
                                      position: "absolute",
                                      top: "28px",
                                      left: "29%",
                                    }}
                                  >
                                    {formerror.days}
                                  </label>
                                  days prior to their arrival, providing them
                                  with the opportunity to upgrade their room or
                                  apartment to a superior option.
                                </div>

                                {rooms.map((data, i) => {
                                  return (
                                    <>
                                      <div
                                        key={i}
                                        style={{
                                          border: "1px solid #f1f1f1",
                                          marginTop: "2%",
                                        }}
                                      >
                                        <div className="room-style-upselling">
                                          {data.room_photo ? (
                                            <div
                                              className=""
                                              style={{ float: "left" }}
                                            >
                                              <img
                                                src={`${Commonimagepath.apiurl}${data.room_photo}`}
                                                alt=""
                                                className="position-absolute"
                                                style={{
                                                  width: "35px",
                                                  height: "30px",
                                                  bottom: "10px",
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <span style={{ paddingLeft: "15%" }}>
                                            {data.label}
                                          </span>
                                        </div>
                                        <img
                                          src="/images/icon/upsell.jpeg"
                                          style={{
                                            paddingLeft: "17%",
                                            height: "50px",
                                            marginTop: "20px",
                                          }}
                                        />

                                        <div
                                          className={
                                            selectedUpsell[i]?.value
                                              ? "room-style-upselling-new"
                                              : "room-style-upselling-new text-center"
                                          }
                                        >
                                          {selectedUpsell[i]?.value ? (
                                            <>
                                              {selectedUpsell[i]?.room_photo ? (
                                                <div
                                                  className=""
                                                  style={{ float: "left" }}
                                                >
                                                  <img
                                                    src={`${Commonimagepath.apiurl}${selectedUpsell[i]?.room_photo}`}
                                                    alt=""
                                                    className="position-absolute"
                                                    style={{
                                                      width: "35px",
                                                      height: "30px",
                                                      bottom: "10px",
                                                    }}
                                                  />
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              <span
                                                style={{ paddingLeft: "15%" }}
                                              >
                                                {selectedUpsell[i]?.label}
                                              </span>
                                              <span
                                                className="bx bxs-chevron-down"
                                                style={{
                                                  float: "right",
                                                  marginTop: "5px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  tog_animationFlip(i)
                                                }
                                              ></span>
                                            </>
                                          ) : (
                                            <span>
                                              <Link
                                                className="extra-rate-add"
                                                to="#"
                                                onClick={() =>
                                                  tog_animationFlip(i)
                                                }
                                              >
                                                +
                                              </Link>
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}

                                <div
                                  style={{
                                    border: "1px solid #f1f1f1",
                                    marginTop: "2%",
                                  }}
                                >
                                  <h5
                                    style={{
                                      marginTop: "15px",
                                      marginLeft: "5%",
                                    }}
                                  >
                                    EMAIL SENDING CRITERIA
                                  </h5>
                                  <div style={{ marginLeft: "5%" }}>
                                    <div
                                      className="col-lg-8"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <div className="mb-3">
                                        <div className="input-group">
                                          <label
                                            className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default"
                                          >
                                            Send based on the cancellation
                                            penalty criteria{" "}
                                            <span className="form-validation-required">
                                              *
                                            </span>
                                          </label>
                                          <select
                                            className="form-select"
                                            id="idPayment"
                                            value={list?.cancel_penalty_type}
                                            onChange={(e) =>
                                              handleConditionChange(
                                                "cancel_penalty_type",
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">Select...</option>
                                            <option value="with_penalty">
                                              Only to reservations with penalty
                                              (deadline exceeded)
                                            </option>
                                            <option value="without_penalty">
                                              Only to reservations WITHOUT a
                                              penalty (deadline not exceeded)
                                            </option>
                                            <option value="both">All</option>
                                          </select>
                                        </div>
                                      </div>
                                      <label style={{ color: "red" }}>
                                        {formerror.cancel_penalty_type}
                                      </label>
                                    </div>
                                    <div className="col-lg-8">
                                      <div className="mb-3">
                                        <div className="input-group">
                                          <label
                                            className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default"
                                            style={{ width: "40%" }}
                                          >
                                            Send based on prepayment conditions
                                            <span className="form-validation-required">
                                              *
                                            </span>
                                            <span
                                              className="info-tag"
                                              style={{ left: "5px" }}
                                            >
                                              i
                                              <span className="tooltiptext">
                                                at the time of reservation
                                              </span>
                                            </span>
                                          </label>
                                          <select
                                            className="form-select"
                                            id="idPayment"
                                            value={list?.prepayment_type}
                                            onChange={(e) =>
                                              handleConditionChange(
                                                "prepayment_type",
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">Select...</option>
                                            <option value="with_prepayment">
                                              BAR
                                            </option>
                                            <option value="without_prepayment">
                                              NR
                                            </option>
                                            <option value="both">All</option>
                                          </select>
                                        </div>
                                      </div>
                                      <label style={{ color: "red" }}>
                                        {formerror.prepayment_type}
                                      </label>
                                    </div>
                                    <div className="col-lg-8">
                                      <div className="mb-3">
                                        <div className="input-group">
                                          <label
                                            className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default"
                                            style={{ width: "40%" }}
                                          >
                                            Send based on Club membership
                                            criteria
                                            <span className="form-validation-required">
                                              *
                                            </span>
                                          </label>
                                          <select
                                            className="form-select"
                                            id="idPayment"
                                            value={list?.member_type}
                                            onChange={(e) =>
                                              handleConditionChange(
                                                "member_type",
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">Select...</option>
                                            <option value="club_member">
                                              Only to member reservations
                                            </option>
                                            <option value="non_member">
                                              Only to non-member reservations
                                            </option>
                                            <option value="both">All</option>
                                          </select>
                                        </div>
                                      </div>
                                      <label style={{ color: "red" }}>
                                        {formerror.member_type}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="padding-20"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {toastmodal === true ? (
          <Toastmodal
            setToast={setToastmodal}
            message={toastmessage}
            type={toasttype}
            large={toastlarge}
          />
        ) : (
          ""
        )}
        <Modal
          id="flipModal"
          className="new_modal_loyal"
          isOpen={modal_animationFlip}
          toggle={() => {
            tog_animationFlip();
          }}
          modalClassName="flip"
          centered
          style={{ fontFamily: "IBM Plex Sans,sans-serif" }}
        >
          <ModalBody
            className="modal-body text-center"
            style={{ height: "120px", width: "500px" }}
          >
            <div className="restr_policy_expand" style={{ marginTop: "10px" }}>
              <div className="col-lg-12">
                <div className="input-group">
                  <label
                    className="input-group-text input-joint-color"
                    id="inputGroup-sizing-default"
                  >
                    Upsell Rooms
                  </label>
                  <div className="col-md-8">
                    <Select
                      placeholder="Select..."
                      className="form-control multiselect_p-sale"
                      data-choices
                      name="choices-single-default"
                      id="choices-single-default"
                      value={selectedUpsell[loop]}
                      onChange={(val) => handleUpsellChange(val)}
                      options={upsell}
                    ></Select>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="btn btn-link link-success fw-medium text-decoration-none"
              onClick={() => {
                tog_animationFlip();
              }}
              style={{ marginTop: "10px" }}
            >
              {" "}
              <i className="ri-close-line me-1 align-middle"></i>
              Close{" "}
            </button>
          </ModalBody>
        </Modal>
        <Footer />
      </div>
    </div>
  );
}

export default Upselling;
