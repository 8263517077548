import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import Commonimagepath from "../Commoncomponents/Commonimagepath";
import Config from "../Commoncomponents/Config";
import { Decodetoken} from '../Commoncomponents/Decodetoken';
import { useAuthHandling } from "../Commoncomponents/Common/useAuthHandling";

const ProfileDropdown = () => {
  // const { user } = useSelector((state) => ({
  //   user: state.Profile.user,
  // }));
  const { user } = useState("Anju");
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  let navigate = useNavigate();
  // const [userName, setUserName] = useState("Admin");
  const username = localStorage.getItem("user_name");
  const user_type =localStorage.getItem("user_type");

  const [alert, setAlert] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [userdatas,setUserdatas]=useState([]);
  const [hotel,setHotel]=useState([]);
  const[loader,setLoader]=useState(true);
  // const { tokens, handleUnauthorized,removeToken } = Decodetoken();


// useEffect(() => {
//   handleUnauthorized();  
// }, [handleUnauthorized]);

  useEffect(() => {
    
    userdata();

  }, [username, user]);

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  console.log("in profile");
  const userdata = async (e) => {
    console.log("in fn user data");

    const res = await fetch(`${Config.apiurl}profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (res.status === 200) {
      console.log("in response");
      const data1 = await res.json();
      setLoader(false);
      if(data1.status_code==="200"){
        setUserdatas(data1.data.user); 
        
        if(user_type==="hotel-user"){
          localStorage.setItem('myData', JSON.stringify(data1.data.hotels));
        }  
      }
      else if(data1.reauth===true){
        handleUnauthorized();
        userdata();
      }

    } 
   
  };

  const Logout = async (e) => {
    e.preventDefault();

    const res = await fetch(`${Config.apiurl}logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    const data1 = await res.json();
    if (data1.status_code === 200) {
      setAlert(true);
      setAlertmessage("Logout sucessfully");

      setAlerttype("success");
      // 
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("user_type");
      localStorage.removeItem("selected-hotel-id");
      localStorage.removeItem("selected-hotel");
      localStorage.removeItem("refreshtoken");

      
      setTimeout(() => navigate("/"), 3000);
    } else if(data1.reauth===true){
      handleUnauthorized();
      Logout();
      }
    else
      {
      setAlert(true);
      setAlertmessage(data1.message);

      setAlerttype("error");
    }
  };

  return (
    <React.Fragment>
      {!loader?
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className=" header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            {userdatas.profile_image?
            <img
              className="rounded-circle header-profile-user"
              src={`${Commonimagepath.apiurl}${userdatas.profile_image}`}
              alt="Header Avatar"
            />
            :
            <img
            className="rounded-circle header-profile-user"
            src={avatar1}
            alt="Header Avatar"
          />
          }
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text head_username">
                {userdatas.name}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12  user-name-sub-text" >
                {userdatas.user_type==="admin-user"?"Admin":user_type==="hotel-user"?"Hotel":""}              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome {userdatas.name}!</h6>
          <DropdownItem href={process.env.PUBLIC_URL + "/profile"}>
            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Profile</span>
          </DropdownItem>
          {/* <DropdownItem href={process.env.PUBLIC_URL + "/apps-chat"}>
            <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Messages</span>
          </DropdownItem>
          <DropdownItem href="#">
            <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Taskboard</span>
          </DropdownItem>
          <DropdownItem href={process.env.PUBLIC_URL + "/pages-faqs"}>
            <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Help</span>
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem href={process.env.PUBLIC_URL + "/pages-profile"}>
            <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">
              Balance : <b>$5971.67</b>
            </span>
          </DropdownItem> */}
          {/* <DropdownItem
            href={process.env.PUBLIC_URL + "/pages-profile-settings"}
          >
            
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Settings</span>
          </DropdownItem> */}
          
          {/* <DropdownItem
            href={process.env.PUBLIC_URL + "/auth-lockscreen-basic"}
          >
            <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Lock screen</span>
          </DropdownItem> */}
          <DropdownItem href="#" onClick={Logout}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      :""}
    </React.Fragment>
  );
};

export default ProfileDropdown;
