import React, { useEffect, useState } from "react";
import Footer from "../../Common/Footer";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import Config from "../../Config";
import Alerts from "../../Alerts";
import { useLocation } from "react-router-dom";
import Loading from "../../Loading";
import axios from "axios";
import Commonheader from "../../Common/Commonheader";
import Commonimagepath from "../../Commonimagepath";

function Adminuseredit() {
  const location = useLocation();
  let navigate = useNavigate();

  const [ids, setId] = useState(location.state.admin_id);

  const [active, setActive] = useState("en");
  const [role, setRole] = useState([]);
  const [be_language, setBe_language] = useState([]);
  const language = localStorage.getItem("be_language");
  const [alert, setAlert] = useState(false);
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [formerror, setError] = useState({});

  const [signlerole, setSinglerole] = useState("");
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState([]);
  const [status, setStatus] = useState("");
  const [adminuser, setAdminuser] = useState();
  const [country, setCountry] = useState([]);
  const [roledata, setRoledata] = useState([]);
  const [defaultlang, setDefaultlang] = useState([]);
  const [optionallang, setOptionallang] = useState([]);
  const [langdata, setLangdata] = useState("");
  const [countrydata, setCountrydata] = useState("");
  const [be_languagefull, setBe_languagefull] = useState([]);
  const [multi, setMulti] = useState([]);
  const [selectedUsertype, setSelectedUsertype] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState([]);
  const [selectedHotelnew, setSelectedHotelnew] = useState([]);
  const [allhotels, setAllhotels] = useState("");
  const [hotelnew, setHotelnew] = useState("");
  const [selectedUsertypenew, setSelectedUsertypenew] = useState([]);

  const usertype = [
    { value: "admin-user", label: "Admin" },
    { value: "vendor-user", label: "Vendor" },
  ];

  const getHotel = async (e) => {
    const res = await fetch(`${Config.apiurl}hotels`, {
      method: "GET",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setHotelnew(data);
    }
  };

  const getRole = async (e) => {
    if (language === null) {
      const res = await fetch(`${Config.apiurl}role-list`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data1 = await res.json();
      const data = data1.data;

      if (data1.status_code !== "200" || !data) {
        console.log("error");
      } else {
        setRole(data);
      }
    } else {
      const res = await fetch(`${Config.apiurl}role-list`, {
        method: "POST",
        body: JSON.stringify({
          language: language,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data1 = await res.json();
      const data = data1.data;

      if (data1.status_code !== "200" || !data) {
        console.log("error");
      } else {
        setRole(data);
      }
    }
  };

  const getlanguage = async (e) => {
    const res = await fetch(`${Config.apiurl}optional-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data.data) {
      console.log("error");
    } else {
      setBe_language(data.data);
    }
  };

  const getfulllanguage = async (e) => {
    const res = await fetch(`${Config.apiurl}booking-engine-languages`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data.data) {
      console.log("error");
    } else {
      setBe_languagefull(data.data);
    }
  };
  const getcountry = async (e) => {
    const res = await fetch(`${Config.apiurl}country-list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data.data) {
      console.log("error");
    } else {
      setCountry(data.data);
    }
  };

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setAdminuser({ ...adminuser, [name]: value });
  };
  const handleInputdata = (roledata) => {
    setRoledata(roledata);
  };

  const handleInputlang = (langdata) => {
    setDefaultlang(langdata);
  };
  const handleInputcountry = (countrydata) => {
    setCountrydata(countrydata);
  };
  const [selectedMulti, setselectedMulti] = useState([]);
  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }

  const [selectedMulti1, setselectedMulti1] = useState([]);
  function handleMulti1(selectedMulti1) {
    setselectedMulti1(selectedMulti1);
  }
  // const validate = (values) => {
  //   const errors = {};
  //   var regex = /^\+(?:[0-9] ?)/;
  //   if (!values.name) {
  //     errors.name = "Name is required!";
  //   }
  //   if (!values.surname) {
  //     errors.surname = "Surname is required!";
  //   }
  //   if (!values.email) {
  //     errors.email = "Email is required!";
  //   } else if (regex.test(values.email)) {
  //     errors.email = "This is not a valid email format!";
  //   }

  //   return errors;
  // };

  // const validaterole = (values) => {
  //   const errors = {};
  //   if (!values) {
  //     errors.role = "Role is required!";
  //   }

  //   return errors;
  // };
  // const validatelang = (values) => {
  //   const errors = {};
  //   if (!values) {
  //     errors.lang = "Language is required!";
  //   }

  //   return errors;
  // };
  // const validatecountry = (values) => {
  //   const errors = {};
  //   if (!values) {
  //     errors.country = "Country is required!";
  //   }

  //   return errors;
  // };
  // const validateselect = (values) => {
  //   const errors = {};
  //   if (values.length === 0) {
  //     errors.langselect = "Language is required!";
  //   }

  //   return errors;
  // };
  // const validatestatus = (values) => {
  //   const errors = {};
  //   if (!values) {
  //     errors.status = "Status is required!";
  //   }

  //   return errors;
  // };
  // const validateimage = (values) => {
  //   const errors = {};
  //   if (!values) {
  //     if (adminuser.profile_image === null) {
  //       errors.img = "Image is required!";
  //     }
  //   } else {
  //     if (
  //       values.type === "image/jpeg" ||
  //       values.type === "image/jpg" ||
  //       values.type === "image/png" ||
  //       values.type === "image/avif" ||
  //       values.type === "image/webp"

  //     ) {
  //       console.log("");
  //       if (values.size > 1024 * 1000) {
  //         errors.img =
  //           "maximum file size 1 mb...please upload an image less than 1 mb";
  //       }
  //     } else {
  //       errors.img = "Only support jpg, jpeg, png image!";
  //     }
  //   }

  //   return errors;
  // };
  // const PostData = async (e) => {
  //   e.preventDefault();

  //   const ress = validate(adminuser);
  //   const ressrole = validaterole(roledata);
  //   const resslang = validatelang(defaultlang);
  //   const resscountry = validatecountry(countrydata);
  //   const ressselect = validateselect(optionallang);
  //   const ressstatus = validatestatus(status);
  //   const ressimg = validateimage(image);

  //   setError(ress);
  //   setError1(ressrole);
  //   setError2(resslang);
  //   setError3(resscountry);
  //   setError4(ressselect);
  //   setError5(ressstatus);
  //   setError6(ressimg);

  //   if (
  //     !ress.user_name &&
  //     !ress.surname &&
  //     !ress.email &&
  //     !ress.password &&
  //     !ress.c_password &&
  //     !ressrole.role &&
  //     !ressstatus.status &&
  //     !resslang.lang &&
  //     !resscountry.country &&
  //     !ressselect.langselect
  //   ) {
  //     const formData = new FormData();
  //     formData.append("en_name", adminuser.name);
  //     formData.append("en_surname", adminuser.surname);
  //     formData.append("password", adminuser.password);
  //     formData.append("email", adminuser.email);
  //     formData.append("user_type", adminuser.user_type);
  //     if (image) {
  //       formData.append("profile_image", image);
  //     }
  //     formData.append("password_confirmation", adminuser.c_password);
  //     formData.append("is_active", status);
  //     formData.append("role", roledata.value);
  //     formData.append("default_language", defaultlang.value);
  //     formData.append("country", countrydata.value);
  //     for (let i = 0; i < optionallang.length; i++) {
  //       formData.append(`optional_language[${i}]`, optionallang[i].value);
  //     }
  //     for (let i = 0; i < multi.length; i++) {
  //       if (multi[i].name) {
  //         if (multi[i].code !== "en") {
  //           formData.append(`multi_lang[${i}][lang_code]`, multi[i].code);
  //           formData.append(`multi_lang[${i}][fields][name]`, multi[i].name);
  //           formData.append(
  //             `multi_lang[${i}][fields][surname]`,
  //             multi[i].surname
  //           );
  //         }
  //       }
  //     }
  //     axios
  //       .post(`${Config.apiurl}admin/admin-user/${ids}`, formData, {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       })
  //       .then((res) => {
  //         if (res.status_code !== "200" || !res) {
  //           setAlert(true);
  //           setAlertmessage(res.message);
  //           setOpen(true);
  //           setAlerttype("error");
  //         } else {
  //           setAlert(true);
  //           setAlertmessage(res.message);
  //           setOpen(true);
  //           setAlerttype("success");
  //           Reset();
  //           setTimeout(() => navigate("/admin_user_listing"), 3000);
  //         }
  //       });
  //   }
  // };

  const PostData = async (e) => {
    e.preventDefault();
    let isFormValid = true;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const errors = {};

    if (!adminuser.name) {
      isFormValid = false;
      errors.name = "Name is required!";
    }
    if (!adminuser.surname) {
      isFormValid = false;
      errors.surname = "Surname is required!";
    }
    if (!adminuser.email) {
      isFormValid = false;
      errors.email = "Email is required!";
    } else if (!regex.test(adminuser.email)) {
      isFormValid = false;
      errors.email = "This is not a valid email format!";
    }
    if (!image) {
      isFormValid = false;
      errors.image = "Profile Image is required!";
    }
    if (roledata.length===0) {
      isFormValid = false;
      errors.roledata = "Role is required!";
    }
    if (selectedUsertype.length===0) {
      isFormValid = false;
      errors.selectedUsertype = "User Type is required!";
    }
    if(selectedUsertype.value==="vendor-user"){
    if (selectedHotel.length===0) {
      isFormValid = false;
      errors.selectedHotel = "Hotel is required!";
    }
  }
    if (defaultlang.length===0) {
      isFormValid = false;
      errors.defaultlang = "Default language is required!";
    }
    if (optionallang.length===0) {
      isFormValid = false;
      errors.optionallang = "Optional language is required!";
    }
    if (countrydata.length===0) {
      isFormValid = false;
      errors.countrydata = "Country is required!";
    }
    if (status.length===0) {
      isFormValid = false;
      errors.status = "Status is required!";
    }
    setError(errors);

    if (isFormValid===true) {
      const formData = new FormData();
      formData.append("en_name", adminuser.name);
      formData.append("en_surname", adminuser.surname);
      formData.append("email", adminuser.email);
      if (image) {
        formData.append("profile_image", image);
      }
      formData.append("is_active", status);
      formData.append("role", roledata.value);
      formData.append("default_language", defaultlang.value);
      formData.append("country", countrydata.value);
      formData.append("user_type", selectedUsertype.value);
      if(selectedUsertype.value==="vendor-user"){
      for (let i = 0; i < selectedHotel.length; i++) {
        formData.append(`hotels[${i}]`, selectedHotel[i].value);
      }
    }
      for (let i = 0; i < optionallang.length; i++) {
        formData.append(`optional_language[${i}]`, optionallang[i].value);
      }
      for (let i = 0; i < multi.length; i++) {
        if (multi[i].name) {
          if (multi[i].code !== "en") {
            formData.append(`multi_lang[${i}][lang_code]`, multi[i].code);
            formData.append(`multi_lang[${i}][fields][name]`, multi[i].name);
            formData.append(
              `multi_lang[${i}][fields][surname]`,
              multi[i].surname
            );
          }
        }
      }
      axios
        .post(`${Config.apiurl}admin/admin-user/${ids}`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.status_code !== "200" || !res) {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("error");
          } else {
            setAlert(true);
            setAlertmessage(res.message);
            setOpen(true);
            setAlerttype("success");
            Reset();
            setTimeout(() => navigate("/admin_user_listing"), 3000);
          }
        });
    }
  };

  function Reset() {
    setAdminuser({
      user_name: "",
      surname: "",
      email: "",
      password: "",
      c_password: "",
    });
    setRoledata("");
    setLangdata("");
    setCountrydata("");
    setselectedMulti([]);
    setselectedMulti1([]);
    setStatus("");
  }

  useEffect(() => {
    if (hotelnew) {
      const matchedHotels = hotelnew.map((hotel) => ({
        value: hotel.hotel_id,
        label: hotel.hotel_name,
      }));
      
      setAllhotels(matchedHotels);
    }
  }, [hotelnew.length]);

  useEffect(() => {
    const getinfo = async (e) => {
      const res = await fetch(`${Config.apiurl}admin/admin-user/${ids}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await res.json();

      setAdminuser(data.data.user);
      setRoledata(data.data.role);
      setOptionallang(data.data.optonal_languages);
      setMulti(data.data.multi_lang);
      setCountrydata(data.data.country);
      setDefaultlang(data.data.language);
      setStatus(data.data.user.is_active);
      setSelectedUsertypenew(data.data.user.user_type);
      setSelectedHotelnew(data.data.hotels);
    };
    getinfo();
    getRole();
    getcountry();
    getlanguage();
    getfulllanguage();
    getHotel();

  }, [language, ids]);

  useEffect(() => {
    if (selectedHotelnew) {
      const matchedHotels = selectedHotelnew.map((hotel) => ({
        value: hotel.hotel_id,
        label: hotel.hotel_name,
      }));
      
      setSelectedHotel(matchedHotels);
    }
  }, [selectedHotelnew.length]);

  useEffect(() => {
    const initialSelectedOption = usertype.find(
      (option) => option.value === selectedUsertypenew
    );

    setSelectedUsertype(initialSelectedOption);
    
  }, [usertype.length,selectedUsertypenew]);


  const onFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <div id="layout-wrapper">
      <Commonheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="db-page-title">ADMIN USER</h4>
                </div>
              </div>
            </div>
            {alert ? (
              <Alerts type={alerttype} message={alertmessage} open={open} />
            ) : (
              ""
            )}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex res-header">
                      <h4 className="card-title mb-0 flex-grow-1">
                        Admin User
                      </h4>
                      <div className="flex-shrink-0">
                        <Link
                          type="button"
                          className="btn db-other-button btn-admin-user"
                          to="/admin_user_listing"
                          style={{ marginRight: "5px" }}
                        >
                          Admin User
                        </Link>
                        <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to=""
                          onClick={Reset}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"
                          onClick={PostData}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    {/* { adminuser!==undefined && adminuser.profile_image !==null?
                        <div className="image-edit-class">
                       
                                  <img src={`${Commonimagepath.apiurl}${adminuser.profile_image}`}/>
                               
                                </div>
                                 :""} */}
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-translation-style">
                                <ul>
                                  <li
                                    className={active === "en" ? "active" : ""}
                                  >
                                    <button
                                      type="button"
                                      onClick={() => setActive("en")}
                                      className={
                                        active === "en" ? "active" : ""
                                      }
                                      style={{
                                        border: "none",
                                        background: "transparent",
                                      }}
                                    >
                                      EN
                                    </button>
                                  </li>
                                  <li
                                    className={
                                      active === "trans" ? "active" : ""
                                    }
                                  >
                                    <button
                                      type="button"
                                      onClick={() => setActive("trans")}
                                      className={
                                        active === "trans" ? "active" : ""
                                      }
                                      style={{
                                        border: "none",
                                        background: "transparent",
                                      }}
                                    >
                                      Translations
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {adminuser !== undefined ? (
                              <>
                                {active === "en" ? (
                                  <>
                                    <div className="col-md-6">
                                      <div className="mb-3">
                                        <label
                                          for="firstNameinput"
                                          className="form-label"
                                        >
                                          Name
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder=""
                                          value={adminuser.name}
                                          name="name"
                                          onChange={handleInput}
                                        />
                                        <label
                                          style={{
                                            color: "red",
                                            marginTop: "3px",
                                          }}
                                        >
                                          {formerror.name}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mb-3">
                                        <label
                                          for="firstNameinput"
                                          className="form-label"
                                        >
                                          Surname
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder=""
                                          value={adminuser.surname}
                                          name="surname"
                                          onChange={handleInput}
                                        />
                                        <label
                                          style={{
                                            color: "red",
                                            marginTop: "3px",
                                          }}
                                        >
                                          {formerror.surname}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="mb-3">
                                        <label
                                          for="firstNameinput"
                                          className="form-label"
                                        >
                                          Email
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="email"
                                          className="form-control"
                                          placeholder=""
                                          value={adminuser.email}
                                          name="email"
                                          onChange={handleInput}
                                        />
                                        <label
                                          style={{
                                            color: "red",
                                            marginTop: "3px",
                                          }}
                                        >
                                          {formerror.email}
                                        </label>
                                      </div>
                                    </div>
                                   
                                    <div className="col-md-6">
                                      <div className="mb-3">
                                        <label
                                          for="firstNameinput"
                                          className="form-label"
                                        >
                                          Profile Image
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="file"
                                          className="form-control"
                                          placeholder=""
                                          id="lastNameinput"
                                          name="image"
                                          onChange={onFileChange}
                                        />
                                        {adminuser.profile_image !== null ? (
                                          <img
                                            className="user-img-edit mt-2"
                                            src={`${Commonimagepath.apiurl}${adminuser.profile_image}`}
                                          />
                                        ) : (
                                          ""
                                        )}
                                        <label
                                          style={{
                                            color: "red",
                                            marginTop: "3px",
                                          }}
                                        >
                                          {formerror.img}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="mb-3">
                                        <label
                                          for="firstNameinput"
                                          className="form-label"
                                        >
                                          Role
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                        </label>
                                        <Select
                                          className="form-select1"
                                          data-trigger
                                          options={role}
                                          value={roledata}
                                          onChange={handleInputdata}
                                        ></Select>

                                        <label
                                          style={{
                                            color: "red",
                                            marginTop: "3px",
                                          }}
                                        >
                                          {formerror.roledata}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      User Type
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <Select
                                      className="form-select1"
                                      data-trigger
                                      options={usertype}
                                      value={selectedUsertype}
                                      onChange={(e)=>setSelectedUsertype(e)}
                                    ></Select>

                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.selectedUsertype}
                                    </label>
                                  </div>
                                </div>
                                {selectedUsertype?.value==="vendor-user"?
                                <div className="col-md-4">
                                  <div className="mb-3">
                                    <label
                                      for="firstNameinput"
                                      className="form-label"
                                    >
                                      Hotels
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <MultiSelect
                                      options={allhotels}
                                      value={selectedHotel}
                                      onChange={setSelectedHotel}
                                      labelledBy="Select"
                                      selectionType="counter"
                                      closeOnChangedValue={false}
                                      aria-expanded="true"
                                    />

                                    <label
                                      style={{ color: "red", marginTop: "3px" }}
                                    >
                                      {formerror.selectedHotel}
                                    </label>
                                  </div>
                                </div>
                                :""}

                                    <div className="col-md-4">
                                      <div className="mb-3">
                                        <label
                                          for="firstNameinput"
                                          className="form-label"
                                        >
                                          Default Language
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                        </label>
                                        <Select
                                          id="ForminputState"
                                          className="form-select1"
                                          options={be_languagefull}
                                          value={defaultlang}
                                          onChange={handleInputlang}
                                        ></Select>
                                        <label
                                          style={{
                                            color: "red",
                                            marginTop: "3px",
                                          }}
                                        >
                                          {formerror.defaultlang}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="mb-3">
                                        <label
                                          for="firstNameinput"
                                          className="form-label"
                                        >
                                          Optional Language
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                        </label>
                                        <MultiSelect
                                          options={be_languagefull}
                                          value={optionallang}
                                          onChange={setOptionallang}
                                          labelledBy="Select"
                                          selectionType="counter"
                                          closeOnChangedValue={false}
                                          aria-expanded="true"
                                        />
                                        <label
                                          style={{
                                            color: "red",
                                            marginTop: "3px",
                                          }}
                                        >
                                          {formerror.optionallang}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="mb-3">
                                        <label className="form-label">
                                          Country{" "}
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                        </label>
                                        <Select
                                          className="form-control"
                                          data-choices
                                          name="choices-single-default"
                                          id="choices-single-default"
                                          options={country}
                                          value={countrydata}
                                          onChange={handleInputcountry}
                                        ></Select>

                                        <label
                                          style={{
                                            color: "red",
                                            marginTop: "3px",
                                          }}
                                        >
                                          {formerror.countrydata}
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-md-4">
                                      <div className="mb-3">
                                        <label
                                          for="firstNameinput"
                                          className="form-label"
                                        >
                                          Status{" "}
                                          <span className="form-validation-required">
                                            *
                                          </span>
                                        </label>
                                        <select
                                          id="ForminputState"
                                          className="form-select"
                                          value={status}
                                          onChange={(e) =>
                                            setStatus(e.target.value)
                                          }
                                        >
                                          <option selected>Choose...</option>
                                          <option
                                            {...(status === true
                                              ? "selected"
                                              : "")}
                                            value={true}
                                          >
                                            Active
                                          </option>
                                          <option
                                            {...(status === false
                                              ? "selected"
                                              : "")}
                                            value={false}
                                          >
                                            Inactive
                                          </option>
                                        </select>
                                        <label style={{ color: "red" }}>
                                          {formerror.status}
                                        </label>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="form-translation-choose-lang">
                                      <span
                                        for="firstNameinput"
                                        className="form-label db-lang-choose"
                                      >
                                        Choose Language:{" "}
                                      </span>
                                      <div className="lang-choose-select-option">
                                        <MultiSelect
                                          options={be_language}
                                          value={multi}
                                          onChange={setMulti}
                                          labelledBy="Select"
                                          selectionType="counter"
                                          closeOnChangedValue={false}
                                          aria-expanded="true"
                                        />
                                      </div>
                                    </div>
                                    <div className="appendmore"></div>
                                    {multi.length > 0
                                      ? multi.map((element, index) => {
                                          return (
                                            <div
                                              key={index}
                                              class="col-md-12 translation-fill-section"
                                              id="lang_ +val"
                                              data-value="1"
                                            >
                                              <div class="translation-selected-lang">
                                                <p>
                                                  <span class="translation-selected-lang-head">
                                                    {element.code}
                                                  </span>
                                                </p>
                                              </div>
                                              <div className="row">
                                                <div class="col-md-6">
                                                  <div class="mb-3">
                                                    <label
                                                      for="firstNameinput"
                                                      class="form-label"
                                                    >
                                                      Name
                                                      <span class="form-validation-required">
                                                        *
                                                      </span>
                                                    </label>
                                                    <input
                                                      type="text"
                                                      class="form-control"
                                                      placeholder=""
                                                      value={multi[index].name}
                                                      onChange={(e) =>
                                                        (multi[index].name =
                                                          e.target.value)
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <div class="col-md-6">
                                                  <div class="mb-3">
                                                    <label
                                                      for="firstNameinput"
                                                      class="form-label"
                                                    >
                                                      Surname
                                                      <span class="form-validation-required">
                                                        *
                                                      </span>
                                                    </label>
                                                    <input
                                                      type="text"
                                                      class="form-control"
                                                      placeholder=""
                                                      value={
                                                        multi[index].surname
                                                      }
                                                      onChange={(e) =>
                                                        (multi[index].surname =
                                                          e.target.value)
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })
                                      : ""}
                                  </>
                                )}
                              </>
                            ) : (
                              <Loading />
                            )}
                          </div>

                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Adminuseredit;
