import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Commonheader from "../../Common/Commonheader";
import { useState, useEffect, useRef } from "react";
import Footer from "../../Common/Footer";
import Config from "../../Config";
import moment from "moment";
import axios from "axios";
import Toastmodal from "../../Common/Toastmodal";
import Offerstep1edit from "./Offeredit/Offerstep1edit";
import Offerstep2edit from "./Offeredit/Offerstep2edit";
import Offerstep3edit from "./Offeredit/Offerstep3edit";
import Offerstep4edit from "./Offeredit/Offerstep4edit";
import Offerstep5edit from "./Offeredit/Offerstep5edit";
import Offerstep6edit from "./Offeredit/Offerstep6edit";

function Offeredit() {
  let navigate = useNavigate();
  let params = useParams();

  const [id, setId] = useState(params.hotel_id);
  const [offerid, setOfferid] = useState(params.offerid);

  const [tab, setTab] = useState("step1");
  const [offer1valid, setOffer1valid] = useState(false);
  const [offer2valid, setOffer2valid] = useState(false);
  const [offer3valid, setOffer3valid] = useState(false);

  const [offer4valid, setOffer4valid] = useState(false);
  const [offer5valid, setOffer5valid] = useState(false);

  const [offer6valid, setOffer6valid] = useState(false);

  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [errorroom, setErrorroom] = useState([{}]);
  const [multicountri3, setMulticountry3] = useState([]);
  const [multicountri4, setMulticountry4] = useState([]);
  const [otherboard, setOtherboard] = useState([]);
  const [selectedotherboard, setSelectedotherboard] = useState([]);

  const [offer, setOffer] = useState([]);
  const [loyaltyinclude, setLoyaltyinclude] = useState([]);
  const [offerroom, setOfferroom] = useState([]);
  const [offerboard, setOfferboard] = useState([]);
  const [offerrate, setOfferrate] = useState([]);
  const [offerprice, setOfferprice] = useState([]);
  const [availability, setAvailability] = useState([]);
  const [d_checkin, setD_checkin] = useState([]);
  const [d_checkout, setD_checkout] = useState([]);
  const [d_reserve, setD_reserve] = useState([]);
  const [d_stay, setD_stay] = useState([]);
  const [country_discount, setCountry_discount] = useState([]);
  const [offer_countries, setOffer_countries] = useState([]);
  const [loyalty_club, setLoyalty_club] = useState([]);
  const [offerIncludelist, setOfferIncludelist] = useState([]);
  const [optionimage, setOptionimage] = useState([]);
  const [imagechoose, setImagechoose] = useState("");
  const [rateexception, setRateexception] = useState([]);

  const [offerexception, setOfferexception] = useState([]);

  const [mul, setMul] = useState([]);

  // offer1 page
  const [offer1error, setOffer1error] = useState({});
  const [offer1Check, setOffer1check] = useState(false);
  const [offername, setOffername] = useState("");
  const [activestaydate, setActivestaydate] = useState(false);
  const [activestaydateopen, setActivestaydateopen] = useState(false);

  const [activecheckindate, setActivecheckindate] = useState(false);
  const [activecheckindateopen, setActivecheckindateopen] = useState(false);

  const [activecheckoutdate, setActivecheckoutdate] = useState(false);
  const [activecheckoutdateopen, setActivecheckoutdateopen] = useState(false);

  const [activereservedate, setActivereservedate] = useState(false);
  const [activereservedateopen, setActivereservedateopen] = useState(false);

  const [datefromstay, setDatefromstay] = useState("");
  const [datetostay, setDatetostay] = useState("");

  const [datefromcheckin, setDatefromcheckin] = useState("");
  const [datetocheckin, setDatetocheckin] = useState("");

  const [datefromcheckout, setDatefromcheckout] = useState("");
  const [datetocheckout, setDatetocheckout] = useState("");

  const [datefromreserve, setDatefromreserve] = useState("");
  const [datetoreserve, setDatetoreserve] = useState("");

  const fpstay = useRef(null);
  const fpcheckin = useRef(null);
  const fpcheckout = useRef(null);
  const fpreserve = useRef(null);

  const [selectedWeekdaysstay, setSelectedWeekdaysstay] = useState([]);
  const [selectedWeekdayscheckin, setSelectedWeekdayscheckin] = useState([]);
  const [selectedWeekdayscheckout, setSelectedWeekdayscheckout] = useState([]);
  const [selectedWeekdaysreserve, setSelectedWeekdaysreserve] = useState([]);

  const [clickedDaysstay, setClickedDaysstay] = useState([]);
  const [clickedDayscheckin, setClickedDayscheckin] = useState([]);
  const [clickedDayscheckout, setClickedDayscheckout] = useState([]);
  const [clickedDaysreserve, setClickedDaysreserve] = useState([]);

  const [openclosestay, setOpenclosestay] = useState("open");
  const [openclosecheckin, setOpenclosecheckin] = useState("open");
  const [openclosecheckout, setOpenclosecheckout] = useState("open");
  const [openclosereserve, setOpenclosereserve] = useState("open");

  const [language, setLanguage] = useState([]);

  const [selectedDatesstay, setSelectedDatesstay] = useState([]);
  const [selectedDatescheckin, setSelectedDatescheckin] = useState([]);
  const [selectedDatescheckout, setSelectedDatescheckout] = useState([]);
  const [selectedDatesreserve, setSelectedDatesreserve] = useState([]);

  const [selectedMulti, setselectedMulti] = useState([]);

  // offer2 page
  const [offer2error, setOffer2error] = useState({});

  const [offer2room, setOffer2room] = useState([]);
  const [boardoffer2, setBoardoffer2] = useState([]);
  const [ratelistoffer2, setRatelistoffer2] = useState([]);
  const [activeroom, setActiveroom] = useState(false);
  const [activeboard, setActiveboard] = useState(false);
  const [activerate, setActiverate] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [availableboard, setAvailbleboard] = useState([]);
  const [selectedroomocc, setSelectedroomocc] = useState([]);
  //offer3 page
  const [offer3fields, setOffer3fields] = useState({
    price_type: "same",
    fixed_week_price_status: false,
    discount_unit_price: "percentage",
    discount_value: "",
    increment_status: false,
    increment_type: "per_person",
    increment_frequency: "once",
    increment_room_value: "",
    increment_per_adult: "",
    increment_per_child: "",
    increment_unit: "currency",
    free_night_status: false,
    free_night_type: "last_night",
    free_booking_night: "2",
    booking_time_discount_status: false,
    booking_time_discount: "",
    booking_hours_status: false,
    booking_weekend_discount_status: false,
    hours_discount_from: "",
    hours_discount_to: "",
    long_stay_discount_status: false,
    long_stay_discount0_night: "1",
    long_stay_discount0_percentage: "",
    long_stay_discount1_night: "",
    long_stay_discount1_percentage: "",
    long_stay_discount2_night: "",
    long_stay_discount2_percentage: "",
    long_stay_discount3_night: "",
    long_stay_discount3_percentage: "",
    early_booking_discount_status: false,
    early_booking_discount0_days: "",
    early_booking_discount0_percentage: "",
    early_booking_discount1_days: "",
    early_booking_discount1_percentage: "",
    early_booking_discount2_days: "",
    early_booking_discount2_percentage: "",
    early_booking_discount3_days: "",
    early_booking_discount3_percentage: "",
    country_discount_status: false,
    device_discount_status: false,
    device_discount_type: "desktop",
    device_discount_percentage: "",
    last_minute_discount_status: false,
    last_minute_discount0_from: "12",
    last_minute_discount0_discount: "",
    last_minute_discount1_from: "",
    last_minute_discount1_discount: "",
    last_minute_discount2_from: "",
    last_minute_discount2_discount: "",
    last_minute_discount3_from: "",
    last_minute_discount3_discount: "",
  });
  const [exceptionstaydate, setExceptionstaydate] = useState(false);
  const [exceptionnight, setExceptionnight] = useState(false);
  const [countryOffer3, setCountryOffer3] = useState([]);
  const [inputFieldsexception, setInputFieldsexception] = useState([]);
  // const [inputFieldsexception, setInputFieldsexception] = useState([
  //   { fromdate: [], todate:[], discount:''}
  // ]);
  const [inputFieldsextra, setInputFieldsextra] = useState([]);
  const [showAlloffer3country, setShowAlloffer3country] = useState(false);
  const [ratetab, setRatetab] = useState("rate");
  const [board_apply_status,setBoard_apply_status]=useState("yes");

  const visibleItemsoffer3 =
    showAlloffer3country === true ? countryOffer3 : countryOffer3.slice(0, 20);
  const [offer3error, setOffer3error] = useState({});
  const [offer3validrate, setOffer3validrate] = useState(false);
  const [offer3validncrement, setOffer3validncrement] = useState(false);
  const [offer3validfreenight, setOffer3validfreenight] = useState(false);
  const [offer3validextra, setOffer3validextrae] = useState(false);
  const [offer3validcountry, setOffer3validcountry] = useState(false);
  const [offer3validdivice, setOffer3validdivice] = useState(false);

  // offer4 page
  const [offer4fields, setOffer4fields] = useState({
    promotional_code: false,
    loyalty_club: false,
    advanced_booking: false,
    different_payment: false,
    minimum_stay: false,
    maximum_stay: false,
    minimum_room: false,
    close_offer: false,
    maximum_rooms: false,
    last_minute: false,
    display_night: false,
    apply_country: false,
    only_device: false,
    ad_booking_days: "",
    cancel_policy: "",
    minimum_stay_count: "2",
    minimum_stay_night: false,
    maximum_stay_count: "2",
    max_stay_all_night_offered: "offer",
    min_rooms_per_booking: "2",
    offer_close_for_room: "2",
    max_room_per_night: "1",
    offer_last_minute_hours: "12",
    offer_display_hours_status: false,
    offer_display_from: "4",
    offer_display_to: "5",
    offer_display_weekend_status: false,
    offer_available_device_type: "",
  });

  const [legend, setLegend] = useState([]);
  const [countrylist, setCountrylist] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const visibleItems =
    showAll === true ? countrylist : countrylist.slice(0, 20);
  const [tabroom, setTabroom] = useState([]);
  const [tabroomweek, setTabroomweek] = useState([]);
  const [Loyaltyclublist, setLoyaltyclublist] = useState([]);

  const [offer4error, setOffer4error] = useState({});

  //offer4 page

  //offer5 page
  const [offer_include_status, setOffer_include_status] = useState(false);
  const [loyalty, setLoyalty] = useState([]);
  const [otherloyal_status, setOtherloyal_status] = useState(false);
  const [otherloyal, setOtherloyal] = useState([
    {
      loyalty_name: "",
      lang: [],
    },
  ]);
  const [alllanguage, setAlllanguage] = useState([]);
  const [loyallang, setLoyallang] = useState("");
  const [offer5error, setOffer5error] = useState({});

  //offer5 page

  //offer6 page

  const [offer6fields, setOffer6fields] = useState({
    description: "",
    price_description: "",
    hide_offer_status: false,
    show_offer_discount: false,
  });
  const [image, setImage] = useState([]);
  const [pdf, setPdf] = useState([]);

  const [selectedMulti6, setselectedMulti6] = useState([]);

  // offer1 page

  const getall = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/offer/all`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setLanguage(data.data[0].optional_language);
      setOffer2room(data.data[0].rooms_occupancies);
      setBoardoffer2(data.data[0].hotel_board);
      setRatelistoffer2(data.data[0].rate_lists);
      setLegend(data.data[0].policy_data);
      setCountrylist(data.data[0].countries);
      setCountryOffer3(data.data[0].countries);
      setLoyalty(data.data[0].inclusions);
      setAlllanguage(data.data[0].optional_language);
      setCurrency(data.data[0].currency);
      setLoyaltyclublist(data.data[0].loyalty_clubs);
      setOptionimage(data.data[0].option_images);
    }
  };

  const getoffer = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/offer/view`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: id,
        offer_id: offerid,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setOfferroom(data.data.rooms);
      setOffername(data.data.offer.title);
      setOfferboard(data.data.boards);
      setOfferrate(data.data.rates);
      setOfferprice(data.data.offer_price);
      setOffer(data.data.offer);
      setLoyalty_club(data.data.loyalty_club);
      setCountry_discount(data.data.country_discount);
      setOffer_countries(data.data.offer_countries);
      setD_checkin(data.data?.availability?.d_checkin);
      setD_checkout(data.data?.availability?.d_checkout);
      setD_stay(data.data?.availability?.d_stay);
      setD_reserve(data.data?.availability?.d_reserve);
      setActivestaydate(data.data?.availability?.d_stay?.status);
      setActivecheckindate(data.data?.availability?.d_checkin?.status);
      setActivecheckoutdate(data.data?.availability?.d_checkout?.status);
      setActivereservedate(data.data?.availability?.d_reserve?.status);
      setSelectedDatesstay(data.data?.availability?.d_stay?.dates);
      setSelectedDatescheckin(data.data?.availability?.d_checkin?.dates);
      setSelectedDatescheckout(data.data?.availability?.d_checkout?.dates);
      setSelectedDatesreserve(data.data?.availability?.d_reserve?.dates);
      setOffer_include_status(data.data.offer.offer_include_status);
      setOfferIncludelist(data.data.offer.offer_include_list);
      setImage(data.data.offer.offer_image);
      setPdf(data.data.offer.offer_pdf);
      // if(data.data.exception_night_disocunt){
      setRateexception(data.data.exception_night_disocunt);
      // }
      // if (data.data.offer_discount) {
        setOfferexception(data.data.offer_discount);
      // }
      setMul(data.data.multi_lang);
      //    setLegend(data.data[0].policy_data);
      //   setCountrylist(data.data[0].countries);
      //   setCountryOffer3(data.data[0].countries);
      setLoyaltyinclude(data.data.offer_include_list);
      //   setAlllanguage(data.data[0].optional_language);
      setCurrency(data.data.currency);
      setBoard_apply_status(data.data.offer.board_rate_include===true?"yes":"no");
    }
  };

  const handleoffereditcheckbox = (isChecked, name) => {
    setOffer({ ...offer, [name]: isChecked });
  };

  const handleApplyToCalendarstay = () => {
    if (openclosestay === "open") {
      if (fpstay.current) {
        fpstay.current.flatpickr.clear(); // Clear the selected dates in Flatpickr
      }
      if (datefromstay && datetostay && selectedWeekdaysstay.length > 0) {
        const dates = [];
        let currentDate = new Date(datefromstay);

        while (currentDate <= datetostay) {
          const dayOfWeek = currentDate.getDay();
          if (!clickedDaysstay.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatesstay(dates);
      }
    } else {
      if (fpstay.current) {
        fpstay.current.flatpickr.clear(); // Clear the selected dates in Flatpickr
      }

      if (
        datefromstay &&
        datetostay &&
        selectedWeekdaysstay.length > 0 &&
        clickedDaysstay.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromstay);

        while (currentDate <= datetostay) {
          const dayOfWeek = currentDate.getDay();
          if (clickedDaysstay.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatesstay(dates);
      }
    }
  };

  const handleApplyToCalendarcheckin = () => {
    if (openclosecheckin === "open") {
      if (
        datefromcheckin &&
        datetocheckin &&
        selectedWeekdayscheckin.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromcheckin);

        while (currentDate <= datetocheckin) {
          const dayOfWeek = currentDate.getDay();
          if (!clickedDayscheckin.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatescheckin(dates);
      }
    } else {
      if (fpcheckin.current) {
        fpcheckin.current.flatpickr.clear(); // Clear the selected dates in Flatpickr
      }

      if (
        datefromcheckin &&
        datetocheckin &&
        selectedWeekdayscheckin.length > 0 &&
        clickedDayscheckin.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromcheckin);

        while (currentDate <= datetocheckin) {
          const dayOfWeek = currentDate.getDay();
          if (clickedDayscheckin.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatescheckin(dates);
      }
    }
  };

  const handleApplyToCalendarcheckout = () => {
    if (openclosecheckout === "open") {
      if (
        datefromcheckout &&
        datetocheckout &&
        selectedWeekdayscheckout.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromcheckout);

        while (currentDate <= datetocheckout) {
          const dayOfWeek = currentDate.getDay();
          if (!clickedDayscheckout.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatescheckout(dates);
      }
    } else {
      if (fpcheckout.current) {
        fpcheckout.current.flatpickr.clear(); // Clear the selected dates in Flatpickr
      }
      if (
        datefromcheckout &&
        datetocheckout &&
        selectedWeekdayscheckout.length > 0 &&
        clickedDayscheckout.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromcheckout);

        while (currentDate <= datetocheckout) {
          const dayOfWeek = currentDate.getDay();
          if (clickedDayscheckout.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatescheckout(dates);
      }
    }
  };

  const handleApplyToCalendareserve = () => {
    if (openclosereserve === "open") {
      if (
        datefromreserve &&
        datetoreserve &&
        selectedWeekdaysreserve.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromreserve);

        while (currentDate <= datetoreserve) {
          const dayOfWeek = currentDate.getDay();
          if (!clickedDaysreserve.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatesreserve(dates);
      }
    } else {
      if (fpreserve.current) {
        fpreserve.current.flatpickr.clear(); // Clear the selected dates in Flatpickr
      }
      if (
        datefromreserve &&
        datetoreserve &&
        selectedWeekdaysreserve.length > 0 &&
        clickedDaysreserve.length > 0
      ) {
        const dates = [];
        let currentDate = new Date(datefromreserve);

        while (currentDate <= datetoreserve) {
          const dayOfWeek = currentDate.getDay();
          if (clickedDaysreserve.includes(dayOfWeek)) {
            dates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }

        setSelectedDatesreserve(dates);
      }
    }
  };

  const toggleSelectedDaystay = (dayIndex) => {
    if (selectedWeekdaysstay.includes(dayIndex)) {
      if (clickedDaysstay.includes(dayIndex)) {
        setClickedDaysstay((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayIndex)
        );
      } else {
        setClickedDaysstay((prevClickedDays) => [...prevClickedDays, dayIndex]);
      }
    }
  };

  const toggleSelectedDaycheckin = (dayIndex) => {
    if (selectedWeekdayscheckin.includes(dayIndex)) {
      if (clickedDayscheckin.includes(dayIndex)) {
        setClickedDayscheckin((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayIndex)
        );
      } else {
        setClickedDayscheckin((prevClickedDays) => [
          ...prevClickedDays,
          dayIndex,
        ]);
      }
    }
  };
  const toggleSelectedDaycheckout = (dayIndex) => {
    if (selectedWeekdayscheckout.includes(dayIndex)) {
      if (clickedDayscheckout.includes(dayIndex)) {
        setClickedDayscheckout((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayIndex)
        );
      } else {
        setClickedDayscheckout((prevClickedDays) => [
          ...prevClickedDays,
          dayIndex,
        ]);
      }
    }
  };
  const toggleSelectedDayreserve = (dayIndex) => {
    if (selectedWeekdaysreserve.includes(dayIndex)) {
      if (clickedDaysreserve.includes(dayIndex)) {
        setClickedDaysreserve((prevClickedDays) =>
          prevClickedDays.filter((day) => day !== dayIndex)
        );
      } else {
        setClickedDaysreserve((prevClickedDays) => [
          ...prevClickedDays,
          dayIndex,
        ]);
      }
    }
  };

  const handleOpenclosestay = (e) => {
    setActivestaydateopen(false);
  };

  const handleOpenclosecheckin = (e) => {
    setActivecheckindateopen(false);
  };

  const handleOpenclosecheckout = (e) => {
    setActivecheckoutdateopen(false);
  };

  const handleOpenclosereserve = (e) => {
    setActivereservedateopen(false);
  };

  const handleCalendarChangeFrom = (selectedDates) => {
    setDatefromstay(selectedDates[0]);
  };

  const handleCalendarChangeTo = (selectedDates) => {
    setDatetostay(selectedDates[0]);
  };

  const handleCalendarChangeFromcheckin = (selectedDates) => {
    setDatefromcheckin(selectedDates[0]);
  };

  const handleCalendarChangeTocheckin = (selectedDates) => {
    setDatetocheckin(selectedDates[0]);
  };

  const handleCalendarChangeFromcheckout = (selectedDates) => {
    setDatefromcheckout(selectedDates[0]);
  };

  const handleCalendarChangeTocheckout = (selectedDates) => {
    setDatetocheckout(selectedDates[0]);
  };

  const handleCalendarChangeFromreserve = (selectedDates) => {
    setDatefromreserve(selectedDates[0]);
  };

  const handleCalendarChangeToreserve = (selectedDates) => {
    setDatetoreserve(selectedDates[0]);
  };

  const nextoffterstep1 = () => {
    let isFormValid = true;
    const errors = {};

    if (!offername) {
      isFormValid = false;

      errors.offername = "Offer name is required!";
    }

    setOffer1error(errors);

    if (isFormValid === true) {
      setTab("step2");
      setOffer1valid(true);
    }
  };

  // offer1 page

  //offer2 page

  const handleChangeroomoffer2 = (isChecked) => {
    setActiveroom(isChecked);
    const updatedList = [...offer2room];
    const updatedAvailableBoard = [...availableboard];
    const updatedroomocc = [...selectedroomocc];
    const updatedother = [...selectedotherboard];
    const updatedallotherboard = [...otherboard];

    if (isChecked === true) {
      setActiveboard([]);

      updatedList.forEach((newone) => {
        newone.display_board.forEach((newItem) => {
          const newItemId = newItem;
          const existingItem = updatedAvailableBoard.find(
            (item) => item.id === newItemId
          );
          if (existingItem) {
            existingItem.count += existingItem.count;
          } else {
            // If the item doesn't exist in availableboard, add it with its count
            updatedAvailableBoard.push({ id: newItemId, count: 1 });
          }
        });
        if (updatedroomocc.length > 0) {
          if (!updatedroomocc.some((item) => item.value === newone.value))
            updatedroomocc.push({
              key: newone.key,
              label: newone.label,
              value: newone.value,
            });
        } else {
          updatedroomocc.push({
            key: newone.key,
            label: newone.label,
            value: newone.value,
          });
        }
        //new occupancy
        for (let i = 0; i < updatedroomocc.length; i++) {
          if (updatedroomocc[i].value === newone.value) {
            if (!Array.isArray(updatedroomocc[i].occupancies)) {
              updatedroomocc[i].occupancies = [];
            }
            for (let j = 0; j < newone.occupancies.length; j++) {
              updatedroomocc[i].occupancies.push(
                {
                  adult: newone.occupancies[j].adult,
                  child: newone.occupancies[j].child,
                  id: newone.occupancies[j].id,
                  occupancy_id: newone.occupancies[j].occupancy_id,
                }
                // newone.occupancies[j]
              );
            }
            // updatedroomocc[i].occupancies.push(newone.occupancies);
            // break; // Exit the loop after finding and updating the matching room
          }
        }

        //new board
        boardoffer2.forEach((newItem) => {
          for (let j = 0; j < updatedroomocc.length; j++) {
            if (newone.value === updatedroomocc[j].value) {
              if (newone.display_board.includes(newItem.value)) {
                for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
                  if (!updatedroomocc[j].occupancies[k].boards) {
                    updatedroomocc[j].occupancies[k].boards = [];
                  }
                  if (
                    !updatedroomocc[j].occupancies[k].boards.includes(
                      newItem.value
                    )
                  ) {
                    updatedroomocc[j].occupancies[k].boards.push({
                      hotel_board: newItem.hotel_borad,
                      label: newItem.label,
                      value: newItem.value,
                    });
                  }
                }
              }
            }
          }
        });
      });

      for (let i = 0; i < updatedroomocc.length; i++) {
        if (!updatedother[i]) {
          updatedother[i] = { selectedboard: [] };
        }
        if (!updatedallotherboard[i]) {
          updatedallotherboard[i] = { otherboard: [] };
        }
      }
      setSelectedotherboard(updatedother);
      setOtherboard(updatedallotherboard);
      setAvailbleboard(updatedAvailableBoard);
      setSelectedroomocc(updatedroomocc);
      // updatedList.forEach(newone=>{
      // updatedroomocc.push({ key: newone.key, label: newone.label ,value:newone.value });
      // })
      // setSelectedroomocc(updatedroomocc);
    } else {
      setAvailbleboard([]);
      setSelectedroomocc([]);
      updatedList.forEach((room) => {
        room.room_type = "";
      });
    }

    updatedList.forEach((room) => {
      room.selected = isChecked;
      room.occupancies.forEach((occ) => {
        occ.selected = isChecked;
      });
    });
    setOffer2room(updatedList);
  };

  const handlesingleroomselect = (i, isChecked) => {
    const updatedList = [...offer2room];
    const updatedroomocc = [...selectedroomocc];
    const updatedother = [...selectedotherboard];
    updatedList[i]["selected"] = isChecked;

    if (isChecked === true) {
      //count add to list
      if (availableboard.length > 0) {
        const updatedAvailableBoard = [...availableboard];

        updatedList[i].display_board.forEach((newItem) => {
          const newItemId = newItem;
          const existingItem = updatedAvailableBoard.find(
            (item) => item.id === newItemId
          );
          if (existingItem) {
            existingItem.count += existingItem.count;
          } else {
            // If the item doesn't exist in availableboard, add it with its count
            updatedAvailableBoard.push({ id: newItemId, count: 1 });
          }
        });

        setAvailbleboard(updatedAvailableBoard);
      } else {
        const newAvailableBoard = updatedList[i].display_board.map(
          (newItem) => ({
            id: newItem,
            count: 1,
          })
        );
        setAvailbleboard(newAvailableBoard);
      }

      //room add to new list

      updatedroomocc.push({
        key: updatedList[i].key,
        label: updatedList[i].label,
        value: updatedList[i].value,
      });
      setSelectedroomocc(updatedroomocc);

      let updatedother = [...selectedotherboard];
      const updatedallother = [...otherboard];

      if (!updatedother[i]) {
        updatedother[i] = { selectedboard: [] };
      }
      if (!updatedallother[i]) {
        updatedallother[i] = { otherboard: [] };
      }
      setSelectedotherboard(updatedother);
      setOtherboard(updatedallother);
    } else {
      updatedList[i]["room_type"] = "";
      updatedList[i]["all_label"] = false;

      //count minus
      if (availableboard.length > 0) {
        const updatedAvailableBoard = [...availableboard];

        updatedList[i].display_board.forEach((newItem) => {
          const newItemId = newItem;
          const existingItem = updatedAvailableBoard.find(
            (item) => item.id === newItemId
          );

          if (existingItem) {
            // Decrease the count when isChecked is false
            existingItem.count -= 1;

            // If count is zero or less, remove the item
            if (existingItem.count <= 0) {
              updatedAvailableBoard.splice(
                updatedAvailableBoard.indexOf(existingItem),
                1
              );
            }
          } else {
            console.error("Item not found with id:", newItemId);
          }
        });

        setAvailbleboard(updatedAvailableBoard);
      } else {
        // Handle the case where availableboard is empty
        console.error("Availableboard is empty");
      }

      const updatedroomoccFiltered = updatedroomocc.filter((item) => {
        return item.key !== updatedList[i].key;
      });

      setSelectedroomocc(updatedroomoccFiltered);
    }

    updatedList[i].occupancies.forEach((occ) => {
      occ.selected = false;
    });

    setOffer2room(updatedList);

    if (isChecked === false) {
      setActiveroom(isChecked);
    } else {
      const hasSelectedFalse = updatedList.some(
        (item) => item.selected === false || !("selected" in item)
      );
      if (!hasSelectedFalse) {
        setActiveroom(true);
      }
    }
  };

  const handlesingleoccupancyselect = (index, indx, isChecked) => {
    const updatedList = [...offer2room];

    const updatedroomocc = [...selectedroomocc];
    if (isChecked === true) {
      //occupancy id remove to new list
      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          if (!Array.isArray(updatedroomocc[i].occupancies)) {
            updatedroomocc[i].occupancies = [];
          }
          updatedroomocc[i].occupancies.push(
            updatedList[index].occupancies[indx]
          );
          break; // Exit the loop after finding and updating the matching room
        }
      }
      //room select after board select

      if (boardoffer2.find((item) => item.selected === true)) {
        updatedList.forEach((newone) => {
          boardoffer2.forEach((newItem) => {
            for (let j = 0; j < updatedroomocc.length; j++) {
              if (newone.value === updatedroomocc[j].value) {
                if (newone.room_type === "per_person") {
                  if (
                    newone.display_board.includes(newItem.value) &&
                    newItem.selected === true
                  ) {
                    for (
                      let k = 0;
                      k < updatedroomocc[j].occupancies.length;
                      k++
                    ) {
                      if (!updatedroomocc[j].occupancies[k].boards) {
                        updatedroomocc[j].occupancies[k].boards = [];
                        updatedroomocc[j].occupancies[k].boards.push({
                          hotel_board: newItem.hotel_borad,
                          label: newItem.label,
                          value: newItem.value,
                        });
                      } else {
                        if (
                          !updatedroomocc[j].occupancies[k].boards.some(
                            (item) => item.hotel_board === newItem.hotel_borad
                          )
                        ) {
                          updatedroomocc[j].occupancies[k].boards.push({
                            hotel_board: newItem.hotel_borad,
                            label: newItem.label,
                            value: newItem.value,
                          });
                        }
                      }
                    }
                  }
                }
              }
            }
          });
        });
      }
      setSelectedroomocc(updatedroomocc);

      // if( boardoffer2.find(item => item.selected === true)){
      //   updatedList.forEach(newone=>{
      //     if(newone.room_type === 'per_person'){
      //     boardoffer2.forEach(newItem => {
      //       if(newItem.selected==true){
      //         for (let j = 0; j < updatedroomocc.length; j++) {
      //           if (newone.value === updatedroomocc[j].value) {

      //             if (newone.display_board.includes(newItem.value)) {
      //               for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
      //                 if (!updatedroomocc[j].occupancies[k].boards) {
      //                   updatedroomocc[j].occupancies[k].boards = [];
      //                 }
      //                 if(!updatedroomocc[j].occupancies[k].boards.includes(newItem.value)){
      //                 updatedroomocc[j].occupancies[k].boards.push({
      //                   hotel_board: newItem.hotel_borad,
      //                   label: newItem.label,
      //                   value:newItem.value
      //                 });
      //                 }

      //               }
      //             }
      //           }
      //         }
      //       }
      //     })
      //   }
      //   })
      // }

      //   if( boardoffer2.find(item => item.selected === true)){
      //   updatedList.forEach(newone=>{
      //     boardoffer2.forEach(newItem => {
      //     for (let j = 0; j < updatedroomocc.length; j++) {
      //       if (newone.value === updatedroomocc[j].value) {

      //         if (newone.display_board.includes(newItem.value)) {
      //           for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
      //             if (!updatedroomocc[j].occupancies[k].boards) {
      //               updatedroomocc[j].occupancies[k].boards = [];
      //             }
      //             updatedroomocc[j].occupancies[k].boards.push({
      //               hotel_board: newItem.hotel_borad,
      //               label: newItem.label,
      //               value:newItem.value
      //             });

      //           }
      //         }
      //       }
      //     }
      //     })
      //   })
      // }
      setSelectedroomocc(updatedroomocc);
    } else {
      //occupancy id remove to new list
      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          updatedroomocc[i].occupancies = updatedroomocc[i].occupancies.filter(
            (item) =>
              item.occupancy_id !==
              updatedList[index].occupancies[indx].occupancy_id
          );
          break;
        }
      }
      setSelectedroomocc(updatedroomocc);
    }
    updatedList[index]["occupancies"][indx]["selected"] = isChecked;

    if (isChecked === false) {
      updatedList[index]["all_label"] = isChecked;
    } else {
      const hasSelectedFalse = updatedList[index].occupancies.some(
        (item) => item.selected === false || !("selected" in item)
      );
      if (!hasSelectedFalse) {
        updatedList[index]["all_label"] = isChecked;
      }
    }
    setOffer2room(updatedList);
  };

  const handleChangeboardoffer2 = (isChecked) => {
    setActiveboard(isChecked);
    const updatedList = [...boardoffer2];
    const updatedListroom = [...offer2room];
    const updatedroomocc = [...selectedroomocc];

    updatedList.forEach((room) => {
      if (availableboard.some((item) => item.id === room.value)) {
        room.selected = isChecked;
      }
    });

    setBoardoffer2(updatedList);

    if (isChecked === true) {
      updatedListroom.forEach((newone) => {
        updatedList.forEach((newItem) => {
          for (let j = 0; j < updatedroomocc.length; j++) {
            if (newone.value === updatedroomocc[j].value) {
              if (newone.display_board.includes(newItem.value)) {
                for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
                  if (!updatedroomocc[j].occupancies[k].boards) {
                    updatedroomocc[j].occupancies[k].boards = [];
                  }
                  updatedroomocc[j].occupancies[k].boards.push({
                    hotel_board: newItem.hotel_borad,
                    label: newItem.label,
                    value: newItem.value,
                  });
                }
              }
            }
          }
        });
      });

      setSelectedroomocc(updatedroomocc);
    } else {
      updatedListroom.forEach((newone) => {
        updatedList.forEach((newItem) => {
          for (let j = 0; j < updatedroomocc.length; j++) {
            if (newone.value === updatedroomocc[j].value) {
              if (newone.display_board.includes(newItem.value)) {
                for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
                  updatedroomocc[j].occupancies[k].boards = [];
                }
              }
            }
          }
        });
      });

      setSelectedroomocc(updatedroomocc);
    }
  };

  const handlesingleboardselect = (i, isChecked) => {
    const updatedList = [...boardoffer2];
    const rooms = [...offer2room];
    const updatedroomocc = [...selectedroomocc];

    updatedList[i]["selected"] = isChecked;
    setBoardoffer2(updatedList);

    if (isChecked === false) {
      setActiveboard(isChecked);

      //board added to new list
      for (let l = 0; l < rooms.length; l++) {
        for (let j = 0; j < updatedroomocc.length; j++) {
          if (rooms[l].value === updatedroomocc[j].value) {
            for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
              if (updatedroomocc[j].occupancies[k].boards) {
                updatedroomocc[j].occupancies[k].boards = updatedroomocc[
                  j
                ].occupancies[k].boards.filter((board) => {
                  return board.value !== updatedList[i].value;
                });
              }
            }
          }
        }
      }

      setSelectedroomocc(updatedroomocc);
    } else {
      const hasSelectedFalse = updatedList.some(
        (item) => item.selected === false || !("selected" in item)
      );
      if (!hasSelectedFalse) {
        setActiveboard(true);
      }
      for (let l = 0; l < rooms.length; l++) {
        for (let j = 0; j < updatedroomocc.length; j++) {
          if (rooms[l].value === updatedroomocc[j].value) {
            if (rooms[l].display_board.includes(updatedList[i].value)) {
              for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
                if (!updatedroomocc[j].occupancies[k].boards) {
                  updatedroomocc[j].occupancies[k].boards = [];
                }
                updatedroomocc[j].occupancies[k].boards.push({
                  hotel_board: updatedList[i].hotel_borad,
                  label: updatedList[i].label,
                  value: updatedList[i].value,
                });

                // Add updatedList[i].board to updatedroomocc

                // updatedroomocc[j].boards.push({ hotel_borad: updatedList[i].hotel_borad, label: updatedList[i].label ,value:updatedList[i].value });
              }
            }
          }
        }
      }
      setSelectedroomocc(updatedroomocc);
    }
  };

  const handleChangerateoffer2 = (isChecked) => {
    setActiverate(isChecked);
    const updatedList = [...ratelistoffer2];
    updatedList.forEach((room) => {
      room.selected = isChecked;
    });
    setRatelistoffer2(updatedList);
  };

  const handlesinglerateselect = (i, isChecked) => {
    const updatedList = [...ratelistoffer2];
    updatedList[i]["selected"] = isChecked;
    setRatelistoffer2(updatedList);

    if (isChecked === false) {
      setActiverate(isChecked);
    } else {
      const hasSelectedFalse = updatedList.some(
        (item) => item.selected === false || !("selected" in item)
      );
      if (!hasSelectedFalse) {
        setActiverate(true);
      }
    }
  };

  // const handlebasedon= (index,val)=>{
  //   const updatedroomocc = [...selectedroomocc];

  //   const updatedList = [...offer2room];
  //   updatedList[index]['room_type'] = val;
  //   setOffer2room(updatedList);

  //     if(val==='per_room'){
  //       for (let i = 0; i < updatedroomocc.length; i++) {
  //         if (updatedroomocc[i].value === updatedList[index].value) {
  //           if (!Array.isArray(updatedroomocc[i].occupancies)) {
  //             updatedroomocc[i].occupancies = [];
  //           }
  //           for (let j = 0; j < updatedList[index].occupancies.length; j++) {
  //             updatedroomocc[i].occupancies.push(updatedList[index].occupancies[j]);
  //           }
  //         }
  //       }
  //   //room select after board select
  //         if( boardoffer2.find(item => item.selected === true)){
  //         updatedList.forEach(newone=>{
  //           boardoffer2.forEach(newItem => {
  //           for (let j = 0; j < updatedroomocc.length; j++) {
  //             if (newone.value === updatedroomocc[j].value) {

  //               if (newone.display_board.includes(newItem.value)) {
  //                 for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
  //                   if (!updatedroomocc[j].occupancies[k].boards) {
  //                     updatedroomocc[j].occupancies[k].boards = [];
  //                     updatedroomocc[j].occupancies[k].boards.push({
  //                       hotel_board: newItem.hotel_borad,
  //                       label: newItem.label,
  //                       value:newItem.value
  //                     });
  //                   }
  //                   else{
  //                     if(!updatedroomocc[j].occupancies[k].boards.some((item) => item.hotel_board === newItem.hotel_borad)){
  //                     updatedroomocc[j].occupancies[k].boards.push({
  //                       hotel_board: newItem.hotel_borad,
  //                       label: newItem.label,
  //                       value:newItem.value
  //                     });
  //                   }
  //                   }

  //                 }
  //               }
  //             }
  //           }
  //           })
  //         })
  //         }
  //         setSelectedroomocc(updatedroomocc);
  //    }
  //    else{
  //     for(let i = 0; i < updatedroomocc.length; i++) {
  //       if(updatedroomocc[i].value === updatedList[index].value) {
  //         updatedroomocc[i].occupancies = [];
  //       }
  //     }
  //    }

  // }

  const handlebasedon = (index, val) => {
    const updatedroomocc = [...selectedroomocc];
    const updatedList = [...offer2room];

    updatedList[index]["room_type"] = val;

    if (val === "per_room") {
      updatedList[index]["all_label"] = false;
      updatedList[index].occupancies.forEach((occ) => {
        occ.selected = false;
      });
      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          updatedroomocc[i]["room_type"] = val;

          if (!Array.isArray(updatedroomocc[i].occupancies)) {
            updatedroomocc[i].occupancies = [];
          }

          for (let j = 0; j < updatedList[index].occupancies.length; j++) {
            if (
              !updatedroomocc[i].occupancies.some(
                (item) =>
                  item.occupancy_id ===
                  updatedList[index].occupancies[j].occupancy_id
              )
            ) {
              updatedroomocc[i].occupancies.push(
                updatedList[index].occupancies[j]
              );
            }
          }
        }
      }
      //room select after board select
      if (boardoffer2.find((item) => item.selected === true)) {
        updatedList.forEach((newone) => {
          boardoffer2.forEach((newItem) => {
            for (let j = 0; j < updatedroomocc.length; j++) {
              if (newone.value === updatedroomocc[j].value) {
                if (
                  newone.display_board.includes(newItem.value) &&
                  newItem.selected === true
                ) {
                  for (
                    let k = 0;
                    k < updatedroomocc[j].occupancies.length;
                    k++
                  ) {
                    if (!updatedroomocc[j].occupancies[k].boards) {
                      updatedroomocc[j].occupancies[k].boards = [];
                      updatedroomocc[j].occupancies[k].boards.push({
                        hotel_board: newItem.hotel_borad,
                        label: newItem.label,
                        value: newItem.value,
                      });
                    } else {
                      if (
                        !updatedroomocc[j].occupancies[k].boards.some(
                          (item) => item.hotel_board === newItem.hotel_borad
                        )
                      ) {
                        updatedroomocc[j].occupancies[k].boards.push({
                          hotel_board: newItem.hotel_borad,
                          label: newItem.label,
                          value: newItem.value,
                        });
                      }
                    }
                  }
                }
              }
            }
          });
        });
      }
      setSelectedroomocc(updatedroomocc);
    } else {
      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          updatedroomocc[i]["room_type"] = val;

          updatedroomocc[i].occupancies = [];
        }
      }
      updatedList[index].all_label = false;
      updatedList[index].occupancies.forEach((occ) => {
        occ.selected = false;
      });
    }
    setOffer2room(updatedList);
  };

  const handleChangeroomoffer2all_label = (index, isChecked) => {
    const updatedList = [...offer2room];
    const updatedroomocc = [...selectedroomocc];
    updatedList[index]["all_label"] = isChecked;
    if (isChecked === true) {
      updatedList[index].occupancies.forEach((occ) => {
        occ.selected = true;
      });

      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          if (!Array.isArray(updatedroomocc[i].occupancies)) {
            updatedroomocc[i].occupancies = [];
          }
          // updatedroomocc[i].occupancies.push(newone.occupancies);
          for (let j = 0; j < updatedList[index].occupancies.length; j++) {
            // if (!(updatedList[index].occupancies.every((item) => !updatedroomocc[i].occupancies.find((item5) => item5.occupancy_id === item.occupancy_id && item.selected===true)))) {

            for (let j = 0; j < updatedList[index].occupancies.length; j++) {
              if (updatedList[index].occupancies[j].selected === true) {
                if (
                  !updatedroomocc[i].occupancies.some(
                    (item5) =>
                      item5.occupancy_id ===
                      updatedList[index].occupancies[j].occupancy_id
                  )
                ) {
                  updatedroomocc[i].occupancies.push(
                    updatedList[index].occupancies[j]
                  );
                }
              }
            }
          }
        }
      }

      //room select after board select
      // if( boardoffer2.find(item => item.selected === true)){
      //   updatedList.forEach(newone=>{
      //     if(newone.room_type === 'per_person'){
      //     boardoffer2.forEach(newItem => {
      //       if(newItem.selected==true){
      //         for (let j = 0; j < updatedroomocc.length; j++) {
      //           if (newone.value === updatedroomocc[j].value) {

      //             if (newone.display_board.includes(newItem.value)) {
      //               for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
      //                 if (!updatedroomocc[j].occupancies[k].boards) {
      //                   updatedroomocc[j].occupancies[k].boards = [];
      //                 }
      //                 if(!updatedroomocc[j].occupancies[k].boards.includes(newItem.value)){
      //                 updatedroomocc[j].occupancies[k].boards.push({
      //                   hotel_board: newItem.hotel_borad,
      //                   label: newItem.label,
      //                   value:newItem.value
      //                 });
      //                 }

      //               }
      //             }
      //           }
      //         }
      //       }
      //     })
      //   }
      //   })
      // }

      if (boardoffer2.find((item) => item.selected === true)) {
        updatedList.forEach((newone) => {
          boardoffer2.forEach((newItem) => {
            for (let j = 0; j < updatedroomocc.length; j++) {
              if (newone.value === updatedroomocc[j].value) {
                if (newone.room_type === "per_person") {
                  if (
                    newone.display_board.includes(newItem.value) &&
                    newItem.selected === true
                  ) {
                    for (
                      let k = 0;
                      k < updatedroomocc[j].occupancies.length;
                      k++
                    ) {
                      if (!updatedroomocc[j].occupancies[k].boards) {
                        updatedroomocc[j].occupancies[k].boards = [];
                        updatedroomocc[j].occupancies[k].boards.push({
                          hotel_board: newItem.hotel_borad,
                          label: newItem.label,
                          value: newItem.value,
                        });
                      } else {
                        if (
                          !updatedroomocc[j].occupancies[k].boards.some(
                            (item) => item.hotel_board === newItem.hotel_borad
                          )
                        ) {
                          updatedroomocc[j].occupancies[k].boards.push({
                            hotel_board: newItem.hotel_borad,
                            label: newItem.label,
                            value: newItem.value,
                          });
                        }
                      }
                    }
                  }
                }
              }
            }
          });
        });
      }
      setSelectedroomocc(updatedroomocc);
    } else {
      updatedList[index].occupancies.forEach((occ) => {
        occ.selected = false;
      });

      for (let i = 0; i < updatedroomocc.length; i++) {
        if (updatedroomocc[i].value === updatedList[index].value) {
          updatedroomocc[i].occupancies = [];
        }
      }
    }
    setOffer2room(updatedList);
    setSelectedroomocc(updatedroomocc);
  };

  const nextoffterstep2 = (icon) => {
    let isFormValid = false;
    let isFormValid1 = false;
    let isFormValid2 = false;
    let isFormValid3 = true;

    let arr = [];
    const errors = {};
    const hasSelectedFalse = offer2room.some((item) => item.selected === true);
    if (hasSelectedFalse) {
      isFormValid = true;
    }

    if (hasSelectedFalse) {
      const hasSelectedboard = boardoffer2.filter(
        (item6) => item6.selected === true
      );

      for (let i = 0; i < offer2room.length; i++) {
        const errors1 = {};

        if (offer2room[i].selected === true) {
          const isMatchFound =
            offer2room[i].display_board.filter((item6) =>
              hasSelectedboard.some((item) => item.value === item6)
            ).length > 0;
          if (offer2room[i].room_type === "") {
            isFormValid = false;
          } else {
            if (isMatchFound === true) {
              if (offer2room[i].room_type === "per_person") {
                const hasSelectedTrue = offer2room[i].occupancies.some(
                  (item) => item.selected === true
                );

                if (hasSelectedTrue) {
                  isFormValid = true;
                } else {
                  isFormValid = false;
                }
              } else if (offer2room[i].room_type === "per_room") {
                isFormValid = true;
              } else {
                isFormValid = false;
              }
            } else {
              isFormValid3 = false;
              errors1.room_name = "This room not included the selected board !";
            }
          }
        }
        arr.push(errors1);
      }
      setErrorroom(arr);
    }
    if (isFormValid === false) {
      errors.room = "select one Room and Occupancy!";
    }

    const hasSelectedFalse1 = boardoffer2.some(
      (item) => item.selected === true
    );
    if (hasSelectedFalse1) {
      isFormValid1 = true;
    }

    // for(let i=0;i<boardoffer2.length;i++){
    //   if(boardoffer2[i].selected===true){

    //          isFormValid1 = true;
    //     }
    //   }

    if (isFormValid1 === false) {
      errors.board = "select one Board";
    }

    // for(let i=0;i<ratelistoffer2.length;i++){
    //   if(ratelistoffer2[i].selected===true){

    //          isFormValid2 = true;
    //     }
    //   }

    const hasSelectedFalse3 = ratelistoffer2.some(
      (item) => item.selected === true
    );
    if (hasSelectedFalse3) {
      isFormValid2 = true;
    }

    if (isFormValid2 === false) {
      errors.rate = "select one Rate";
    }

    setOffer2error(errors);

    if (
      isFormValid === true &&
      isFormValid1 === true &&
      isFormValid2 === true &&
      isFormValid3 === true
    ) {
      if (icon === "prev") {
        setTab("step3");
        setOffer2valid(true);
      } else {
        setTab("step3");
        setOffer2valid(true);
      }
    }

    const updatedBoard = [...otherboard];
    for (let j = 0; j < selectedroomocc.length; j++) {
      for (let i = 0; i < boardoffer2.length; i++) {
        if (boardoffer2[i].selected === false || !boardoffer2[i].selected) {
          if (updatedBoard[j].otherboard.length > 0) {
            if (
              !updatedBoard[j].otherboard.some(
                (item) => item.value === boardoffer2[i].value
              )
            ) {
              updatedBoard[j].otherboard.push({
                label: boardoffer2[i].label,
                value: boardoffer2[i].value,
                lang_code: boardoffer2[i].lang_code,
                hotel_borad: boardoffer2[i].hotel_borad,
              });
            }
          } else {
            updatedBoard[j].otherboard.push({
              label: boardoffer2[i].label,
              value: boardoffer2[i].value,
              lang_code: boardoffer2[i].lang_code,
              hotel_borad: boardoffer2[i].hotel_borad,
            });
          }
        } else if (boardoffer2[i].selected === true) {
          for (let k = 0; k < offer2room.length; k++) {
            if (offer2room[k].value === selectedroomocc[j].value) {
              // Found the matching element
              const matchingElement = offer2room[k];
              if (
                !matchingElement.display_board.some(
                  (item) => item === boardoffer2[i].value
                )
              ) {
                if (
                  !updatedBoard[j].otherboard.some(
                    (item) => item.value === boardoffer2[i].value
                  )
                ) {
                  updatedBoard[j].otherboard.push({
                    label: boardoffer2[i].label,
                    value: boardoffer2[i].value,
                    lang_code: boardoffer2[i].lang_code,
                    hotel_borad: boardoffer2[i].hotel_borad,
                  });
                }
              }
            }
          }
        }
      }
    }
    setOtherboard(updatedBoard);
  };

  //offer2 page

  //offer3 page

  const handleOptionChangeoffer3price = (val, name) => {
    setOffer({ ...offer, [name]: val });
  };

  const handleoffer3checkbox = (isChecked, name) => {
    setOffer({ ...offer, [name]: isChecked });
  };

  const handleoffer3weekcheck = (isChecked, name) => {
    const updatedroomocc = [...selectedroomocc];

    if (isChecked === true) {
      for (let j = 0; j < updatedroomocc.length; j++) {
        for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
          // for(let l=0;l<updatedroomocc[j].occupancies[k].boards.length;l++){
          //   updatedroomocc[j].occupancies[k].boards[l].rate='';
          // }
          // Add updatedList[i].board to updatedroomocc
          // updatedroomocc[j].boards.push({ hotel_borad: updatedList[i].hotel_borad, label: updatedList[i].label ,value:updatedList[i].value });
        }
      }
      setSelectedroomocc(updatedroomocc);
    } else {
      for (let j = 0; j < updatedroomocc.length; j++) {
        for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
          for (
            let l = 0;
            l < updatedroomocc[j].occupancies[k].boards.length;
            l++
          ) {
            updatedroomocc[j].occupancies[k].boards[l].monday = "";
            updatedroomocc[j].occupancies[k].boards[l].tuesday = "";
            updatedroomocc[j].occupancies[k].boards[l].wednesday = "";
            updatedroomocc[j].occupancies[k].boards[l].thursday = "";
            updatedroomocc[j].occupancies[k].boards[l].friday = "";
            updatedroomocc[j].occupancies[k].boards[l].saturday = "";
            updatedroomocc[j].occupancies[k].boards[l].sunday = "";
          }
        }
      }
      setSelectedroomocc(updatedroomocc);
    }
    setOffer({ ...offer, [name]: isChecked });
  };

  const addInputFieldoffer3 = () => {
    setInputFieldsexception([
      ...inputFieldsexception,
      {
        datefrom: [],
        dateto: [],
        discounts: "",
      },
    ]);
  };
  const removeInputFieldsoffer3 = (index) => {
    const rows = [...inputFieldsexception];
    rows.splice(index, 1);
    setInputFieldsexception(rows);
  };

  let handleChangedatefrom = (i, selectedDates) => {
    let newval = [...inputFieldsexception];
    //  inputFieldsexception[i]["fromdate"] = data;
    if (selectedDates.length === 1) {
      // newval[i]["fromdate"] = selectedDates[0];
      newval[i]["datefrom"] = moment(selectedDates[0]).format("DD-MM-YYYY");
    } else if (selectedDates.length === 2) {
      // newval[i]["fromdate"] = selectedDates[0];
      // newval[i]["todate"] = selectedDates[1];

      newval[i]["datefrom"] = moment(selectedDates[0]).format("DD-MM-YYYY");
      newval[i]["dateto"] = moment(selectedDates[1]).format("DD-MM-YYYY");
    }
    setInputFieldsexception(newval);
  };

  let handleChangetodate = (i, data) => {
    // setDateto(data);
    let newval = [...inputFieldsexception];
    inputFieldsexception[i]["dateto"] = data;
    setInputFieldsexception(newval);
  };
  let handleChangediscount = (index, val, name) => {
    let newInputFields = [...inputFieldsexception];
    newInputFields[index][name] = val;
    setInputFieldsexception(newInputFields);
  };

  const handleloyalchangeoffer3country = (isChecked, index) => {
    const updatedList = [...countryOffer3];
    updatedList[index]["selected"] = isChecked;
    setCountryOffer3(updatedList);
  };

  let handleotherboard = (ind, val) => {
    let updatedList = [...selectedroomocc];
    for (let i = 0; i < val.length; i++) {
      for (let k = 0; k < updatedList[ind].occupancies.length; k++) {
        if (
          !updatedList[ind].occupancies[k].boards.some(
            (item) => item.value === val[i].value
          )
        ) {
          updatedList[ind].occupancies[k].boards.push({
            hotel_board: val[i].hotel_borad,
            label: val[i].label,
            value: val[i].value,
            rate: "",
          });
        }
      }
    }
    for (let k = 0; k < updatedList[ind]?.occupancies?.length; k++) {
      for (
        let l = 0;
        l < updatedList[ind]?.occupancies[k]?.boards?.length;
        l++
      ) {
        const board = updatedList[ind].occupancies[k].boards[l];
        const isBoardInSelectedOtherBoard = val.some(
          (item) => item.value === board.value
        );
        const isBoardInBoardOffer2 = boardoffer2.some(
          (item) =>
            item.value === board.value &&
            (item.selected === false || !item.selected)
        );
        if (!isBoardInSelectedOtherBoard && isBoardInBoardOffer2) {
          updatedList[ind].occupancies[k].boards.splice(l, 1);
          l--;
        }
      }
    }
    setSelectedroomocc(updatedList);
    let updatedother = [...selectedotherboard];
    if (!updatedother[ind]) {
      updatedother[ind] = { selectedboard: [] };
    }
    updatedother[ind].selectedboard = val;
    setSelectedotherboard(updatedother);
  };

  const addInputFieldextra = () => {
    setInputFieldsextra([
      ...inputFieldsextra,
      {
        fromdate: "",
        todate: "",
        discount: "",
      },
    ]);
  };
  const removeInputFieldsextra = (index) => {
    const rows = [...inputFieldsextra];
    rows.splice(index, 1);
    setInputFieldsextra(rows);
  };

  let handleChangedatefromextra = (i, selectedDates) => {
    //     let newval = [...inputFieldsextra];
    //     inputFieldsextra[i]["datefrom"] = data;
    // setInputFieldsextra(newval)

    let newval = [...inputFieldsextra];
    if (selectedDates.length === 1) {
      // newval[i]["datefrom"] = selectedDates[0];
      newval[i]["fromdate"] = moment(selectedDates[0]).format("DD-MM-YYYY");
    } else if (selectedDates.length === 2) {
      // newval[i]["datefrom"] = selectedDates[0];
      // newval[i]["dateto"] = selectedDates[1];

      newval[i]["fromdate"] = moment(selectedDates[0]).format("DD-MM-YYYY");
      newval[i]["todate"] = moment(selectedDates[1]).format("DD-MM-YYYY");
    }
    setInputFieldsextra(newval);
  };

  let handleChangetodateextra = (i, data) => {
    let newval = [...inputFieldsextra];
    inputFieldsextra[i]["todate"] = data;
    setInputFieldsextra(newval);
  };

  let handleChangediscountsextra = (index, event) => {
    const value = event.target.value;
    const name = event.target.name;
    let newInputFieldsextra = [...inputFieldsextra];
    newInputFieldsextra[index][name] = value;
    setInputFieldsextra(newInputFieldsextra);
  };

  const handleweekinput = (i, ind, index, e) => {
    const updatedList = [...selectedroomocc];
    updatedList[i]["occupancies"][ind]["boards"][index][e.target.name] =
      e.target.value;
    setSelectedroomocc(updatedList);
  };

  const handleoffer3change = (e) => {
    if (e.target.name === "increment_type") {
      setOffer({
        ...offer,
        [e.target.name]: e.target.value,
        ["increment_room"]: "",
        ["increment_per_adult"]: "",
        ["increment_per_child"]: "",
      });
    } else {
      setOffer({ ...offer, [e.target.name]: e.target.value });
    }
  };

  const handleInputChangeconutryvalue = (i, val, name) => {
    const updatedList = [...multicountri3];
    updatedList[i][name] = val;
    setMulticountry3(updatedList);
  };

  let handleClickroomweek = (i) => {
    let newval = [...tabroomweek];
    tabroomweek[i]["click"] = !tabroomweek[i]["click"];
    setTabroomweek(newval);
  };

  let handleTabweek = (val, i) => {
    let newval = [...tabroomweek];
    tabroomweek[i]["tab"] = val;
    setTabroomweek(newval);
  };

  let handleClickroom = (i) => {
    let newval = [...tabroom];
    tabroom[i]["click"] = !tabroom[i]["click"];
    setTabroom(newval);
  };

  let handleTab = (val, i) => {
    let newval = [...tabroom];
    tabroom[i]["tab"] = val;
    setTabroom(newval);
  };

  const handleweekinputextend = (i, ind, index, e) => {
    const updatedList = [...selectedroomocc];
    for (let j = 0; j < updatedList[i].occupancies.length; j++) {
      for (let k = 0; k < updatedList[i].occupancies[j].boards.length; k++) {
        updatedList[i].occupancies[j].boards[index][e.target.name] =
          e.target.value;
      }
    }
    setSelectedroomocc(updatedList);
  };

  const nextoffterstep3rate = (icon) => {
    let isFormValid = true;

    const errors = {};
    if (offer.price_type === "fixed") {
      if (
        offer.fixed_week_price_status === true ||
        offer.fixed_week_price_status === 1
      ) {
        for (let i = 0; i < selectedroomocc.length; i++) {
          for (let j = 0; j < selectedroomocc[i].occupancies.length; j++) {
            for (
              let k = 0;
              k < selectedroomocc[i].occupancies[j].boards.length;
              k++
            ) {
              if (
                !selectedroomocc[i].occupancies[j].boards[k].monday &&
                !selectedroomocc[i].occupancies[j].boards[k].tuesday &&
                !selectedroomocc[i].occupancies[j].boards[k].wednesday &&
                !selectedroomocc[i].occupancies[j].boards[k].thursday &&
                !selectedroomocc[i].occupancies[j].boards[k].friday &&
                !selectedroomocc[i].occupancies[j].boards[k].saturday &&
                !selectedroomocc[i].occupancies[j].boards[k].sunday
              ) {
                isFormValid = false;
                errors.fixed_week_price =
                  "Price for each day of the week is required.";
              }
            }
          }
        }
      } else {
        for (let i = 0; i < selectedroomocc.length; i++) {
          for (let j = 0; j < selectedroomocc[i].occupancies.length; j++) {
            for (
              let k = 0;
              k < selectedroomocc[i].occupancies[j].boards.length;
              k++
            ) {
              if (!selectedroomocc[i].occupancies[j].boards[k].rate) {
                isFormValid = false;
                errors.fixed_price =
                  "Please choose a fixed price for the offer";
              }
            }
          }
        }
      }
    }
    if (offer.price_type === "discount") {
      if (
        !offer.discount_value ||
        offer.discount_value === "0" ||
        offer.discount_value === 0
      ) {
        isFormValid = false;
        errors.discount_value = "Please provide a value";
      }
    }
    setOffer3error(errors);
    if (isFormValid === true) {
      if (icon === "prev") {
        setTab("step2");
        setOffer3validrate(true);
      } else {
        setRatetab("increment");
        setOffer3validrate(true);
      }
    }
  };

  const nextoffterstep3increment = (icon) => {
    let isFormValid = true;

    const errors = {};
    if (offer.increment_status === true) {
      if (!offer.increment_type || offer.increment_type === "per_person") {
        if (
          (!offer.increment_per_adult ||
            offer.increment_per_adult === "0" ||
            offer.increment_per_adult === 0) &&
          (!offer.increment_per_child ||
            offer.increment_per_child === "0" ||
            offer.increment_per_child === 0)
        ) {
          isFormValid = false;
          errors.increment_type =
            "If selecting the increment option, please enter a positive value ";
        }
      } else {
        if (
          !offer.increment_room ||
          offer.increment_room === "0" ||
          offer.increment_room === 0
        ) {
          isFormValid = false;
          errors.increment_type =
            "If selecting the increment option, please specify a positive value ";
        }
      }
    }

    setOffer3error(errors);

    if (isFormValid === true) {
      if (icon === "prev") {
        setRatetab("rate");
        setOffer3validncrement(true);
      } else {
        setRatetab("free_nights");
        setOffer3validncrement(true);
      }
    }
  };

  const nextoffterstep3freenight = (icon) => {
    let isFormValid = true;

    const errors = {};

    if (offer.free_night_status === true) {
      if (!offer.free_night_type) {
        isFormValid = false;
        errors.free_booking_night1 = "Please select one field.";
      } else {
        if (offer.free_night_type === "last_night") {
          if (!offer.free_booking_night) {
            isFormValid = false;
            errors.free_booking_night1 = "Please select a value.";
          }
        } else if (offer.free_night_type === "cheapest_night") {
          if (!offer.free_booking_night) {
            isFormValid = false;
            errors.free_booking_night2 = "Please select a value.";
          }
        }
      }
    }
    setOffer3error(errors);

    if (isFormValid === true) {
      if (icon === "prev") {
        setRatetab("increment");
        setOffer3validfreenight(true);
      } else {
        setRatetab("extra_discount");
        setOffer3validfreenight(true);
      }
    }
  };

  const nextoffterstep3extra = (icon) => {
    let isFormValid = true;

    const errors = {};

    if (offer.booking_at_night_status === true) {
      if (
        !offer.booking_night_discount ||
        offer.booking_night_discount === 0 ||
        offer.booking_night_discount === "0"
      ) {
        isFormValid = false;
        errors.booking_time_discount = "Please enter a valid discount.";
      }

      // if((!offer.hours_discount_from ) || !offer.hours_discount_to){
      if (
        offer.hours_discount_from === undefined ||
        offer.hours_discount_to === undefined ||
        offer.hours_discount_from === null ||
        offer.hours_discount_to === null
      ) {
        isFormValid = false;
        errors.hours_discount_to = "Please input the hours.";
      }
    }

    if (offer.booking_weekend_discount_status === true) {
      if (
        !offer.booking_weekend_discount ||
        offer.booking_weekend_discount === 0 ||
        offer.booking_weekend_discount === "0"
      ) {
        isFormValid = false;
        errors.booking_weekend_discount = "Please enter a valid discount.";
      }
      if (
        !offer.booking_weekend_week_from ||
        offer.booking_weekend_time_from === undefined ||
        offer.booking_weekend_time_from === null ||
        !offer.booking_weekend_week_to ||
        offer.booking_weekend_time_to === undefined ||
        offer.booking_weekend_time_to === null
      ) {
        isFormValid = false;
        errors.booking_weekend_week_from = "Please input all the fields.";
      }
    }
    if (offer.long_stay_discount_status === true) {
      if (!offer.long_stay_discount_01) {
        isFormValid = false;
        errors.long_stay_discount0_percentage =
          "Please input a discount for a long stay.";
      }
    }
    if (offer.long_stay_discount_status === true) {
      let first = 1;
      if (offer?.long_stay_discount_nights_01) {
        first = offer?.long_stay_discount_nights_01;
      }
      if (
        (first &&
          offer?.long_stay_discount_nights_02 &&
          parseInt(first) > parseInt(offer?.long_stay_discount_nights_02)) ||
        (offer?.long_stay_discount_nights_02 &&
          offer?.long_stay_discount_nights_03 &&
          parseInt(offer?.long_stay_discount_nights_02) >
            parseInt(offer?.long_stay_discount_nights_03)) ||
        (offer?.long_stay_discount_nights_03 &&
          offer?.long_stay_discount_nights_04 &&
          parseInt(offer?.long_stay_discount_nights_03) >
            parseInt(offer?.long_stay_discount_nights_04))
      ) {
        isFormValid = false;
        errors.long_stay_discount1_night =
          "Sequence discounts: short to long stays.";
      }
    }
    if (offer.early_booking_discount_status === true) {
      if (
        !offer.early_booking_discount_upto_01 ||
        !offer.early_booking_discount_01
      ) {
        isFormValid = false;
        errors.early_booking_discount_status =
          "A discount for early booking must be inserted";
      }
    }

    if (offer.last_minute_discount_status === true) {
      if (!offer.last_minute_discount_01) {
        isFormValid = false;
        errors.last_minute_discount0_discount =
          "Please input a discount for last-minute bookings";
      }
    }

    setOffer3error(errors);

    if (isFormValid === true) {
      if (icon === "prev") {
        setRatetab("free_nights");
        setOffer3validextrae(true);
      } else {
        setRatetab("country");
        setOffer3validextrae(true);
      }
    }
  };

  const nextoffterstep3country = (icon) => {
    let isFormValid = true;

    const errors = {};

    if (offer.country_discount_status === true) {
      if (multicountri3.length === 0) {
        isFormValid = false;
        errors.country_discount = "You must select at least one area.";
      } else {
        const hasSelectedFalse = multicountri3.some((item) => {
          if (!item.discount) {
            isFormValid = false;
            errors.country_discount = "Please specify a value.";

            return true;
          }
        });
      }
    }

    setOffer3error(errors);

    if (isFormValid === true) {
      if (icon === "prev") {
        setRatetab("extra_discount");
        setOffer3validcountry(true);
      } else {
        setRatetab("device_discount");
        setOffer3validcountry(true);
      }
    }
  };

  const nextoffterstep3device = (icon) => {
    let isFormValid = true;

    const errors = {};
    if (offer.device_discount_status === true) {
      if (!offer.device_discount_type) {
        isFormValid = false;
        errors.device_discount_percentage = "Please select one field";
      } else {
        if (!offer.device_discount_value) {
          isFormValid = false;
          errors.device_discount_percentage = "Please specify a value";
        }
      }
    }

    setOffer3error(errors);

    if (isFormValid === true) {
      if (icon === "prev") {
        setRatetab("country");
        setOffer3validdivice(true);
      } else {
        setTab("step4");
        setOffer3valid(true);
      }
    }
  };

  //offer3 page

  //offer4 page

  const handleoffer4checkbox = (isChecked, name) => {
    setOffer({ ...offer, [name]: isChecked });
  };

  const handleloyalchangeoffer4 = (isChecked, index) => {
    const updatedList = [...Loyaltyclublist];
    updatedList[index]["selected"] = isChecked;
    setLoyaltyclublist(updatedList);
  };

  const handlechangeoffer4 = (e) => {
    setOffer({ ...offer, [e.target.name]: e.target.value });
  };

  const handleOptionChangeoffer4Cancelpolicy = (val, name) => {
    setOffer({ ...offer, [name]: val });
  };

  const handleloyalchangeoffer4country = (isChecked, index) => {
    const updatedList = [...countrylist];
    updatedList[index]["offercountry"] = isChecked;
    setCountrylist(updatedList);
  };

  const handleloyalchangeoffer4loyal = (index, isChecked, value) => {
    console.log(isChecked);
    console.log(value);
    let updatedBoardTog;
    if (loyalty_club){
     updatedBoardTog = [...loyalty_club];
    }
    else{
       updatedBoardTog = [];

    }
    const updateloyal = [...Loyaltyclublist];
    if (isChecked) {
      if (!updatedBoardTog?.some((item) => item === value)) {
        updatedBoardTog.push(value);
      }
    } else {
      if (updatedBoardTog?.some((item) => item === value)) {
        updatedBoardTog = updatedBoardTog.filter((item) => item !== value);
      }
    }
    setLoyalty_club(updatedBoardTog);
  };

  const nextoffterstep4 = (icon) => {
    let isFormValid = true;

    const errors = {};
    // if(offer.promocode_available_status===false && offer.loyality_club_status===false && offer.advance_booking_status===false && offer.cancelation_policy_status===false && offer.offer_min_stay_status===false && offer.offer_max_stay_status===false&& offer.min_rooms_per_booking_status===false&& offer.offer_close_for_min_rooms_status===false && offer.max_room_per_night_status===false && offer.offer_last_minute_status===false && offer.offer_display_time_status===false &&offer.offer_available_country_status===false && offer.offer_available_device_status===false){
    //   isFormValid=false;
    //   errors.offer4 = "select one Field";
    // }

    if (offer.loyality_club_status === true) {
      //  const hasSelectedFalse = Loyaltyclublist.some((item) => item.selected === true );
      if (loyalty_club.length === 0) {
        isFormValid = false;
        errors.loyalty_club = "select one Field";
      }
    }

    if (offer.advance_booking_status === true) {
      if (!offer.advance_booking_days) {
        isFormValid = false;
        errors.ad_booking_days = "select one Field";
      }
    }

    if (offer.cancelation_policy_status === true) {
      if (!offer.cancelation_policy_id) {
        isFormValid = false;
        errors.cancel_policy = "select one Field";
      }
    }

    if (offer.offer_display_time_status === true) {
      if (
        offer.offer_display_hours_status === false &&
        offer.offer_display_weekend_status === false
      ) {
        isFormValid = false;
        errors.offer_display_hours_status = "select one Field";
      } else if (offer.offer_display_weekend_status === true) {
        if (
          !offer.offer_display_weekend_week_from ||
          !offer.offer_display_weekend_time_from ||
          !offer.offer_display_weekend_week_to ||
          !offer.offer_display_weekend_time_to
        ) {
          isFormValid = false;
          errors.offer_display_hours_status = "Please choose one field";
        }
      }
    }

    if (offer.offer_available_country_status === true) {
      // const hasSelectedFalse = countrylist.some((item) => item.offercountry === true );
      // if (!hasSelectedFalse) {
      //   isFormValid=false;
      //   errors.apply_country = "select one Field";

      // }
      if (multicountri4.length === 0) {
        isFormValid = false;
        errors.apply_country = "Please select one country";
      }
    }

    if (offer.offer_available_device_status === true) {
      if (!offer.offer_available_device_type) {
        isFormValid = false;
        errors.only_device = "select one Field";
      }
    }
    setOffer4error(errors);

    if (isFormValid === true) {
      if (icon === "prev") {
        setTab("step3");
        setOffer4valid(true);
      } else {
        setTab("step5");
        setOffer4valid(true);
      }
    }
  };

  //offer4 page

  //offer5 page

  const addInputField = () => {
    setOtherloyal([
      ...otherloyal,
      {
        loyalty_name: "",
        lang: [],
      },
    ]);
  };
  const removeInputFields = (index) => {
    const rows = [...otherloyal];
    rows.splice(index, 1);
    setOtherloyal(rows);
  };

  const handleloyalchange = (isChecked, index) => {
    const updatedList = [...loyalty];
    updatedList[index]["selected"] = isChecked;
    setLoyalty(updatedList);
  };

  const hangleotherloyal = (index, val, name) => {
    const updatedList = [...otherloyal];
    updatedList[index][name] = val;
    setOtherloyal(updatedList);
  };

  const handlelanglo = (val) => {
    setLoyallang(val);
    const updatedList = [...otherloyal];
    for (let i = 0; i < updatedList.length; i++) {
      if (!updatedList[i].lang.some((item) => item.lang_code === val)) {
        updatedList[i].lang.push({ lang_code: val, loyalty_lang_name: "" });
      }
    }
    setOtherloyal(updatedList);
  };

  const hangleotherloyallang = (index, val, name) => {
    const updatedList = [...otherloyal];
    const matchingIndex = updatedList[index].lang.findIndex(
      (language) => language.lang_code === loyallang
    );
    if (matchingIndex !== -1) {
      updatedList[index]["lang"][matchingIndex][name] = val;
    } else {
      const newLanguage = { lang_code: loyallang };
      newLanguage[name] = val;
      updatedList[index].lang.push(newLanguage);
    }
    setOtherloyal(updatedList);
  };

  const nextoffterstep5 = (icon) => {
    if (icon === "prev") {
      if (offer_include_status === false) {
        setTab("step4");
        setOffer5valid(true);
      } else {
        let isFormValid = true;

        const errors = {};

        const hasSelectedFalse = loyalty.some((item) => item.selected === true);
        if (!hasSelectedFalse && otherloyal_status === false) {
          isFormValid = false;
          errors.loyalty = "select one Field";
        }

        if (otherloyal_status === true) {
          const hasSelectedFalse = otherloyal.some(
            (item) => item.loyalty_name !== ""
          );
          if (!hasSelectedFalse) {
            isFormValid = false;
            errors.loyalty_name = "Enter atleast one name";
          }
        }

        setOffer5error(errors);

        if (isFormValid === true) {
          if (icon === "prev") {
            setTab("step4");
            setOffer5valid(true);
          } else {
            setTab("step6");
            setOffer5valid(true);
          }
        }
      }
    } else {
      if (offer_include_status === false) {
        setTab("step6");
        setOffer5valid(true);
      } else {
        let isFormValid = true;

        const errors = {};

        const hasSelectedFalse = loyalty.some((item) => item.selected === true);
        if (!hasSelectedFalse && otherloyal_status === false) {
          isFormValid = false;
          errors.loyalty = "select one Field";
        }

        if (otherloyal_status === true) {
          const hasSelectedFalse = otherloyal.some(
            (item) => item.loyalty_name !== ""
          );
          if (!hasSelectedFalse) {
            isFormValid = false;
            errors.loyalty_name = "Enter atleast one name";
          }
        }

        setOffer5error(errors);

        if (isFormValid === true) {
          if (icon === "prev") {
            setTab("step4");
            setOffer5valid(true);
          } else {
            setTab("step6");
            setOffer5valid(true);
          }
        }
      }
    }
  };

  //offer5 page

  //offer6 page

  const handlechangeoffer6 = (e) => {
    setOffer({ ...offer, [e.target.name]: e.target.value });
  };

  const onFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleoffer6checkbox = (isChecked, name) => {
    setOffer({ ...offer, [name]: isChecked });
  };

  const onFileChangepdf = (e) => {
    setPdf(e.target.files[0]);
  };
  //offer6 page
  const elementsNotInSelectedMulti = selectedMulti6.filter(
    (item6) => !selectedMulti.some((item) => item.code === item6.code)
  );

  const Save = async () => {
    const formData = new FormData();
    //step1
    formData.append("hotel_id", id);
    formData.append("offer_id", offerid);

    formData.append("title", offername);
    if (offer.description) {
      formData.append("description", offer.description);
    } else {
      formData.append("description", "");
    }
    if (offer?.price_description) {
      formData.append("price_description", offer?.price_description);
    } else {
      formData.append("price_description", "");
    }

    let mulcount = 0;
    let mulc;

    for (let i = 0; i < selectedMulti.length; i++) {
      const newi = selectedMulti6.findIndex(
        (item) => item.code === selectedMulti[i].code
      );
      if (selectedMulti6.some((item) => item.code === selectedMulti[i].code)) {
        if (
          selectedMulti[i].name ||
          selectedMulti6[newi].description ||
          selectedMulti6[newi].price_description
        ) {
          formData.append(
            `multi_lang[${mulcount}][lang_code]`,
            selectedMulti[i].code
          );
          if (selectedMulti[i].name) {
            formData.append(
              `multi_lang[${mulcount}][title]`,
              selectedMulti[i].name
            );
          } else {
            formData.append(`multi_lang[${mulcount}][title]`, "");
          }

          if (selectedMulti6[newi].description) {
            formData.append(
              `multi_lang[${mulcount}][description]`,
              selectedMulti6[newi].description
            );
          } else {
            formData.append(`multi_lang[${mulcount}][description]`, "");
          }

          if (selectedMulti6[newi].price_description) {
            formData.append(
              `multi_lang[${mulcount}][price_description]`,
              selectedMulti6[newi].price_description
            );
          } else {
            formData.append(`multi_lang[${mulcount}][price_description]`, "");
          }

          mulcount += 1;
        }
      } else if (
        !selectedMulti6.some((item) => item.code === selectedMulti[i].code)
      ) {
        if (selectedMulti[i].name) {
          formData.append(
            `multi_lang[${mulcount}][lang_code]`,
            selectedMulti[i].code
          );

          formData.append(
            `multi_lang[${mulcount}][title]`,
            selectedMulti[i].name
          );
        }
        mulcount += 1;
      }

      mulc = mulcount;
    }

    for (let j = 0; j < elementsNotInSelectedMulti.length; j++) {
      if (
        elementsNotInSelectedMulti[j].description ||
        elementsNotInSelectedMulti[j].price_description
      ) {
        formData.append(
          `multi_lang[${mulc}][lang_code]`,
          elementsNotInSelectedMulti[j].code
        );

        if (elementsNotInSelectedMulti[j].description) {
          formData.append(
            `multi_lang[${mulc}][description]`,
            elementsNotInSelectedMulti[j].description
          );
        } else {
          formData.append(`multi_lang[${mulc}][description]`, "");
        }

        if (elementsNotInSelectedMulti[j].price_description) {
          formData.append(
            `multi_lang[${mulc}][price_description]`,
            elementsNotInSelectedMulti[j].price_description
          );
        } else {
          formData.append(`multi_lang[${mulc}][price_description]`, "");
        }
      }
      mulc += 1;
    }
    if (offer.status === true) {
      formData.append("status", 1);
    } else {
      formData.append("status", 0);
    }

    //step2
    if (offer.status === true) {
      // formData.append("d_stay", activestaydate);
      if (activestaydate === true || activestaydate === 1) {
        formData.append("d_stay", 1);
      } else {
        formData.append("d_stay", 0);
      }
      if (activestaydate === true || activestaydate === 1) {
        for (let i = 0; i < selectedDatesstay.length; i++) {
          const formattedDate = moment(selectedDatesstay[i]).format(
            "DD-MM-YYYY"
          );
          formData.append(`stay_dates[${i}]`, formattedDate);
        }
      }
      if (activecheckindate === true || activecheckindate === 1) {
        formData.append("d_checkin", 1);
      } else {
        formData.append("d_checkin", 0);
      }
      if (activecheckindate === true || activecheckindate === 1) {
        for (let i = 0; i < selectedDatescheckin.length; i++) {
          const formattedDate = moment(selectedDatescheckin[i]).format(
            "DD-MM-YYYY"
          );
          formData.append(`checkin_dates[${i}]`, formattedDate);
        }
      }
      if (activecheckoutdate === true || activecheckoutdate === 1) {
        formData.append("d_checkout", 1);
      } else {
        formData.append("d_checkout", 0);
      }
      if (activecheckoutdate === true || activecheckoutdate === 1) {
        for (let i = 0; i < selectedDatescheckout.length; i++) {
          const formattedDate = moment(selectedDatescheckout[i]).format(
            "DD-MM-YYYY"
          );
          formData.append(`checkout_dates[${i}]`, formattedDate);
        }
      }
      if (activereservedate === true || activereservedate === 1) {
        formData.append("d_reserve", 1);
      } else {
        formData.append("d_reserve", 0);
      }
      if (activereservedate === true || activereservedate === 1) {
        for (let i = 0; i < selectedDatesreserve.length; i++) {
          const formattedDate = moment(selectedDatesreserve[i]).format(
            "DD-MM-YYYY"
          );
          formData.append(`reserve_dates[${i}]`, formattedDate);
        }
      }
    }

    // for(let i=0;i<offer2room.length;i++){
    //   if(offer2room[i].selected===true){
    //     formData.append(`rooms[${i}][room]`,offer2room[i].value );
    //     for(let j=0;j<offer2room[i].occupancies.length;j++){
    //       if(offer2room[i].occupancies[j].selected===true){
    //         formData.append(`rooms[${i}][occupancy][${j}][occupancy_id]`,offer2room[i].occupancies[j].occupancy_id );

    //       }
    //     }
    //   }

    // }

    for (let i = 0; i < selectedroomocc.length; i++) {
      formData.append(`rooms[${i}][room]`, selectedroomocc[i].value);
      for (let j = 0; j < selectedroomocc[i].occupancies.length; j++) {
        formData.append(
          `rooms[${i}][occupancy][${j}][occupancy_id]`,
          selectedroomocc[i].occupancies[j].occupancy_id
        );
      }
      formData.append(`rooms[${i}][type]`, selectedroomocc[i].room_type);
    }
    let count = 0;
    for (let i = 0; i < boardoffer2.length; i++) {
      if (boardoffer2[i].selected === true) {
        formData.append(`boards[${count}]`, boardoffer2[i].value);
        count += 1;
      }
    }

    let count1 = 0;

    for (let i = 0; i < ratelistoffer2.length; i++) {
      if (ratelistoffer2[i].selected === true) {
        formData.append(`rates[${count1}]`, ratelistoffer2[i].value);
        count1 += 1;
      }
    }
    //step3

    formData.append("price_type", offer.price_type);
    if(board_apply_status==="yes"){
      formData.append("board_apply_status", 1);  
    }
    else if(board_apply_status==="no"){
      formData.append("board_apply_status", 0);  

    }
    if (offer.price_type === "same") {
      formData.append("fixed_week_price_status", 0);
    } else if (offer.price_type === "fixed") {
      
      if (
        offer.fixed_week_price_status === true ||
        offer.fixed_week_price_status === 1
      ) {
        formData.append("fixed_week_price_status", 1);
      } else {
        formData.append("fixed_week_price_status", 0);
      }
      if (
        offer.fixed_week_price_status === true ||
        offer.fixed_week_price_status === 1
      ) {
        for (let i = 0; i < selectedroomocc.length; i++) {
          for (let j = 0; j < selectedroomocc[i].occupancies.length; j++) {
            for (
              let k = 0;
              k < selectedroomocc[i].occupancies[j].boards.length;
              k++
            ) {
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][board_id]`,
                selectedroomocc[i].occupancies[j].boards[k].value
              );
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][week_data][monday]`,
                parseInt(selectedroomocc[i].occupancies[j].boards[k].monday)
              );
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][week_data][tuesday]`,
                parseInt(selectedroomocc[i].occupancies[j].boards[k].tuesday)
              );
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][week_data][wednesday]`,
                parseInt(selectedroomocc[i].occupancies[j].boards[k].wednesday)
              );
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][week_data][thursday]`,
                parseInt(selectedroomocc[i].occupancies[j].boards[k].thursday)
              );
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][week_data][friday]`,
                parseInt(selectedroomocc[i].occupancies[j].boards[k].friday)
              );
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][week_data][saturday]`,
                parseInt(selectedroomocc[i].occupancies[j].boards[k].saturday)
              );
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][week_data][sunday]`,
                parseInt(selectedroomocc[i].occupancies[j].boards[k].sunday)
              );
            }
          }
        }
      } else {
        for (let i = 0; i < selectedroomocc.length; i++) {
          for (let j = 0; j < selectedroomocc[i].occupancies.length; j++) {
            for (
              let k = 0;
              k < selectedroomocc[i].occupancies[j].boards.length;
              k++
            ) {
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][board_id]`,
                selectedroomocc[i].occupancies[j].boards[k].value
              );
              formData.append(
                `rooms[${i}][occupancy][${j}][board][${k}][rate]`,
                parseInt(selectedroomocc[i].occupancies[j].boards[k].rate)
              );
            }
          }
        }
      }
    } else if (offer.price_type === "discount") {
      formData.append("discount_value", offer.discount_value);
      if (offer.discount_unit_price) {
        formData.append("discount_unit_price", offer.discount_unit_price);
      } else {
        formData.append("discount_unit_price", "percentage");
      }
      let count = 0;
      for (let i = 0; i < inputFieldsexception.length; i++) {
        if (
          inputFieldsexception[i].datefrom &&
          inputFieldsexception[i].dateto &&
          inputFieldsexception[i].discounts
        ) {
          formData.append(
            `offer_discount[${count}][from]`,
            inputFieldsexception[i].datefrom
          );
          formData.append(
            `offer_discount[${count}][until]`,
            inputFieldsexception[i].dateto
          );
          formData.append(
            `offer_discount[${count}][discount]`,
            inputFieldsexception[i].discounts
          );
          if (inputFieldsexception[i].unit_value) {
            formData.append(
              `offer_discount[${count}][unit_value]`,
              inputFieldsexception[i].unit_value
            );
          } else {
            formData.append(
              `offer_discount[${count}][unit_value]`,
              "percentage"
            );
          }
          formData.append("fixed_week_price_status", 0);
          count += 1;
        }
      }
    }
    if (offer.increment_status === true) {
      formData.append("increment_status", 1);
      if (offer.increment_type) {
        formData.append("increment_type", offer.increment_type);
      } else {
        formData.append("increment_type", "per_person");
      }
      if (offer.increment_type === "per_room") {
        formData.append("increment_room_value", offer.increment_room);
      } else {
        formData.append("increment_per_adult", offer.increment_per_adult);
        formData.append("increment_per_child", offer.increment_per_child);
      }
      if (offer.increment_frequency) {
        formData.append("increment_frequency", offer.increment_frequency);
      } else {
        formData.append("increment_frequency", "once");
      }
      if (offer.increment_unit) {
        formData.append("increment_unit", offer.increment_unit);
      } else {
        formData.append("increment_unit", "currency");
      }
    } else {
      formData.append("increment_status", 0);
    }

    if (offer.free_night_status === true) {
      formData.append("free_night_status", 1);
      formData.append("free_night_type", offer.free_night_type);
      formData.append("free_booking_night", offer.free_booking_night);
    } else {
      formData.append("free_night_status", 0);
    }
    if (offer.booking_at_night_status === true) {
      formData.append("booking_at_night_status", 1);

      formData.append("booking_night_discount", offer.booking_night_discount);
      formData.append("booking_hours_status", 1);
      formData.append("hours_discount_from", offer.hours_discount_from);

      formData.append("hours_discount_to", offer.hours_discount_to);

      if (offer.booking_night_unit_value) {
        formData.append(
          "booking_night_unit_value",
          offer.booking_night_unit_value
        );
      } else {
        formData.append("booking_night_unit_value", "percentage");
      }
    } else {
      formData.append("booking_at_night_status", 0);
    }

    if (offer.booking_weekend_discount_status === true) {
      formData.append("booking_weekend_discount_status", 1);
      formData.append(
        "booking_weekend_week_from",
        offer.booking_weekend_week_from
      );
      formData.append(
        "booking_weekend_time_from",
        offer.booking_weekend_time_from
      );

      formData.append("booking_weekend_week_to", offer.booking_weekend_week_to);
      formData.append("booking_weekend_time_to", offer.booking_weekend_time_to);
      formData.append(
        "booking_weekend_discount",
        offer.booking_weekend_discount
      );
      if (offer.booking_weekend_unit_value) {
        formData.append(
          "booking_weekend_unit_value",
          offer.booking_weekend_unit_value
        );
      } else {
        formData.append("booking_weekend_unit_value", "percentage");
      }
      let count = 0;
      for (let i = 0; i < inputFieldsextra.length; i++) {
        if (
          inputFieldsextra[i].fromdate &&
          inputFieldsextra[i].todate &&
          inputFieldsextra[i].discount
        ) {
          formData.append(
            `extra_discount_exception[${count}][from]`,
            inputFieldsextra[i].fromdate
          );
          formData.append(
            `extra_discount_exception[${count}][until]`,
            inputFieldsextra[i].todate
          );
          formData.append(
            `extra_discount_exception[${count}][discount]`,
            inputFieldsextra[i].discount
          );
          if (inputFieldsextra[i].unit_price) {
            formData.append(
              `extra_discount_exception[${count}][unit_value]`,
              inputFieldsextra[i].unit_price
            );
          } else {
            formData.append(
              `extra_discount_exception[${count}][unit_value]`,
              "percentage"
            );
          }
          count += 1;
        }
      }
    } else {
      formData.append("booking_weekend_discount_status", 0);
    }

    if (offer.long_stay_discount_status === true) {
      formData.append("long_stay_discount_status", 1);
      if (offer.long_stay_discount_nights_01) {
        formData.append(
          "long_stay_discount[0][night]",
          offer.long_stay_discount_nights_01
        );
      } else {
        formData.append("long_stay_discount[0][night]", 1);
      }
      formData.append(
        "long_stay_discount[0][discount]",
        offer.long_stay_discount_01
      );
      if (offer.long_stay_discount_unit_value01) {
        formData.append(
          "long_stay_discount[0][unit_value]",
          offer.long_stay_discount_unit_value01
        );
      } else {
        formData.append("long_stay_discount[0][unit_value]", "percentage");
      }

      if (offer.long_stay_discount_nights_02 && offer.long_stay_discount_02) {
        formData.append(
          "long_stay_discount[1][night]",
          offer.long_stay_discount_nights_02
        );
        formData.append(
          "long_stay_discount[1][discount]",
          offer.long_stay_discount_02
        );
        if (offer.long_stay_discount_unit_value02) {
          formData.append(
            "long_stay_discount[1][unit_value]",
            offer.long_stay_discount_unit_value02
          );
        } else {
          formData.append("long_stay_discount[1][unit_value]", "percentage");
        }
      }
      if (offer.long_stay_discount_nights_03 && offer.long_stay_discount_03) {
        formData.append(
          "long_stay_discount[2][night]",
          offer.long_stay_discount_nights_03
        );
        formData.append(
          "long_stay_discount[2][discount]",
          offer.long_stay_discount_03
        );
        if (offer.long_stay_discount_unit_value03) {
          formData.append(
            "long_stay_discount[2][unit_value]",
            offer.long_stay_discount_unit_value03
          );
        } else {
          formData.append("long_stay_discount[2][unit_value]", "percentage");
        }
      }
      if (offer.long_stay_discount_nights_04 && offer.long_stay_discount_04) {
        formData.append(
          "long_stay_discount[3][night]",
          offer.long_stay_discount_nights_04
        );
        formData.append(
          "long_stay_discount[3][discount]",
          offer.long_stay_discount_04
        );

        if (offer.long_stay_discount_unit_value04) {
          formData.append(
            "long_stay_discount[3][unit_value]",
            offer.long_stay_discount_unit_value04
          );
        } else {
          formData.append("long_stay_discount[3][unit_value]", "percentage");
        }
      }
    } else {
      formData.append("long_stay_discount_status", 0);
    }

    if (offer.early_booking_discount_status === true) {
      formData.append("early_booking_discount_status", 1);
      formData.append(
        "early_booking_discount[0][days]",
        offer.early_booking_discount_upto_01
      );
      formData.append(
        "early_booking_discount[0][discount]",
        offer.early_booking_discount_01
      );
      if (offer.early_booking_discount_unit_value01) {
        formData.append(
          "early_booking_discount[0][unit_value]",
          offer.early_booking_discount_unit_value01
        );
      } else {
        formData.append("early_booking_discount[0][unit_value]", "percentage");
      }
      if (
        offer.early_booking_discount_upto_02 &&
        offer.early_booking_discount_02
      ) {
        formData.append(
          "early_booking_discount[1][days]",
          offer.early_booking_discount_upto_02
        );
        formData.append(
          "early_booking_discount[1][discount]",
          offer.early_booking_discount_02
        );
        if (offer.early_booking_discount_unit_value02) {
          formData.append(
            "early_booking_discount[1][unit_value]",
            offer.early_booking_discount_unit_value02
          );
        } else {
          formData.append(
            "early_booking_discount[1][unit_value]",
            "percentage"
          );
        }
      }
      if (
        offer.early_booking_discount_upto_03 &&
        offer.early_booking_discount_03
      ) {
        formData.append(
          "early_booking_discount[2][days]",
          offer.early_booking_discount_upto_03
        );
        formData.append(
          "early_booking_discount[2][discount]",
          offer.early_booking_discount_03
        );
        if (offer.early_booking_discount_unit_value03) {
          formData.append(
            "early_booking_discount[2][unit_value]",
            offer.early_booking_discount_unit_value03
          );
        } else {
          formData.append(
            "early_booking_discount[2][unit_value]",
            "percentage"
          );
        }
      }
      if (
        offer.early_booking_discount_upto_04 &&
        offer.early_booking_discount_04
      ) {
        formData.append(
          "early_booking_discount[3][days]",
          offer.early_booking_discount_upto_04
        );
        formData.append(
          "early_booking_discount[3][discount]",
          offer.early_booking_discount_04
        );
        if (offer.early_booking_discount_unit_value04) {
          formData.append(
            "early_booking_discount[3][unit_value]",
            offer.early_booking_discount_unit_value04
          );
        } else {
          formData.append(
            "early_booking_discount[3][unit_value]",
            "percentage"
          );
        }
      }
    } else {
      formData.append("early_booking_discount_status", 0);
    }

    if (offer.country_discount_status === true) {
      formData.append("country_discount_status", 1);
      let count = 0;
      for (let i = 0; i < multicountri3.length; i++) {
        if (multicountri3[i].discount) {
          formData.append(
            `discount_country[${count}][country]`,
            multicountri3[i].value
          );
          formData.append(
            `discount_country[${count}][discount]`,
            multicountri3[i].discount
          );
          if (!multicountri3[i].country_unit_value) {
            formData.append(
              `discount_country[${count}][unit_value]`,
              "currency"
            );
          } else {
            formData.append(
              `discount_country[${count}][unit_value]`,
              multicountri3[i].country_unit_value
            );
          }
          count += 1;
        }
      }
    } else {
      formData.append("country_discount_status", 0);
    }
    if (offer.device_discount_status === true) {
      formData.append("device_discount_status", 1);
      if (offer.device_discount_type) {
        formData.append("device_discount_type", offer.device_discount_type);
      } else {
        formData.append("device_discount_type", "desktop");
      }

      formData.append("device_discount", offer.device_discount_value);
      if (offer.device_discount_unit_value) {
        formData.append(
          "device_discount_unit_value",
          offer.device_discount_unit_value
        );
      } else {
        formData.append("device_discount_unit_value", "currency");
      }
    } else {
      formData.append("device_discount_status", 0);
    }

    if (offer.last_minute_discount_status === true) {
      formData.append("last_minute_discount_status", 1);
      if (offer.last_minute_discount_from_01) {
        formData.append(
          "last_minute_discount[0][from]",
          offer.last_minute_discount_from_01
        );
      } else {
        formData.append("last_minute_discount[0][from]", 12);
      }
      formData.append(
        "last_minute_discount[0][discount]",
        offer.last_minute_discount_01
      );
      if (offer.last_minute_discount_unit_value01) {
        formData.append(
          "last_minute_discount[0][unit_value]",
          offer.last_minute_discount_unit_value01
        );
      } else {
        formData.append("last_minute_discount[0][unit_value]", "percentage");
      }

      if (offer.last_minute_discount_from_02 && offer.last_minute_discount_02) {
        formData.append(
          "last_minute_discount[1][from]",
          offer.last_minute_discount_from_02
        );
        formData.append(
          "last_minute_discount[1][discount]",
          offer.last_minute_discount_02
        );
        if (offer.last_minute_discount_unit_value02) {
          formData.append(
            "last_minute_discount[1][unit_value]",
            offer.last_minute_discount_unit_value02
          );
        } else {
          formData.append("last_minute_discount[1][unit_value]", "percentage");
        }
      }
      if (offer.last_minute_discount_from_03 && offer.last_minute_discount_03) {
        formData.append(
          "last_minute_discount[2][from]",
          offer.last_minute_discount_from_03
        );
        formData.append(
          "last_minute_discount[2][discount]",
          offer.last_minute_discount_03
        );
        if (offer.last_minute_discount_unit_value03) {
          formData.append(
            "last_minute_discount[2][unit_value]",
            offer.last_minute_discount_unit_value03
          );
        } else {
          formData.append("last_minute_discount[2][unit_value]", "percentage");
        }
      }
      if (offer.last_minute_discount_from_04 && offer.last_minute_discount_04) {
        formData.append(
          "last_minute_discount[3][from]",
          offer.last_minute_discount_from_04
        );
        formData.append(
          "last_minute_discount[3][discount]",
          offer.last_minute_discount_04
        );
        if (offer.last_minute_discount_unit_value04) {
          formData.append(
            "last_minute_discount[3][unit_value]",
            offer.last_minute_discount_unit_value04
          );
        } else {
          formData.append("last_minute_discount[3][unit_value]", "percentage");
        }
      }
    } else {
      formData.append("last_minute_discount_status", 0);
    }

    //step4
    if (offer.promocode_available_status === true) {
      formData.append("promocode_available_status", 1);
    } else {
      formData.append("promocode_available_status", 0);
    }

    if (offer.loyality_club_status === true) {
      formData.append("loyality_club_status", 1);
      let count = 0;
      for (let i = 0; i < loyalty_club.length; i++) {
        if (loyalty_club[i] !== "undefined") {
          formData.append(`loyality_clubs[${count}]`, loyalty_club[i]);
          count += 1;
        }
      }
      // for(let i=0;i<Loyaltyclublist.length;i++){
      //   if(Loyaltyclublist[i].selected===true){
      //     formData.append(`loyality_clubs[${i}]`,Loyaltyclublist[i].value );

      //   }
      // }
    } else {
      formData.append("loyality_club_status", 0);
    }

    if (offer.advance_booking_status === true) {
      formData.append("advance_booking_status", 1);
      formData.append("advance_booking_days", offer.advance_booking_days);
    } else {
      formData.append("advance_booking_status", 0);
    }
    if (offer.cancelation_policy_status === true) {
      formData.append("cancelation_policy_status", 1);
      formData.append("cancelation_policy", offer.cancelation_policy_id);
    } else {
      formData.append("cancelation_policy_status", 0);
    }
    if (offer.offer_min_stay_status === true) {
      formData.append("offer_min_stay_status", 1);
      if (offer.offer_min_stay_days) {
        formData.append("offer_min_stay_days", offer.offer_min_stay_days);
      } else {
        formData.append("offer_min_stay_days", 2);
      }
      if (offer.all_min_night_consider_status === true) {
        formData.append("all_min_night_consider_status", 1);
      } else {
        formData.append("all_min_night_consider_status", 0);
      }
    } else {
      formData.append("offer_min_stay_status", 0);
    }

    if (offer.offer_max_stay_status === true) {
      formData.append("offer_max_stay_status", 1);
      if (offer.offer_max_stay_days) {
        formData.append("offer_max_stay_days", offer.offer_max_stay_days);
      } else {
        formData.append("offer_max_stay_days", 2);
      }
      if (offer.max_stay_all_night_offered) {
        formData.append(
          "max_stay_all_night_offered",
          offer.max_stay_all_night_offered
        );
      } else {
        formData.append("max_stay_all_night_offered", "offer");
      }
    } else {
      formData.append("offer_max_stay_status", 0);
    }

    if (offer.min_rooms_per_booking_status === true) {
      formData.append("min_rooms_per_booking_status", 1);
      if (offer.min_rooms_per_booking) {
        formData.append("min_rooms_per_booking", offer.min_rooms_per_booking);
      } else {
        formData.append("min_rooms_per_booking", 2);
      }
    } else {
      formData.append("min_rooms_per_booking_status", 0);
    }
    if (offer.offer_close_for_min_rooms_status === true) {
      formData.append("offer_close_for_min_rooms_status", 1);
      if (offer.offer_close_for_room) {
        formData.append("offer_close_for_room", offer.offer_close_for_room);
      } else {
        formData.append("offer_close_for_room", 2);
      }
    } else {
      formData.append("offer_close_for_min_rooms_status", 0);
    }

    if (offer.max_room_per_night_status === true) {
      formData.append("max_room_per_night_status", 1);
      if (offer.max_room_per_night) {
        formData.append("max_room_per_night", offer.max_room_per_night);
      } else {
        formData.append("max_room_per_night", 1);
      }
    } else {
      formData.append("max_room_per_night_status", 0);
    }
    if (
      offer.offer_last_minute_status === true ||
      offer.offer_last_minute_status === 1
    ) {
      formData.append("offer_last_minute_status", 1);
      if (offer.offer_last_minute_hours) {
        formData.append(
          "offer_last_minute_hours",
          offer.offer_last_minute_hours
        );
      } else {
        formData.append("offer_last_minute_hours", 12);
      }
    } else {
      formData.append("offer_last_minute_status", 0);
    }
    if (offer.offer_display_time_status === true) {
      formData.append("offer_display_time_status", 1);
      if (offer.offer_display_hours_status === true) {
        formData.append("offer_display_hours_status", 1);
        if (offer.offer_display_from) {
          formData.append("offer_display_from", offer.offer_display_from);
        } else {
          formData.append("offer_display_from", "0");
        }
        if (offer.offer_display_to) {
          formData.append("offer_display_to", offer.offer_display_to);
        } else {
          formData.append("offer_display_to", "12");
        }
      } else {
        formData.append("offer_display_hours_status", 0);
      }
      if (offer.offer_display_weekend_status === true) {
        formData.append("offer_display_weekend_status", 1);
        formData.append(
          "offer_display_weekend_week_from",
          offer.offer_display_weekend_week_from
        );
        formData.append(
          "offer_display_weekend_time_from",
          offer.offer_display_weekend_time_from
        );
        formData.append(
          "offer_display_weekend_week_to",
          offer.offer_display_weekend_week_to
        );
        formData.append(
          "offer_display_weekend_time_to",
          offer.offer_display_weekend_time_to
        );
      } else {
        formData.append("offer_display_weekend_status", 0);
      }
    } else {
      formData.append("offer_display_time_status", 0);
    }

    if (offer.offer_available_country_status === true) {
      formData.append("offer_available_country_status", 1);
      // let count=0;
      for (let i = 0; i < multicountri4.length; i++) {
        // if(countrylist[i].offercountry===true){
        formData.append(`offer_country[${i}]`, multicountri4[i].value);
        // count+=1;
        // }
      }
    } else {
      formData.append("offer_available_country_status", 0);
    }

    if (offer.offer_available_device_status === true) {
      formData.append("offer_available_device_status", 1);
      formData.append(
        "offer_available_device_type",
        offer.offer_available_device_type
      );
    } else {
      formData.append("offer_available_device_status", 0);
    }
    //step 5
    if (offer_include_status === true) {
      formData.append("offer_include_status", 1);
      let count = 0;
      for (let i = 0; i < loyalty.length; i++) {
        if (loyalty[i].selected && loyalty[i].selected === true) {
          formData.append(`offer_include_list[${count}]`, loyalty[i].value);
          count += 1;
        }
      }

      if (otherloyal_status === true) {
        formData.append("offer_include_list_other_status", 1);
        // formData.append("offer_include_list_other_language_code", loyallang);
        for (let i = 0; i < otherloyal.length; i++) {
          if (otherloyal[i].loyalty_name) {
            formData.append(
              `offer_include_other[${i}][title]`,
              otherloyal[i].loyalty_name
            );
          }
          for (let j = 0; j < otherloyal[i].lang.length; j++) {
            if (
              otherloyal[i].lang[j].lang_code &&
              otherloyal[i].lang[j].loyalty_lang_name
            ) {
              formData.append(
                `offer_include_other[${i}][multi_lang][${j}][lang_code]`,
                otherloyal[i].lang[j].lang_code
              );
              formData.append(
                `offer_include_other[${i}][multi_lang][${j}][title]`,
                otherloyal[i].lang[j].loyalty_lang_name
              );
            }
          }
        }
      } else {
        formData.append("offer_include_list_other_status", 0);
      }
    } else {
      formData.append("offer_include_status", 0);
    }

    //step6

    if (offer.hide_offer_in_dynamic_section === true) {
      formData.append("hide_offer_status", 1);
    } else {
      formData.append("hide_offer_status", 0);
    }
    if (offer.show_exact_discount_in_offer === true) {
      formData.append("show_offer_discount", 1);
    } else {
      formData.append("show_offer_discount", 0);
    }
    if (offer.hide_offer_in_dynamic_section_search === true) {
      formData.append("hide_offer_in_search", 1);
    } else {
      formData.append("hide_offer_in_search", 0);
    }
    if (image) {
      if (image instanceof File) {
        formData.append("image", image);
      } else {
        formData.append("choosen_image", image);
      }
    } else {
      formData.append("choosen_image", "");
    }
    if (pdf instanceof File) {
      formData.append("pdf_attachment", pdf);
    }

    axios
      .post(`${Config.apiurl}offer/update`, formData, {})
      .then((res) => {
        if (res.status_code !== "200" || !res) {
          console.log("error");

          setToastmodal(true);
          setToastmessage(res.message);
          setToasttype("error");
          setToastlarge(true);
        } else {
          console.log("sucess");

          setToastmodal(true);
          setToastmessage(res.message);
          setToasttype("success");
          setToastlarge(false);
          setTimeout(() => navigate("/offers"), 3000);
        }
      });
  };

  useEffect(() => {
    getall();
    getoffer();
  }, []);

  useEffect(() => {
    const updatedList = [...offer2room];
    const updatedroomocc = [...selectedroomocc];
    const roomlist = [...offerroom];
    const Boards = [...offerboard];
    const allboard = [...boardoffer2];
    const updatedAvailableBoard = [...availableboard];
    const updatedListrate = [...ratelistoffer2];
    const updatedother = [...selectedotherboard];
    const updatedallotherboard = [...otherboard];
    roomlist.forEach((newone) => {
      updatedList.forEach((newoneroom) => {
        if (newone.room_id === newoneroom.value) {
          newoneroom.display_board.forEach((newItem) => {
            const newItemId = newItem;
            // for(let a=0;a<Boards.length;a++){
            const newid = Boards.find((item) => item === newItemId);
            // if(newItemId===Boards[a]){
            if (newid) {
              const existingItem = updatedAvailableBoard.find(
                (item) => item.id === newItemId
              );
              if (existingItem) {
                existingItem.count += existingItem.count;
              } else {
                updatedAvailableBoard.push({ id: newItemId, count: 1 });
              }
            } else {
              const existingItem = updatedAvailableBoard.find(
                (item) => item.id === newItemId
              );
              if (!existingItem) {
                updatedAvailableBoard.push({ id: newItemId, count: 1 });
              }
            }
            // }
          });
        }
      });
    });
    updatedList.forEach((newone) => {
      //room wise board

      // new selected room
      if (roomlist.find((item) => item.room_id === newone.value)) {
        newone.selected = true;
        roomlist.forEach((newroo) => {
          if (newroo.room_id === newone.value) {
            newone.room_type = newroo.room_type;
          }
        });
        updatedroomocc.push({
          key: newone.key,
          label: newone.label,
          value: newone.value,
          room_type: newone.room_type,
        });
        for (let i = 0; i < updatedroomocc.length; i++) {
          for (let k = 0; k < roomlist.length; k++) {
            for (let l = 0; l < roomlist[i].occupancy_id.length; l++) {
              if (
                updatedroomocc[i].value === roomlist[k].room_id &&
                roomlist[k].room_id === newone.value
              ) {
                for (let n = 0; n < newone.occupancies.length; n++) {
                  if (
                    newone.occupancies[n].occupancy_id ===
                    roomlist[k].occupancy_id[l]
                  ) {
                    newone.occupancies[n].selected = true;
                    if (!Array.isArray(updatedroomocc[i].occupancies)) {
                      updatedroomocc[i].occupancies = [];
                    }
                    updatedroomocc[i].occupancies.push(newone.occupancies[n]);
                  }
                  if (
                    updatedroomocc[i]?.occupancies?.length ===
                    newone?.occupancies?.length
                  ) {
                    newone.all_label = true;
                  }
                }
              }
            }
          }
        }
      }

      setAvailbleboard(updatedAvailableBoard);
      setSelectedroomocc(updatedroomocc);
      // new board
      allboard.forEach((newItem) => {
        for (let j = 0; j < updatedroomocc.length; j++) {
          if (newone.value === updatedroomocc[j].value) {
            if (newone.display_board.includes(newItem.value)) {
              for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
                for (let l = 0; l < Boards.length; l++) {
                  if (newItem.value === Boards[l]) {
                    if (!updatedroomocc[j].occupancies[k].boards) {
                      updatedroomocc[j].occupancies[k].boards = [];
                    }
                    updatedroomocc[j].occupancies[k].boards.push({
                      hotel_board: newItem.hotel_borad,
                      label: newItem.label,
                      value: newItem.value,
                    });
                    newItem.selected = true;
                  }
                }
              }
            }
          }
        }
      });

      //otherboard
      for (let i = 0; i < updatedroomocc.length; i++) {
        if (!updatedother[i]) {
          updatedother[i] = { selectedboard: [] };
        }
        if (!updatedallotherboard[i]) {
          updatedallotherboard[i] = { otherboard: [] };
        }
      }
      //
      for (let j = 0; j < updatedroomocc.length; j++) {
        for (let m = 0; m < offerprice.length; m++) {
          if (updatedroomocc[j].value === offerprice[m].room_id) {
            for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
              if (
                updatedroomocc[j].occupancies[k].occupancy_id ===
                offerprice[m].occupancy_id
              ) {
                for (
                  let l = 0;
                  l < updatedroomocc[j].occupancies[k].boards.length;
                  l++
                ) {
                  if (
                    updatedroomocc[j].occupancies[k].boards[l].value ===
                    offerprice[m].board_id
                  ) {
                    if (
                      offer.fixed_week_price_status === true ||
                      offer.fixed_week_price_status === 1
                    ) {
                      updatedroomocc[j].occupancies[k].boards[l].monday =
                        offerprice[m].monday_rate;
                      updatedroomocc[j].occupancies[k].boards[l].tuesday =
                        offerprice[m].tuesday_rate;
                      updatedroomocc[j].occupancies[k].boards[l].wednesday =
                        offerprice[m].wednesday_rate;
                      updatedroomocc[j].occupancies[k].boards[l].thursday =
                        offerprice[m].thursday_rate;
                      updatedroomocc[j].occupancies[k].boards[l].friday =
                        offerprice[m].friday_rate;
                      updatedroomocc[j].occupancies[k].boards[l].saturday =
                        offerprice[m].saturday_rate;
                      updatedroomocc[j].occupancies[k].boards[l].sunday =
                        offerprice[m].sunday_rate;
                    } else {
                      updatedroomocc[j].occupancies[k].boards[l].rate =
                        offerprice[m].rate;
                    }
                  }
                }
                if (
                  !updatedroomocc[j].occupancies[k].boards.some(
                    (item) => item.value === offerprice[m].board_id
                  )
                ) {
                  for (let z = 0; z < boardoffer2.length; z++) {
                    if (boardoffer2[z].value === offerprice[m].board_id) {
                      updatedroomocc[j].occupancies[k].boards.push({
                        hotel_board: boardoffer2[z].hotel_board,
                        label: boardoffer2[z].label,
                        value: boardoffer2[z].value,
                        lang_code: boardoffer2[z].lang_code,
                        rate: "",
                      });
                      if (
                        !updatedother[j].selectedboard.some(
                          (item) => item.value === offerprice[m].board_id
                        )
                      ) {
                        updatedother[j].selectedboard.push({
                          label: boardoffer2[z].label,
                          value: boardoffer2[z].value,
                          lang_code: boardoffer2[z].lang_code,
                          hotel_board: boardoffer2[z].hotel_borad,
                        });
                      }
                    }
                  }
                }
              }
            }
          }
        }
        for (let i = 0; i < allboard.length; i++) {
          if (allboard[i].selected === false || !allboard[i].selected) {
            if (updatedallotherboard[j]?.otherboard?.length > 0) {
              if (
                !updatedallotherboard[j]?.otherboard?.some(
                  (item) => item.value === allboard[i].value
                )
              ) {
                updatedallotherboard[j].otherboard.push({
                  label: allboard[i].label,
                  value: allboard[i].value,
                  lang_code: allboard[i].lang_code,
                  hotel_borad: allboard[i].hotel_borad,
                });
              }
            } else {
              updatedallotherboard[j]?.otherboard.push({
                label: allboard[i].label,
                value: allboard[i].value,
                lang_code: allboard[i].lang_code,
                hotel_borad: allboard[i].hotel_borad,
              });
            }
          } else if (allboard[i].selected === true) {
            for (let k = 0; k < offer2room.length; k++) {
              if (offer2room[k].value === updatedroomocc[j].value) {
                // Found the matching element
                const matchingElement = offer2room[k];
                if (
                  !matchingElement.display_board.some(
                    (item) => item === allboard[i].value
                  )
                ) {
                  if (
                    !updatedallotherboard[j].otherboard.some(
                      (item) => item.value === allboard[i].value
                    )
                  ) {
                    updatedallotherboard[j].otherboard.push({
                      label: allboard[i].label,
                      value: allboard[i].value,
                      lang_code: allboard[i].lang_code,
                      hotel_borad: allboard[i].hotel_borad,
                    });
                  }
                }
              }
            }
          }
        }
      }

      setSelectedotherboard(updatedother);
      setOtherboard(updatedallotherboard);
      setSelectedroomocc(updatedroomocc);
      setBoardoffer2(allboard);
    });

    updatedListrate.forEach((newItem) => {
      if (offerrate.includes(newItem.value)) {
        newItem.selected = true;
      }
    });
    setRatelistoffer2(updatedListrate);
    setOffer2room(updatedList);
  }, [offerroom?.length, offer2room?.length]);

  useEffect(() => {
    if (offerroom?.length === offer2room?.length) {
      setActiveroom(true);
    } else {
      setActiveroom(false);
    }
  }, [offerroom?.length, offer2room?.length]);

  useEffect(() => {
    if (offerboard?.length === boardoffer2?.length) {
      setActiveboard(true);
    } else {
      setActiveboard(false);
    }

    // const updatedBoard=[...otherboard];

    // for(let j=0;j<selectedroomocc.length;j++){
    //   for(let i=0;i<boardoffer2.length;i++){
    //     if(boardoffer2[i].selected===false || !boardoffer2[i].selected){
    //       if(updatedBoard[j]?.otherboard?.length>0){
    //         if(!updatedBoard[j]?.otherboard?.some((item)=>item.value === boardoffer2[i].value)){
    //         updatedBoard[j].otherboard.push({
    //         label:boardoffer2[i].label,
    //         value:boardoffer2[i].value,
    //         lang_code:boardoffer2[i].lang_code,
    //         hotel_borad:boardoffer2[i].hotel_borad,

    //       })
    //       }
    //       }

    //       else{
    //         updatedBoard[j]?.otherboard.push({
    //           label:boardoffer2[i].label,
    //           value:boardoffer2[i].value,
    //           lang_code:boardoffer2[i].lang_code,
    //           hotel_borad:boardoffer2[i].hotel_borad,

    //         })
    //       }
    //     }
    //     else if(boardoffer2[i].selected===true){
    //       for (let k = 0; k < offer2room.length; k++) {
    //         if (offer2room[k].value === selectedroomocc[j].value) {
    //           // Found the matching element
    //           const matchingElement = offer2room[k];
    //           if(!matchingElement.display_board.some((item) => item===boardoffer2[i].value)){
    //             if(!updatedBoard[j].otherboard.some((item)=>item.value === boardoffer2[i].value)){
    //               updatedBoard[j].otherboard.push({
    //               label:boardoffer2[i].label,
    //               value:boardoffer2[i].value,
    //               lang_code:boardoffer2[i].lang_code,
    //               hotel_borad:boardoffer2[i].hotel_borad,

    //               })
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    // for(let i=0;i<boardoffer2.length;i++){
    //   if(boardoffer2[i].selected===false || !boardoffer2[i].selected){
    //     if(updatedBoard.length>0){
    //       if(!updatedBoard.some((item)=>item.value === boardoffer2[i].value)){
    //       updatedBoard.push({
    //       label:boardoffer2[i].label,
    //       value:boardoffer2[i].value,
    //       lang_code:boardoffer2[i].lang_code,
    //       hotel_borad:boardoffer2[i].hotel_borad,

    //     })
    //     }
    //   }

    //   else{
    //     updatedBoard.push({
    //       label:boardoffer2[i].label,
    //       value:boardoffer2[i].value,
    //       lang_code:boardoffer2[i].lang_code,
    //       hotel_borad:boardoffer2[i].hotel_borad,

    //     })
    //   }
    //   }
    // }
    // setOtherboard(updatedBoard);
  }, [offerboard?.length, boardoffer2?.length, selectedroomocc.length]);

  console.log(otherboard);
  console.log(selectedotherboard);

  console.log(selectedroomocc);
  useEffect(() => {
    if (ratelistoffer2?.length === offerrate?.length) {
      setActiverate(true);
    } else {
      setActiverate(false);
    }
  }, [ratelistoffer2?.length, offerrate?.length]);

  // useEffect(() => {
  //   if(country_discount?.length>0){
  //   const updatedList = [...countryOffer3];
  //   const updateddis=[...country_discount];

  //   updatedList?.forEach(newone=>{
  //     const dis=updateddis?.find(item => item.country=== newone.value)
  //     if(dis){
  //       newone.selected=true;
  //       newone.discount=dis.discount;
  //       newone.country_unit_value=dis.unit_value;
  //     }
  //   })
  //       setCountryOffer3(updatedList);
  // }

  // },[countryOffer3?.length,country_discount?.length])

  useEffect(() => {
    if (country_discount?.length > 0) {
      const updatedList = [...countryOffer3];
      const updateddis = [...country_discount];

      updatedList?.forEach((newone) => {
        let ar = {};
        const dis = updateddis?.find((item) => item.country === newone.value);
        if (dis) {
          ar.code = newone.code;
          ar.label = newone.label;
          ar.value = newone.value;
          ar.discount = dis.discount;
          ar.country_unit_value = dis.unit_value;
          multicountri3.push(ar);
        }
      });
    }
  }, [countryOffer3?.length, country_discount?.length]);

  useEffect(() => {
    if (offer_countries?.length > 0) {
      const updatedList = [...countrylist];
      const updateddis = [...offer_countries];
      updatedList?.forEach((newone) => {
        let ar = {};
        const dis = updateddis?.find((item) => item === newone.value);
        if (dis) {
          ar.code = newone.code;
          ar.label = newone.label;
          ar.value = newone.value;

          multicountri4.push(ar);
        }
      });
      setCountrylist(updatedList);
    }
  }, [offer_countries?.length, countrylist?.length]);

  useEffect(() => {
    if (loyaltyinclude?.length > 0) {
      const updatedList = [...loyalty];
      const updateddis = loyaltyinclude;
      updatedList?.forEach((newone) => {
        const dis = updateddis?.find((item) => item === newone.value);
        if (dis) {
          newone.selected = true;
        }
      });
      setLoyalty(updatedList);
    }
  }, [loyalty?.length, loyaltyinclude?.length]);

  useEffect(() => {
    for (let i = 0; i < selectedroomocc.length; i++) {
      let ar = {};
      ar.tab = "default";
      ar.click = i === 0;
      tabroom.push(ar);
    }
  }, [selectedroomocc?.length]);

  useEffect(() => {
    for (let i = 0; i < selectedroomocc.length; i++) {
      let ar = {};
      ar.tab = "default";
      ar.click = i === 0;
      tabroomweek.push(ar);
    }
  }, [selectedroomocc?.length]);

  useEffect(() => {
    if (rateexception) {
      let previousDiscount = null;
      let previousunit_value = null;
      let currentDateRange = null;
      for (let i = 0; i < rateexception?.length; i++) {
        let ar = {};

        if (
          rateexception[i].discount !== previousDiscount ||
          rateexception[i].unit_value !== previousunit_value ||
          !isConsecutiveDates(currentDateRange.to, rateexception[i].date)
        ) {
          ar.fromdate = moment(rateexception[i].date, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          );
          ar.discount = rateexception[i].discount;
          ar.unit_value = rateexception[i].unit_value;
          currentDateRange = {
            from: rateexception[i].date,
            to: rateexception[i].date,
          };
        } else {
          console.log(offerexception);
          currentDateRange.to = offerexception[i]?.date;
          inputFieldsextra[inputFieldsextra.length - 1].todate = moment(
            rateexception[i]?.date,
            "YYYY-MM-DD"
          ).format("DD-MM-YYYY");
        }

        previousDiscount = rateexception[i]?.discount;
        previousunit_value = rateexception[i]?.unit_value;
        if (ar.discount) {
          inputFieldsextra.push(ar);
        }
      }
    } 
    else {
      inputFieldsextra.push({ fromdate: "", todate: "", discount: "" });
    }

    if (inputFieldsextra?.length > 0 && inputFieldsextra[0]?.discount) {
      setExceptionnight(true);
    }
  }, [rateexception?.length]);

  function isConsecutiveDates(date1, date2) {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(
      Math.abs((new Date(date1) - new Date(date2)) / oneDay)
    );
    return diffDays === 1;
  }

  console.log("offerexception",offerexception);
  console.log("rateex",rateexception);
  useEffect(() => {
    if (offerexception) {
      // let previousDiscount = null;
      // let previousunit_value = null;
      // let currentDateRange = null;
      // for (let i = 0; i < offerexception.length; i++) {
      //   let ar = {};

      //   if (offerexception[i].discount !== previousDiscount || offerexception[i].unit_value !== previousunit_value) {
      //     ar.datefrom =  moment(offerexception[i].date,'YYYY-MM-DD').format('DD-MM-YYYY');
      //     ar.discounts = offerexception[i].discount;
      //     ar.unit_value = offerexception[i].unit_value;
      //   } else {
      //     inputFieldsexception[inputFieldsexception.length - 1].dateto = moment(offerexception[i].date,'YYYY-MM-DD').format('DD-MM-YYYY');
      //    }

      //   previousDiscount = offerexception[i].discount;
      //   previousunit_value = offerexception[i].unit_value;
      //    if(ar.discounts){
      //     inputFieldsexception.push(ar);
      //    }
      // }

      let previousDiscount = null;
      let previousUnitValue = null;
      let currentDateRange = null;

      for (let i = 0; i < offerexception.length; i++) {
        let ar = {};

        if (
          offerexception[i].discount !== previousDiscount ||
          offerexception[i].unit_value !== previousUnitValue ||
          !isConsecutiveDates(currentDateRange.to, offerexception[i].date)
        ) {
          ar.datefrom = moment(offerexception[i].date, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          );
          ar.discounts = offerexception[i].discount;
          ar.unit_value = offerexception[i].unit_value;
          currentDateRange = {
            from: offerexception[i].date,
            to: offerexception[i].date,
          };
        } else {
          currentDateRange.to = offerexception[i].date;
          inputFieldsexception[inputFieldsexception.length - 1].dateto = moment(
            offerexception[i].date,
            "YYYY-MM-DD"
          ).format("DD-MM-YYYY");
        }

        previousDiscount = offerexception[i].discount;
        previousUnitValue = offerexception[i].unit_value;

        if (ar.discounts) {
          inputFieldsexception.push(ar);
        }
      }
    } else {
      inputFieldsexception.push({ datefrom: "", dateto: "", discounts: "" });
    }

    if (inputFieldsexception.length > 0 && inputFieldsexception[0].discounts) {
      setExceptionstaydate(true);
    }
  }, [offerexception?.length]);

  useEffect(() => {
    if (mul) {
      for (let i = 0; i < mul.length; i++) {
        let ar = {};

        if (mul[i].title) {
          ar.label = mul[i].label;
          ar.code = mul[i].lang_code;
          ar.value = mul[i].value;
          ar.name = mul[i].title;
        }

        if (mul[i].title) {
          selectedMulti.push(ar);
        }
      }

      for (let j = 0; j < mul.length; j++) {
        let ar = {};

        if (mul[j].description || mul[j].price_description) {
          ar.label = mul[j].label;
          ar.code = mul[j].lang_code;
          ar.value = mul[j].value;
          ar.description = mul[j].description;
          ar.price_description = mul[j].price_description;
        }

        if (mul[j].description || mul[j].price_description) {
          selectedMulti6.push(ar);
        }
      }
    }
  }, [mul?.length]);

  // useEffect(() => {
  //   setActiveroom(true);
  //   const updatedList = [...offer2room];
  //   const updatedroomocc=[...selectedroomocc];
  //   const updatedAvailableBoard = [...availableboard];

  //   updatedList.forEach(newone=>{
  //     //room wise board
  //   newone.display_board.forEach(newItem => {
  //     const newItemId = newItem;
  //     const existingItem = updatedAvailableBoard.find(item => item.id === newItemId);
  //     if (existingItem) {
  //         existingItem.count += existingItem.count;
  //     }  else {
  //       // If the item doesn't exist in availableboard, add it with its count
  //       updatedAvailableBoard.push({ id: newItemId, count: 1 });
  //     }
  //   });
  //         // new selected room
  //     updatedroomocc.push({ key: newone.key, label: newone.label ,value:newone.value });
  //       //new occupancy
  //     for (let i = 0; i < updatedroomocc.length; i++) {
  //       if (updatedroomocc[i].value === newone.value) {
  //         if (!Array.isArray(updatedroomocc[i].occupancies)) {
  //           updatedroomocc[i].occupancies = [];
  //         }
  //         // updatedroomocc[i].occupancies.push(newone.occupancies);
  //         for (let j = 0; j < newone.occupancies.length; j++) {
  //           updatedroomocc[i].occupancies.push(newone.occupancies[j]);
  //         }
  //         // break; // Exit the loop after finding and updating the matching room
  //       }
  //     }

  //       //new board
  //       boardoffer2.forEach(newItem => {
  //       for (let j = 0; j < updatedroomocc.length; j++) {
  //         if (newone.value === updatedroomocc[j].value) {

  //           if (newone.display_board.includes(newItem.value)) {
  //             for (let k = 0; k < updatedroomocc[j].occupancies.length; k++) {
  //               if (!updatedroomocc[j].occupancies[k].boards) {
  //                 updatedroomocc[j].occupancies[k].boards = [];
  //               }
  //               updatedroomocc[j].occupancies[k].boards.push({
  //                 hotel_board: newItem.hotel_borad,
  //                 label: newItem.label,
  //                 value:newItem.value
  //               });

  //             }
  //           }
  //         }
  //       }
  //     })

  //     // setSelectedroomocc(updatedroomocc);

  // })
  //   setAvailbleboard(updatedAvailableBoard);
  //    setSelectedroomocc(updatedroomocc);

  //   updatedList.forEach(room => {
  //       room.selected = true;
  //       room.occupancies.forEach(occ=>{
  //         occ.selected=true;
  //       })
  //     });
  //     setOffer2room(updatedList);
  // }, [offer2room.length]);

  // useEffect(() => {
  //   setActiveboard(true);
  //   const updatedList = [...boardoffer2];
  //   updatedList.forEach(room => {
  //       room.selected = true;
  //     });
  //     setBoardoffer2(updatedList);
  // }, [boardoffer2.length]);

  useEffect(() => {
    const getWeekdays = (startDate, endDate) => {
      const weekdays = [];
      const currentDay = new Date(startDate);
      const lastDay = new Date(endDate);

      while (currentDay <= lastDay) {
        const dayOfWeek = currentDay.getDay();
        if (dayOfWeek >= 0 && dayOfWeek <= 6) {
          weekdays.push(dayOfWeek);
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }

      return weekdays;
    };

    const getWeekdaysBetweenSelectedDates = (datefromstay, datetostay) => {
      const weekdays = getWeekdays(datefromstay, datetostay);
      setSelectedWeekdaysstay(weekdays);
      setClickedDaysstay([]);
    };

    if (datefromstay && datetostay) {
      getWeekdaysBetweenSelectedDates(datefromstay, datetostay);
    } else {
      setSelectedWeekdaysstay([]);
    }
  }, [datefromstay, datetostay]);

  useEffect(() => {
    const getWeekdays = (startDate, endDate) => {
      const weekdays = [];
      const currentDay = new Date(startDate);
      const lastDay = new Date(endDate);

      while (currentDay <= lastDay) {
        const dayOfWeek = currentDay.getDay();
        if (dayOfWeek >= 0 && dayOfWeek <= 6) {
          weekdays.push(dayOfWeek);
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }

      return weekdays;
    };

    const getWeekdaysBetweenSelectedDates = (
      datefromcheckin,
      datetocheckin
    ) => {
      const weekdays = getWeekdays(datefromcheckin, datetocheckin);
      setSelectedWeekdayscheckin(weekdays);
      setClickedDayscheckin([]);
    };

    if (datefromcheckin && datetocheckin) {
      getWeekdaysBetweenSelectedDates(datefromcheckin, datetocheckin);
    } else {
      setSelectedWeekdayscheckin([]);
    }
  }, [datefromcheckin, datetocheckin]);

  useEffect(() => {
    const getWeekdays = (startDate, endDate) => {
      const weekdays = [];
      const currentDay = new Date(startDate);
      const lastDay = new Date(endDate);

      while (currentDay <= lastDay) {
        const dayOfWeek = currentDay.getDay();
        if (dayOfWeek >= 0 && dayOfWeek <= 6) {
          weekdays.push(dayOfWeek);
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }

      return weekdays;
    };

    const getWeekdaysBetweenSelectedDates = (
      datefromcheckout,
      datetocheckout
    ) => {
      const weekdays = getWeekdays(datefromcheckout, datetocheckout);
      setSelectedWeekdayscheckout(weekdays);
      setClickedDayscheckout([]);
    };

    if (datefromcheckout && datetocheckout) {
      getWeekdaysBetweenSelectedDates(datefromcheckout, datetocheckout);
    } else {
      setSelectedWeekdayscheckout([]);
    }
  }, [datefromcheckout, datetocheckout]);

  useEffect(() => {
    const getWeekdays = (startDate, endDate) => {
      const weekdays = [];
      const currentDay = new Date(startDate);
      const lastDay = new Date(endDate);

      while (currentDay <= lastDay) {
        const dayOfWeek = currentDay.getDay();
        if (dayOfWeek >= 0 && dayOfWeek <= 6) {
          weekdays.push(dayOfWeek);
        }
        currentDay.setDate(currentDay.getDate() + 1);
      }

      return weekdays;
    };

    const getWeekdaysBetweenSelectedDates = (
      datefromreserve,
      datetoreserve
    ) => {
      const weekdays = getWeekdays(datefromreserve, datetoreserve);
      setSelectedWeekdaysreserve(weekdays);
      setClickedDaysreserve([]);
    };

    if (datefromreserve && datetoreserve) {
      getWeekdaysBetweenSelectedDates(datefromreserve, datetoreserve);
    } else {
      setSelectedWeekdaysreserve([]);
    }
  }, [datefromreserve, datetoreserve]);

  return (
    <div className="main-content">
      <Commonheader />
      <div className="page-content">
        <div className="container-fluid">
          <div className="row project-wrapper">
            <div className="offer-list-main">
              <div className="offer-list-top">
                <div className="offer-heading">
                  <h6 className="offer-list-head">Update Offer</h6>
                </div>
                <Link
                    type="button"
                    className="btn db-other-button"
                    to="/offers"
                    style={{ marginRight: "1%" }}>
                    Offers list
                  </Link>
              </div>
              <div className="col-md-1">
                <ul
                  className="offer-create-list"
                  style={{ display: "contents" }}
                >
                  {/* {offer1valid===true? */}
                  <li
                    className={
                      tab === "step1"
                        ? "offer-list_new_1 active"
                        : "offer-list_new_1"
                    }
                    onClick={(e) => setTab("step1")}
                  >
                    Step 1<div style={{ fontSize: "12px" }}>Availability</div>
                  </li>
                  {/* :
                              <li className={tab==="step1"?"offer-list_new_1 active":"offer-list_new_1"} >Step 1
                                <div style={{fontSize:"12px"}}>Availability</div>
                              </li>

                            } */}
                  {/* {offer1valid===true? */}
                  <li
                    className={
                      tab === "step2"
                        ? "offer-list_new_1 active"
                        : "offer-list_new_1"
                    }
                    onClick={(e) => setTab("step2")}
                  >
                    Step 2<div style={{ fontSize: "12px" }}>Room Types</div>
                  </li>
                  {/* :
                              <li className={tab==="step2"?"offer-list_new_1 active":"offer-list_new_1"} >Step 2
                                <div style={{fontSize:"12px"}}>Room Types</div>
                              </li>
                            }  */}
                  {/* {offer1valid===true && offer2valid===true? */}
                  <li
                    className={
                      tab === "step3"
                        ? "offer-list_new_1 active"
                        : "offer-list_new_1"
                    }
                    onClick={(e) => setTab("step3")}
                  >
                    Step 3<div style={{ fontSize: "12px" }}>Rates</div>
                  </li>
                  {/* :
                              <li className={tab==="step3"?"offer-list_new_1 active":"offer-list_new_1"}>Step 3
                                <div style={{fontSize:"12px"}}>Rates</div>
                              </li>
                            }  */}
                  {/* {offer1valid===true && offer2valid===true && offer3valid===true? */}
                  <li
                    className={
                      tab === "step4"
                        ? "offer-list_new_1 active"
                        : "offer-list_new_1"
                    }
                    onClick={(e) => setTab("step4")}
                  >
                    Step 4<div style={{ fontSize: "12px" }}>Rules</div>
                  </li>
                  {/* :
                                <li className={tab==="step4"?"offer-list_new_1 active":"offer-list_new_1"} >Step 4
                                  <div style={{fontSize:"12px"}}>Rules</div>
                                </li>
                              } */}
                  {/* {offer1valid===true && offer2valid===true && offer3valid===true && offer4valid===true? */}
                  <li
                    className={
                      tab === "step5"
                        ? "offer-list_new_1 active"
                        : "offer-list_new_1"
                    }
                    onClick={(e) => setTab("step5")}
                  >
                    Step 5<div style={{ fontSize: "12px" }}>Inclusions</div>
                  </li>
                  {/* :
                              <li className={tab==="step5"?"offer-list_new_1 active":"offer-list_new_1"} >Step 5
                                <div style={{fontSize:"12px"}}>Inclusions</div>
                              </li>

                            }  */}
                  {/* {offer1valid===true && offer2valid===true && offer3valid===true && offer4valid===true && offer5valid===true? */}

                  <li
                    className={
                      tab === "step6"
                        ? "offer-list_new_1 active"
                        : "offer-list_new_1"
                    }
                    onClick={(e) => setTab("step6")}
                  >
                    Step 6<div style={{ fontSize: "12px" }}>Definition</div>
                  </li>
                  {/* :
                              <li className={tab==="step6"?"offer-list_new_1 active":"offer-list_new_1"}>Step 6
                                <div style={{fontSize:"12px"}}>Definition</div>
                              </li>
                              } */}
                </ul>
              </div>

              {tab === "step1" ? (
                <Offerstep1edit
                  setTab={setTab}
                  offer={offer}
                  handleoffereditcheckbox={handleoffereditcheckbox}
                  d_checkin={d_checkin}
                  d_checkout={d_checkout}
                  d_stay={d_stay}
                  d_reserve={d_reserve}
                  activestaydate={activestaydate}
                  setActivestaydate={setActivestaydate}
                  activestaydateopen={activestaydateopen}
                  setActivestaydateopen={setActivestaydateopen}
                  activecheckindate={activecheckindate}
                  setActivecheckindate={setActivecheckindate}
                  activecheckindateopen={activecheckindateopen}
                  setActivecheckindateopen={setActivecheckindateopen}
                  activecheckoutdate={activecheckoutdate}
                  setActivecheckoutdate={setActivecheckoutdate}
                  activecheckoutdateopen={activecheckoutdateopen}
                  setActivecheckoutdateopen={setActivecheckoutdateopen}
                  activereservedate={activereservedate}
                  setActivereservedate={setActivereservedate}
                  activereservedateopen={activereservedateopen}
                  setActivereservedateopen={setActivereservedateopen}
                  datefromstay={datefromstay}
                  datetostay={datetostay}
                  datefromcheckin={datefromcheckin}
                  datetocheckin={datetocheckin}
                  datefromcheckout={datefromcheckout}
                  datetocheckout={datetocheckout}
                  datefromreserve={datefromreserve}
                  datetoreserve={datetoreserve}
                  fpstay={fpstay}
                  fpcheckin={fpcheckin}
                  fpcheckout={fpcheckout}
                  fpreserve={fpreserve}
                  selectedWeekdaysstay={selectedWeekdaysstay}
                  selectedWeekdayscheckin={selectedWeekdayscheckin}
                  selectedWeekdayscheckout={selectedWeekdayscheckout}
                  selectedWeekdaysreserve={selectedWeekdaysreserve}
                  clickedDaysstay={clickedDaysstay}
                  clickedDayscheckin={clickedDayscheckin}
                  clickedDayscheckout={clickedDayscheckout}
                  clickedDaysreserve={clickedDaysreserve}
                  openclosestay={openclosestay}
                  setOpenclosestay={setOpenclosestay}
                  openclosecheckin={openclosecheckin}
                  setOpenclosecheckin={setOpenclosecheckin}
                  openclosecheckout={openclosecheckout}
                  setOpenclosecheckout={setOpenclosecheckout}
                  openclosereserve={openclosereserve}
                  setOpenclosereserve={setOpenclosereserve}
                  language={language}
                  selectedDatesstay={selectedDatesstay}
                  setSelectedDatesstay={setSelectedDatesstay}
                  selectedDatescheckin={selectedDatescheckin}
                  setSelectedDatescheckin={setSelectedDatescheckin}
                  selectedDatescheckout={selectedDatescheckout}
                  setSelectedDatescheckout={setSelectedDatescheckout}
                  selectedDatesreserve={selectedDatesreserve}
                  setSelectedDatesreserve={setSelectedDatesreserve}
                  handleApplyToCalendarstay={handleApplyToCalendarstay}
                  handleApplyToCalendarcheckin={handleApplyToCalendarcheckin}
                  handleApplyToCalendarcheckout={handleApplyToCalendarcheckout}
                  handleApplyToCalendareserve={handleApplyToCalendareserve}
                  toggleSelectedDaystay={toggleSelectedDaystay}
                  toggleSelectedDaycheckin={toggleSelectedDaycheckin}
                  toggleSelectedDaycheckout={toggleSelectedDaycheckout}
                  toggleSelectedDayreserve={toggleSelectedDayreserve}
                  handleOpenclosestay={handleOpenclosestay}
                  handleOpenclosecheckin={handleOpenclosecheckin}
                  handleOpenclosecheckout={handleOpenclosecheckout}
                  handleOpenclosereserve={handleOpenclosereserve}
                  handleCalendarChangeFrom={handleCalendarChangeFrom}
                  handleCalendarChangeTo={handleCalendarChangeTo}
                  handleCalendarChangeFromcheckin={
                    handleCalendarChangeFromcheckin
                  }
                  handleCalendarChangeTocheckin={handleCalendarChangeTocheckin}
                  handleCalendarChangeFromcheckout={
                    handleCalendarChangeFromcheckout
                  }
                  handleCalendarChangeTocheckout={
                    handleCalendarChangeTocheckout
                  }
                  handleCalendarChangeFromreserve={
                    handleCalendarChangeFromreserve
                  }
                  handleCalendarChangeToreserve={handleCalendarChangeToreserve}
                  offer1Check={offer1Check}
                  setOffer1check={setOffer1check}
                  selectedMulti={selectedMulti}
                  setselectedMulti={setselectedMulti}
                  offername={offername}
                  setOffername={setOffername}
                  nextoffterstep1={nextoffterstep1}
                  offer1error={offer1error}
                />
              ) : tab === "step2" ? (
                <Offerstep2edit
                  setTab={setTab}
                  offer2room={offer2room}
                  offerroom={offerroom}
                  offerrate={offerrate}
                  offerboard={offerboard}
                  handlebasedon={handlebasedon}
                  handleChangeroomoffer2all_label={
                    handleChangeroomoffer2all_label
                  }
                  boardoffer2={boardoffer2}
                  ratelistoffer2={ratelistoffer2}
                  activeroom={activeroom}
                  activeboard={activeboard}
                  activerate={activerate}
                  handleChangeroomoffer2={handleChangeroomoffer2}
                  handlesingleroomselect={handlesingleroomselect}
                  handlesingleoccupancyselect={handlesingleoccupancyselect}
                  handleChangeboardoffer2={handleChangeboardoffer2}
                  handlesingleboardselect={handlesingleboardselect}
                  handleChangerateoffer2={handleChangerateoffer2}
                  handlesinglerateselect={handlesinglerateselect}
                  nextoffterstep2={nextoffterstep2}
                  offer2error={offer2error}
                  availableboard={availableboard}
                  errorroom={errorroom}
                />
              ) : tab === "step3" ? (
                <Offerstep3edit
                  setTab={setTab}
                  selectedroomocc={selectedroomocc}
                  handleweekinput={handleweekinput}
                  currency={currency}
                  offer3fields={offer3fields}
                  handleOptionChangeoffer3price={handleOptionChangeoffer3price}
                  handleoffer3checkbox={handleoffer3checkbox}
                  handleoffer3change={handleoffer3change}
                  exceptionstaydate={exceptionstaydate}
                  setExceptionstaydate={setExceptionstaydate}
                  inputFieldsexception={inputFieldsexception}
                  setInputFieldsexception={setInputFieldsexception}
                  addInputFieldoffer3={addInputFieldoffer3}
                  removeInputFieldsoffer3={removeInputFieldsoffer3}
                  handleChangedatefrom={handleChangedatefrom}
                  handleChangetodate={handleChangetodate}
                  handleChangediscount={handleChangediscount}
                  exceptionnight={exceptionnight}
                  setExceptionnight={setExceptionnight}
                  inputFieldsextra={inputFieldsextra}
                  addInputFieldextra={addInputFieldextra}
                  removeInputFieldsextra={removeInputFieldsextra}
                  handleChangedatefromextra={handleChangedatefromextra}
                  handleChangetodateextra={handleChangetodateextra}
                  handleChangediscountsextra={handleChangediscountsextra}
                  countryOffer3={countryOffer3}
                  visibleItemsoffer3={visibleItemsoffer3}
                  handleloyalchangeoffer3country={
                    handleloyalchangeoffer3country
                  }
                  showAlloffer3country={showAlloffer3country}
                  setShowAlloffer3country={setShowAlloffer3country}
                  handleInputChangeconutryvalue={handleInputChangeconutryvalue}
                  // nextoffterstep3={nextoffterstep3}
                  offer3error={offer3error}
                  handleoffer3weekcheck={handleoffer3weekcheck}
                  ratetab={ratetab}
                  setRatetab={setRatetab}
                  tabroom={tabroom}
                  tabroomweek={tabroomweek}
                  handleTabweek={handleTabweek}
                  handleTab={handleTab}
                  handleClickroomweek={handleClickroomweek}
                  handleClickroom={handleClickroom}
                  handleweekinputextend={handleweekinputextend}
                  offer={offer}
                  nextoffterstep3rate={nextoffterstep3rate}
                  nextoffterstep3increment={nextoffterstep3increment}
                  nextoffterstep3freenight={nextoffterstep3freenight}
                  nextoffterstep3extra={nextoffterstep3extra}
                  nextoffterstep3country={nextoffterstep3country}
                  nextoffterstep3device={nextoffterstep3device}
                  offer3validrate={offer3validrate}
                  offer3validncrement={offer3validncrement}
                  offer3validfreenight={offer3validfreenight}
                  offer3validextra={offer3validextra}
                  offer3validcountry={offer3validcountry}
                  offer3validdivice={offer3validdivice}
                  country_discount={country_discount}
                  multicountri3={multicountri3}
                  setMulticountry3={setMulticountry3}
                  handleotherboard={handleotherboard}
                  otherboard={otherboard}
                  selectedotherboard={selectedotherboard}
                  board_apply_status={board_apply_status}
                  setBoard_apply_status={setBoard_apply_status}
                />
              ) : tab === "step4" ? (
                <Offerstep4edit
                  setTab={setTab}
                  offer4fields={offer4fields}
                  handleoffer4checkbox={handleoffer4checkbox}
                  Loyaltyclublist={Loyaltyclublist}
                  handleloyalchangeoffer4={handleloyalchangeoffer4}
                  handlechangeoffer4={handlechangeoffer4}
                  legend={legend}
                  handleOptionChangeoffer4Cancelpolicy={
                    handleOptionChangeoffer4Cancelpolicy
                  }
                  showAll={showAll}
                  setShowAll={setShowAll}
                  visibleItems={visibleItems}
                  handleloyalchangeoffer4country={
                    handleloyalchangeoffer4country
                  }
                  countrylist={countrylist}
                  nextoffterstep4={nextoffterstep4}
                  offer4error={offer4error}
                  offer={offer}
                  loyalty_club={loyalty_club}
                  handleloyalchangeoffer4loyal={handleloyalchangeoffer4loyal}
                  multicountri4={multicountri4}
                  setMulticountry4={setMulticountry4}
                />
              ) : tab === "step5" ? (
                <Offerstep5edit
                  setTab={setTab}
                  offer_include_status={offer_include_status}
                  setOffer_include_status={setOffer_include_status}
                  loyalty={loyalty}
                  otherloyal_status={otherloyal_status}
                  setOtherloyal_status={setOtherloyal_status}
                  otherloyal={otherloyal}
                  addInputField={addInputField}
                  removeInputFields={removeInputFields}
                  alllanguage={alllanguage}
                  loyallang={loyallang}
                  setLoyallang={setLoyallang}
                  hangleotherloyal={hangleotherloyal}
                  nextoffterstep5={nextoffterstep5}
                  handleloyalchange={handleloyalchange}
                  offer5error={offer5error}
                  offer={offer}
                  offerIncludelist={offerIncludelist}
                  hangleotherloyallang={hangleotherloyallang}
                  handlelanglo={handlelanglo}
                />
              ) : tab === "step6" ? (
                <Offerstep6edit
                  setTab={setTab}
                  offer6fields={offer6fields}
                  language={language}
                  selectedMulti6={selectedMulti6}
                  setselectedMulti6={setselectedMulti6}
                  handlechangeoffer6={handlechangeoffer6}
                  onFileChange={onFileChange}
                  handleoffer6checkbox={handleoffer6checkbox}
                  onFileChangepdf={onFileChangepdf}
                  Save={Save}
                  offer={offer}
                  currency={currency}
                  setImage={setImage}
                  image={image}
                  imagechoose={imagechoose}
                  setImagechoose={setImagechoose}
                  optionimage={optionimage}
                  pdf={pdf}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {toastmodal === true ? (
          <Toastmodal
            setToast={setToastmodal}
            message={toastmessage}
            type={toasttype}
            large={toastlarge}
          />
        ) : (
          ""
        )}
      </div>

      <Footer />
    </div>
  );
}

export default Offeredit;
