import React, { useEffect } from "react";
import { useNavigate, Route,useLocation } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch } from "react-redux";
import jwt from "jwt-decode";


const NonAuthProtected = (props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  if(token){
   
      if (token && (location.pathname === '/' || location.pathname === '/verify')) {
       navigate('/dashboard'); 
      } else if (!token && location.pathname !== '/') {
       navigate('/'); 
      }   
  }
  useEffect(() => {
    if (token) {
      
      if (token && (location.pathname === '/' || location.pathname === '/verify')) {
        navigate('/dashboard'); 
       } else if (!token && location.pathname !== '/') {
        navigate('/'); 
       }
    }
  }, [token, navigate]);

 

  // <Navigate to={{ pathname: "/", state: { from: props.location } }} />;
  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { NonAuthProtected, AccessRoute };
