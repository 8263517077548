import { useState } from 'react';
import Cookies from 'js-cookie';
import jwt from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import Config from '../Config';


export function useAuthHandling() {
    let navigate = useNavigate();

  const [isAuthorized, setIsAuthorized] = useState(true);
  const refreshtoken=localStorage.getItem("refreshtoken");
  

  const handleUnauthorized = async() => {
    
    const toekns= jwt(refreshtoken);
    const expirationTime = (toekns.exp * 1000) - 60000
    const date=Date.now();
    if (date >= expirationTime){
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("user_type");
      localStorage.removeItem("refreshtoken");

    navigate("/")
    }
    else{
      const res = await fetch(`${Config.apiurl}refresh-token`, {
        method: "POST",        
        body: JSON.stringify({
          old_token: `Bearer ${localStorage.getItem("refreshtoken")}`,
        }),
        headers: {       
          "Content-type": "application/json; charset=UTF-8",
        },
      });
    
      if (res.status === 200) {
        const data1 = await res.json();

        localStorage.setItem("token",data1.token);
        }
       
     
      else if(res.status===401){
        handleUnauthorized();
        handleUnauthorized();
  
      }
      else {
        console.log("");
       
      }
    }
  
    setIsAuthorized(false);
  };


  return { isAuthorized, handleUnauthorized };
}