import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Roomheader from "../../Common/Roomheader";
import Select from "react-select";
import Config from "../../Config";
import Commonimagepath from "../../Commonimagepath";
import axios from "axios";
import Alerts from "../../Alerts";
import ReactPaginate from "react-paginate";
import { useAuthHandling } from "../../Common/useAuthHandling";
import { UserContext } from "../../Contexts/UserContext";
import Footer from "../../Common/Footer";
import Toastmodal from "../../Common/Toastmodal";

function Roomconfiguration() {
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [list, setList] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [page, setPage] = useState(1);
  const [pageindex, setPageindex] = useState(1);
  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [bed, setBed] = useState([]);
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [showFullData, setShowFullData] = useState([]);
  const [changeddata, setChangeddata] = useState([]);

  const handleDropdownClickhotel = (i) => {
    // Toggle the showFullData state for the corresponding item
    const updatedShowFullData = [...showFullData];
    showFullData[i].hotel = !showFullData[i].hotel;
    setShowFullData(updatedShowFullData);
  };

  const handleChange = (i, index, e) => {
    let name = e.target.name;
    let value = e.target.value;
    let newval = [...list];
    let prv = list[i]["rooms"][index][name];

    if (value === "") {
      value = "";
      list[i]["rooms"][index][name] = value;
      list[i]["rooms"][index]["prv_rooms"] = prv;
      setList(newval);
    } else {
      let pr = list[i]["rooms"][index]["prv_rooms"];
      list[i]["rooms"][index][name] = value;

      if (pr && pr > value) {
        // If the new value is smaller than the previous value, remove excess bed types
        const reducedLength = value;
        list[i]["rooms"][index]["bedtype"] = list[i]["rooms"][index][
          "bedtype"
        ].slice(0, reducedLength);
      } else {
        // Calculate the difference between the new value and the previous value
        let com;
        if (pr) {
          com = value - pr;
        } else {
          com = value;
        }

        // Add new bed types based on the difference
        for (let j = 0; j < com; j++) {
          list[i]["rooms"][index]["bedtype"].push({
            bed_count: "",
            bed_type: [],
            bedsize: "",
          });
        }
      }

      list[i]["rooms"][index]["prv_rooms"] = value; // Update the previous value
      setList(newval);
    }
  };

  // const handleBedChange1 = (indx, index, i, value, fieldName) => {
  //   const updatedList = [...list];
  //   const bedData = updatedList[indx]["rooms"][index].bedtype[i];
  //   if (bedData) {
  //     bedData[fieldName] = value;
  //     setList(updatedList);
  //   }
  // };
  // const handleBedChange2 = (indx, index, i, value, fieldName) => {
  //   const updatedList = [...list];
  //   const bedData = updatedList[indx]["rooms"][index].bedtype[i];

  //   if (bedData) {
  //     bedData[fieldName] = value;
  //     setList(updatedList);
  //   }
  // };

  const handleBedChange = (indx, index, i, value, fieldName) => {
    const updatedList = [...list];
    const bedData = updatedList[indx]["rooms"][index].bedtype[i];

    if (bedData) {
      bedData[fieldName] = value;
      setList(updatedList);
      let changedDataCopy = [...changeddata];
      changedDataCopy[indx] = {
        ...changedDataCopy[indx],
        hotel_id: updatedList[indx].hotel_id,
        rooms: updatedList[indx].rooms.map((room, roomIndex) => {
          if (roomIndex === index) {
            return {
              ...room,
              bedtype: room.bedtype.map((bed, bedIndex) => {
                if (bedIndex === i) {
                  return {
                    ...bed,
                    [fieldName]: value,
                  };
                } else {
                  return room;
                }
              }),
            };
          } else {
            return room;
          }
        }),
      };
      // changedDataCopy[indx].rooms[index].bedtype[i].icon = bedData.icon;
      // changedDataCopy[indx].rooms[index].bedtype[i].bedsize = bedData.bedsize;

      setChangeddata(changedDataCopy);
    }
  };

  const handleBedChange1 = (indx, index, i, value, fieldName, bed_id) => {
    const updatedList = [...list];
    const bedData = updatedList[indx]["rooms"][index].bedtype[i];
    if (bedData) {
      bedData[fieldName] = value;
      setList(updatedList);

      const getBedDetails = async (e) => {
        const res = await fetch(`${Config.apiurl}room-bedtype/${value.value}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const data1 = await res.json();
        const data = data1.data;

        if (data1.status_code === "200") {
          let newval = [...list];
          list[indx]["rooms"][index]["bedtype"][i]["bedsize"] = data.bedsize;
          list[indx]["rooms"][index]["bedtype"][i]["bed_type"]["icon"] =
            data.icon;
          setList(newval);
        } else if (data1.reauth === true) {
          handleUnauthorized();
          getBedDetails();
        } else {
          console.log("error");
        }
      };
      getBedDetails();

      // let changedDataCopy = [...changeddata];
      // changedDataCopy[indx] = {
      //   ...changedDataCopy[indx],
      //   hotel_id: updatedList[indx].hotel_id,
      //   rooms: updatedList[indx].rooms.map((room, roomIndex) => {
      //     if (roomIndex === index) {
      //       return {
      //         ...room,
      //         bedtype: room.bedtype.map((bed, bedIndex) => {
      //           if (bedIndex === i) {
      //             return {
      //               ...bed,
      //               [fieldName]: value,
      //             };
      //           } else {
      //             return bed;
      //           }
      //         }),
      //       };
      //     } else {
      //       return room;
      //     }
      //   }),
      // };

      let changedDataCopy = [...changeddata];
      changedDataCopy[indx] = {
        ...changedDataCopy[indx],
        hotel_id: updatedList[indx].hotel_id,
        rooms: updatedList[indx].rooms.map((room, roomIndex) => {
          if (roomIndex === index) {
            console.log(room);
            let updatedRoom = { ...room };
            // let updatedBedtype = room.bedtype.map((bed, bedIndex) => {
            //   if (bedIndex === i) {
            //     return {
            //       ...bed,
            //       [fieldName]: value,
            //     };
            //   }
            //   return bed;
            // });

            // Check if bedtype needs to be updated
            // if (updatedBedtype !== room.bedtype) {
            //   updatedRoom = {
            //     ...updatedRoom,
            //     bedtype: updatedBedtype,
            //   };
            // }

            return updatedRoom;
          }
          return room;
        }),
      };

      setChangeddata(changedDataCopy);
      console.log(changedDataCopy);
    }
  };

  const getBed = async (e) => {
    const res = await fetch(`${Config.apiurl}bedtypes`, {
      method: "POST",
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setBed(data);
    }
  };

  const fetchComments = async (currentPage) => {
    const res = await fetch(
      `${Config.apiurl}hotel/room-bedtype/list?page=${currentPage}`,
      {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );

    const data = await res.json();
    if (data.status_code === "200") {
      return data.data.data;
    } else if (data.reauth === true) {
      handleUnauthorized();
      fetchComments(currentPage);
    }
  };

  const handlePageClick = async (data) => {
    if (data === 1) {
      setPage(data);
      setPageindex(1);
      const commentsFormServer = await fetchComments(data);
      setList(commentsFormServer);
    } else {
      let currentPage = data.selected + 1;
      setPageindex(currentPage);
      setPage(data);
      const commentsFormServer = await fetchComments(currentPage);
      setList(commentsFormServer);
    }
  };

  const fetchPost = async () => {
    const response = await fetch(
      `${Config.apiurl}hotel/room-bedtype/list?page=1`,
      {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const data = await response.json();
    if (response.status === 200) {
      if (data.status_code === "200") setList(data.data.data);
      setPaginate(data.data.last_page);
    } else if (data.reauth === true) {
      handleUnauthorized();
      fetchPost();
    } else {
      console.log("error");
    }
  };

  const PostData = async (e) => {
    e.preventDefault();

    if (changeddata?.length > 0) {
      let formdata = new FormData();
      for (let i = 0; i < changeddata.length; i++) {
        if (changeddata[i] !== undefined) {
          for (let j = 0; j < changeddata[i].rooms?.length; j++) {
            if (changeddata[i].rooms[j].room_id) {
              formdata.append(
                `hotels[${i}][hotel_id]`,
                changeddata[i].hotel_id
              );
              formdata.append(
                `hotels[${i}][rooms][${j}][room_id]`,
                changeddata[i].rooms[j].room_id
              );
              formdata.append(
                `hotels[${i}][rooms][${j}][no_of_rooms]`,
                changeddata[i].rooms[j]?.no_of_rooms
              );

              for (
                let k = 0;
                k < changeddata[i].rooms[j]?.bedtype?.length;
                k++
              ) {
                if (changeddata[i].rooms[j].bedtype[k]?.bed_count) {
                  formdata.append(
                    `hotels[${i}][rooms][${j}][room_data][${k}][bed]`,
                    changeddata[i].rooms[j].bedtype[k]?.bed_count
                  );
                }
                if (changeddata[i].rooms[j].bedtype[k]?.bed_type?.value) {
                  formdata.append(
                    `hotels[${i}][rooms][${j}][room_data][${k}][bedtype]`,
                    changeddata[i].rooms[j].bedtype[k]?.bed_type?.value
                  );
                }
                if (changeddata[i].rooms[j].bedtype[k]?.bedsize) {
                  formdata.append(
                    `hotels[${i}][rooms][${j}][room_data][${k}][bedsize]`,
                    changeddata[i].rooms[j].bedtype[k]?.bedsize
                  );
                }
              }
            }
          }
        }
      }
      axios
        .post(`${Config.apiurl}hotel/room-bedtype/bulk-update`, formdata, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.status_code === "200") {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(false);
            fetchPost();
          } else if (res.reauth === true) {
            handleUnauthorized();
            PostData();
          } else {
            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(false);
          }
        });
    } else {
      setToastmodal(true);
      setToastmessage("You have made no changes to save");
      setToasttype("success");
      setToastlarge(true);
    }
  };
  useEffect(() => {
    getBed();
  }, []);

  useEffect(() => {
    fetchPost();
  }, [id]);

  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    if (list.length > 0) {
      const initialState = list.map((item, index) => ({
        hotel: index === 0,
        rooms: Array(item.rooms.length).fill(false),
      }));
      setShowFullData(initialState);
    }
  }, [list.length]);

  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);
  const [symbolsrate] = useState(["e", "E", "+", "-"]);

  return (
    <>
      <div id="layout-wrapper">
        <Roomheader />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box-in d-sm-flex align-items-center justify-content-between">
                    <Link
                      type="button"
                      to="#"
                      className="btn db-save-button"
                      onClick={PostData}
                    >
                      Update
                    </Link>
                  </div>
                </div>
              </div>

              <div className="row project-wrapper">
                <div className="row dashboard-top-tab-style">
                  <div className="col-xxl-12 dashboard-main-style">
                    <div className="card" style={{ marginTop: "5px" }}>
                      <div className="card-body">
                        <div>
                          {/* <ul className="nav nav-tabs nav-tabs-custom nav-success mb-3" role="tablist">
                        </ul> */}

                          <div className="">
                            {list.map((li, indx) => {
                              return (
                                <>
                                  {list.length > 0 ? (
                                    // <div className="rooms_list_hname">{li.hotel_name}</div>
                                    <div
                                      className={
                                        list.length > 1
                                          ? "row hotel_single_list"
                                          : "row"
                                      }
                                    >
                                      {list.length > 1 ? (
                                        <div className="hotel_service_display_12">
                                          <div className="hk_service_all_hotel">
                                            <span className="">
                                              {pageindex === 1
                                                ? indx + 1
                                                : (pageindex - 1) * 10 +
                                                  indx +
                                                  1}
                                              .
                                            </span>
                                            <span
                                              style={{ marginLeft: "10px" }}
                                            >
                                              {li.hotel_name}
                                            </span>
                                          </div>
                                          {showFullData[indx]?.hotel ===
                                          true ? (
                                            <span
                                              className="db-modify_toggle_btn"
                                              onClick={() =>
                                                handleDropdownClickhotel(indx)
                                              }
                                            >
                                              <span
                                                className="bx bxs-chevron-up"
                                                style={{ fontSize: "20px" }}
                                              ></span>
                                            </span>
                                          ) : (
                                            <span
                                              className="db-modify_toggle_btn"
                                              onClick={() =>
                                                handleDropdownClickhotel(indx)
                                              }
                                            >
                                              <span
                                                className="bx bxs-chevron-down"
                                                style={{ fontSize: "20px" }}
                                              ></span>
                                            </span>
                                          )}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <table
                                        id="fixed-header orderTable"
                                        className={`table table-nowrap align-middle ${
                                          showFullData[indx]?.hotel === true
                                            ? ""
                                            : "d-none"
                                        }`}
                                      >
                                        <thead className="">
                                          <tr>
                                            <td
                                              className=""
                                              data-sort="customer_name"
                                            >
                                              Room Name
                                            </td>
                                            <td className="" data-sort="status">
                                              Bed Rooms
                                            </td>
                                            <td
                                              colspan="4"
                                              className="bedtype-td-layer"
                                            >
                                              <table className="bedtype-td-table">
                                                <tr className="bedtype-tr-style">
                                                  <td
                                                    className="bedtype-td-style"
                                                    colspan="4"
                                                  >
                                                    Bed Details
                                                  </td>
                                                </tr>
                                                <tr className="bedtype-tr-lower-style">
                                                  <td
                                                    className=""
                                                    data-sort="status"
                                                  >
                                                    Bed/Room
                                                  </td>
                                                  <td
                                                    className=""
                                                    data-sort="status"
                                                  >
                                                    Bed Name
                                                  </td>
                                                  <td
                                                    className=""
                                                    data-sort="status"
                                                  >
                                                    Bed Icon
                                                  </td>
                                                  <td
                                                    className=""
                                                    data-sort="status"
                                                  >
                                                    Bed Size
                                                  </td>
                                                </tr>
                                              </table>
                                            </td>
                                          </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                          {li.rooms.map((user, index) => {
                                            return (
                                              <>
                                                {
                                                  //user.no_of_rooms!==""?
                                                  user.bedtype.map((be, i) => {
                                                    return (
                                                      <tr
                                                        key={i}
                                                        // className={i===0?"pa_t":i===user.bedtype.length-1?"pa_b":"pa_null"}
                                                        className={
                                                          user?.no_of_rooms >
                                                            1 && i === 0
                                                            ? "pa_t"
                                                            : user?.no_of_rooms >
                                                                  1 &&
                                                                i ===
                                                                  user.bedtype
                                                                    .length -
                                                                    1
                                                              ? "pa_b"
                                                              : "pa_null"
                                                        }
                                                      >
                                                        <td className="customer_name">
                                                          {i === 0
                                                            ? user.room_name
                                                            : ""}
                                                        </td>
                                                        {i === 0 ? (
                                                          <td className="">
                                                            <input
                                                              type="number"
                                                              onKeyDown={(e) =>
                                                                symbolsArr.includes(
                                                                  e.key
                                                                ) &&
                                                                e.preventDefault()
                                                              }
                                                              className="form-bed-type-text"
                                                              name="no_of_rooms"
                                                              min={1}
                                                              value={
                                                                user.no_of_rooms
                                                              }
                                                              onChange={(e) =>
                                                                handleChange(
                                                                  indx,
                                                                  index,
                                                                  e
                                                                )
                                                              }
                                                              onFocus={(e) =>
                                                                e.target.addEventListener(
                                                                  "wheel",
                                                                  function (e) {
                                                                    e.preventDefault();
                                                                  },
                                                                  {
                                                                    passive: false,
                                                                  }
                                                                )
                                                              }
                                                            />
                                                          </td>
                                                        ) : (
                                                          <td></td>
                                                        )}
                                                        <td className="">
                                                          <input
                                                            type="number"
                                                            onKeyDown={(e) =>
                                                              symbolsArr.includes(
                                                                e.key
                                                              ) &&
                                                              e.preventDefault()
                                                            }
                                                            min={0}
                                                            className="form-bed-type-text"
                                                            name="bed_count"
                                                            value={be.bed_count}
                                                            onChange={(e) =>
                                                              handleBedChange(
                                                                indx,
                                                                index,
                                                                i,
                                                                e.target.value,
                                                                "bed_count"
                                                              )
                                                            }
                                                            onFocus={(e) =>
                                                              e.target.addEventListener(
                                                                "wheel",
                                                                function (e) {
                                                                  e.preventDefault();
                                                                },
                                                                {
                                                                  passive: false,
                                                                }
                                                              )
                                                            }
                                                          />
                                                        </td>
                                                        <td
                                                          className="customer_name bedtype_roomedit"
                                                          style={{
                                                            width: "200px",
                                                          }}
                                                        >
                                                          <Select
                                                            className="form-control bedtype_roomedit_select"
                                                            id="exampleInputUsername1"
                                                            options={bed}
                                                            value={be.bed_type}
                                                            onChange={(val) =>
                                                              handleBedChange1(
                                                                indx,
                                                                index,
                                                                i,
                                                                val,
                                                                "bed_type",
                                                                be.bed_type_id
                                                              )
                                                            }
                                                          />
                                                        </td>
                                                        <td className="customer_name">
                                                          <img
                                                            src={`${Commonimagepath.apiurl}${be.bed_type.icon}`}
                                                            alt=""
                                                            style={{
                                                              marginLeft:
                                                                "100px",
                                                            }}
                                                          />
                                                        </td>
                                                        <td className="customer_name">
                                                          <input
                                                            type="text"
                                                            className="form-bed-type-text"
                                                            name="bedsize"
                                                            value={be.bedsize}
                                                            onChange={(e) =>
                                                              handleBedChange(
                                                                indx,
                                                                index,
                                                                i,
                                                                e.target.value,
                                                                "bedsize"
                                                              )
                                                            }
                                                            style={{
                                                              width: "150px",
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>
                                                    );
                                                  })
                                                  //:
                                                  // user.bedtype.map((be,i)=>{
                                                  //   return(
                                                  //     <tr key={i}>
                                                  //     <td className="customer_name">{i === 0 ? user.room_name : ''}</td>
                                                  //     {i === 0 ? (
                                                  //         <td className="db-photosList" rowSpan={user.no_of_rooms}>
                                                  //         <input
                                                  //             type="number"
                                                  //             className="form-bed-type-text"
                                                  //             name="no_of_rooms"
                                                  //             value={user.no_of_rooms}
                                                  //             onChange={(e) => handleChange(indx,index, e)}
                                                  //         />
                                                  //         </td>
                                                  //     ) : null}
                                                  //     <td className="db-photosList">
                                                  //         <input
                                                  //         type="number"
                                                  //         className="form-bed-type-text"
                                                  //         name="bed_count"
                                                  //         value={be.bed_count}
                                                  //         onChange={(e) => handleBedChange1(indx,index, i, e.target.value, "bed_count")}
                                                  //         />
                                                  //     </td>
                                                  //     <td className="customer_name bedtype_roomedit" style={{ width: "200px" }}>
                                                  //         <Select
                                                  //         className="form-control"
                                                  //         id="exampleInputUsername1"
                                                  //         options={bed}
                                                  //         value={be.bed_type}
                                                  //         onChange={(val) => handleBedChange(indx,index, i, val, "bed_type",be.bed_type_id)}
                                                  //         />
                                                  //     </td>
                                                  //     <td className="customer_name" style={{padding: "0.75rem 2rem"}}>
                                                  //         <img
                                                  //         src={`${Commonimagepath.apiurl}${be.bed_type.icon}`}
                                                  //         alt="" style={{marginLeft:"100px"}}
                                                  //         />
                                                  //     </td>
                                                  //     <td className="customer_name">
                                                  //         <input
                                                  //         type="text"
                                                  //         className="form-bed-type-text"
                                                  //         name="bedsize"
                                                  //         value={be.bedsize}
                                                  //         onChange={(e) => handleBedChange2(indx,index, i, e.target.value, "bedsize")}

                                                  //         style={{ width: "150px" }}
                                                  //         />
                                                  //     </td>
                                                  //     </tr>
                                                  //   )
                                                  // })
                                                }
                                              </>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })}
                            {paginate > 1 ? (
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={paginate}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={handlePageClick}
                                containerClassName={
                                  "pagination justify-content-end"
                                }
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            ) : (
                              ""
                            )}
                            <div
                              className="noresult"
                              style={{ display: "none" }}
                            >
                              <div className="text-center">
                                <lord-icon
                                  src="https://cdn.lordicon.com/msoeawqm.json"
                                  trigger="loop"
                                  colors="primary:#405189,secondary:#0ab39c"
                                  style={{ width: "75px", height: "75px" }}
                                ></lord-icon>
                                <h5 className="mt-2">Sorry! No Result Found</h5>
                                <p className="text-muted">
                                  We've searched more than 150+ Orders We did
                                  not find any orders for you search.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
      <Footer />
    </>
  );
}

export default Roomconfiguration;
