import React from "react";
import { Link, NavLink } from "react-router-dom";

function HotelSidebar() {
  return (
    <div>
      <div
        id="removeNotificationModal"
        className="modal fade zoomIn"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="NotificationModalbtn-close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mt-2 text-center">
                <lord-icon
                  src="https://cdn.lordicon.com/gsqxdxog.json"
                  trigger="loop"
                  colors="primary:#f7b84b,secondary:#f06548"
                  style={{ width: "100px", height: "100px" }}
                ></lord-icon>
                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                  <h4>Are you sure ?</h4>
                  <p className="text-muted mx-4 mb-0">
                    Are you sure you want to remove this Notification ?
                  </p>
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                <button
                  type="button"
                  className="btn w-sm btn-light"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn w-sm btn-danger"
                  id="delete-notification"
                >
                  Yes, Delete It!
                </button>
              </div>
            </div>
          </div>
          {/* /.modal-content  */}
        </div>
        {/* /.modal-dialog  */}
      </div>
      <div className="app-menu navbar-menu">
        {/* LOGO  */}
        <div className="navbar-brand-box">
          {/* Dark Logo */}
          <Link to="/dashboard" className="logo logo-dark">
            <span className="logo-sm">
              <img src="/images/sm-white.png" alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src="/images/logo-white.png" alt="" height="40" />
            </span>
          </Link>
          {/* Light Logo */}
          <Link to="/dashboard" className="logo logo-light">
            <span className="logo-sm">
              <img src="/images/sm-white.png" alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src="/images/logo-white.png" alt="" height="40" />
            </span>
          </Link>
          <button
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>

        <div id="scrollbar">
          <div className="container-fluid">
            <div id="two-column-menu"></div>
            <ul className="navbar-nav" id="navbar-nav">
              <li className="menu-title">
                <span data-key="t-menu">Menu</span>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link menu-link common"
                  to="/hotel-dashboard"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarDashboards"
                >
                  <i className="las la-tachometer-alt"></i>{" "}
                  <span data-key="t-dashboards">Dashboard</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link menu-link common "
                  to="#"
                  role="button"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="sidebarDashboards"
                >
                  <i className="bx bxs-school"></i>{" "}
                  <span data-key="t-dashboards">Hotel info</span>
                </Link>
                <div className="collapse menu-dropdown" id="sidebarSuperAdmin">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/general-information"
                        className="nav-link common"
                        data-key="t-google"
                      >
                        General information
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/reviews"
                        className="nav-link common"
                        data-key="t-vector"
                      >
                        Reviews
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <Link
                        to="#"
                        className="nav-link common"
                        data-key="t-vector"
                      >
                        Hotel board
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      <Link
                        to="/hotel-services"
                        className="nav-link common"
                        data-key="t-vector"
                      >
                        HK services
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/rooms"
                        className="nav-link common"
                        data-key="t-vector"
                      >
                        Room Informations
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/cancellation-policies-list"
                        state={{
                          tabname: "details",
                        }}
                        className="nav-link"
                        data-key="t-google"
                      >
                        Cancellation policies
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/cancellation-policies-features"
                        state={{
                          tabname: "details",
                        }}
                        className="nav-link"
                        data-key="t-google"
                      >
                        Featured Cancellation Policies
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        to="/child-policies"
                        state={{
                          tabname: "details",
                        }}
                        className="nav-link"
                        data-key="t-google"
                      >
                        Child Policy
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                    <Link
                      to="/hotel-user-list"
                      state={{                               
                        tabname: "details",
                      }}
                      className="nav-link"
                      data-key="t-google"
                    >
                   Hotel User Create
                    </Link>
                  </li> */}
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <Link
                  className="nav-link menu-link common"
                  to="#"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="ri-currency-line"></i>{" "}
                  <span data-key="t-maps">Rates</span>
                </Link>
                <div className="collapse menu-dropdown" id="sidebarSuperAdmin">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to="/board-plan"
                        className="nav-link common"
                        data-key="t-vector"
                      >
                        Board plans
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/rate-plan-list"
                        className="nav-link common"
                        data-key="t-vector"
                      >
                        Rate plans
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/tax-edit"
                        className="nav-link common"
                        data-key="t-vector"
                      >
                        Tax
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/price"
                        className="nav-link common"
                        data-key="t-google"
                      >
                        Rates
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                    <Link
                      to="#"
                      className="nav-link common"
                      data-key="t-vector"
                    >
                     Market currency
                    </Link>
                  </li> */}
                  </ul>
                </div>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link menu-link common"
                  to="/offers"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="las la-percentage"></i>{" "}
                  <span data-key="t-maps">Offers</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link menu-link common"
                  to="/add-on"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="las la-plus-square"></i>{" "}
                  <span data-key="t-maps">Add-ons</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link menu-link common"
                  to="/loyalty-club"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="las la-gift"></i>{" "}
                  <span data-key="t-maps">Loyalty</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link menu-link common"
                  to="/reservations"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="las la-calendar"></i>{" "}
                  <span data-key="t-maps">Reservations</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link menu-link common"
                  to="/codes"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="las la-tag"></i>{" "}
                  <span data-key="t-maps">Private sale</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link menu-link common"
                  to="#"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMaps"
                >
                  <i className="las la-laptop-code"></i>{" "}
                  <span data-key="t-maps">BI</span>
                </Link>
              </li>

              <li className="nav-item">
                <NavLink
                  to="/availability"
                  className="nav-link menu-link common"
                  data-key="t-vector"
                >
                  <i className="las la-calendar-check"></i>{" "}
                  <span data-key="t-maps">Availability</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <Link
                  to="/conditions"
                  className="nav-link menu-link common"
                  data-key="t-vector"
                >
                  <i className="las la-file-alt"></i>{" "}
                  <span data-key="t-maps">Condition</span>
                </Link>
              </li>
            </ul>
          </div>
          {/* <!-- Sidebar --> */}
        </div>

        <div className="sidebar-background"></div>
      </div>
      <div className="vertical-overlay"></div>
    </div>
    // <div>
    //   <div
    //     id="removeNotificationModal"
    //     className="modal fade zoomIn"
    //     tabindex="-1"
    //     aria-hidden="true"
    //   >
    //     <div className="modal-dialog modal-dialog-centered">
    //       <div className="modal-content">
    //         <div className="modal-header">
    //           <button
    //             type="button"
    //             className="btn-close"
    //             data-bs-dismiss="modal"
    //             aria-label="Close"
    //             id="NotificationModalbtn-close"
    //           ></button>
    //         </div>
    //         <div className="modal-body">
    //           <div className="mt-2 text-center">
    //             <lord-icon
    //               src="https://cdn.lordicon.com/gsqxdxog.json"
    //               trigger="loop"
    //               colors="primary:#f7b84b,secondary:#f06548"
    //               style={{ width: "100px", height: "100px" }}
    //             ></lord-icon>
    //             <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
    //               <h4>Are you sure ?</h4>
    //               <p className="text-muted mx-4 mb-0">
    //                 Are you sure you want to remove this Notification ?
    //               </p>
    //             </div>
    //           </div>
    //           <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
    //             <button
    //               type="button"
    //               className="btn w-sm btn-light"
    //               data-bs-dismiss="modal"
    //             >
    //               Close
    //             </button>
    //             <button
    //               type="button"
    //               className="btn w-sm btn-danger"
    //               id="delete-notification"
    //             >
    //               Yes, Delete It!
    //             </button>
    //           </div>
    //         </div>
    //       </div>
    //       {/* /.modal-content  */}
    //     </div>
    //     {/* /.modal-dialog  */}
    //   </div>
    //   <div className="app-menu navbar-menu">
    //     {/* LOGO  */}
    //     <div className="navbar-brand-box">
    //       {/* Dark Logo */}
    //       <Link to="/dashboard" className="logo logo-dark">
    //         <span className="logo-sm">
    //           <img src="/images/sm-white.png" alt="" height="22" />
    //         </span>
    //         <span className="logo-lg">
    //           <img src="/images/logo-white.png" alt="" height="40" />
    //         </span>
    //       </Link>
    //       {/* Light Logo */}
    //       <Link to="/dashboard" className="logo logo-light">
    //         <span className="logo-sm">
    //           <img src="/images/sm-white.png" alt="" height="22" />
    //         </span>
    //         <span className="logo-lg">
    //           <img src="/images/logo-white.png" alt="" height="40" />
    //         </span>
    //       </Link>
    //       <button
    //         type="button"
    //         className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
    //         id="vertical-hover"
    //       >
    //         <i className="ri-record-circle-line"></i>
    //       </button>
    //     </div>

    //     <div id="scrollbar">
    //       <div className="container-fluid">
    //         <div id="two-column-menu"></div>
    //         <ul className="navbar-nav" id="navbar-nav">
    //           <li className="menu-title">
    //             <span data-key="t-menu">Menu</span>
    //           </li>
    //           <li className="nav-item">
    //             <NavLink
    //               className="nav-link menu-link"
    //               to="/dashboard"
    //               role="button"
    //               aria-expanded="false"
    //               aria-controls="sidebarDashboards"
    //             >
    //               <i className="las la-tachometer-alt"></i>{" "}
    //               <span data-key="t-dashboards">Dashboard</span>
    //             </NavLink>
    //           </li>
    //           <li className="nav-item">
    //             <Link
    //               className="nav-link menu-link"
    //               to="#"

    //               role="button"
    //               aria-expanded="false"
    //               aria-controls="sidebarMaps"
    //             >
    //                <i className="bx bxs-school"></i>{" "}
    //               <span data-key="t-maps">Hotel</span>
    //             </Link>
    //             <div className="collapse menu-dropdown" id="sidebarAuth">
    //               <ul className="nav nav-sm flex-column">
    //                 <li className="nav-item">
    //                   <Link
    //                     to="/general-information"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //                    General Information
    //                   </Link>
    //                 </li>
    //                 <li className="nav-item">
    //                   <Link
    //                     to="#"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //                   Review
    //                   </Link>
    //                 </li>
    //               </ul>
    //             </div>
    //           </li>
    //           <li className="nav-item">
    //             <Link
    //               className="nav-link menu-link"
    //               to="#"

    //               role="button"
    //               aria-expanded="false"
    //               aria-controls="sidebarMaps"
    //             >
    //             <i className="mdi mdi-bed-king"></i>{" "}
    //               <span data-key="t-maps">Rooms</span>
    //             </Link>
    //             <div className="collapse menu-dropdown" id="sidebarAuth">
    //               <ul className="nav nav-sm flex-column">
    //                 <li className="nav-item">
    //                   <Link
    //                     to="/rooms"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //                     Rooms
    //                   </Link>
    //                 </li>
    //                 <li className="nav-item">
    //                   <Link
    //                     to="room-details-en.html"
    //                     className="nav-link"
    //                     data-key="t-vector"
    //                   >
    //                     Create Room
    //                   </Link>
    //                 </li>
    //                 <li className="nav-item">
    //                   <Link
    //                    to="/rooms"
    //                    state={{
    //                      tabname: "details",
    //                    }}
    //                     className="nav-link"

    //                     role="button"
    //                     aria-expanded="false"
    //                     aria-controls="sidebarSignIn"
    //                     data-key="t-signin"
    //                   >
    //                     {" "}
    //                     Room Info
    //                   </Link>

    //                 </li>
    //               </ul>
    //             </div>
    //           </li>
    //           <li className="nav-item">
    //             <Link
    //               className="nav-link menu-link"
    //               to="#sidebarBasicSettings"

    //               role="button"
    //               aria-expanded="false"
    //               aria-controls="sidebarMaps"
    //             >
    //               <i className="ri-settings-5-fill"></i>{" "}
    //               <span data-key="t-maps">Rates</span>
    //             </Link>

    //             <div className="collapse menu-dropdown" id="sidebarAuth">
    //               <ul className="nav nav-sm flex-column">
    //                 <li className="nav-item">
    //                   <Link
    //                     to="/price"

    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //                   Rate
    //                   </Link>
    //                 </li>
    //                 <li className="nav-item">
    //                   <Link
    //                     to="#"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //                 Markets Currencies
    //                   </Link>
    //                 </li>
    //                 <li className="nav-item">
    //                   <Link
    //                     to="#"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //                Rate Plan
    //                   </Link>
    //                 </li>
    //                 <li className="nav-item">
    //                   <Link
    //                     to="#"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //                Tax
    //                   </Link>
    //                 </li>
    //               </ul>
    //             </div>
    //           </li>
    //           <li className="nav-item">
    //             <Link
    //               className="nav-link menu-link"
    //               to="#"

    //               role="button"
    //               aria-expanded="false"
    //               aria-controls="sidebarMaps"
    //             >
    //               <i className="fa-solid fa-calendar-days"></i>{" "}
    //               <span data-key="t-maps">Availability</span>
    //             </Link>

    //           </li>

    //           <li className="nav-item">
    //             <Link
    //               className="nav-link menu-link"
    //               to="#sidebarAuth"

    //               role="button"
    //               aria-expanded="false"
    //               aria-controls="sidebarAuth"
    //             >
    //               <i className="mdi mdi-bed-king"></i>{" "}
    //               <span data-key="t-authentication">Conditions</span>
    //             </Link>
    //             <div className="collapse menu-dropdown" id="sidebarAuth">
    //               <ul className="nav nav-sm flex-column">
    //                 <li className="nav-item">
    //                   <Link
    //                     to="#"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //                   Open / Close
    //                   </Link>
    //                 </li>
    //                 <li className="nav-item">
    //                   <Link
    //                     to="#"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //                 Minimum Stay
    //                   </Link>
    //                 </li>
    //                 <li className="nav-item">
    //                   <Link
    //                     to="#"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //               Maximum Stay
    //                   </Link>
    //                 </li>
    //                 <li className="nav-item">
    //                   <Link
    //                     to="#"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //              Cancelation & Payment Policy
    //                   </Link>
    //                 </li>
    //                 <li className="nav-item">
    //                   <Link
    //                     to="#"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //             Booking Window
    //                   </Link>
    //                 </li>
    //                 <li className="nav-item">
    //                   <Link
    //                     to="#"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //            Other Condition
    //                   </Link>
    //                 </li>
    //               </ul>
    //             </div>
    //           </li>
    //           <li className="nav-item">
    //             <Link
    //               className="nav-link menu-link"
    //               to="/offers"

    //               role="button"
    //               aria-expanded="false"
    //               aria-controls="sidebarAuth"
    //             >
    //               <i className="mdi mdi-bed-king"></i>{" "}
    //               <span data-key="t-authentication">Offers</span>
    //             </Link>

    //           </li>
    //           <li className="nav-item">
    //             <Link
    //               className="nav-link menu-link"
    //               to="/extras"

    //               role="button"
    //               aria-expanded="false"
    //               aria-controls="sidebarAuth"
    //             >
    //               <i className="mdi mdi-bed-king"></i>{" "}
    //               <span data-key="t-authentication">Extras</span>
    //             </Link>

    //           </li>
    //           <li className="nav-item">
    //             <Link
    //               className="nav-link menu-link"
    //               to="#sidebarAuth"

    //               role="button"
    //               aria-expanded="false"
    //               aria-controls="sidebarAuth"
    //             >
    //               <i className="mdi mdi-bed-king"></i>{" "}
    //               <span data-key="t-authentication">Reservations</span>
    //             </Link>
    //             <div className="collapse menu-dropdown" id="sidebarAuth">
    //               <ul className="nav nav-sm flex-column">
    //                 <li className="nav-item">
    //                   <Link
    //                     to="#"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //                  Reservations
    //                   </Link>
    //                 </li>
    //                 <li className="nav-item">
    //                   <Link
    //                     to="#"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //                Download
    //                   </Link>
    //                 </li>
    //                 <li className="nav-item">
    //                   <Link
    //                     to="#"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //              Revision
    //                   </Link>
    //                 </li>
    //                 <li className="nav-item">
    //                   <Link
    //                     to="#"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //           Black Cards
    //                   </Link>
    //                 </li>

    //               </ul>
    //             </div>
    //           </li>

    //           <li className="nav-item">
    //             <Link
    //               className="nav-link menu-link"
    //               to="#sidebarAuth"

    //               role="button"
    //               aria-expanded="false"
    //               aria-controls="sidebarAuth"
    //             >
    //               <i className="mdi mdi-bed-king"></i>{" "}
    //               <span data-key="t-authentication">Private Sale</span>
    //             </Link>
    //             <div className="collapse menu-dropdown" id="sidebarAuth">
    //               <ul className="nav nav-sm flex-column">
    //                 <li className="nav-item">
    //                   <Link
    //                     to="#"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //                 Code
    //                   </Link>
    //                 </li>
    //                 <li className="nav-item">
    //                   <Link
    //                     to="#"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //                Agencies & Companies
    //                   </Link>
    //                 </li>
    //                 <li className="nav-item">
    //                   <Link
    //                     to="#"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //            Club
    //                   </Link>
    //                 </li>
    //                 <li className="nav-item">
    //                   <Link
    //                     to="#"
    //                     state={{
    //                       tabname: "details",
    //                     }}
    //                     className="nav-link"
    //                     data-key="t-google"
    //                   >
    //          Upselling
    //                   </Link>
    //                 </li>

    //               </ul>
    //             </div>
    //           </li>
    //           <li className="nav-item">
    //             <Link
    //               className="nav-link menu-link"
    //               to="#sidebarAuth"

    //               role="button"
    //               aria-expanded="false"
    //               aria-controls="sidebarAuth"
    //             >
    //               <i className="mdi mdi-bed-king"></i>{" "}
    //               <span data-key="t-authentication">BI</span>
    //             </Link>

    //           </li>

    //         </ul>
    //       </div>
    //       {/* <!-- Sidebar --> */}
    //     </div>

    //     <div className="sidebar-background"></div>
    //   </div>
    //   <div className="vertical-overlay"></div>
    // </div>
  );
}

export default HotelSidebar;
