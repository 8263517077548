import React, { useEffect } from 'react'
import { MultiSelect } from "react-multi-select-component";
import { useState } from 'react';
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import Footer from '../../Common/Footer';
import { Link } from 'react-router-dom';
import Chainedit from './Chainedit';
import Commonheader from '../../Common/Commonheader';
import { FaArrowTurnDown } from "react-icons/fa6";
import Config from '../../Config';
import ReactPaginate from 'react-paginate';

function Chain() {

    const [paginate, setPaginate] = useState([]);
    const[page,setPage]=useState(1);
    const[pageindex,setPageindex]=useState(1);
    const[pageindexgroup,setPageindexgroup]=useState(1);
    const [listgroup,setListgroup]=useState([]);

    const fetchComments = async (currentPage) => {
      const res = await fetch(
        `${Config.apiurl}hotel-groups/list?page=${currentPage}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
  
      const data = await res.json();
      return data.data.data;
    };
  
  
    const handlePageClick = async (data) => {
      if(data===1){
        setPage(data);
        setPageindex(1);
        const commentsFormServer = await fetchComments(data);
      
        setListgroup(commentsFormServer);
      
      }
      else{
        let currentPage = data.selected + 1;
        setPageindex(currentPage);
        setPage(data);
        const commentsFormServer = await fetchComments(currentPage);
      
        setListgroup(commentsFormServer);
      }
    };


    const fetchPost = async () => {
      const response = await fetch(
         `${Config.apiurl}hotel-groups/list?page=1`,{
          method:'POST',
          headers:{
            Authorization:`Bearer ${localStorage.getItem('token')}`
          }
         }
      );
      const data = await response.json();
      console.log(data);
         if(data.status_code==="200")
         { 
            setListgroup(data.data.data);
          setPaginate(data.data.last_page);
  
        }
         else{console.log("error");}
  
   };
   function rowclick(i){
    let newval = [...listgroup];
    listgroup[i]["disp_status"] =listgroup[i]["disp_status"]? !listgroup[i]["disp_status"]:true;
    setListgroup(newval);
  }
    const [selected, setSelected] = useState([]);
    const options = [
        { label: "Rose Hotel", value: "rose-hotel" },
        { label: "Pearl Marina", value: "pearl-marina" },
      ];

      const [modal_backdrop, setmodal_backdrop] = useState(false);
      function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop);
       
      }
      const [modal_animationFlip, setmodal_animationFlip] = useState(false);
      function tog_animationFlip() {
        setmodal_animationFlip(!modal_animationFlip);
      }
    
     useEffect(() => {
         fetchPost();
        }, []);

        console.log(listgroup);

        function hotelset(name,id){
          localStorage.setItem("selected-hotel",name)
          localStorage.setItem("selected-hotel-id",id)
        }

  return (
    <>
    <Commonheader/>
    <div className="main-content">

    <div className="page-content">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    </div>
                </div>
            </div>
            <div className="row project-wrapper">
                <div className="row dashboard-top-tab-style">
                  
                    <div className="col-lg-12">
                        <div className="card" id="orderList">
                            <div className="card-header border-0">
                                <div className="row align-items-center gy-3">
                                    <div className="col-sm">
                                        <h5 className="card-title mb-0">List Of Hotel Group</h5>
                                    </div>
                                   
                                            {/* <select className="form-control" data-choices data-choices-search-false
                                                name="choices-single-default" id="idPayment">
                                                <option value="">Select Category</option>
                                                <option>3 Star</option>
                                                <option>4 Star</option>
                                                <option>5 Star</option>
                                            </select> */}
                                </div>
                            </div>

                            <div className="card-body pt-0">
                                <div>
                                    <ul className="nav nav-tabs nav-tabs-custom nav-success mb-3" role="tablist">
                                    </ul>

                                    <div className="table-responsive table-card mb-1">
                                        <table id="fixed-header orderTable" className="table table-nowrap">
                                            <thead className="text-muted table-light">
                                                <tr className="text-uppercase">
                                                    
                                                    <th className="" data-sort="id">SL</th>
                                                    <th className="chain_group_size" data-sort="customer_name">Group</th>
                                                    <th className="" data-sort="status">Status</th>
                                                    {/* <th className="" data-sort="city">Action</th> */}
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                            {listgroup.map((user, index) => {
                              return (
                                <>
                                <tr key={index} className= {user.disp_status && user.disp_status===true ? "main_row":""}>
                                  <td className="id temp_hotel_sl">{pageindexgroup===1 ? index+1: (pageindexgroup-1) * 10 + index +1}</td>
                                 
                                  <td className="temp_hotel_group" >
                                   {user.name}
                                   <span className="row_expand_hotel" onClick={(e)=>rowclick(index)}><FaArrowTurnDown/></span>
                                 </td>
                                 <td className="status">
                                      {user.hotel_status ===  false ? (
                                        <span className="badge badge-soft-danger text-uppercase">
                                          {" "}
                                          INACTIVE
                                        </span>
                                      ) : (
                                        <span className="badge badge-soft-success text-uppercase">
                                         ACTIVE
                                        </span>
                                      )}
                                    </td>
        
                                  <td className="temp_hotel_action" >
                                    <ul className="list-inline hstack gap-2 mb-1">
                                      {/* <li
                                        className="list-inline-item"
                                        data-bs-toggle="tooltip"
                                        data-bs-trigger="hover"
                                        data-bs-placement="top"
                                        title="Remove"
                                      >
                                        <Link
                                          className="text-danger d-inline-block remove-item-btn"
                                          data-bs-toggle="modal"
                                          to="#"
                                          onClick={() =>
                                            tog_animationFlip()
                                          }
                                        >
                                          <i className="ri-delete-bin-5-fill fs-16"></i>
                                        </Link>
                                      </li> */}
                                    </ul>
                                  </td>
                                 
                                </tr>
                                {user.hotels.map((hte,i)=>{
                                   return(
                                <tr className={user.disp_status && user.disp_status===true? user.hotels.length-1===i? "row_sub":'row_display':"d-none"} key={i}>
                                  <td className=""></td> 
                                <td className="temp_inside_table temp_hotel_group" style={{cursor:"pointer"}} onClick={(e)=>hotelset(hte.hotel_name,hte.hotel_id)} >{hte.hotel_name}</td>                                 
                                <td className="status"></td>  
                                <td className="temp_hotel_action"></td>
                       
                                </tr>
                                  )})} 
                                </>
                              );
                            })}
                          </tbody>
                                         
                                        </table>
                                       
                                        {paginate > 1 ?
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={paginate}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-end"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                           :""}
                                    </div>
                                  
                                </div>
                              
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>

    <Modal
        id="flipModal"
        isOpen={modal_animationFlip}
        toggle={() => {
          tog_animationFlip();
        }}
        modalClassName="flip"
        centered
        style={{ fonFamily: "IBM Plex Sans,sans-serif" }}
      >
        <ModalBody className="modal-body p-5 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#405189,secondary:#f06548"
            style={{ width: "90px", height: "90px" }}
          ></lord-icon>

          <div className="mt-4 text-center">
            <h4>You are about to delete a page ?</h4>
            <p className="text-muted fs-15 mb-4">
              Deleting your page will remove all of your information from our
              database.
            </p>
          </div>

          <button
            className="btn btn-link link-success fw-medium text-decoration-none"
            onClick={() => {
              tog_animationFlip();
            }}
          >
            {" "}
            <i className="ri-close-line me-1 align-middle"></i>
            Close{" "}
          </button>
          <Button color="danger"> Yes, Delete It </Button>
        </ModalBody>
      </Modal>
   

      {modal_backdrop ? (
          <Chainedit
           
            modal_backdrop={modal_backdrop}
            tog_backdrop={tog_backdrop}
          selected={selected}
          setSelected={setSelected}
            options={options}
           
          />
        ) : (
          ""
        )}
</div>
</>
  )
}

export default Chain