import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../Common/Footer";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import Commonheader from "../../Common/Commonheader";
import Alerts from "../../Alerts";
import Config from "../../Config";
import { useEffect } from "react";
import axios from "axios";
import Loading from "../../Loading";
import Basicsettingsheader from "../../Common/Basicsettingsheader";
import Commonimagepath from "../../Commonimagepath";
import { useAuthHandling } from "../../Common/useAuthHandling";

function Bedtypeedit() {
    const location = useLocation();
    let navigate = useNavigate();
    const { isAuthorized, handleUnauthorized } = useAuthHandling();

    const [ids, setId] = useState(location.state.type_id);

        const [active, setActive] = useState("en");
        const[be_language,setBe_language]=useState([])
        const language = localStorage.getItem("be_language");
        const [alert, setAlert] = useState(false);
        const [alertmessage, setAlertmessage] = useState("");
        const [alerttype, setAlerttype] = useState("");

        const [formerror1, setError1] = useState({});
        const [formerror4, setError4] = useState({});
        const [formerror5, setError5] = useState({});
        const [open, setOpen] = useState(false);
        const [image,setImage]=useState("");
        const [status,setStatus]=useState("")
        const [bedtype,setBedtype]=useState([]);
     
        const[langdata,setLangdata]=useState('')
        const[countrydata,setCountrydata]=useState('')
        const[be_languagefull,setBe_languagefull]=useState([])
        const [multi,setMulti]=useState([]);

     
        const getlanguage = async (e) => {
    
          const res = await fetch(`${Config.apiurl}optional-languages`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
    
            },
          });
          const data = await res.json();
          if (data.status_code === "200" ) {
            setBe_language(data.data);

          } 
          else if(data.reauth===true){
            handleUnauthorized();
            getlanguage();
          }
          else {
            console.log("error");
          }
        
        
        };
        const getfulllanguage = async (e) => {

          const res = await fetch(`${Config.apiurl}booking-engine-languages`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
    
            },
          });
          const data = await res.json();
          if (data.status_code === "200" ) {
            setBe_languagefull(data.data);
          }
          else if(data.reauth===true){
            handleUnauthorized();
            getfulllanguage();
          }
          else {
            console.log("error");

          }
        
        
        };
        
        const handleInput = (e) => {
          let name = e.target.name;
          let value = e.target.value;
          setBedtype({ ...bedtype, [name]: value });
        };
       
     
        
      const [selectedMulti, setselectedMulti] = useState([]);
      function handleMulti(selectedMulti) {
        setselectedMulti(selectedMulti);
      }
     
      const [selectedMulti1, setselectedMulti1] = useState([]);
      function handleMulti1(selectedMulti1) {
        setselectedMulti1(selectedMulti1);
      }
      const validate = (values) => {
        const errors = {};
        if (!values.title) {
          errors.title = "Name is required!";
        }
            
        if (!values.bedsize) {
          errors.bedsize = "Bed size is required!";
        }
        return errors;
      };
      const validate1 = (values) => {
        console.log(values);
        const errors = {};
        if (!values) {
          if(!bedtype.icon ||!bedtype.icon===null)
          errors.image = "Image is required!";
        }
        return errors;
      };
      const validatestatus = (values) => {
        const errors = {};
        // console.log(values);
        // if (values===true || values===false) {
        //  console.log("");
        // }
        // else{
        //     errors.status = "Status is required!";
        // }
        if (!status) {
          errors.status = "Status is required!";
        }
        return errors;
      };
      
      const PostData = async (e) => {
        e.preventDefault();
       
        const ress=validate(bedtype)
        const ress1=validate1(image);
        const ressstatus=validatestatus(status)

        setError1(ress1);
        setError4(ress);
        setError5(ressstatus);
    
        if (
          !ressstatus.status &&!ress.title && !ress1.image && !ress.bedsize
        ) {
    
    
          const formData = new FormData();
          formData.append("en_title", bedtype.title);
          formData.append("icon_image",image);
          formData.append("bedsize",bedtype.bedsize);

          formData.append("is_active", status);
                      for (let i = 0; i < multi.length; i++) {
                        if (multi[i].name) {
                          if (multi[i].code !== "en") {
                            
                          
                            formData.append(`multi_lang[${i}][lang_code]`, multi[i].code);
                            formData.append(`multi_lang[${i}][title]`, multi[i].name);
                           
                          }
                        }
                      }               
                      axios.post(`${Config.apiurl}bedtype/update/${ids}`, formData, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                       "Content-type": "application/json; charset=UTF-8",
                  },
                })
                .then((res) => {
    
              if (res.status_code === "200" ) {
                setAlert(true);
                setAlertmessage(res.message);
                setOpen(true);
                setAlerttype("success");
                Reset();
                setTimeout(() => navigate("/basic_settings/bedtype-list"), 3000);               
              } 
              else if(res.reauth===true){
                handleUnauthorized();
                PostData();
              }
              else {
                setAlert(true);
                setAlertmessage(res.message);
                setOpen(true);
                setAlerttype("error");

              }
               
        }
                )}
      };
    
      function Reset(){
        setBedtype("");
        setLangdata("");
        setCountrydata("");
        setselectedMulti([]);
        setselectedMulti1([]);
        setStatus("");
      }
    
      useEffect(() => {
        const getinfo = async (e) => {
            const res = await fetch(`${Config.apiurl}bedtype/${ids}`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            });
            const data = await res.json();
            if (data.status_code === "200" ) {
              setBedtype(data.data.bedtype);
              setMulti(data.data.multi_lang);
              setStatus(data.data.bedtype.status);
  
            } 
            else if(data.reauth===true){
              handleUnauthorized();
              getinfo();
            }
            else {
              console.log("error");
            }
             
            
          };
          getinfo();
          getlanguage();
          getfulllanguage();
    },[language,ids]);

      const onFileChange = (e) => {
        console.log(e);
        setImage(  e.target.files[0] );
      };

return (
    <div id="layout-wrapper">
  <Basicsettingsheader/>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
            
            </div>
            {alert ? (
              <Alerts type={alerttype} message={alertmessage} open={open} />
            ) : (
              ""
            )}
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="card-header align-items-center d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">
                     Update Bedtype
                      </h4>
                      <div className="flex-shrink-0">
                        <Link
                          type="button"
                          className="btn db-other-button"
                          to="/basic_settings/bedtype-list"
                          style={{ marginRight: "5px" }}
                        >
                          Bedtype
                        </Link>
                        {/* <button
                          type="button"
                          className="btn db-cancel-button"
                          data-bs-toggle="offcanvas"
                          to=""
                          onClick={Reset}
                        >
                          Cancel
                        </button> */}
                        <button
                          type="button"
                          className="btn db-save-button"
                          data-bs-toggle="modal"                       
                          onClick={PostData}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                   
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-translation-style">
                                <ul>
                                <li
                                    className={active === "en" ? "active" : ""}
                                  >
                                    <button
                                         type="button"
                                      onClick={() => setActive("en")}
                                      className={
                                        active === "en" ? "active" : ""
                                      }
                                      style={{border:"none",background:"transparent"}}
                                    >
                                      EN
                                    </button>
                                  </li>
                                  <li
                                    className={
                                      active === "trans" ? "active" : ""
                                    }
                                  >
                                    <button   
                                                 type="button"
                                      onClick={() => setActive("trans")}
                                      className={
                                        active === "trans" ? "active" : ""
                                      }
                                      style={{border:"none",background:"transparent"}}
                                    >
                                      Translations
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>                 

                              {bedtype!==undefined ?


                            <>
                {active==="en"?
                <>
                                  <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                  Name
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  value={bedtype.title}
                                  name="title"
                                  onChange={handleInput}
                                />
                                 <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror4.title}
                                </label>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                  Icon Image
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  placeholder=""
                                 
                                  onChange={onFileChange}/>
                                 <label style={{ color: "red" }}>
                                    {formerror1.image}
                                </label>
                                 <img
                                        src={`${Commonimagepath.apiurl}${bedtype.icon}`}
                                        alt=""
                                      /> 
                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                  Bed size
                                  <span className="form-validation-required">
                                    *
                                  </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Bed size in Square meter"
                                  value={bedtype.bedsize}
                                  name="bedsize"
                                  onChange={handleInput}
                                />
                                 <label
                                  style={{ color: "red", marginTop: "3px" }}
                                >
                                  {formerror4.bedsize}
                                </label>
                              </div>
                            </div>
                            <div className="row g-3">
                            <div class="col-lg-4">
                              <div className="input-group">
                            <label className="input-group-text input-joint-color"
                                id="inputGroup-sizing-default">
                                      Status{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <select
                                      id="ForminputState"
                                      className="form-select"
                                      value={status}
                                      onChange={(e)=>setStatus(e.target.value)}
                                     
                                    >
                                      <option selected>Choose...</option>
                                      <option {...(status === true
                                    ? "selected"
                                    : "")}
                                  value={true}  >Active</option>
                                      <option {...(status === false
                                    ? "selected"
                                    : "")}
                                  value={false} >Inactive</option>
                                    </select>
                                    <label style={{ color: "red" }}>
                                      {formerror5.status}
                                    </label>
                                  </div>
                                </div>
                                </div>
                            </>:
                                <>
                                <div className="col-lg-4" style={{ marginTop: "20px" }}>
                                  <div className="input-group">
                                    <label className="input-group-text input-joint-color" id="inputGroup-sizing-default" style={{ width: "130px" }}>
                                      Choose Language:
                                    </label>
                                    <div className="col-md-7">
                                      <MultiSelect
                                        options={be_language}
                                        value={multi}
                                        onChange={setMulti}
                                        labelledBy="Select"
                                        selectionType="counter"
                                        closeOnChangedValue={true}
                                        aria-expanded="true"
                                        hasSelectAll={false}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="appendmore"></div>
                                {multi.length > 0 &&
                                  multi.map((element, index) => (
                                    <div
                                      key={index}
                                      className="col-md-12 translation-fill-section"
                                      id={`lang_${element.value}`}
                                      data-value="1"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <div className="translation-selected-lang">
                                        <p>
                                          <span className="translation-selected-lang-head">
                                            {element.code}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="row">
                                        <div className="col-lg-4">
                                          <div className="input-group">
                                            <label className="input-group-text input-joint-color" id="inputGroup-sizing-default">
                                              Name
                                              <span className="form-validation-required">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder=""
                                              value={element.name}
                                              onChange={(e) =>
                                                setMulti((prevMulti) =>
                                                  prevMulti.map((item, i) =>
                                                    i === index ? { ...item, name: e.target.value } : item
                                                  )
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </>
                              }
                                </>:<Loading/>}
                          </div>

                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Bedtypeedit