import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

//import images
import Config from "../Commoncomponents/Config";
import { MultiSelect } from "react-multi-select-component";
import { UserContext } from "../Commoncomponents/Contexts/UserContext";
import { useAuthHandling } from "../Commoncomponents/Common/useAuthHandling";
import CustomOption from "./Customoption";

const SearchOption = ({ setShowhotel, showhotel }) => {
  let navigate = useNavigate();
  const { isAuthorized, handleUnauthorized } = useAuthHandling();

  const [value, setValue] = useState(localStorage.getItem("selected-hotel"));
  const { updateHotel_select, hotel_select } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [hotel, setHotel] = useState([]);
  const [hotelid, setHotelid] = useState([]);
  const [hoteldetail, setHoteldetail] = useState([]);
  const [selectedHotels, setSelectedHotels] = useState(
    hotel_select ? hotel_select : []
  );
  const [allHotels, setAllHotels] = useState([]);
  const [prevSelectedOptions, setPrevSelectedOptions] = useState([]);
  // const { tokens, handleUnauthorized,removeToken } = Decodetoken();

  // useEffect(() => {
  //   handleUnauthorized();
  // }, [handleUnauthorized]);

  const onChangeData = async (value) => {
    setValue(value);
    const response = await fetch(`${Config.apiurl}hotels/autocomplete`, {
      method: "POST",
      body: JSON.stringify({
        hotel: value,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await response.json();
    if (data.status_code === "200") {
      setHotel(data.data);
    }
  };

  const fetchPost = async () => {
    const response = await fetch(`${Config.apiurl}hotels/autocomplete`, {
      method: "POST",
      body: JSON.stringify({
        hotel: value,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      if (data.status_code === "200") {
        setHotel(data.data);
      } else if (data.reauth === true) {
        handleUnauthorized();
        fetchPost();
      }
    }
    // else if(response.status === 401){
    //   handleUnauthorized();
    //   fetchPost();
    // }
    else {
      console.log("error");
    }
  };

console.log(hotel);

  function handlehotels(name, id) {
    setValue(name);
    setHotelid(id);
    setShowhotel(true);
    localStorage.setItem("selected-hotel", name);
    localStorage.setItem("selected-hotel-id", id);
  }
  function remove() {
    localStorage.removeItem("selected-hotel");
    localStorage.removeItem("selected-hotel-id");
    setShowhotel(false);
    setValue("");
    navigate("/dashboard");
  }
  useEffect(() => {
    fetchPost();
  }, []);

  const handleInputClick = async (value) => {
    setValue(value);
    setShow(true);
    const response = await fetch(`${Config.apiurl}hotels/autocomplete`, {
      method: "POST",
      body: JSON.stringify({
        hotel: value,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await response.json();
    if (data.status_code === "200") {
      setHotel(data.data);
    }
  };

  const handleHotelClick = (hotelId, hotelName) => {
    const isSelected = selectedHotels.some((hotel) => hotel.id === hotelId);

    if (isSelected) {
      setSelectedHotels((prevSelected) =>
        prevSelected.filter((hotel) => hotel.id !== hotelId)
      );
    } else {
      setSelectedHotels((prevSelected) => [
        ...prevSelected,
        { id: hotelId, name: hotelName },
      ]);
    }
  };

  useEffect(() => {
    const updatedAllHotels = [];
    // updatedAllHotels.push({
    //   label: "Select all Group Hotel",
    //   value: "groupall",
    //   style: { marginLeft: '0px',color:"black" }
    // });
    // updatedAllHotels.push({
    //   label: "Select all Individual Hotel",
    //   value: "individual",
    //   style: { marginLeft: '0px',color:"black" }
    // });

    for (let i = 0; i < hotel.length; i++) {
      if (hotel[i].group_id) {
        // If the entry is a group, add the group name first
        updatedAllHotels.push({
          label: hotel[i].name,
          value: hotel[i].group_id,
          isGroup: true,

          style: { marginLeft: "0px", color: "#0480ad" },
        });

        // Then add individual hotels under the group
        for (let j = 0; j < hotel[i].hotels.length; j++) {
          updatedAllHotels.push({
            label: hotel[i].hotels[j].hotel_name,
            value: hotel[i].hotels[j].hotel_id,
            isGroupChild: true,
            parentGroupValue: hotel[i].group_id,
            style: {
              marginLeft: "15px",
              color: "#000",
              fontWeight: "400",
              fontSize: "13px",
            },
          });
        }
      } else {
        // If it's an individual hotel, add it directly
        updatedAllHotels.push({
          label: hotel[i].hotel_name,
          value: hotel[i].hotel_id,
          style: { marginLeft: "0px", color: "#066906" },
        });
      }
    }

    setAllHotels(updatedAllHotels);
  }, [hotel.length]);

  function onselectHotels(val) {
    let selectedval = [...selectedHotels];

    //delete from list
    // const deselectedOptions = prevSelectedOptions.filter((prevOption) => !val.includes(prevOption));
    // deselectedOptions.forEach((deselectedOption) => {
    //   if (deselectedOption.isGroup) {
    //     const childrenToRemove = allHotels.filter((hotel) => hotel.parentGroupValue === deselectedOption.value);
    //     childrenToRemove.forEach((child) => {
    //       const childIndexToRemove = selectedval.findIndex((option) => option.value === child.value);
    //       if (childIndexToRemove !== -1) {
    //         selectedval.splice(childIndexToRemove, 1);
    //       }
    //     });
    //     const parentIndexToRemove = selectedval.findIndex((option) => option.value === deselectedOption.value);
    //     selectedval.splice(parentIndexToRemove, 1);
    //   }
    //   else{
    //     const parentIndexToRemove = selectedval.findIndex((option) => option.value === deselectedOption.value);
    //     selectedval.splice(parentIndexToRemove, 1);

    //   }
    // });

    const deselectedOptions = prevSelectedOptions.filter(
      (prevOption) => !val.includes(prevOption)
    );
    deselectedOptions.forEach((deselectedOption) => {
      if (deselectedOption.isGroup) {
        const childrenToRemove = allHotels.filter(
          (hotel) => hotel.parentGroupValue === deselectedOption.value
        );
        childrenToRemove.forEach((child) => {
          const childIndexToRemove = selectedval.findIndex(
            (option) => option.value === child.value
          );
          if (childIndexToRemove !== -1) {
            selectedval.splice(childIndexToRemove, 1);
          }
        });

        // Check if the parentGroupValue of the parent is not present in the selectedval
        const parentGroupValue = deselectedOption.value;

        const isParentGroupPresent = selectedval.some(
          (option) => option.parentGroupValue === parentGroupValue
        );
        if (!isParentGroupPresent) {
          const parentIndexToRemove = selectedval.findIndex(
            (option) => option.value === parentGroupValue
          );

          if (parentIndexToRemove !== -1) {
            selectedval.splice(parentIndexToRemove, 1);
          }
        }
      } else {
        //deselect select allgroup hotel
        if (deselectedOption.value === "groupall") {
          const groupparent = allHotels.filter(
            (hotel) => hotel.isGroup === true || hotel.isGroupChild === true
          );
          if (groupparent) {
            groupparent.forEach((child) => {
              const childIndexToRemove = selectedval.findIndex(
                (option) => option.value === child.value
              );
              if (childIndexToRemove !== -1) {
                selectedval.splice(childIndexToRemove, 1);
              }
            });
          }
        }
        //deselect select all individual hotel
        if (deselectedOption.value === "individual") {
          const groupChildrens = allHotels.filter(
            (hotel) =>
              !(
                hotel.isGroupChild === true ||
                hotel.isGroup === true ||
                hotel.value === "groupall"
              )
          );
          if (groupChildrens) {
            groupChildrens.forEach((child) => {
              const childIndexToRemove = selectedval.findIndex(
                (option) => option.value === child.value
              );
              if (childIndexToRemove !== -1) {
                selectedval.splice(childIndexToRemove, 1);
              }
            });
          }
        }

        const parentIndexToRemove = selectedval.findIndex(
          (option) => option.value === deselectedOption.value
        );
        if (parentIndexToRemove !== -1) {
          selectedval.splice(parentIndexToRemove, 1);
        }

        // Check if the parentGroupValue of the parent is not present in the selectedval
        if (deselectedOption.isGroupChild) {
          const parentGroupValue = deselectedOption.parentGroupValue;

          const isParentGroupPresent = selectedval.some(
            (option) => option.parentGroupValue === parentGroupValue
          );
          if (!isParentGroupPresent) {
            const parentIndexToRemove = selectedval.findIndex(
              (option) => option.value === parentGroupValue
            );
            const parentIndexToRemoveval = val.findIndex(
              (option) => option.value === parentGroupValue
            );

            if (parentIndexToRemove !== -1) {
              selectedval.splice(parentIndexToRemove, 1);
            }

            if (parentIndexToRemoveval !== -1) {
              val.splice(parentIndexToRemoveval, 1);
            }
          }
        }
      }
    });

    //add to list
    if (val.length > 0) {
      val.forEach((selectedOption) => {
        const existingOption = selectedval.find(
          (option) => option.value === selectedOption.value
        );
        if (!existingOption) {
          // select all group
          if (selectedOption.value === "groupall") {
            const groupparent = allHotels.filter(
              (hotel) => hotel.isGroup === true
            );
            const groupChildren = allHotels.filter(
              (hotel) => hotel.isGroupChild === true
            );
            selectedval.push(...groupparent);

            selectedval.push(...groupChildren);
            selectedval.push(selectedOption);
          }
          //select all group end

          // select all individual
          if (selectedOption.value === "individual") {
            const groupChildrens = allHotels.filter(
              (hotel) =>
                !(
                  hotel.isGroupChild === true ||
                  hotel.isGroup === true ||
                  hotel.value === "groupall"
                )
            );
            selectedval.push(...groupChildrens);
            selectedval.push(selectedOption);
          }
          //select all individual end
          if (selectedOption.isGroup) {
            const groupChildren = allHotels.filter(
              (hotel) => hotel.parentGroupValue === selectedOption.value
            );
            selectedval.push(...groupChildren);
          }

          const existingOption1 = selectedHotels.find(
            (option) => option.value === selectedOption.value
          );
          const existingOption2 = selectedval.find(
            (option) => option.value === selectedOption.value
          );

          if (!existingOption1 && !existingOption2) {
            selectedval.push(selectedOption);
          }

          //add group
          const group = allHotels.filter(
            (hotel) =>
              hotel.parentGroupValue === selectedOption.parentGroupValue
          );
          if (group) {
            const allElementsSelected = group.every((element) =>
              selectedval.some(
                (selectedHotel) => selectedHotel.value === element.value
              )
            );

            if (allElementsSelected) {
              const grouphotel = allHotels.filter(
                (hotel) => hotel.value === selectedOption.parentGroupValue
              )[0];
              if (grouphotel) {
                const isParentGroupPresent = selectedval.some(
                  (option) => option.value === grouphotel.value
                );
                if (!isParentGroupPresent) {
                  selectedval.push(grouphotel);
                }
              }
            }
          }
        }
      });
    }

    setSelectedHotels(selectedval);
    setPrevSelectedOptions(selectedval);

    updateHotel_select(selectedval);

    // if(val.length>0){
    //   for(let i=0;i<val.length;i++){
    //     if(val[i].isGroup){
    //       for(let j=0;j<allHotels.length;j++){
    //         if(val[i].value===allHotels[j].parentGroupValue){
    //           if(!selectedval.includes(allHotels[j].value)){
    //             selectedval.push({
    //               isGroupChild:allHotels[j].isGroupChild,
    //               label:allHotels[j].label,
    //               value:allHotels[j].value,
    //               parentGroupValue:allHotels[j].parentGroupValue
    //             })
    //           }
    //         }
    //       }
    //       selectedval.push({
    //         isGroup:val[i].isGroup,
    //         label:val[i].label,
    //         value:val[i].value,
    //       })
    //     }
    //     else{
    //       for(let j=0;j<allHotels.length;j++){
    //         if(val[i].value===allHotels[j].value){
    //           if(!selectedval.includes(allHotels[j].value)){
    //             selectedval.push({
    //               label:allHotels[j].label,
    //               value:allHotels[j].value,
    //             })
    //           }
    //         }
    //       }
    //     }
    //   }

    // }
    // setSelectedHotels(selectedval);
    // updateHotel_select(selectedval);

    if (val.length === 0) {
      navigate("/dashboard");
      // window.location.href="/dashboard"
    } else {
      // const url=window.location.href;
      const url = window.location.pathname;

      const parts = url.split("/");
      let path = parts[1];
      if (
        path === "dashboard" ||
        path === "modules" ||
        path === "languages" ||
        path === "booking_engine-languages" ||
        path === "role_listing" ||
        path === "role" ||
        path === "editrole" ||
        path === "admin_user_listing" ||
        path === "admin_user" ||
        path === "admin_user-edit" ||
        path === "pages" ||
        path === "page-content-listing" ||
        path === "page-content-edit" ||
        path === "page-content" ||
        path === "basic-settings" ||
        path === "hotel-group" ||
        path === "loyaltysettings"
      ) {
        navigate("/hotel-dashboard");
      }
    }
  }

  useEffect(() => {
    setPrevSelectedOptions(selectedHotels);
  }, [selectedHotels]);

  const customStyles = {
    option: (provided, state) => {
      const isGroup = state.data.isGroup;

      return {
        ...provided,
        marginLeft: isGroup ? "1rem" : "2rem",
        color: isGroup ? "black" : "white",
        backgroundColor: state.isFocused ? "#4CAF50" : "white",
      };
    },
  };

  const ItemRenderer = ({ checked, option, onClick }) => (
    <div
      onClick={() => onClick(option)}
      style={{
        display: "flex",
        alignItems: "center",
        marginLeft: option.style?.marginLeft,
      }}
    >
      <input type="checkbox" checked={checked} readOnly />
      <span
        style={{
          marginLeft: "5px",
          color: option.style?.color,
          fontWeight: option.style?.fontWeight,
          fontSize: option.style?.fontSize,
        }}
      >
        {option.label}
      </span>
    </div>
  );
  // useEffect(()=>{
  //   if(selectedHotels.length===0){
  //     // navigate("/dashboard");
  //     window.location.href = "/dashboard";
  //   }
  // },[])

  useEffect(() => {
    localStorage.setItem("myData", JSON.stringify(selectedHotels));
  }, [selectedHotels]);

  useEffect(() => {
    localStorage.setItem("allhotel", JSON.stringify(allHotels));
  }, [allHotels]);

  const multiSelectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        multiSelectRef?.current &&
        !multiSelectRef?.current?.contains(event.target)
      ) {
        multiSelectRef.current.closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    // <React.Fragment>
    <div className="app-search d-none d-md-block hotel-search-header">
      <div className="position-relative">
        <MultiSelect
          options={allHotels}
          value={selectedHotels}
          onChange={onselectHotels}
          labelledBy="Choose hotel..."
          closeOnSelect={true}
          hasSelectAll={true}
          className="choose_hotel"
          components={{
            Option: CustomOption, // Use the custom option component
          }}
          ItemRenderer={ItemRenderer}
          ref={multiSelectRef}
        />
      </div>
    </div>
    // </React.Fragment>
  );
};

export default SearchOption;
